@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@font-face {
    font-family: HKGroteskPro;
    font-weight: 400;
    src: url(../fonts/HKGroteskPro/HKGroteskPro-Regular.woff2) format("woff2"), url(../fonts/HKGroteskPro/HKGroteskPro-Regular.woff) format("woff");
}

@font-face {
    font-family: HKGroteskPro;
    font-weight: 600;
    src: url(../fonts/HKGroteskPro/HKGroteskPro-Medium.woff2) format("woff2"), url(../fonts/HKGroteskPro/HKGroteskPro-Medium.woff) format("woff");
}

body {
    margin: 0;
    font-family: "HKGroteskPro",sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    text-align: left;
    background-color: #fff;
}

.LandingTheme .display-1, .LandingTheme .display-2, .LandingTheme .display-3, .LandingTheme .display-4 {
    font-family: "HKGroteskPro",sans-serif !important;
    letter-spacing: -.02em;
}

.LandingTheme .display-1 {
    font-size: 2.8125rem !important;
}

.LandingTheme .display-2 {
    font-size: 2.8125rem !important;
}

.LandingTheme .display-3 {
    font-size: 2.34375rem !important;
}

.LandingTheme .display-4 {
    font-size: 2.10938rem !important;
}

@media (min-width: 768px) {
    .LandingTheme .display-1 {
        font-size: 4.80469rem !important;
    }
    .LandingTheme .display-2 {
        font-size: 4.21875rem !important;
    }
    .LandingTheme .display-3 {
        font-size: 3.75rem !important;
    }
    .LandingTheme .display-4 {
        font-size: 2.8125rem !important;
    }
}

.LandingTheme .h1, .LandingTheme .h2, .LandingTheme .h3, .LandingTheme .h4, .LandingTheme .h5, .LandingTheme .h6, .LandingTheme h1, .LandingTheme h2, .LandingTheme h3, .LandingTheme h4, .LandingTheme h5, .LandingTheme h6 {
    font-family: "HKGroteskPro",sans-serif !important;
}

.LandingTheme .h1, .LandingTheme h1 {
    letter-spacing: -.02em;
}

.LandingTheme .h2, .LandingTheme .h3, .LandingTheme .h4, .LandingTheme h2, .LandingTheme h3, .LandingTheme h4 {
    letter-spacing: -.01em;
}

.LandingTheme #text-aa-serif {
    color: rgba(255, 255, 255, 0.2) !important;
}

.LandingTheme #text-aa-sans-serif {
    color: #fff !important;
}

.LandingTheme :root {
    --blue:#007bff;
    --indigo:#6610f2;
    --purple:#6f42c1;
    --pink:#e83e8c;
    --red:#dc3545;
    --orange:#fd7e14;
    --yellow:#ffc107;
    --green:#28a745;
    --teal:#20c997;
    --cyan:#17a2b8;
    --white:#FFF;
    --gray:#575a7b;
    --gray-dark:#342e6c;
    --primary:#6658ea;
    --secondary:#575a7b;
    --success:#2cd4a2;
    --info:#17a2b8;
    --warning:#f5c070;
    --danger:#f9655b;
    --light:#f3f8ff;
    --dark:#1b1642;
    --primary-dark:#564caf;
    --primary-light:#687ded;
    --breakpoint-xs:0;
    --breakpoint-sm:576px;
    --breakpoint-md:768px;
    --breakpoint-lg:992px;
    --breakpoint-xl:1200px;
    --font-family-sans-serif:"HKGroteskPro",sans-serif;
    --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

.LandingTheme *, .LandingTheme ::after, .LandingTheme ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.LandingTheme html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(27, 22, 66, 0);
}

.LandingTheme article, .LandingTheme aside, .LandingTheme figcaption, .LandingTheme figure, .LandingTheme footer, .LandingTheme header, .LandingTheme hgroup, .LandingTheme main, .LandingTheme nav, .LandingTheme section {
    display: block;
}

.LandingTheme [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

.LandingTheme hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

.LandingTheme h1, .LandingTheme h2, .LandingTheme h3, .LandingTheme h4, .LandingTheme h5, .LandingTheme h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.LandingTheme p {
    margin-top: 0;
    margin-bottom: 2rem;
}

.LandingTheme abbr[data-original-title], .LandingTheme abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

.LandingTheme address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

.LandingTheme dl, .LandingTheme ol, .LandingTheme ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.LandingTheme ol ol, .LandingTheme ol ul, .LandingTheme ul ol, .LandingTheme ul ul {
    margin-bottom: 0;
}

.LandingTheme dt {
    font-weight: 500;
}

.LandingTheme dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

.LandingTheme blockquote {
    margin: 0 0 1rem;
}

.LandingTheme b, .LandingTheme strong {
    font-weight: 700;
}

.LandingTheme small {
    font-size: 80%;
}

.LandingTheme sub, .LandingTheme sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.LandingTheme sub {
    bottom: -.25em;
}

.LandingTheme sup {
    top: -.5em;
}

.LandingTheme a {
    color: #6658ea;
    text-decoration: none;
    background-color: transparent;
}

.LandingTheme a:hover {
    color: #5546e8;
    text-decoration: none;
}

.LandingTheme a:not([href]) {
    color: inherit;
    text-decoration: none;
}

.LandingTheme a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

.LandingTheme code, .LandingTheme kbd, .LandingTheme pre, .LandingTheme samp {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-size: 1em;
}

.LandingTheme pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

.LandingTheme figure {
    margin: 0 0 1rem;
}

.LandingTheme img {
    vertical-align: middle;
    border-style: none;
}

.LandingTheme svg {
    overflow: hidden;
    vertical-align: middle;
}

.LandingTheme table {
    border-collapse: collapse;
}

.LandingTheme caption {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #575a7b;
    text-align: left;
    caption-side: bottom;
}

.LandingTheme th {
    text-align: inherit;
}

.LandingTheme label {
    display: inline-block;
    margin-bottom: .5rem;
}

.LandingTheme button {
    border-radius: 0;
}

.LandingTheme button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

.LandingTheme button, .LandingTheme input, .LandingTheme optgroup, .LandingTheme select, .LandingTheme textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.LandingTheme button, .LandingTheme input {
    overflow: visible;
}

.LandingTheme button, .LandingTheme select {
    text-transform: none;
}

.LandingTheme select {
    word-wrap: normal;
}

.LandingTheme [type=button], .LandingTheme [type=reset], .LandingTheme [type=submit], .LandingTheme button {
    -webkit-appearance: button;
}

.LandingTheme [type=button]:not(:disabled), .LandingTheme [type=reset]:not(:disabled), .LandingTheme [type=submit]:not(:disabled), .LandingTheme button:not(:disabled) {
    cursor: pointer;
}

.LandingTheme [type=button]::-moz-focus-inner, .LandingTheme [type=reset]::-moz-focus-inner, .LandingTheme [type=submit]::-moz-focus-inner, .LandingTheme button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.LandingTheme input[type=checkbox], .LandingTheme input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

.LandingTheme input[type=date], .LandingTheme input[type=datetime-local], .LandingTheme input[type=month], .LandingTheme input[type=time] {
    -webkit-appearance: listbox;
}

.LandingTheme textarea {
    overflow: auto;
    resize: vertical;
}

.LandingTheme fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

.LandingTheme legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.LandingTheme progress {
    vertical-align: baseline;
}

.LandingTheme [type=number]::-webkit-inner-spin-button, .LandingTheme [type=number]::-webkit-outer-spin-button {
    height: auto;
}

.LandingTheme [type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

.LandingTheme [type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.LandingTheme ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

.LandingTheme output {
    display: inline-block;
}

.LandingTheme summary {
    display: list-item;
    cursor: pointer;
}

.LandingTheme template {
    display: none;
}

.LandingTheme [hidden] {
    display: none !important;
}

.LandingTheme .h1, .LandingTheme .h2, .LandingTheme .h3, .LandingTheme .h4, .LandingTheme .h5, .LandingTheme .h6, .LandingTheme h1, .LandingTheme h2, .LandingTheme h3, .LandingTheme h4, .LandingTheme h5, .LandingTheme h6 {
    margin-bottom: .5rem;
    font-family: "DM Serif Display",serif;
    font-weight: 500;
    line-height: 1.2;
}

.LandingTheme .h1, .LandingTheme h1 {
    font-size: 2.25rem;
}

.LandingTheme .h2, .LandingTheme h2 {
    font-size: 1.75rem;
}

.LandingTheme .h3, .LandingTheme h3 {
    font-size: 1.25rem;
}

.LandingTheme .h4, .LandingTheme h4 {
    font-size: 1.125rem;
}

.LandingTheme .h5, .LandingTheme h5 {
    font-size: 1rem;
}

.LandingTheme .h6, .LandingTheme h6 {
    font-size: .875rem;
}

.LandingTheme .lead {
    font-size: 1.375rem;
    font-weight: 400;
}

.LandingTheme .display-1 {
    font-size: 5.125rem;
    font-weight: 500;
    line-height: 1.08;
}

.LandingTheme .display-2 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 1.08;
}

.LandingTheme .display-3 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.08;
}

.LandingTheme .display-4 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.08;
}

.LandingTheme hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #eaf2fe;
}

.LandingTheme .small, .LandingTheme small {
    font-size: 88%;
    font-weight: 400;
}

.LandingTheme .mark, .LandingTheme mark {
    padding: .2em;
    background-color: #fcf8e3;
}

.LandingTheme .list-unstyled {
    padding-left: 0;
    list-style: none;
}

.LandingTheme .list-inline {
    padding-left: 0;
    list-style: none;
}

.LandingTheme .list-inline-item {
    display: inline-block;
}

.LandingTheme .list-inline-item:not(:last-child) {
    margin-right: .5rem;
}

.LandingTheme .initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.LandingTheme .blockquote {
    margin-bottom: 1rem;
    font-size: 1.40625rem;
}

.LandingTheme .blockquote-footer {
    display: block;
    font-size: 88%;
    color: #575a7b;
}

.LandingTheme .blockquote-footer::before {
    content: "\2014\00A0";
}

.LandingTheme .img-fluid {
    max-width: 100%;
    height: auto;
}

.LandingTheme .img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dce0f3;
    border-radius: 1rem;
    -webkit-box-shadow: 0 1px 2px rgba(27, 22, 66, 0.075);
    box-shadow: 0 1px 2px rgba(27, 22, 66, 0.075);
    max-width: 100%;
    height: auto;
}

.LandingTheme .figure {
    display: inline-block;
}

.LandingTheme .figure-img {
    margin-bottom: .5rem;
    line-height: 1;
}

.LandingTheme .figure-caption {
    font-size: 90%;
    color: #575a7b;
}

.LandingTheme code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

.LandingTheme a > code {
    color: inherit;
}

.LandingTheme kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #2b265a;
    border-radius: .5rem;
    -webkit-box-shadow: inset 0 -0.1rem 0 rgba(27, 22, 66, 0.25);
    box-shadow: inset 0 -0.1rem 0 rgba(27, 22, 66, 0.25);
}

.LandingTheme kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme pre {
    display: block;
    font-size: 87.5%;
    color: #2b265a;
}

.LandingTheme pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.LandingTheme .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.LandingTheme .container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .LandingTheme .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .LandingTheme .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .LandingTheme .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .container {
        max-width: 1140px;
    }
}

.LandingTheme .container-fluid, .LandingTheme .container-lg, .LandingTheme .container-md, .LandingTheme .container-sm, .LandingTheme .container-xl {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .LandingTheme .container, .LandingTheme .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .LandingTheme .container, .LandingTheme .container-md, .LandingTheme .container-sm {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .LandingTheme .container, .LandingTheme .container-lg, .LandingTheme .container-md, .LandingTheme .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .container, .LandingTheme .container-lg, .LandingTheme .container-md, .LandingTheme .container-sm, .LandingTheme .container-xl {
        max-width: 1140px;
    }
}

.LandingTheme .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
}

.LandingTheme .no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.LandingTheme .no-gutters > .col, .LandingTheme .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.LandingTheme .col, .LandingTheme .col-1, .LandingTheme .col-10, .LandingTheme .col-11, .LandingTheme .col-12, .LandingTheme .col-2, .LandingTheme .col-3, .LandingTheme .col-4, .LandingTheme .col-5, .LandingTheme .col-6, .LandingTheme .col-7, .LandingTheme .col-8, .LandingTheme .col-9, .LandingTheme .col-auto, .LandingTheme .col-lg, .LandingTheme .col-lg-1, .LandingTheme .col-lg-10, .LandingTheme .col-lg-11, .LandingTheme .col-lg-12, .LandingTheme .col-lg-2, .LandingTheme .col-lg-3, .LandingTheme .col-lg-4, .LandingTheme .col-lg-5, .LandingTheme .col-lg-6, .LandingTheme .col-lg-7, .LandingTheme .col-lg-8, .LandingTheme .col-lg-9, .LandingTheme .col-lg-auto, .LandingTheme .col-md, .LandingTheme .col-md-1, .LandingTheme .col-md-10, .LandingTheme .col-md-11, .LandingTheme .col-md-12, .LandingTheme .col-md-2, .LandingTheme .col-md-3, .LandingTheme .col-md-4, .LandingTheme .col-md-5, .LandingTheme .col-md-6, .LandingTheme .col-md-7, .LandingTheme .col-md-8, .LandingTheme .col-md-9, .LandingTheme .col-md-auto, .LandingTheme .col-sm, .LandingTheme .col-sm-1, .LandingTheme .col-sm-10, .LandingTheme .col-sm-11, .LandingTheme .col-sm-12, .LandingTheme .col-sm-2, .LandingTheme .col-sm-3, .LandingTheme .col-sm-4, .LandingTheme .col-sm-5, .LandingTheme .col-sm-6, .LandingTheme .col-sm-7, .LandingTheme .col-sm-8, .LandingTheme .col-sm-9, .LandingTheme .col-sm-auto, .LandingTheme .col-xl, .LandingTheme .col-xl-1, .LandingTheme .col-xl-10, .LandingTheme .col-xl-11, .LandingTheme .col-xl-12, .LandingTheme .col-xl-2, .LandingTheme .col-xl-3, .LandingTheme .col-xl-4, .LandingTheme .col-xl-5, .LandingTheme .col-xl-6, .LandingTheme .col-xl-7, .LandingTheme .col-xl-8, .LandingTheme .col-xl-9, .LandingTheme .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
}

.LandingTheme .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.LandingTheme .row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.LandingTheme .row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.LandingTheme .row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.LandingTheme .row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.LandingTheme .row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.LandingTheme .row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.LandingTheme .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.LandingTheme .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.LandingTheme .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.LandingTheme .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.LandingTheme .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.LandingTheme .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.LandingTheme .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.LandingTheme .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.LandingTheme .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.LandingTheme .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.LandingTheme .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.LandingTheme .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.LandingTheme .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.LandingTheme .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.LandingTheme .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
}

.LandingTheme .order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}

.LandingTheme .order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.LandingTheme .order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.LandingTheme .order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}

.LandingTheme .order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}

.LandingTheme .order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
}

.LandingTheme .order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
}

.LandingTheme .order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
}

.LandingTheme .order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
}

.LandingTheme .order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
}

.LandingTheme .order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
}

.LandingTheme .order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
}

.LandingTheme .order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
}

.LandingTheme .offset-1 {
    margin-left: 8.33333%;
}

.LandingTheme .offset-2 {
    margin-left: 16.66667%;
}

.LandingTheme .offset-3 {
    margin-left: 25%;
}

.LandingTheme .offset-4 {
    margin-left: 33.33333%;
}

.LandingTheme .offset-5 {
    margin-left: 41.66667%;
}

.LandingTheme .offset-6 {
    margin-left: 50%;
}

.LandingTheme .offset-7 {
    margin-left: 58.33333%;
}

.LandingTheme .offset-8 {
    margin-left: 66.66667%;
}

.LandingTheme .offset-9 {
    margin-left: 75%;
}

.LandingTheme .offset-10 {
    margin-left: 83.33333%;
}

.LandingTheme .offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .LandingTheme .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .LandingTheme .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .LandingTheme .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .LandingTheme .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .LandingTheme .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .LandingTheme .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .LandingTheme .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .LandingTheme .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .LandingTheme .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .LandingTheme .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .LandingTheme .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .LandingTheme .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .LandingTheme .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .LandingTheme .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .LandingTheme .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .LandingTheme .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .LandingTheme .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .LandingTheme .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .LandingTheme .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .LandingTheme .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .LandingTheme .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .LandingTheme .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .LandingTheme .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .LandingTheme .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .LandingTheme .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .LandingTheme .offset-sm-0 {
        margin-left: 0;
    }
    .LandingTheme .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .LandingTheme .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .LandingTheme .offset-sm-3 {
        margin-left: 25%;
    }
    .LandingTheme .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .LandingTheme .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .LandingTheme .offset-sm-6 {
        margin-left: 50%;
    }
    .LandingTheme .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .LandingTheme .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .LandingTheme .offset-sm-9 {
        margin-left: 75%;
    }
    .LandingTheme .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .LandingTheme .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .LandingTheme .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .LandingTheme .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .LandingTheme .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .LandingTheme .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .LandingTheme .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .LandingTheme .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .LandingTheme .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .LandingTheme .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .LandingTheme .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .LandingTheme .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .LandingTheme .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .order-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .LandingTheme .order-md-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .LandingTheme .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .LandingTheme .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .LandingTheme .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .LandingTheme .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .LandingTheme .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .LandingTheme .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .LandingTheme .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .LandingTheme .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .LandingTheme .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .LandingTheme .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .LandingTheme .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .LandingTheme .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .LandingTheme .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .LandingTheme .offset-md-0 {
        margin-left: 0;
    }
    .LandingTheme .offset-md-1 {
        margin-left: 8.33333%;
    }
    .LandingTheme .offset-md-2 {
        margin-left: 16.66667%;
    }
    .LandingTheme .offset-md-3 {
        margin-left: 25%;
    }
    .LandingTheme .offset-md-4 {
        margin-left: 33.33333%;
    }
    .LandingTheme .offset-md-5 {
        margin-left: 41.66667%;
    }
    .LandingTheme .offset-md-6 {
        margin-left: 50%;
    }
    .LandingTheme .offset-md-7 {
        margin-left: 58.33333%;
    }
    .LandingTheme .offset-md-8 {
        margin-left: 66.66667%;
    }
    .LandingTheme .offset-md-9 {
        margin-left: 75%;
    }
    .LandingTheme .offset-md-10 {
        margin-left: 83.33333%;
    }
    .LandingTheme .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .LandingTheme .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .LandingTheme .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .LandingTheme .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .LandingTheme .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .LandingTheme .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .LandingTheme .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .LandingTheme .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .LandingTheme .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .LandingTheme .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .LandingTheme .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .LandingTheme .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .LandingTheme .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .LandingTheme .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .LandingTheme .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .LandingTheme .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .LandingTheme .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .LandingTheme .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .LandingTheme .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .LandingTheme .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .LandingTheme .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .LandingTheme .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .LandingTheme .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .LandingTheme .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .LandingTheme .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .LandingTheme .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .LandingTheme .offset-lg-0 {
        margin-left: 0;
    }
    .LandingTheme .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .LandingTheme .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .LandingTheme .offset-lg-3 {
        margin-left: 25%;
    }
    .LandingTheme .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .LandingTheme .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .LandingTheme .offset-lg-6 {
        margin-left: 50%;
    }
    .LandingTheme .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .LandingTheme .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .LandingTheme .offset-lg-9 {
        margin-left: 75%;
    }
    .LandingTheme .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .LandingTheme .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .LandingTheme .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .LandingTheme .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .LandingTheme .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .LandingTheme .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .LandingTheme .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .LandingTheme .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .LandingTheme .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .LandingTheme .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .LandingTheme .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .LandingTheme .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .LandingTheme .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .LandingTheme .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .LandingTheme .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .LandingTheme .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .LandingTheme .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .LandingTheme .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .LandingTheme .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .LandingTheme .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .LandingTheme .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .LandingTheme .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .LandingTheme .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .LandingTheme .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .LandingTheme .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .LandingTheme .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .LandingTheme .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .LandingTheme .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .LandingTheme .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .LandingTheme .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .LandingTheme .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .LandingTheme .offset-xl-0 {
        margin-left: 0;
    }
    .LandingTheme .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .LandingTheme .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .LandingTheme .offset-xl-3 {
        margin-left: 25%;
    }
    .LandingTheme .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .LandingTheme .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .LandingTheme .offset-xl-6 {
        margin-left: 50%;
    }
    .LandingTheme .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .LandingTheme .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .LandingTheme .offset-xl-9 {
        margin-left: 75%;
    }
    .LandingTheme .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .LandingTheme .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.LandingTheme .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #1b1642;
}

.LandingTheme .table td, .LandingTheme .table th {
    padding: 1.25rem;
    vertical-align: top;
    border-top: 1px solid #eaf2fe;
}

.LandingTheme .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eaf2fe;
}

.LandingTheme .table tbody + tbody {
    border-top: 2px solid #eaf2fe;
}

.LandingTheme .table-sm td, .LandingTheme .table-sm th {
    padding: .3rem;
}

.LandingTheme .table-bordered {
    border: 1px solid #eaf2fe;
}

.LandingTheme .table-bordered td, .LandingTheme .table-bordered th {
    border: 1px solid #eaf2fe;
}

.LandingTheme .table-bordered thead td, .LandingTheme .table-bordered thead th {
    border-bottom-width: 2px;
}

.LandingTheme .table-borderless tbody + tbody, .LandingTheme .table-borderless td, .LandingTheme .table-borderless th, .LandingTheme .table-borderless thead th {
    border: 0;
}

.LandingTheme .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f8ff;
}

.LandingTheme .table-hover tbody tr:hover {
    color: #1b1642;
    background-color: #f3f8ff;
}

.LandingTheme .table-primary, .LandingTheme .table-primary > td, .LandingTheme .table-primary > th {
    background-color: #d4d0f9;
}

.LandingTheme .table-primary tbody + tbody, .LandingTheme .table-primary td, .LandingTheme .table-primary th, .LandingTheme .table-primary thead th {
    border-color: #afa8f4;
}

.LandingTheme .table-hover .table-primary:hover {
    background-color: #bfb9f6;
}

.LandingTheme .table-hover .table-primary:hover > td, .LandingTheme .table-hover .table-primary:hover > th {
    background-color: #bfb9f6;
}

.LandingTheme .table-secondary, .LandingTheme .table-secondary > td, .LandingTheme .table-secondary > th {
    background-color: #d0d1da;
}

.LandingTheme .table-secondary tbody + tbody, .LandingTheme .table-secondary td, .LandingTheme .table-secondary th, .LandingTheme .table-secondary thead th {
    border-color: #a8a9ba;
}

.LandingTheme .table-hover .table-secondary:hover {
    background-color: #c2c3cf;
}

.LandingTheme .table-hover .table-secondary:hover > td, .LandingTheme .table-hover .table-secondary:hover > th {
    background-color: #c2c3cf;
}

.LandingTheme .table-success, .LandingTheme .table-success > td, .LandingTheme .table-success > th {
    background-color: #c4f3e5;
}

.LandingTheme .table-success tbody + tbody, .LandingTheme .table-success td, .LandingTheme .table-success th, .LandingTheme .table-success thead th {
    border-color: #91e9cf;
}

.LandingTheme .table-hover .table-success:hover {
    background-color: #afefdc;
}

.LandingTheme .table-hover .table-success:hover > td, .LandingTheme .table-hover .table-success:hover > th {
    background-color: #afefdc;
}

.LandingTheme .table-info, .LandingTheme .table-info > td, .LandingTheme .table-info > th {
    background-color: #bee5eb;
}

.LandingTheme .table-info tbody + tbody, .LandingTheme .table-info td, .LandingTheme .table-info th, .LandingTheme .table-info thead th {
    border-color: #86cfda;
}

.LandingTheme .table-hover .table-info:hover {
    background-color: #abdde5;
}

.LandingTheme .table-hover .table-info:hover > td, .LandingTheme .table-hover .table-info:hover > th {
    background-color: #abdde5;
}

.LandingTheme .table-warning, .LandingTheme .table-warning > td, .LandingTheme .table-warning > th {
    background-color: #fcedd7;
}

.LandingTheme .table-warning tbody + tbody, .LandingTheme .table-warning td, .LandingTheme .table-warning th, .LandingTheme .table-warning thead th {
    border-color: #fadeb5;
}

.LandingTheme .table-hover .table-warning:hover {
    background-color: #fae2bf;
}

.LandingTheme .table-hover .table-warning:hover > td, .LandingTheme .table-hover .table-warning:hover > th {
    background-color: #fae2bf;
}

.LandingTheme .table-danger, .LandingTheme .table-danger > td, .LandingTheme .table-danger > th {
    background-color: #fdd4d1;
}

.LandingTheme .table-danger tbody + tbody, .LandingTheme .table-danger td, .LandingTheme .table-danger th, .LandingTheme .table-danger thead th {
    border-color: #fcafaa;
}

.LandingTheme .table-hover .table-danger:hover {
    background-color: #fcbdb9;
}

.LandingTheme .table-hover .table-danger:hover > td, .LandingTheme .table-hover .table-danger:hover > th {
    background-color: #fcbdb9;
}

.LandingTheme .table-light, .LandingTheme .table-light > td, .LandingTheme .table-light > th {
    background-color: #fcfdff;
}

.LandingTheme .table-light tbody + tbody, .LandingTheme .table-light td, .LandingTheme .table-light th, .LandingTheme .table-light thead th {
    border-color: #f9fbff;
}

.LandingTheme .table-hover .table-light:hover {
    background-color: #e3ecff;
}

.LandingTheme .table-hover .table-light:hover > td, .LandingTheme .table-hover .table-light:hover > th {
    background-color: #e3ecff;
}

.LandingTheme .table-dark, .LandingTheme .table-dark > td, .LandingTheme .table-dark > th {
    background-color: #bfbeca;
}

.LandingTheme .table-dark tbody + tbody, .LandingTheme .table-dark td, .LandingTheme .table-dark th, .LandingTheme .table-dark thead th {
    border-color: #88869d;
}

.LandingTheme .table-hover .table-dark:hover {
    background-color: #b1b0bf;
}

.LandingTheme .table-hover .table-dark:hover > td, .LandingTheme .table-hover .table-dark:hover > th {
    background-color: #b1b0bf;
}

.LandingTheme .table-primary-dark, .LandingTheme .table-primary-dark > td, .LandingTheme .table-primary-dark > th {
    background-color: #d0cde9;
}

.LandingTheme .table-primary-dark tbody + tbody, .LandingTheme .table-primary-dark td, .LandingTheme .table-primary-dark th, .LandingTheme .table-primary-dark thead th {
    border-color: #a7a2d5;
}

.LandingTheme .table-hover .table-primary-dark:hover {
    background-color: #bfbbe1;
}

.LandingTheme .table-hover .table-primary-dark:hover > td, .LandingTheme .table-hover .table-primary-dark:hover > th {
    background-color: #bfbbe1;
}

.LandingTheme .table-primary-light, .LandingTheme .table-primary-light > td, .LandingTheme .table-primary-light > th {
    background-color: #d5dbfa;
}

.LandingTheme .table-primary-light tbody + tbody, .LandingTheme .table-primary-light td, .LandingTheme .table-primary-light th, .LandingTheme .table-primary-light thead th {
    border-color: #b0bbf6;
}

.LandingTheme .table-hover .table-primary-light:hover {
    background-color: #bec7f7;
}

.LandingTheme .table-hover .table-primary-light:hover > td, .LandingTheme .table-hover .table-primary-light:hover > th {
    background-color: #bec7f7;
}

.LandingTheme .table-active, .LandingTheme .table-active > td, .LandingTheme .table-active > th {
    background-color: #f3f8ff;
}

.LandingTheme .table-hover .table-active:hover {
    background-color: #dae9ff;
}

.LandingTheme .table-hover .table-active:hover > td, .LandingTheme .table-hover .table-active:hover > th {
    background-color: #dae9ff;
}

.LandingTheme .table .thead-dark th {
    color: #fff;
    background-color: #1b1642;
    border-color: #27205f;
}

.LandingTheme .table .thead-light th {
    color: #1b1642;
    background-color: #eaf2fe;
    border-color: #eaf2fe;
}

.LandingTheme .table-dark {
    color: #fff;
    background-color: #1b1642;
}

.LandingTheme .table-dark td, .LandingTheme .table-dark th, .LandingTheme .table-dark thead th {
    border-color: #27205f;
}

.LandingTheme .table-dark.table-bordered {
    border: 0;
}

.LandingTheme .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.LandingTheme .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .LandingTheme .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .LandingTheme .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .LandingTheme .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .LandingTheme .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .LandingTheme .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .LandingTheme .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .LandingTheme .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .LandingTheme .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.LandingTheme .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.LandingTheme .table-responsive > .table-bordered {
    border: 0;
}

.LandingTheme .form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    background-color: #f3f8ff;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.LandingTheme .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1b1642;
}

.LandingTheme .form-control:focus {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: none, 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: none, 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .form-control::-webkit-input-placeholder {
    color: #575a7b;
    opacity: 1;
}

.LandingTheme .form-control::-moz-placeholder {
    color: #575a7b;
    opacity: 1;
}

.LandingTheme .form-control:-ms-input-placeholder {
    color: #575a7b;
    opacity: 1;
}

.LandingTheme .form-control::-ms-input-placeholder {
    color: #575a7b;
    opacity: 1;
}

.LandingTheme .form-control::placeholder {
    color: #575a7b;
    opacity: 1;
}

.LandingTheme .form-control:disabled, .LandingTheme .form-control[readonly] {
    background-color: #eaf2fe;
    opacity: 1;
}

.LandingTheme select.form-control:focus::-ms-value {
    color: #1b1642;
    background-color: #f3f8ff;
}

.LandingTheme .form-control-file, .LandingTheme .form-control-range {
    display: block;
    width: 100%;
}

.LandingTheme .col-form-label {
    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6;
}

.LandingTheme .col-form-label-lg {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    font-size: 1.125rem;
    line-height: 1.6;
}

.LandingTheme .col-form-label-sm {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: .875rem;
    line-height: 1.6;
}

.LandingTheme .form-control-plaintext {
    display: block;
    width: 100%;
    padding: .75rem 0;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.6;
    color: #1b1642;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.LandingTheme .form-control-plaintext.form-control-lg, .LandingTheme .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}

.LandingTheme .form-control-sm {
    height: calc(1.6em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .form-control-lg {
    height: calc(1.6em + 2rem + 2px);
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme select.form-control[multiple], .LandingTheme select.form-control[size] {
    height: auto;
}

.LandingTheme textarea.form-control {
    height: auto;
}

.LandingTheme .form-group {
    margin-bottom: 1.5rem;
}

.LandingTheme .form-text {
    display: block;
    margin-top: .25rem;
}

.LandingTheme .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.LandingTheme .form-row > .col, .LandingTheme .form-row > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

.LandingTheme .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.LandingTheme .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
}

.LandingTheme .form-check-input:disabled ~ .form-check-label, .LandingTheme .form-check-input[disabled] ~ .form-check-label {
    color: #575a7b;
}

.LandingTheme .form-check-label {
    margin-bottom: 0;
}

.LandingTheme .form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
}

.LandingTheme .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.LandingTheme .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 1rem;
    color: #2cd4a2;
}

.LandingTheme .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem 1rem;
    margin-top: .1rem;
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    background-color: #2cd4a2;
    border-radius: .375rem;
}

.LandingTheme .is-valid ~ .valid-feedback, .LandingTheme .is-valid ~ .valid-tooltip, .LandingTheme .was-validated :valid ~ .valid-feedback, .LandingTheme .was-validated :valid ~ .valid-tooltip {
    display: block;
}

.LandingTheme .form-control.is-valid, .LandingTheme .was-validated .form-control:valid {
    border-color: #2cd4a2;
    padding-right: calc(1.6em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M22 4L12 14.01L9 11.01' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.4em + .375rem) center;
    background-size: calc(.8em + .75rem) calc(.8em + .75rem);
}

.LandingTheme .form-control.is-valid:focus, .LandingTheme .was-validated .form-control:valid:focus {
    border-color: #2cd4a2;
    -webkit-box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
    box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
}

.LandingTheme .was-validated textarea.form-control:valid, .LandingTheme textarea.form-control.is-valid {
    padding-right: calc(1.6em + 1.5rem);
    background-position: top calc(.4em + .375rem) right calc(.4em + .375rem);
}

.LandingTheme .custom-select.is-valid, .LandingTheme .was-validated .custom-select:valid {
    border-color: #2cd4a2;
    padding-right: calc(.75em + 3.375rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3e%3cpath fill='none' stroke='%238D9AAE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M1 1L7 7L13 1'/%3e%3c/svg%3e") no-repeat right 1.25rem center/14px 8px, url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M22 4L12 14.01L9 11.01' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") #f3f8ff no-repeat center right 2.25rem/calc(.8em + .75rem) calc(.8em + .75rem);
}

.LandingTheme .custom-select.is-valid:focus, .LandingTheme .was-validated .custom-select:valid:focus {
    border-color: #2cd4a2;
    -webkit-box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
    box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
}

.LandingTheme .form-check-input.is-valid ~ .form-check-label, .LandingTheme .was-validated .form-check-input:valid ~ .form-check-label {
    color: #2cd4a2;
}

.LandingTheme .form-check-input.is-valid ~ .valid-feedback, .LandingTheme .form-check-input.is-valid ~ .valid-tooltip, .LandingTheme .was-validated .form-check-input:valid ~ .valid-feedback, .LandingTheme .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
}

.LandingTheme .custom-control-input.is-valid ~ .custom-control-label, .LandingTheme .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #2cd4a2;
}

.LandingTheme .custom-control-input.is-valid ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: #2cd4a2;
}

.LandingTheme .custom-control-input.is-valid:checked ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: #56ddb5;
    background-color: #56ddb5;
}

.LandingTheme .custom-control-input.is-valid:focus ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
    box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
}

.LandingTheme .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #2cd4a2;
}

.LandingTheme .custom-file-input.is-valid ~ .custom-file-label, .LandingTheme .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #2cd4a2;
}

.LandingTheme .custom-file-input.is-valid:focus ~ .custom-file-label, .LandingTheme .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #2cd4a2;
    -webkit-box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
    box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.25);
}

.LandingTheme .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 1rem;
    color: #f9655b;
}

.LandingTheme .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem 1rem;
    margin-top: .1rem;
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    background-color: #f9655b;
    border-radius: .375rem;
}

.LandingTheme .is-invalid ~ .invalid-feedback, .LandingTheme .is-invalid ~ .invalid-tooltip, .LandingTheme .was-validated :invalid ~ .invalid-feedback, .LandingTheme .was-validated :invalid ~ .invalid-tooltip {
    display: block;
}

.LandingTheme .form-control.is-invalid, .LandingTheme .was-validated .form-control:invalid {
    border-color: #f9655b;
    padding-right: calc(1.6em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 8V12' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 16H12.01' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.4em + .375rem) center;
    background-size: calc(.8em + .75rem) calc(.8em + .75rem);
}

.LandingTheme .form-control.is-invalid:focus, .LandingTheme .was-validated .form-control:invalid:focus {
    border-color: #f9655b;
    -webkit-box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
    box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
}

.LandingTheme .was-validated textarea.form-control:invalid, .LandingTheme textarea.form-control.is-invalid {
    padding-right: calc(1.6em + 1.5rem);
    background-position: top calc(.4em + .375rem) right calc(.4em + .375rem);
}

.LandingTheme .custom-select.is-invalid, .LandingTheme .was-validated .custom-select:invalid {
    border-color: #f9655b;
    padding-right: calc(.75em + 3.375rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3e%3cpath fill='none' stroke='%238D9AAE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M1 1L7 7L13 1'/%3e%3c/svg%3e") no-repeat right 1.25rem center/14px 8px, url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 8V12' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 16H12.01' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") #f3f8ff no-repeat center right 2.25rem/calc(.8em + .75rem) calc(.8em + .75rem);
}

.LandingTheme .custom-select.is-invalid:focus, .LandingTheme .was-validated .custom-select:invalid:focus {
    border-color: #f9655b;
    -webkit-box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
    box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
}

.LandingTheme .form-check-input.is-invalid ~ .form-check-label, .LandingTheme .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #f9655b;
}

.LandingTheme .form-check-input.is-invalid ~ .invalid-feedback, .LandingTheme .form-check-input.is-invalid ~ .invalid-tooltip, .LandingTheme .was-validated .form-check-input:invalid ~ .invalid-feedback, .LandingTheme .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
}

.LandingTheme .custom-control-input.is-invalid ~ .custom-control-label, .LandingTheme .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #f9655b;
}

.LandingTheme .custom-control-input.is-invalid ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: #f9655b;
}

.LandingTheme .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
    border-color: #fb938c;
    background-color: #fb938c;
}

.LandingTheme .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
    box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
}

.LandingTheme .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .LandingTheme .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f9655b;
}

.LandingTheme .custom-file-input.is-invalid ~ .custom-file-label, .LandingTheme .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #f9655b;
}

.LandingTheme .custom-file-input.is-invalid:focus ~ .custom-file-label, .LandingTheme .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #f9655b;
    -webkit-box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
    box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.25);
}

.LandingTheme .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.LandingTheme .form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .LandingTheme .form-inline label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .LandingTheme .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }
    .LandingTheme .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .LandingTheme .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .LandingTheme .form-inline .custom-select, .LandingTheme .form-inline .input-group {
        width: auto;
    }
    .LandingTheme .form-inline .form-check {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .LandingTheme .form-inline .form-check-input {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0;
    }
    .LandingTheme .form-inline .custom-control {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .LandingTheme .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.LandingTheme .btn {
    display: inline-block;
    font-weight: 500;
    color: #1b1642;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .75rem 1.75rem;
    font-size: 1.125rem;
    line-height: 1.6;
    /*border-radius: .5rem 0 .5rem 0;*/
    border-radius: .375rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .btn:hover {
    color: #1b1642;
    text-decoration: none;
}

.LandingTheme .btn.focus, .LandingTheme .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .btn.disabled, .LandingTheme .btn:disabled {
    opacity: .65;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .btn:not(:disabled):not(.disabled).active, .LandingTheme .btn:not(:disabled):not(.disabled):active {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25), 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25), 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme a.btn.disabled, .LandingTheme fieldset:disabled a.btn {
    pointer-events: none;
}

.LandingTheme .btn-primary {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-primary:hover {
    color: #fff;
    background-color: #4736e6;
    border-color: #3c2be4;
}

.LandingTheme .btn-primary.focus, .LandingTheme .btn-primary:focus {
    color: #fff;
    background-color: #4736e6;
    border-color: #3c2be4;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
}

.LandingTheme .btn-primary.disabled, .LandingTheme .btn-primary:disabled {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .btn-primary:not(:disabled):not(.disabled).active, .LandingTheme .btn-primary:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3c2be4;
    border-color: #321fe3;
}

.LandingTheme .btn-primary:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-primary:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
}

.LandingTheme .btn-secondary {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-secondary:hover {
    color: #fff;
    background-color: #474a65;
    border-color: #42445d;
}

.LandingTheme .btn-secondary.focus, .LandingTheme .btn-secondary:focus {
    color: #fff;
    background-color: #474a65;
    border-color: #42445d;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(112, 115, 143, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(112, 115, 143, 0.5);
}

.LandingTheme .btn-secondary.disabled, .LandingTheme .btn-secondary:disabled {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b;
}

.LandingTheme .btn-secondary:not(:disabled):not(.disabled).active, .LandingTheme .btn-secondary:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #42445d;
    border-color: #3d3f56;
}

.LandingTheme .btn-secondary:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-secondary:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(112, 115, 143, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(112, 115, 143, 0.5);
}

.LandingTheme .btn-success {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-success:hover {
    color: #fff;
    background-color: #25b58a;
    border-color: #23aa82;
}

.LandingTheme .btn-success.focus, .LandingTheme .btn-success:focus {
    color: #fff;
    background-color: #25b58a;
    border-color: #23aa82;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(76, 218, 176, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(76, 218, 176, 0.5);
}

.LandingTheme .btn-success.disabled, .LandingTheme .btn-success:disabled {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2;
}

.LandingTheme .btn-success:not(:disabled):not(.disabled).active, .LandingTheme .btn-success:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #23aa82;
    border-color: #21a07a;
}

.LandingTheme .btn-success:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-success:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(76, 218, 176, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(76, 218, 176, 0.5);
}

.LandingTheme .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.LandingTheme .btn-info.focus, .LandingTheme .btn-info:focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(58, 176, 195, 0.5);
}

.LandingTheme .btn-info.disabled, .LandingTheme .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.LandingTheme .btn-info:not(:disabled):not(.disabled).active, .LandingTheme .btn-info:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.LandingTheme .btn-info:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-info:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(58, 176, 195, 0.5);
}

.LandingTheme .btn-warning {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-warning:hover {
    color: #fff;
    background-color: #f3b04c;
    border-color: #f2ab40;
}

.LandingTheme .btn-warning.focus, .LandingTheme .btn-warning:focus {
    color: #fff;
    background-color: #f3b04c;
    border-color: #f2ab40;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(247, 201, 133, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(247, 201, 133, 0.5);
}

.LandingTheme .btn-warning.disabled, .LandingTheme .btn-warning:disabled {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070;
}

.LandingTheme .btn-warning:not(:disabled):not(.disabled).active, .LandingTheme .btn-warning:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f2ab40;
    border-color: #f1a634;
}

.LandingTheme .btn-warning:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-warning:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(247, 201, 133, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(247, 201, 133, 0.5);
}

.LandingTheme .btn-danger {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-danger:hover {
    color: #fff;
    background-color: #f84236;
    border-color: #f7372a;
}

.LandingTheme .btn-danger.focus, .LandingTheme .btn-danger:focus {
    color: #fff;
    background-color: #f84236;
    border-color: #f7372a;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(250, 124, 116, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(250, 124, 116, 0.5);
}

.LandingTheme .btn-danger.disabled, .LandingTheme .btn-danger:disabled {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b;
}

.LandingTheme .btn-danger:not(:disabled):not(.disabled).active, .LandingTheme .btn-danger:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f7372a;
    border-color: #f72b1e;
}

.LandingTheme .btn-danger:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-danger:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(250, 124, 116, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(250, 124, 116, 0.5);
}

.LandingTheme .btn-light {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-light:hover {
    color: #1b1642;
    background-color: #cde2ff;
    border-color: #c0daff;
}

.LandingTheme .btn-light.focus, .LandingTheme .btn-light:focus {
    color: #1b1642;
    background-color: #cde2ff;
    border-color: #c0daff;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(211, 214, 227, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(211, 214, 227, 0.5);
}

.LandingTheme .btn-light.disabled, .LandingTheme .btn-light:disabled {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff;
}

.LandingTheme .btn-light:not(:disabled):not(.disabled).active, .LandingTheme .btn-light:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-light.dropdown-toggle {
    color: #1b1642;
    background-color: #c0daff;
    border-color: #b3d3ff;
}

.LandingTheme .btn-light:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-light:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(211, 214, 227, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(211, 214, 227, 0.5);
}

.LandingTheme .btn-dark {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-dark:hover {
    color: #fff;
    background-color: #0f0c25;
    border-color: #0b091c;
}

.LandingTheme .btn-dark.focus, .LandingTheme .btn-dark:focus {
    color: #fff;
    background-color: #0f0c25;
    border-color: #0b091c;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(61, 57, 94, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(61, 57, 94, 0.5);
}

.LandingTheme .btn-dark.disabled, .LandingTheme .btn-dark:disabled {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642;
}

.LandingTheme .btn-dark:not(:disabled):not(.disabled).active, .LandingTheme .btn-dark:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0b091c;
    border-color: #070612;
}

.LandingTheme .btn-dark:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-dark:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(61, 57, 94, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(61, 57, 94, 0.5);
}

.LandingTheme .btn-primary-dark {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-primary-dark:hover {
    color: #fff;
    background-color: #494094;
    border-color: #453d8b;
}

.LandingTheme .btn-primary-dark.focus, .LandingTheme .btn-primary-dark:focus {
    color: #fff;
    background-color: #494094;
    border-color: #453d8b;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(111, 103, 187, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(111, 103, 187, 0.5);
}

.LandingTheme .btn-primary-dark.disabled, .LandingTheme .btn-primary-dark:disabled {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf;
}

.LandingTheme .btn-primary-dark:not(:disabled):not(.disabled).active, .LandingTheme .btn-primary-dark:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #453d8b;
    border-color: #403983;
}

.LandingTheme .btn-primary-dark:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-primary-dark:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-primary-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(111, 103, 187, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(111, 103, 187, 0.5);
}

.LandingTheme .btn-primary-light {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-primary-light:hover {
    color: #fff;
    background-color: #4660e9;
    border-color: #3a56e8;
}

.LandingTheme .btn-primary-light.focus, .LandingTheme .btn-primary-light:focus {
    color: #fff;
    background-color: #4660e9;
    border-color: #3a56e8;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(127, 145, 240, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(127, 145, 240, 0.5);
}

.LandingTheme .btn-primary-light.disabled, .LandingTheme .btn-primary-light:disabled {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded;
}

.LandingTheme .btn-primary-light:not(:disabled):not(.disabled).active, .LandingTheme .btn-primary-light:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #3a56e8;
    border-color: #2f4ce6;
}

.LandingTheme .btn-primary-light:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-primary-light:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-primary-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(127, 145, 240, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(127, 145, 240, 0.5);
}

.LandingTheme .btn-outline-primary {
    color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .btn-outline-primary:hover {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .btn-outline-primary.focus, .LandingTheme .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.5);
}

.LandingTheme .btn-outline-primary.disabled, .LandingTheme .btn-outline-primary:disabled {
    color: #6658ea;
    background-color: transparent;
}

.LandingTheme .btn-outline-primary:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-primary:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(102, 88, 234, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(102, 88, 234, 0.5);
}

.LandingTheme .btn-outline-secondary {
    color: #575a7b;
    border-color: #575a7b;
}

.LandingTheme .btn-outline-secondary:hover {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b;
}

.LandingTheme .btn-outline-secondary.focus, .LandingTheme .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(87, 90, 123, 0.5);
    box-shadow: 0 0 0 0 rgba(87, 90, 123, 0.5);
}

.LandingTheme .btn-outline-secondary.disabled, .LandingTheme .btn-outline-secondary:disabled {
    color: #575a7b;
    background-color: transparent;
}

.LandingTheme .btn-outline-secondary:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-secondary:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b;
}

.LandingTheme .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(87, 90, 123, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(87, 90, 123, 0.5);
}

.LandingTheme .btn-outline-success {
    color: #2cd4a2;
    border-color: #2cd4a2;
}

.LandingTheme .btn-outline-success:hover {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2;
}

.LandingTheme .btn-outline-success.focus, .LandingTheme .btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.5);
    box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.5);
}

.LandingTheme .btn-outline-success.disabled, .LandingTheme .btn-outline-success:disabled {
    color: #2cd4a2;
    background-color: transparent;
}

.LandingTheme .btn-outline-success:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-success:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2;
}

.LandingTheme .btn-outline-success:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-success:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(44, 212, 162, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(44, 212, 162, 0.5);
}

.LandingTheme .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.LandingTheme .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.LandingTheme .btn-outline-info.focus, .LandingTheme .btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.LandingTheme .btn-outline-info.disabled, .LandingTheme .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.LandingTheme .btn-outline-info:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-info:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.LandingTheme .btn-outline-info:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-info:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.LandingTheme .btn-outline-warning {
    color: #f5c070;
    border-color: #f5c070;
}

.LandingTheme .btn-outline-warning:hover {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070;
}

.LandingTheme .btn-outline-warning.focus, .LandingTheme .btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 192, 112, 0.5);
    box-shadow: 0 0 0 0 rgba(245, 192, 112, 0.5);
}

.LandingTheme .btn-outline-warning.disabled, .LandingTheme .btn-outline-warning:disabled {
    color: #f5c070;
    background-color: transparent;
}

.LandingTheme .btn-outline-warning:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-warning:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070;
}

.LandingTheme .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(245, 192, 112, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(245, 192, 112, 0.5);
}

.LandingTheme .btn-outline-danger {
    color: #f9655b;
    border-color: #f9655b;
}

.LandingTheme .btn-outline-danger:hover {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b;
}

.LandingTheme .btn-outline-danger.focus, .LandingTheme .btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.5);
    box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.5);
}

.LandingTheme .btn-outline-danger.disabled, .LandingTheme .btn-outline-danger:disabled {
    color: #f9655b;
    background-color: transparent;
}

.LandingTheme .btn-outline-danger:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-danger:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b;
}

.LandingTheme .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(249, 101, 91, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(249, 101, 91, 0.5);
}

.LandingTheme .btn-outline-light {
    color: #f3f8ff;
    border-color: #f3f8ff;
}

.LandingTheme .btn-outline-light:hover {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff;
}

.LandingTheme .btn-outline-light.focus, .LandingTheme .btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 248, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 248, 255, 0.5);
}

.LandingTheme .btn-outline-light.disabled, .LandingTheme .btn-outline-light:disabled {
    color: #f3f8ff;
    background-color: transparent;
}

.LandingTheme .btn-outline-light:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-light:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-light.dropdown-toggle {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff;
}

.LandingTheme .btn-outline-light:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-light:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(243, 248, 255, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(243, 248, 255, 0.5);
}

.LandingTheme .btn-outline-dark {
    color: #1b1642;
    border-color: #1b1642;
}

.LandingTheme .btn-outline-dark:hover {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642;
}

.LandingTheme .btn-outline-dark.focus, .LandingTheme .btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(27, 22, 66, 0.5);
    box-shadow: 0 0 0 0 rgba(27, 22, 66, 0.5);
}

.LandingTheme .btn-outline-dark.disabled, .LandingTheme .btn-outline-dark:disabled {
    color: #1b1642;
    background-color: transparent;
}

.LandingTheme .btn-outline-dark:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-dark:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642;
}

.LandingTheme .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(27, 22, 66, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(27, 22, 66, 0.5);
}

.LandingTheme .btn-outline-primary-dark {
    color: #564caf;
    border-color: #564caf;
}

.LandingTheme .btn-outline-primary-dark:hover {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf;
}

.LandingTheme .btn-outline-primary-dark.focus, .LandingTheme .btn-outline-primary-dark:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(86, 76, 175, 0.5);
    box-shadow: 0 0 0 0 rgba(86, 76, 175, 0.5);
}

.LandingTheme .btn-outline-primary-dark.disabled, .LandingTheme .btn-outline-primary-dark:disabled {
    color: #564caf;
    background-color: transparent;
}

.LandingTheme .btn-outline-primary-dark:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf;
}

.LandingTheme .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-primary-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(86, 76, 175, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(86, 76, 175, 0.5);
}

.LandingTheme .btn-outline-primary-light {
    color: #687ded;
    border-color: #687ded;
}

.LandingTheme .btn-outline-primary-light:hover {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded;
}

.LandingTheme .btn-outline-primary-light.focus, .LandingTheme .btn-outline-primary-light:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(104, 125, 237, 0.5);
    box-shadow: 0 0 0 0 rgba(104, 125, 237, 0.5);
}

.LandingTheme .btn-outline-primary-light.disabled, .LandingTheme .btn-outline-primary-light:disabled {
    color: #687ded;
    background-color: transparent;
}

.LandingTheme .btn-outline-primary-light:not(:disabled):not(.disabled).active, .LandingTheme .btn-outline-primary-light:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-outline-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded;
}

.LandingTheme .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-outline-primary-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(104, 125, 237, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(104, 125, 237, 0.5);
}

.LandingTheme .btn-link {
    font-weight: 400;
    color: #6658ea;
    text-decoration: none;
}

.LandingTheme .btn-link:hover {
    color: #5546e8;
    text-decoration: none;
}

.LandingTheme .btn-link.focus, .LandingTheme .btn-link:focus {
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .btn-link.disabled, .LandingTheme .btn-link:disabled {
    color: #575a7b;
    pointer-events: none;
}

.LandingTheme .btn-group-lg > .btn, .LandingTheme .btn-lg {
    padding: 1rem 2.25rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .btn-group-sm > .btn, .LandingTheme .btn-sm {
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .btn-block {
    display: block;
    width: 100%;
}

.LandingTheme .btn-block + .btn-block {
    margin-top: .5rem;
}

.LandingTheme input[type=button].btn-block, .LandingTheme input[type=reset].btn-block, .LandingTheme input[type=submit].btn-block {
    width: 100%;
}

.LandingTheme .fade {
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .fade {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .fade:not(.show) {
    opacity: 0;
}

.LandingTheme .collapse:not(.show) {
    display: none;
}

.LandingTheme .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    -o-transition: height .35s ease;
    transition: height .35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .collapsing {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .dropdown, .LandingTheme .dropleft, .LandingTheme .dropright, .LandingTheme .dropup {
    position: relative;
}

.LandingTheme .dropdown-toggle {
    white-space: nowrap;
}

.LandingTheme .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 16rem;
    padding: 2rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #1b1642;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(27, 22, 66, 0.15);
    border-radius: 1rem 0 1rem 0;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .dropdown-menu-left {
    right: auto;
    left: 0;
}

.LandingTheme .dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .LandingTheme .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .LandingTheme .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .LandingTheme .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .LandingTheme .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .LandingTheme .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .LandingTheme .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .LandingTheme .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.LandingTheme .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem;
}

.LandingTheme .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem;
}

.LandingTheme .dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.LandingTheme .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem;
}

.LandingTheme .dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.LandingTheme .dropdown-menu[x-placement^=bottom], .LandingTheme .dropdown-menu[x-placement^=left], .LandingTheme .dropdown-menu[x-placement^=right], .LandingTheme .dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto;
}

.LandingTheme .dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #eaf2fe;
}

.LandingTheme .dropdown-item, .LandingTheme .dropdown-link {
    display: block;
    width: 100%;
    padding: 0;
    clear: both;
    font-weight: 400;
    color: #1b1642;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.LandingTheme .dropdown-item:focus, .LandingTheme .dropdown-item:hover, .LandingTheme .dropdown-link:focus, .LandingTheme .dropdown-link:hover {
    color: #6658ea;
    text-decoration: none;
    background-color: transparent;
}

.LandingTheme .active.dropdown-link, .LandingTheme .dropdown-item.active, .LandingTheme .dropdown-item:active, .LandingTheme .dropdown-link:active {
    color: #6658ea;
    text-decoration: none;
    background-color: transparent;
}

.LandingTheme .disabled.dropdown-link, .LandingTheme .dropdown-item.disabled, .LandingTheme .dropdown-item:disabled, .LandingTheme .dropdown-link:disabled {
    color: #575a7b;
    pointer-events: none;
    background-color: transparent;
}

.LandingTheme .dropdown-menu.show {
    display: block;
}

.LandingTheme .dropdown-header {
    display: block;
    padding: 2rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: #6658ea;
    white-space: nowrap;
}

.LandingTheme .dropdown-item-text {
    display: block;
    padding: 0;
    color: #1b1642;
}

.LandingTheme .btn-group, .LandingTheme .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.LandingTheme .btn-group-vertical > .btn, .LandingTheme .btn-group > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.LandingTheme .btn-group-vertical > .btn:hover, .LandingTheme .btn-group > .btn:hover {
    z-index: 1;
}

.LandingTheme .btn-group-vertical > .btn.active, .LandingTheme .btn-group-vertical > .btn:active, .LandingTheme .btn-group-vertical > .btn:focus, .LandingTheme .btn-group > .btn.active, .LandingTheme .btn-group > .btn:active, .LandingTheme .btn-group > .btn:focus {
    z-index: 1;
}

.LandingTheme .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.LandingTheme .btn-toolbar .input-group {
    width: auto;
}

.LandingTheme .btn-group > .btn-group:not(:first-child), .LandingTheme .btn-group > .btn:not(:first-child) {
    margin-left: -1px;
}

.LandingTheme .btn-group > .btn-group:not(:last-child) > .btn, .LandingTheme .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.LandingTheme .btn-group > .btn-group:not(:first-child) > .btn, .LandingTheme .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
}

.LandingTheme .dropdown-toggle-split::after, .LandingTheme .dropright .dropdown-toggle-split::after, .LandingTheme .dropup .dropdown-toggle-split::after {
    margin-left: 0;
}

.LandingTheme .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.LandingTheme .btn-group-sm > .btn + .dropdown-toggle-split, .LandingTheme .btn-sm + .dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem;
}

.LandingTheme .btn-group-lg > .btn + .dropdown-toggle-split, .LandingTheme .btn-lg + .dropdown-toggle-split {
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
}

.LandingTheme .btn-group.show .dropdown-toggle {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-group.show .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.LandingTheme .btn-group-vertical > .btn, .LandingTheme .btn-group-vertical > .btn-group {
    width: 100%;
}

.LandingTheme .btn-group-vertical > .btn-group:not(:first-child), .LandingTheme .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px;
}

.LandingTheme .btn-group-vertical > .btn-group:not(:last-child) > .btn, .LandingTheme .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .btn-group-vertical > .btn-group:not(:first-child) > .btn, .LandingTheme .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.LandingTheme .btn-group-toggle > .btn, .LandingTheme .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}

.LandingTheme .btn-group-toggle > .btn input[type=checkbox], .LandingTheme .btn-group-toggle > .btn input[type=radio], .LandingTheme .btn-group-toggle > .btn-group > .btn input[type=checkbox], .LandingTheme .btn-group-toggle > .btn-group > .btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.LandingTheme .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.LandingTheme .input-group > .custom-file, .LandingTheme .input-group > .custom-select, .LandingTheme .input-group > .form-control, .LandingTheme .input-group > .form-control-plaintext {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}

.LandingTheme .input-group > .custom-file + .custom-file, .LandingTheme .input-group > .custom-file + .custom-select, .LandingTheme .input-group > .custom-file + .form-control, .LandingTheme .input-group > .custom-select + .custom-file, .LandingTheme .input-group > .custom-select + .custom-select, .LandingTheme .input-group > .custom-select + .form-control, .LandingTheme .input-group > .form-control + .custom-file, .LandingTheme .input-group > .form-control + .custom-select, .LandingTheme .input-group > .form-control + .form-control, .LandingTheme .input-group > .form-control-plaintext + .custom-file, .LandingTheme .input-group > .form-control-plaintext + .custom-select, .LandingTheme .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px;
}

.LandingTheme .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .LandingTheme .input-group > .custom-select:focus, .LandingTheme .input-group > .form-control:focus {
    z-index: 3;
}

.LandingTheme .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}

.LandingTheme .input-group > .custom-select:not(:last-child), .LandingTheme .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.LandingTheme .input-group > .custom-select:not(:first-child), .LandingTheme .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.LandingTheme .input-group > .custom-file:not(:last-child) .custom-file-label, .LandingTheme .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.LandingTheme .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .input-group-append, .LandingTheme .input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.LandingTheme .input-group-append .btn, .LandingTheme .input-group-prepend .btn {
    position: relative;
    z-index: 2;
}

.LandingTheme .input-group-append .btn:focus, .LandingTheme .input-group-prepend .btn:focus {
    z-index: 3;
}

.LandingTheme .input-group-append .btn + .btn, .LandingTheme .input-group-append .btn + .input-group-text, .LandingTheme .input-group-append .input-group-text + .btn, .LandingTheme .input-group-append .input-group-text + .input-group-text, .LandingTheme .input-group-prepend .btn + .btn, .LandingTheme .input-group-prepend .btn + .input-group-text, .LandingTheme .input-group-prepend .input-group-text + .btn, .LandingTheme .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
}

.LandingTheme .input-group-prepend {
    margin-right: -1px;
}

.LandingTheme .input-group-append {
    margin-left: -1px;
}

.LandingTheme .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #8d9aae;
    text-align: center;
    white-space: nowrap;
    background-color: #f3f8ff;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .input-group-text input[type=checkbox], .LandingTheme .input-group-text input[type=radio] {
    margin-top: 0;
}

.LandingTheme .input-group-lg > .custom-select, .LandingTheme .input-group-lg > .form-control:not(textarea) {
    height: calc(1.6em + 2rem + 2px);
}

.LandingTheme .input-group-lg > .custom-select, .LandingTheme .input-group-lg > .form-control, .LandingTheme .input-group-lg > .input-group-append > .btn, .LandingTheme .input-group-lg > .input-group-append > .input-group-text, .LandingTheme .input-group-lg > .input-group-prepend > .btn, .LandingTheme .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .input-group-sm > .custom-select, .LandingTheme .input-group-sm > .form-control:not(textarea) {
    height: calc(1.6em + 1rem + 2px);
}

.LandingTheme .input-group-sm > .custom-select, .LandingTheme .input-group-sm > .form-control, .LandingTheme .input-group-sm > .input-group-append > .btn, .LandingTheme .input-group-sm > .input-group-append > .input-group-text, .LandingTheme .input-group-sm > .input-group-prepend > .btn, .LandingTheme .input-group-sm > .input-group-prepend > .input-group-text {
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .input-group-lg > .custom-select, .LandingTheme .input-group-sm > .custom-select {
    padding-right: 2.25rem;
}

.LandingTheme .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .LandingTheme .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .LandingTheme .input-group > .input-group-append:not(:last-child) > .btn, .LandingTheme .input-group > .input-group-append:not(:last-child) > .input-group-text, .LandingTheme .input-group > .input-group-prepend > .btn, .LandingTheme .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.LandingTheme .input-group > .input-group-append > .btn, .LandingTheme .input-group > .input-group-append > .input-group-text, .LandingTheme .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .LandingTheme .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .LandingTheme .input-group > .input-group-prepend:not(:first-child) > .btn, .LandingTheme .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .custom-control {
    position: relative;
    display: block;
    min-height: 1.8rem;
    padding-left: 1.875rem;
}

.LandingTheme .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.LandingTheme .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.4625rem;
    opacity: 0;
}

.LandingTheme .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #6658ea;
    background-color: #6658ea;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: none, 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: none, 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: transparent;
}

.LandingTheme .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .custom-control-input:disabled ~ .custom-control-label, .LandingTheme .custom-control-input[disabled] ~ .custom-control-label {
    color: #575a7b;
}

.LandingTheme .custom-control-input:disabled ~ .custom-control-label::before, .LandingTheme .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #eaf2fe;
}

.LandingTheme .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.LandingTheme .custom-control-label::before {
    position: absolute;
    top: .3375rem;
    left: -1.875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    background-color: #dce0f3;
    border: #8d9aae solid 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .custom-control-label::after {
    position: absolute;
    top: .3375rem;
    left: -1.875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background: no-repeat 50%/70% 70%;
}

.LandingTheme .custom-checkbox .custom-control-label::before {
    border-radius: 1rem;
}

.LandingTheme .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3e%3cpath fill='none' stroke='%23FFF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round' d='M10.5 0.785156L4.3125 7.21373L1.5 4.29165'/%3e%3c/svg%3e");
}

.LandingTheme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #6658ea;
    background-color: #6658ea;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e");
}

.LandingTheme .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(102, 88, 234, 0.5);
}

.LandingTheme .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(102, 88, 234, 0.5);
}

.LandingTheme .custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.LandingTheme .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3e%3cpath fill='none' stroke='%23FFF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round' d='M10.5 0.785156L4.3125 7.21373L1.5 4.29165'/%3e%3c/svg%3e");
}

.LandingTheme .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(102, 88, 234, 0.5);
}

.LandingTheme .custom-switch {
    padding-left: 3.25rem;
}

.LandingTheme .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: 10rem;
}

.LandingTheme .custom-switch .custom-control-label::after {
    top: .3375rem;
    left: -3.25rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #8d9aae;
    border-radius: 10rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-transform .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-transform .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #dce0f3;
    -webkit-transform: translateX(1.375rem);
    -ms-transform: translateX(1.375rem);
    transform: translateX(1.375rem);
}

.LandingTheme .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(102, 88, 234, 0.5);
}

.LandingTheme .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    padding: .75rem 2.25rem .75rem 1.25rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    vertical-align: middle;
    background: #f3f8ff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3e%3cpath fill='none' stroke='%238D9AAE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M1 1L7 7L13 1'/%3e%3c/svg%3e") no-repeat right 1.25rem center/14px 8px;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.LandingTheme .custom-select:focus {
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: none, 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: none, 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .custom-select:focus::-ms-value {
    color: #1b1642;
    background-color: #f3f8ff;
}

.LandingTheme .custom-select[multiple], .LandingTheme .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none;
}

.LandingTheme .custom-select:disabled {
    color: #575a7b;
    background-color: #eaf2fe;
}

.LandingTheme .custom-select::-ms-expand {
    display: none;
}

.LandingTheme .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1b1642;
}

.LandingTheme .custom-select-sm {
    height: calc(1.6em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: .875rem;
}

.LandingTheme .custom-select-lg {
    height: calc(1.6em + 2rem + 2px);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    font-size: 1.125rem;
}

.LandingTheme .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    margin-bottom: 0;
}

.LandingTheme .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    margin: 0;
    opacity: 0;
}

.LandingTheme .custom-file-input:focus ~ .custom-file-label {
    border-color: transparent;
    -webkit-box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .custom-file-input:disabled ~ .custom-file-label, .LandingTheme .custom-file-input[disabled] ~ .custom-file-label {
    background-color: #eaf2fe;
}

.LandingTheme .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}

.LandingTheme .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.LandingTheme .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.6em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    background-color: #f3f8ff;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.5rem);
    padding: .75rem 1.25rem;
    line-height: 1.6;
    color: #1b1642;
    content: "Browse";
    background-color: #f3f8ff;
    border-left: inherit;
    border-radius: 0 .5rem 0 .5rem 0 .5rem 0 .5rem 0 0;
}

.LandingTheme .custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.LandingTheme .custom-range:focus {
    outline: 0;
}

.LandingTheme .custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .custom-range::-moz-focus-outer {
    border: 0;
}

.LandingTheme .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #6658ea;
    border: 0;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0.1rem 0.25rem rgba(27, 22, 66, 0.1);
    box-shadow: 0 0.1rem 0.25rem rgba(27, 22, 66, 0.1);
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.LandingTheme .custom-range::-webkit-slider-thumb:active {
    background-color: #f7f7fe;
}

.LandingTheme .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dce0f3;
    border-color: transparent;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 0.25rem 0.25rem rgba(27, 22, 66, 0.1);
    box-shadow: inset 0 0.25rem 0.25rem rgba(27, 22, 66, 0.1);
}

.LandingTheme .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #6658ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(27, 22, 66, 0.1);
    -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.LandingTheme .custom-range::-moz-range-thumb:active {
    background-color: #f7f7fe;
}

.LandingTheme .custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dce0f3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(27, 22, 66, 0.1);
}

.LandingTheme .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #6658ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(27, 22, 66, 0.1);
    -ms-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.LandingTheme .custom-range::-ms-thumb:active {
    background-color: #f7f7fe;
}

.LandingTheme .custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(27, 22, 66, 0.1);
}

.LandingTheme .custom-range::-ms-fill-lower {
    background-color: #dce0f3;
    border-radius: 1rem;
}

.LandingTheme .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dce0f3;
    border-radius: 1rem;
}

.LandingTheme .custom-range:disabled::-webkit-slider-thumb {
    background-color: #8d9aae;
}

.LandingTheme .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.LandingTheme .custom-range:disabled::-moz-range-thumb {
    background-color: #8d9aae;
}

.LandingTheme .custom-range:disabled::-moz-range-track {
    cursor: default;
}

.LandingTheme .custom-range:disabled::-ms-thumb {
    background-color: #8d9aae;
}

.LandingTheme .custom-control-label::before, .LandingTheme .custom-file-label, .LandingTheme .custom-select {
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .custom-control-label::before, .LandingTheme .custom-file-label, .LandingTheme .custom-select {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.LandingTheme .nav-link {
    display: block;
    padding: .5rem 1rem;
}

.LandingTheme .nav-link:focus, .LandingTheme .nav-link:hover {
    text-decoration: none;
}

.LandingTheme .nav-link.disabled {
    color: #575a7b;
    pointer-events: none;
    cursor: default;
}

.LandingTheme .nav-tabs {
    border-bottom: 1px solid #dce0f3;
}

.LandingTheme .nav-tabs .nav-item {
    margin-bottom: -1px;
}

.LandingTheme .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.LandingTheme .nav-tabs .nav-link:focus, .LandingTheme .nav-tabs .nav-link:hover {
    border-color: #eaf2fe #eaf2fe #dce0f3;
}

.LandingTheme .nav-tabs .nav-link.disabled {
    color: #575a7b;
    background-color: transparent;
    border-color: transparent;
}

.LandingTheme .nav-tabs .nav-item.show .nav-link, .LandingTheme .nav-tabs .nav-link.active {
    color: #1b1642;
    background-color: #fff;
    border-color: #dce0f3 #dce0f3 #fff;
}

.LandingTheme .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.LandingTheme .nav-pills .nav-link {
    border-radius: .5rem;
}

.LandingTheme .nav-pills .nav-link.active, .LandingTheme .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #6658ea;
}

.LandingTheme .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.LandingTheme .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.LandingTheme .tab-content > .tab-pane {
    display: none;
}

.LandingTheme .tab-content > .active {
    display: block;
}

.LandingTheme .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 2rem 1rem;
}

.LandingTheme .navbar .container, .LandingTheme .navbar .container-fluid, .LandingTheme .navbar .container-lg, .LandingTheme .navbar .container-md, .LandingTheme .navbar .container-sm, .LandingTheme .navbar .container-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.LandingTheme .navbar-brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.75rem;
    line-height: inherit;
    white-space: nowrap;
}

.LandingTheme .navbar-brand:focus, .LandingTheme .navbar-brand:hover {
    text-decoration: none;
}

.LandingTheme .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.LandingTheme .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.LandingTheme .navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.LandingTheme .navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.LandingTheme .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.LandingTheme .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .navbar-toggler:focus, .LandingTheme .navbar-toggler:hover {
    text-decoration: none;
}

.LandingTheme .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .LandingTheme .navbar-expand-sm > .container, .LandingTheme .navbar-expand-sm > .container-fluid, .LandingTheme .navbar-expand-sm > .container-lg, .LandingTheme .navbar-expand-sm > .container-md, .LandingTheme .navbar-expand-sm > .container-sm, .LandingTheme .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .LandingTheme .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .LandingTheme .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .LandingTheme .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }
    .LandingTheme .navbar-expand-sm > .container, .LandingTheme .navbar-expand-sm > .container-fluid, .LandingTheme .navbar-expand-sm > .container-lg, .LandingTheme .navbar-expand-sm > .container-md, .LandingTheme .navbar-expand-sm > .container-sm, .LandingTheme .navbar-expand-sm > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .LandingTheme .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .LandingTheme .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .LandingTheme .navbar-expand-md > .container, .LandingTheme .navbar-expand-md > .container-fluid, .LandingTheme .navbar-expand-md > .container-lg, .LandingTheme .navbar-expand-md > .container-md, .LandingTheme .navbar-expand-md > .container-sm, .LandingTheme .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .LandingTheme .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .LandingTheme .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .LandingTheme .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }
    .LandingTheme .navbar-expand-md > .container, .LandingTheme .navbar-expand-md > .container-fluid, .LandingTheme .navbar-expand-md > .container-lg, .LandingTheme .navbar-expand-md > .container-md, .LandingTheme .navbar-expand-md > .container-sm, .LandingTheme .navbar-expand-md > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .LandingTheme .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .LandingTheme .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .LandingTheme .navbar-expand-lg > .container, .LandingTheme .navbar-expand-lg > .container-fluid, .LandingTheme .navbar-expand-lg > .container-lg, .LandingTheme .navbar-expand-lg > .container-md, .LandingTheme .navbar-expand-lg > .container-sm, .LandingTheme .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .LandingTheme .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .LandingTheme .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .LandingTheme .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }
    .LandingTheme .navbar-expand-lg > .container, .LandingTheme .navbar-expand-lg > .container-fluid, .LandingTheme .navbar-expand-lg > .container-lg, .LandingTheme .navbar-expand-lg > .container-md, .LandingTheme .navbar-expand-lg > .container-sm, .LandingTheme .navbar-expand-lg > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .LandingTheme .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .LandingTheme .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .LandingTheme .navbar-expand-xl > .container, .LandingTheme .navbar-expand-xl > .container-fluid, .LandingTheme .navbar-expand-xl > .container-lg, .LandingTheme .navbar-expand-xl > .container-md, .LandingTheme .navbar-expand-xl > .container-sm, .LandingTheme .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .LandingTheme .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .LandingTheme .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }
    .LandingTheme .navbar-expand-xl > .container, .LandingTheme .navbar-expand-xl > .container-fluid, .LandingTheme .navbar-expand-xl > .container-lg, .LandingTheme .navbar-expand-xl > .container-md, .LandingTheme .navbar-expand-xl > .container-sm, .LandingTheme .navbar-expand-xl > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .LandingTheme .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .LandingTheme .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.LandingTheme .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.LandingTheme .navbar-expand > .container, .LandingTheme .navbar-expand > .container-fluid, .LandingTheme .navbar-expand > .container-lg, .LandingTheme .navbar-expand > .container-md, .LandingTheme .navbar-expand > .container-sm, .LandingTheme .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
}

.LandingTheme .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.LandingTheme .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.LandingTheme .navbar-expand .navbar-nav .nav-link {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
}

.LandingTheme .navbar-expand > .container, .LandingTheme .navbar-expand > .container-fluid, .LandingTheme .navbar-expand > .container-lg, .LandingTheme .navbar-expand > .container-md, .LandingTheme .navbar-expand > .container-sm, .LandingTheme .navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.LandingTheme .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.LandingTheme .navbar-expand .navbar-toggler {
    display: none;
}

.LandingTheme .navbar-light .navbar-brand {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-brand:focus, .LandingTheme .navbar-light .navbar-brand:hover {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-nav .nav-link {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-nav .nav-link:focus, .LandingTheme .navbar-light .navbar-nav .nav-link:hover {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-nav .nav-link.disabled {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-nav .active > .nav-link, .LandingTheme .navbar-light .navbar-nav .nav-link.active, .LandingTheme .navbar-light .navbar-nav .nav-link.show, .LandingTheme .navbar-light .navbar-nav .show > .nav-link {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-toggler {
    color: #1b1642;
    border-color: transparent;
}

.LandingTheme .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23575A7B' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.LandingTheme .navbar-light .navbar-text {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-text a {
    color: #1b1642;
}

.LandingTheme .navbar-light .navbar-text a:focus, .LandingTheme .navbar-light .navbar-text a:hover {
    color: #1b1642;
}

.LandingTheme .navbar-dark .navbar-brand {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-brand:focus, .LandingTheme .navbar-dark .navbar-brand:hover {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-nav .nav-link:focus, .LandingTheme .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-nav .nav-link.disabled {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-nav .active > .nav-link, .LandingTheme .navbar-dark .navbar-nav .nav-link.active, .LandingTheme .navbar-dark .navbar-nav .nav-link.show, .LandingTheme .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-toggler {
    color: #fff;
    border-color: transparent;
}

.LandingTheme .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.LandingTheme .navbar-dark .navbar-text {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-text a {
    color: #fff;
}

.LandingTheme .navbar-dark .navbar-text a:focus, .LandingTheme .navbar-dark .navbar-text a:hover {
    color: #fff;
}

.LandingTheme .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(27, 22, 66, 0.125);
    border-radius: 0;
}

.LandingTheme .card > hr {
    margin-right: 0;
    margin-left: 0;
}

.LandingTheme .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.LandingTheme .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 3rem;
}

.LandingTheme .card-title {
    margin-bottom: 2rem;
}

.LandingTheme .card-subtitle {
    margin-top: -1rem;
    margin-bottom: 0;
}

.LandingTheme .card-text:last-child {
    margin-bottom: 0;
}

.LandingTheme .card-link:hover {
    text-decoration: none;
}

.LandingTheme .card-link + .card-link {
    margin-left: 3rem;
}

.LandingTheme .card-header {
    padding: 2rem 3rem;
    margin-bottom: 0;
    background-color: rgba(27, 22, 66, 0.03);
    border-bottom: 0 solid rgba(27, 22, 66, 0.125);
}

.LandingTheme .card-header:first-child {
    border-radius: 0;
}

.LandingTheme .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

.LandingTheme .card-footer {
    padding: 2rem 3rem;
    background-color: rgba(27, 22, 66, 0.03);
    border-top: 0 solid rgba(27, 22, 66, 0.125);
}

.LandingTheme .card-footer:last-child {
    border-radius: 0;
}

.LandingTheme .card-header-tabs {
    margin-right: -1.5rem;
    margin-bottom: -2rem;
    margin-left: -1.5rem;
    border-bottom: 0;
}

.LandingTheme .card-header-pills {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.LandingTheme .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.LandingTheme .card-img, .LandingTheme .card-img-bottom, .LandingTheme .card-img-top {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
}

.LandingTheme .card-img, .LandingTheme .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.LandingTheme .card-img, .LandingTheme .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .card-deck .card {
    margin-bottom: 16px;
}

@media (min-width: 576px) {
    .LandingTheme .card-deck {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -16px;
        margin-left: -16px;
    }
    .LandingTheme .card-deck .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 16px;
        margin-bottom: 0;
        margin-left: 16px;
    }
}

.LandingTheme .card-group > .card {
    margin-bottom: 16px;
}

@media (min-width: 576px) {
    .LandingTheme .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .LandingTheme .card-group > .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .LandingTheme .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .LandingTheme .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .LandingTheme .card-group > .card:not(:last-child) .card-header, .LandingTheme .card-group > .card:not(:last-child) .card-img-top {
        border-top-right-radius: 0;
    }
    .LandingTheme .card-group > .card:not(:last-child) .card-footer, .LandingTheme .card-group > .card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }
    .LandingTheme .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .LandingTheme .card-group > .card:not(:first-child) .card-header, .LandingTheme .card-group > .card:not(:first-child) .card-img-top {
        border-top-left-radius: 0;
    }
    .LandingTheme .card-group > .card:not(:first-child) .card-footer, .LandingTheme .card-group > .card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }
}

.LandingTheme .card-columns .card {
    margin-bottom: 2rem;
}

@media (min-width: 576px) {
    .LandingTheme .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .LandingTheme .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.LandingTheme .accordion > .card {
    overflow: hidden;
}

.LandingTheme .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.LandingTheme .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.LandingTheme .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0;
}

.LandingTheme .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    font-size: .875rem;
    list-style: none;
    background-color: #f3f8ff;
    border-radius: .5rem;
}

.LandingTheme .breadcrumb-item + .breadcrumb-item {
    padding-left: .625rem;
}

.LandingTheme .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .625rem;
    color: #575a7b;
    content: "";
}

.LandingTheme .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

.LandingTheme .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

.LandingTheme .breadcrumb-item.active {
    color: #1b1642;
}

.LandingTheme .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 1rem;
}

.LandingTheme .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #6658ea;
    background-color: #fff;
    border: 1px solid #eaf2fe;
}

.LandingTheme .page-link:hover {
    z-index: 2;
    color: #5546e8;
    text-decoration: none;
    background-color: #f3f8ff;
    border-color: #dce0f3;
}

.LandingTheme .page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25);
    box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.25);
}

.LandingTheme .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.LandingTheme .page-item:last-child .page-link {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.LandingTheme .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .page-item.disabled .page-link {
    color: #575a7b;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dce0f3;
}

.LandingTheme .pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.LandingTheme .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.LandingTheme .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.LandingTheme .pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: 1rem;
    line-height: 1.5;
}

.LandingTheme .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.LandingTheme .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.LandingTheme .badge {
    display: inline-block;
    padding: .3em .6em;
    font-size: 75%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .badge {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme a.badge:focus, .LandingTheme a.badge:hover {
    text-decoration: none;
}

.LandingTheme .badge:empty {
    display: none;
}

.LandingTheme .btn .badge {
    position: relative;
    top: -1px;
}

.LandingTheme .badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
}

.LandingTheme .badge-primary {
    color: #fff;
    background-color: #6658ea;
}

.LandingTheme a.badge-primary:focus, .LandingTheme a.badge-primary:hover {
    color: #fff;
    background-color: #3c2be4;
}

.LandingTheme a.badge-primary.focus, .LandingTheme a.badge-primary:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(102, 88, 234, 0.5);
}

.LandingTheme .badge-secondary {
    color: #fff;
    background-color: #575a7b;
}

.LandingTheme a.badge-secondary:focus, .LandingTheme a.badge-secondary:hover {
    color: #fff;
    background-color: #42445d;
}

.LandingTheme a.badge-secondary.focus, .LandingTheme a.badge-secondary:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(87, 90, 123, 0.5);
    box-shadow: 0 0 0 0 rgba(87, 90, 123, 0.5);
}

.LandingTheme .badge-success {
    color: #fff;
    background-color: #2cd4a2;
}

.LandingTheme a.badge-success:focus, .LandingTheme a.badge-success:hover {
    color: #fff;
    background-color: #23aa82;
}

.LandingTheme a.badge-success.focus, .LandingTheme a.badge-success:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.5);
    box-shadow: 0 0 0 0 rgba(44, 212, 162, 0.5);
}

.LandingTheme .badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.LandingTheme a.badge-info:focus, .LandingTheme a.badge-info:hover {
    color: #fff;
    background-color: #117a8b;
}

.LandingTheme a.badge-info.focus, .LandingTheme a.badge-info:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.LandingTheme .badge-warning {
    color: #fff;
    background-color: #f5c070;
}

.LandingTheme a.badge-warning:focus, .LandingTheme a.badge-warning:hover {
    color: #fff;
    background-color: #f2ab40;
}

.LandingTheme a.badge-warning.focus, .LandingTheme a.badge-warning:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(245, 192, 112, 0.5);
    box-shadow: 0 0 0 0 rgba(245, 192, 112, 0.5);
}

.LandingTheme .badge-danger {
    color: #fff;
    background-color: #f9655b;
}

.LandingTheme a.badge-danger:focus, .LandingTheme a.badge-danger:hover {
    color: #fff;
    background-color: #f7372a;
}

.LandingTheme a.badge-danger.focus, .LandingTheme a.badge-danger:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.5);
    box-shadow: 0 0 0 0 rgba(249, 101, 91, 0.5);
}

.LandingTheme .badge-light {
    color: #1b1642;
    background-color: #f3f8ff;
}

.LandingTheme a.badge-light:focus, .LandingTheme a.badge-light:hover {
    color: #1b1642;
    background-color: #c0daff;
}

.LandingTheme a.badge-light.focus, .LandingTheme a.badge-light:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(243, 248, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 248, 255, 0.5);
}

.LandingTheme .badge-dark {
    color: #fff;
    background-color: #1b1642;
}

.LandingTheme a.badge-dark:focus, .LandingTheme a.badge-dark:hover {
    color: #fff;
    background-color: #0b091c;
}

.LandingTheme a.badge-dark.focus, .LandingTheme a.badge-dark:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(27, 22, 66, 0.5);
    box-shadow: 0 0 0 0 rgba(27, 22, 66, 0.5);
}

.LandingTheme .badge-primary-dark {
    color: #fff;
    background-color: #564caf;
}

.LandingTheme a.badge-primary-dark:focus, .LandingTheme a.badge-primary-dark:hover {
    color: #fff;
    background-color: #453d8b;
}

.LandingTheme a.badge-primary-dark.focus, .LandingTheme a.badge-primary-dark:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(86, 76, 175, 0.5);
    box-shadow: 0 0 0 0 rgba(86, 76, 175, 0.5);
}

.LandingTheme .badge-primary-light {
    color: #fff;
    background-color: #687ded;
}

.LandingTheme a.badge-primary-light:focus, .LandingTheme a.badge-primary-light:hover {
    color: #fff;
    background-color: #3a56e8;
}

.LandingTheme a.badge-primary-light.focus, .LandingTheme a.badge-primary-light:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(104, 125, 237, 0.5);
    box-shadow: 0 0 0 0 rgba(104, 125, 237, 0.5);
}

.LandingTheme .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eaf2fe;
    border-radius: 2rem;
}

@media (min-width: 576px) {
    .LandingTheme .jumbotron {
        padding: 4rem 2rem;
    }
}

.LandingTheme .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.LandingTheme .alert {
    position: relative;
    padding: 1rem 1.25rem;
    margin-bottom: 1rem;
    border: 0 solid transparent;
    border-radius: .5rem;
}

.LandingTheme .alert-heading {
    color: inherit;
}

.LandingTheme .alert-link {
    font-weight: 500;
}

.LandingTheme .alert-dismissible {
    padding-right: 3.625rem;
}

.LandingTheme .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1.25rem;
    color: inherit;
}

.LandingTheme .alert-primary {
    color: #6658ea;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .alert-primary hr {
    border-top-color: #5141e7;
}

.LandingTheme .alert-primary .alert-link {
    color: #3c2be4;
}

.LandingTheme .alert-secondary {
    color: #575a7b;
    background-color: #575a7b;
    border-color: #575a7b;
}

.LandingTheme .alert-secondary hr {
    border-top-color: #4c4f6c;
}

.LandingTheme .alert-secondary .alert-link {
    color: #42445d;
}

.LandingTheme .alert-success {
    color: #2cd4a2;
    background-color: #2cd4a2;
    border-color: #2cd4a2;
}

.LandingTheme .alert-success hr {
    border-top-color: #27bf92;
}

.LandingTheme .alert-success .alert-link {
    color: #23aa82;
}

.LandingTheme .alert-info {
    color: #17a2b8;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.LandingTheme .alert-info hr {
    border-top-color: #148ea1;
}

.LandingTheme .alert-info .alert-link {
    color: #117a8b;
}

.LandingTheme .alert-warning {
    color: #f5c070;
    background-color: #f5c070;
    border-color: #f5c070;
}

.LandingTheme .alert-warning hr {
    border-top-color: #f3b658;
}

.LandingTheme .alert-warning .alert-link {
    color: #f2ab40;
}

.LandingTheme .alert-danger {
    color: #f9655b;
    background-color: #f9655b;
    border-color: #f9655b;
}

.LandingTheme .alert-danger hr {
    border-top-color: #f84e42;
}

.LandingTheme .alert-danger .alert-link {
    color: #f7372a;
}

.LandingTheme .alert-light {
    color: #f3f8ff;
    background-color: #f3f8ff;
    border-color: #f3f8ff;
}

.LandingTheme .alert-light hr {
    border-top-color: #dae9ff;
}

.LandingTheme .alert-light .alert-link {
    color: #c0daff;
}

.LandingTheme .alert-dark {
    color: #1b1642;
    background-color: #1b1642;
    border-color: #1b1642;
}

.LandingTheme .alert-dark hr {
    border-top-color: #13102f;
}

.LandingTheme .alert-dark .alert-link {
    color: #0b091c;
}

.LandingTheme .alert-primary-dark {
    color: #564caf;
    background-color: #564caf;
    border-color: #564caf;
}

.LandingTheme .alert-primary-dark hr {
    border-top-color: #4d449d;
}

.LandingTheme .alert-primary-dark .alert-link {
    color: #453d8b;
}

.LandingTheme .alert-primary-light {
    color: #687ded;
    background-color: #687ded;
    border-color: #687ded;
}

.LandingTheme .alert-primary-light hr {
    border-top-color: #5169ea;
}

.LandingTheme .alert-primary-light .alert-link {
    color: #3a56e8;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.LandingTheme .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .84375rem;
    background-color: #eaf2fe;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(27, 22, 66, 0.1);
    box-shadow: inset 0 0.1rem 0.1rem rgba(27, 22, 66, 0.1);
}

.LandingTheme .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #6658ea;
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .progress-bar {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .progress-bar-striped {
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.LandingTheme .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.LandingTheme .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.LandingTheme .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.LandingTheme .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.LandingTheme .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.LandingTheme .list-group-item-action:focus, .LandingTheme .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f3f8ff;
}

.LandingTheme .list-group-item-action:active {
    color: #1b1642;
    background-color: #eaf2fe;
}

.LandingTheme .list-group-item {
    position: relative;
    display: block;
    padding: 1.5rem 1.25rem;
    background-color: transparent;
    border: 1px solid #eaf2fe;
}

.LandingTheme .list-group-item:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.LandingTheme .list-group-item:last-child {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.LandingTheme .list-group-item.disabled, .LandingTheme .list-group-item:disabled {
    color: #575a7b;
    pointer-events: none;
    background-color: transparent;
}

.LandingTheme .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .list-group-item + .list-group-item {
    border-top-width: 0;
}

.LandingTheme .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.LandingTheme .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.LandingTheme .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0;
}

.LandingTheme .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0;
}

.LandingTheme .list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.LandingTheme .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.LandingTheme .list-group-horizontal .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .LandingTheme .list-group-horizontal-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
    }
    .LandingTheme .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
    }
    .LandingTheme .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }
    .LandingTheme .list-group-horizontal-sm .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .LandingTheme .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .LandingTheme .list-group-horizontal-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
    }
    .LandingTheme .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
    }
    .LandingTheme .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }
    .LandingTheme .list-group-horizontal-md .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .LandingTheme .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .LandingTheme .list-group-horizontal-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
    }
    .LandingTheme .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
    }
    .LandingTheme .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }
    .LandingTheme .list-group-horizontal-lg .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .LandingTheme .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .list-group-horizontal-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .LandingTheme .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
    }
    .LandingTheme .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
    }
    .LandingTheme .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }
    .LandingTheme .list-group-horizontal-xl .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .LandingTheme .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.LandingTheme .list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.LandingTheme .list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.LandingTheme .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.LandingTheme .list-group-item-primary {
    color: #423899;
    background-color: #d4d0f9;
}

.LandingTheme .list-group-item-primary.list-group-item-action:focus, .LandingTheme .list-group-item-primary.list-group-item-action:hover {
    color: #423899;
    background-color: #bfb9f6;
}

.LandingTheme .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #423899;
    border-color: #423899;
}

.LandingTheme .list-group-item-secondary {
    color: #3a3960;
    background-color: #d0d1da;
}

.LandingTheme .list-group-item-secondary.list-group-item-action:focus, .LandingTheme .list-group-item-secondary.list-group-item-action:hover {
    color: #3a3960;
    background-color: #c2c3cf;
}

.LandingTheme .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3a3960;
    border-color: #3a3960;
}

.LandingTheme .list-group-item-success {
    color: #247974;
    background-color: #c4f3e5;
}

.LandingTheme .list-group-item-success.list-group-item-action:focus, .LandingTheme .list-group-item-success.list-group-item-action:hover {
    color: #247974;
    background-color: #afefdc;
}

.LandingTheme .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #247974;
    border-color: #247974;
}

.LandingTheme .list-group-item-info {
    color: #195f7f;
    background-color: #bee5eb;
}

.LandingTheme .list-group-item-info.list-group-item-action:focus, .LandingTheme .list-group-item-info.list-group-item-action:hover {
    color: #195f7f;
    background-color: #abdde5;
}

.LandingTheme .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #195f7f;
    border-color: #195f7f;
}

.LandingTheme .list-group-item-warning {
    color: #8c6e5a;
    background-color: #fcedd7;
}

.LandingTheme .list-group-item-warning.list-group-item-action:focus, .LandingTheme .list-group-item-warning.list-group-item-action:hover {
    color: #8c6e5a;
    background-color: #fae2bf;
}

.LandingTheme .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8c6e5a;
    border-color: #8c6e5a;
}

.LandingTheme .list-group-item-danger {
    color: #8e3f4f;
    background-color: #fdd4d1;
}

.LandingTheme .list-group-item-danger.list-group-item-action:focus, .LandingTheme .list-group-item-danger.list-group-item-action:hover {
    color: #8e3f4f;
    background-color: #fcbdb9;
}

.LandingTheme .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8e3f4f;
    border-color: #8e3f4f;
}

.LandingTheme .list-group-item-light {
    color: #8b8ca4;
    background-color: #fcfdff;
}

.LandingTheme .list-group-item-light.list-group-item-action:focus, .LandingTheme .list-group-item-light.list-group-item-action:hover {
    color: #8b8ca4;
    background-color: #e3ecff;
}

.LandingTheme .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8b8ca4;
    border-color: #8b8ca4;
}

.LandingTheme .list-group-item-dark {
    color: #1b1642;
    background-color: #bfbeca;
}

.LandingTheme .list-group-item-dark.list-group-item-action:focus, .LandingTheme .list-group-item-dark.list-group-item-action:hover {
    color: #1b1642;
    background-color: #b1b0bf;
}

.LandingTheme .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642;
}

.LandingTheme .list-group-item-primary-dark {
    color: #3a327b;
    background-color: #d0cde9;
}

.LandingTheme .list-group-item-primary-dark.list-group-item-action:focus, .LandingTheme .list-group-item-primary-dark.list-group-item-action:hover {
    color: #3a327b;
    background-color: #bfbbe1;
}

.LandingTheme .list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #3a327b;
    border-color: #3a327b;
}

.LandingTheme .list-group-item-primary-light {
    color: #434c9b;
    background-color: #d5dbfa;
}

.LandingTheme .list-group-item-primary-light.list-group-item-action:focus, .LandingTheme .list-group-item-primary-light.list-group-item-action:hover {
    color: #434c9b;
    background-color: #bec7f7;
}

.LandingTheme .list-group-item-primary-light.list-group-item-action.active {
    color: #fff;
    background-color: #434c9b;
    border-color: #434c9b;
}

.LandingTheme .close {
    float: right;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1;
    color: currentColor;
    text-shadow: none;
    opacity: .5;
}

.LandingTheme .close:hover {
    color: currentColor;
    text-decoration: none;
}

.LandingTheme .close:not(:disabled):not(.disabled):focus, .LandingTheme .close:not(:disabled):not(.disabled):hover {
    opacity: .75;
}

.LandingTheme button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.LandingTheme a.close.disabled {
    pointer-events: none;
}

.LandingTheme .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    color: #575a7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .5rem 0 .5rem 0;
}

.LandingTheme .toast:not(:last-child) {
    margin-bottom: 1rem;
}

.LandingTheme .toast.showing {
    opacity: 1;
}

.LandingTheme .toast.show {
    display: block;
    opacity: 1;
}

.LandingTheme .toast.hide {
    display: none;
}

.LandingTheme .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.25rem 1rem;
    color: #1b1642;
    background-color: transparent;
    background-clip: padding-box;
    border-bottom: 0 solid #eaf2fe;
}

.LandingTheme .toast-body {
    padding: 1rem;
}

.LandingTheme .modal-open {
    overflow: hidden;
}

.LandingTheme .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.LandingTheme .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.LandingTheme .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

.LandingTheme .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    -o-transition: transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.LandingTheme .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
}

.LandingTheme .modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
}

.LandingTheme .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.LandingTheme .modal-dialog-scrollable .modal-footer, .LandingTheme .modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.LandingTheme .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.LandingTheme .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.LandingTheme .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.LandingTheme .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.LandingTheme .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.LandingTheme .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.LandingTheme .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(27, 22, 66, 0.2);
    border-radius: 1rem 0 1rem 0;
    -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    outline: 0;
}

.LandingTheme .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #1b1642;
}

.LandingTheme .modal-backdrop.fade {
    opacity: 0;
}

.LandingTheme .modal-backdrop.show {
    opacity: .9;
}

.LandingTheme .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0 solid #eaf2fe;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.LandingTheme .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.LandingTheme .modal-title {
    margin-bottom: 0;
    line-height: 1.6;
}

.LandingTheme .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 3rem;
}

.LandingTheme .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 2.75rem;
    border-top: 0 solid #eaf2fe;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.LandingTheme .modal-footer > * {
    margin: .25rem;
}

.LandingTheme .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .LandingTheme .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .LandingTheme .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .LandingTheme .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .LandingTheme .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .LandingTheme .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .LandingTheme .modal-content {
        -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
        box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    }
    .LandingTheme .modal-sm {
        max-width: 420px;
    }
}

@media (min-width: 992px) {
    .LandingTheme .modal-lg, .LandingTheme .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .modal-xl {
        max-width: 1140px;
    }
}

.LandingTheme .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "HKGroteskPro",sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 1rem;
    word-wrap: break-word;
    opacity: 0;
}

.LandingTheme .tooltip.show {
    opacity: 1;
}

.LandingTheme .tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem;
}

.LandingTheme .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.LandingTheme .bs-tooltip-auto[x-placement^=top], .LandingTheme .bs-tooltip-top {
    padding: .4rem 0;
}

.LandingTheme .bs-tooltip-auto[x-placement^=top] .arrow, .LandingTheme .bs-tooltip-top .arrow {
    bottom: 0;
}

.LandingTheme .bs-tooltip-auto[x-placement^=top] .arrow::before, .LandingTheme .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #1b1642;
}

.LandingTheme .bs-tooltip-auto[x-placement^=right], .LandingTheme .bs-tooltip-right {
    padding: 0 .4rem;
}

.LandingTheme .bs-tooltip-auto[x-placement^=right] .arrow, .LandingTheme .bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem;
}

.LandingTheme .bs-tooltip-auto[x-placement^=right] .arrow::before, .LandingTheme .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #1b1642;
}

.LandingTheme .bs-tooltip-auto[x-placement^=bottom], .LandingTheme .bs-tooltip-bottom {
    padding: .4rem 0;
}

.LandingTheme .bs-tooltip-auto[x-placement^=bottom] .arrow, .LandingTheme .bs-tooltip-bottom .arrow {
    top: 0;
}

.LandingTheme .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .LandingTheme .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #1b1642;
}

.LandingTheme .bs-tooltip-auto[x-placement^=left], .LandingTheme .bs-tooltip-left {
    padding: 0 .4rem;
}

.LandingTheme .bs-tooltip-auto[x-placement^=left] .arrow, .LandingTheme .bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem;
}

.LandingTheme .bs-tooltip-auto[x-placement^=left] .arrow::before, .LandingTheme .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #1b1642;
}

.LandingTheme .tooltip-inner {
    max-width: 300px;
    padding: .5rem 1rem;
    color: #fff;
    text-align: center;
    background-color: #1b1642;
    border-radius: .375rem;
}

.LandingTheme .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "HKGroteskPro",sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 1.125rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(27, 22, 66, 0.2);
    border-radius: .5rem;
    -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
}

.LandingTheme .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .5rem;
}

.LandingTheme .popover .arrow::after, .LandingTheme .popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.LandingTheme .bs-popover-auto[x-placement^=top], .LandingTheme .bs-popover-top {
    margin-bottom: .5rem;
}

.LandingTheme .bs-popover-auto[x-placement^=top] > .arrow, .LandingTheme .bs-popover-top > .arrow {
    bottom: -.5rem;
}

.LandingTheme .bs-popover-auto[x-placement^=top] > .arrow::before, .LandingTheme .bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(27, 22, 66, 0.25);
}

.LandingTheme .bs-popover-auto[x-placement^=top] > .arrow::after, .LandingTheme .bs-popover-top > .arrow::after {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: #fff;
}

.LandingTheme .bs-popover-auto[x-placement^=right], .LandingTheme .bs-popover-right {
    margin-left: .5rem;
}

.LandingTheme .bs-popover-auto[x-placement^=right] > .arrow, .LandingTheme .bs-popover-right > .arrow {
    left: -.5rem;
    width: .5rem;
    height: 1rem;
    margin: .5rem 0;
}

.LandingTheme .bs-popover-auto[x-placement^=right] > .arrow::before, .LandingTheme .bs-popover-right > .arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(27, 22, 66, 0.25);
}

.LandingTheme .bs-popover-auto[x-placement^=right] > .arrow::after, .LandingTheme .bs-popover-right > .arrow::after {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff;
}

.LandingTheme .bs-popover-auto[x-placement^=bottom], .LandingTheme .bs-popover-bottom {
    margin-top: .5rem;
}

.LandingTheme .bs-popover-auto[x-placement^=bottom] > .arrow, .LandingTheme .bs-popover-bottom > .arrow {
    top: -.5rem;
}

.LandingTheme .bs-popover-auto[x-placement^=bottom] > .arrow::before, .LandingTheme .bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(27, 22, 66, 0.25);
}

.LandingTheme .bs-popover-auto[x-placement^=bottom] > .arrow::after, .LandingTheme .bs-popover-bottom > .arrow::after {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff;
}

.LandingTheme .bs-popover-auto[x-placement^=bottom] .popover-header::before, .LandingTheme .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 0 solid #f7f7f7;
}

.LandingTheme .bs-popover-auto[x-placement^=left], .LandingTheme .bs-popover-left {
    margin-right: .5rem;
}

.LandingTheme .bs-popover-auto[x-placement^=left] > .arrow, .LandingTheme .bs-popover-left > .arrow {
    right: -.5rem;
    width: .5rem;
    height: 1rem;
    margin: .5rem 0;
}

.LandingTheme .bs-popover-auto[x-placement^=left] > .arrow::before, .LandingTheme .bs-popover-left > .arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(27, 22, 66, 0.25);
}

.LandingTheme .bs-popover-auto[x-placement^=left] > .arrow::after, .LandingTheme .bs-popover-left > .arrow::after {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff;
}

.LandingTheme .popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    background-color: #f7f7f7;
    border-bottom: 0 solid #ebebeb;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.LandingTheme .popover-header:empty {
    display: none;
}

.LandingTheme .popover-body {
    padding: 1.5rem 1.5rem;
    color: #1b1642;
}

.LandingTheme .carousel {
    position: relative;
}

.LandingTheme .carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.LandingTheme .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.LandingTheme .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.LandingTheme .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    -o-transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .carousel-item {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .carousel-item-next, .LandingTheme .carousel-item-prev, .LandingTheme .carousel-item.active {
    display: block;
}

.LandingTheme .active.carousel-item-right, .LandingTheme .carousel-item-next:not(.carousel-item-left) {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.LandingTheme .active.carousel-item-left, .LandingTheme .carousel-item-prev:not(.carousel-item-right) {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.LandingTheme .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.LandingTheme .carousel-fade .carousel-item-next.carousel-item-left, .LandingTheme .carousel-fade .carousel-item-prev.carousel-item-right, .LandingTheme .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
}

.LandingTheme .carousel-fade .active.carousel-item-left, .LandingTheme .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s .6s;
    -o-transition: opacity 0s .6s;
    transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .carousel-fade .active.carousel-item-left, .LandingTheme .carousel-fade .active.carousel-item-right {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .carousel-control-next, .LandingTheme .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    -o-transition: opacity .15s ease;
    transition: opacity .15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .carousel-control-next, .LandingTheme .carousel-control-prev {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .carousel-control-next:focus, .LandingTheme .carousel-control-next:hover, .LandingTheme .carousel-control-prev:focus, .LandingTheme .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

.LandingTheme .carousel-control-prev {
    left: 0;
}

.LandingTheme .carousel-control-next {
    right: 0;
}

.LandingTheme .carousel-control-next-icon, .LandingTheme .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}

.LandingTheme .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.LandingTheme .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.LandingTheme .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.LandingTheme .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity .6s ease;
    -o-transition: opacity .6s ease;
    transition: opacity .6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .LandingTheme .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.LandingTheme .carousel-indicators .active {
    opacity: 1;
}

.LandingTheme .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.LandingTheme .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.LandingTheme .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.LandingTheme .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 1.5s linear infinite;
    animation: spinner-grow 1.5s linear infinite;
    /* -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite; */
}

.LandingTheme .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.LandingTheme .align-baseline {
    vertical-align: baseline !important;
}

.LandingTheme .align-top {
    vertical-align: top !important;
}

.LandingTheme .align-middle {
    vertical-align: middle !important;
}

.LandingTheme .align-bottom {
    vertical-align: bottom !important;
}

.LandingTheme .align-text-bottom {
    vertical-align: text-bottom !important;
}

.LandingTheme .align-text-top {
    vertical-align: text-top !important;
}

.LandingTheme .bg-primary {
    background-color: #6658ea !important;
}

.LandingTheme a.bg-primary:focus, .LandingTheme a.bg-primary:hover, .LandingTheme button.bg-primary:focus, .LandingTheme button.bg-primary:hover {
    background-color: #3c2be4 !important;
}

.LandingTheme .bg-secondary {
    background-color: #575a7b !important;
}

.LandingTheme a.bg-secondary:focus, .LandingTheme a.bg-secondary:hover, .LandingTheme button.bg-secondary:focus, .LandingTheme button.bg-secondary:hover {
    background-color: #42445d !important;
}

.LandingTheme .bg-success {
    background-color: #2cd4a2 !important;
}

.LandingTheme a.bg-success:focus, .LandingTheme a.bg-success:hover, .LandingTheme button.bg-success:focus, .LandingTheme button.bg-success:hover {
    background-color: #23aa82 !important;
}

.LandingTheme .bg-info {
    background-color: #17a2b8 !important;
}

.LandingTheme a.bg-info:focus, .LandingTheme a.bg-info:hover, .LandingTheme button.bg-info:focus, .LandingTheme button.bg-info:hover {
    background-color: #117a8b !important;
}

.LandingTheme .bg-warning {
    background-color: #f5c070 !important;
}

.LandingTheme a.bg-warning:focus, .LandingTheme a.bg-warning:hover, .LandingTheme button.bg-warning:focus, .LandingTheme button.bg-warning:hover {
    background-color: #f2ab40 !important;
}

.LandingTheme .bg-danger {
    background-color: #f9655b !important;
}

.LandingTheme a.bg-danger:focus, .LandingTheme a.bg-danger:hover, .LandingTheme button.bg-danger:focus, .LandingTheme button.bg-danger:hover {
    background-color: #f7372a !important;
}

.LandingTheme .bg-light {
    background-color: #f3f8ff !important;
}

.LandingTheme a.bg-light:focus, .LandingTheme a.bg-light:hover, .LandingTheme button.bg-light:focus, .LandingTheme button.bg-light:hover {
    background-color: #c0daff !important;
}

.LandingTheme .bg-dark {
    background-color: #1b1642 !important;
}

.LandingTheme a.bg-dark:focus, .LandingTheme a.bg-dark:hover, .LandingTheme button.bg-dark:focus, .LandingTheme button.bg-dark:hover {
    background-color: #0b091c !important;
}

.LandingTheme .bg-primary-dark {
    background-color: #564caf !important;
}

.LandingTheme a.bg-primary-dark:focus, .LandingTheme a.bg-primary-dark:hover, .LandingTheme button.bg-primary-dark:focus, .LandingTheme button.bg-primary-dark:hover {
    background-color: #453d8b !important;
}

.LandingTheme .bg-primary-light {
    background-color: #687ded !important;
}

.LandingTheme a.bg-primary-light:focus, .LandingTheme a.bg-primary-light:hover, .LandingTheme button.bg-primary-light:focus, .LandingTheme button.bg-primary-light:hover {
    background-color: #3a56e8 !important;
}

.LandingTheme .bg-white {
    background-color: #fff !important;
}

.LandingTheme .bg-transparent {
    background-color: transparent !important;
}

.LandingTheme .border {
    border: 1px solid #eaf2fe !important;
}

.LandingTheme .border-top {
    border-top: 1px solid #eaf2fe !important;
}

.LandingTheme .border-right {
    border-right: 1px solid #eaf2fe !important;
}

.LandingTheme .border-bottom {
    border-bottom: 1px solid #eaf2fe !important;
}

.LandingTheme .border-left {
    border-left: 1px solid #eaf2fe !important;
}

.LandingTheme .border-0 {
    border: 0 !important;
}

.LandingTheme .border-top-0 {
    border-top: 0 !important;
}

.LandingTheme .border-right-0 {
    border-right: 0 !important;
}

.LandingTheme .border-bottom-0 {
    border-bottom: 0 !important;
}

.LandingTheme .border-left-0 {
    border-left: 0 !important;
}

.LandingTheme .border-primary {
    border-color: #6658ea !important;
}

.LandingTheme .border-secondary {
    border-color: #575a7b !important;
}

.LandingTheme .border-success {
    border-color: #2cd4a2 !important;
}

.LandingTheme .border-info {
    border-color: #17a2b8 !important;
}

.LandingTheme .border-warning {
    border-color: #f5c070 !important;
}

.LandingTheme .border-danger {
    border-color: #f9655b !important;
}

.LandingTheme .border-light {
    border-color: #f3f8ff !important;
}

.LandingTheme .border-dark {
    border-color: #1b1642 !important;
}

.LandingTheme .border-primary-dark {
    border-color: #564caf !important;
}

.LandingTheme .border-primary-light {
    border-color: #687ded !important;
}

.LandingTheme .border-white {
    border-color: #fff !important;
}

.LandingTheme .rounded-sm {
    border-radius: .5rem !important;
}

.LandingTheme .rounded {
    border-radius: 1rem !important;
}

.LandingTheme .rounded-top {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.LandingTheme .rounded-right {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.LandingTheme .rounded-bottom {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.LandingTheme .rounded-left {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.LandingTheme .rounded-lg {
    border-radius: 2rem !important;
}

.LandingTheme .rounded-circle {
    border-radius: 50% !important;
}

.LandingTheme .rounded-pill {
    border-radius: 50rem !important;
}

.LandingTheme .rounded-0 {
    border-radius: 0 !important;
}

.LandingTheme .clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.LandingTheme .d-none {
    display: none !important;
}

.LandingTheme .d-inline {
    display: inline !important;
}

.LandingTheme .d-inline-block {
    display: inline-block !important;
}

.LandingTheme .d-block {
    display: block !important;
}

.LandingTheme .d-table {
    display: table !important;
}

.LandingTheme .d-table-row {
    display: table-row !important;
}

.LandingTheme .d-table-cell {
    display: table-cell !important;
}

.LandingTheme .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.LandingTheme .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .LandingTheme .d-sm-none {
        display: none !important;
    }
    .LandingTheme .d-sm-inline {
        display: inline !important;
    }
    .LandingTheme .d-sm-inline-block {
        display: inline-block !important;
    }
    .LandingTheme .d-sm-block {
        display: block !important;
    }
    .LandingTheme .d-sm-table {
        display: table !important;
    }
    .LandingTheme .d-sm-table-row {
        display: table-row !important;
    }
    .LandingTheme .d-sm-table-cell {
        display: table-cell !important;
    }
    .LandingTheme .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .LandingTheme .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .d-md-none {
        display: none !important;
    }
    .LandingTheme .d-md-inline {
        display: inline !important;
    }
    .LandingTheme .d-md-inline-block {
        display: inline-block !important;
    }
    .LandingTheme .d-md-block {
        display: block !important;
    }
    .LandingTheme .d-md-table {
        display: table !important;
    }
    .LandingTheme .d-md-table-row {
        display: table-row !important;
    }
    .LandingTheme .d-md-table-cell {
        display: table-cell !important;
    }
    .LandingTheme .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .LandingTheme .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .d-lg-none {
        display: none !important;
    }
    .LandingTheme .d-lg-inline {
        display: inline !important;
    }
    .LandingTheme .d-lg-inline-block {
        display: inline-block !important;
    }
    .LandingTheme .d-lg-block {
        display: block !important;
    }
    .LandingTheme .d-lg-table {
        display: table !important;
    }
    .LandingTheme .d-lg-table-row {
        display: table-row !important;
    }
    .LandingTheme .d-lg-table-cell {
        display: table-cell !important;
    }
    .LandingTheme .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .LandingTheme .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .d-xl-none {
        display: none !important;
    }
    .LandingTheme .d-xl-inline {
        display: inline !important;
    }
    .LandingTheme .d-xl-inline-block {
        display: inline-block !important;
    }
    .LandingTheme .d-xl-block {
        display: block !important;
    }
    .LandingTheme .d-xl-table {
        display: table !important;
    }
    .LandingTheme .d-xl-table-row {
        display: table-row !important;
    }
    .LandingTheme .d-xl-table-cell {
        display: table-cell !important;
    }
    .LandingTheme .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .LandingTheme .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media print {
    .LandingTheme .d-print-none {
        display: none !important;
    }
    .LandingTheme .d-print-inline {
        display: inline !important;
    }
    .LandingTheme .d-print-inline-block {
        display: inline-block !important;
    }
    .LandingTheme .d-print-block {
        display: block !important;
    }
    .LandingTheme .d-print-table {
        display: table !important;
    }
    .LandingTheme .d-print-table-row {
        display: table-row !important;
    }
    .LandingTheme .d-print-table-cell {
        display: table-cell !important;
    }
    .LandingTheme .d-print-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .LandingTheme .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

.LandingTheme .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.LandingTheme .embed-responsive::before {
    display: block;
    content: "";
}

.LandingTheme .embed-responsive .embed-responsive-item, .LandingTheme .embed-responsive embed, .LandingTheme .embed-responsive iframe, .LandingTheme .embed-responsive object, .LandingTheme .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.LandingTheme .embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.LandingTheme .embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.LandingTheme .embed-responsive-4by3::before {
    padding-top: 75%;
}

.LandingTheme .embed-responsive-1by1::before {
    padding-top: 100%;
}

.LandingTheme .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.LandingTheme .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.LandingTheme .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.LandingTheme .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.LandingTheme .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.LandingTheme .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.LandingTheme .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.LandingTheme .flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.LandingTheme .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.LandingTheme .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.LandingTheme .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.LandingTheme .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.LandingTheme .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.LandingTheme .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.LandingTheme .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.LandingTheme .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.LandingTheme .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.LandingTheme .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.LandingTheme .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.LandingTheme .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.LandingTheme .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.LandingTheme .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.LandingTheme .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.LandingTheme .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.LandingTheme .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.LandingTheme .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.LandingTheme .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.LandingTheme .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.LandingTheme .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.LandingTheme .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.LandingTheme .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.LandingTheme .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.LandingTheme .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.LandingTheme .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .LandingTheme .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .LandingTheme .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .LandingTheme .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .LandingTheme .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .LandingTheme .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .LandingTheme .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .LandingTheme .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .LandingTheme .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .LandingTheme .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .LandingTheme .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .LandingTheme .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .LandingTheme .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .LandingTheme .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .LandingTheme .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .LandingTheme .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .LandingTheme .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .LandingTheme .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .LandingTheme .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .LandingTheme .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .LandingTheme .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .LandingTheme .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .LandingTheme .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .LandingTheme .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .LandingTheme .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .LandingTheme .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .LandingTheme .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .LandingTheme .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .LandingTheme .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .LandingTheme .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .LandingTheme .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .LandingTheme .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .LandingTheme .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .LandingTheme .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .LandingTheme .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .LandingTheme .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .LandingTheme .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .LandingTheme .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .LandingTheme .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .LandingTheme .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .LandingTheme .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .LandingTheme .flex-md-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .LandingTheme .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .LandingTheme .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .LandingTheme .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .LandingTheme .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .LandingTheme .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .LandingTheme .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .LandingTheme .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .LandingTheme .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .LandingTheme .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .LandingTheme .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .LandingTheme .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .LandingTheme .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .LandingTheme .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .LandingTheme .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .LandingTheme .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .LandingTheme .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .LandingTheme .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .LandingTheme .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .LandingTheme .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .LandingTheme .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .LandingTheme .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .LandingTheme .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .LandingTheme .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .LandingTheme .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .LandingTheme .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .LandingTheme .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .LandingTheme .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .LandingTheme .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .LandingTheme .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .LandingTheme .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .LandingTheme .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .LandingTheme .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .LandingTheme .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .LandingTheme .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .LandingTheme .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .LandingTheme .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .LandingTheme .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .LandingTheme .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .LandingTheme .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .LandingTheme .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .LandingTheme .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .LandingTheme .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .LandingTheme .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .LandingTheme .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .LandingTheme .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .LandingTheme .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .LandingTheme .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .LandingTheme .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .LandingTheme .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .LandingTheme .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .LandingTheme .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .LandingTheme .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .LandingTheme .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .LandingTheme .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .LandingTheme .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .LandingTheme .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .LandingTheme .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .LandingTheme .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .LandingTheme .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .LandingTheme .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .LandingTheme .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .LandingTheme .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .LandingTheme .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .LandingTheme .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .LandingTheme .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .LandingTheme .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .LandingTheme .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .LandingTheme .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .LandingTheme .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .LandingTheme .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .LandingTheme .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .LandingTheme .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .LandingTheme .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .LandingTheme .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .LandingTheme .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .LandingTheme .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .LandingTheme .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .LandingTheme .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .LandingTheme .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .LandingTheme .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .LandingTheme .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .LandingTheme .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .LandingTheme .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .LandingTheme .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .LandingTheme .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .LandingTheme .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .LandingTheme .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .LandingTheme .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .LandingTheme .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .LandingTheme .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .LandingTheme .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .LandingTheme .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

.LandingTheme .float-left {
    float: left !important;
}

.LandingTheme .float-right {
    float: right !important;
}

.LandingTheme .float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .LandingTheme .float-sm-left {
        float: left !important;
    }
    .LandingTheme .float-sm-right {
        float: right !important;
    }
    .LandingTheme .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .float-md-left {
        float: left !important;
    }
    .LandingTheme .float-md-right {
        float: right !important;
    }
    .LandingTheme .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .float-lg-left {
        float: left !important;
    }
    .LandingTheme .float-lg-right {
        float: right !important;
    }
    .LandingTheme .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .float-xl-left {
        float: left !important;
    }
    .LandingTheme .float-xl-right {
        float: right !important;
    }
    .LandingTheme .float-xl-none {
        float: none !important;
    }
}

.LandingTheme .overflow-auto {
    overflow: auto !important;
}

.LandingTheme .overflow-hidden {
    overflow: hidden !important;
}

.LandingTheme .position-static {
    position: static !important;
}

.LandingTheme .position-relative {
    position: relative !important;
}

.LandingTheme .position-absolute {
    position: absolute !important;
}

.LandingTheme .position-fixed {
    position: fixed !important;
}

.LandingTheme .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.LandingTheme .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.LandingTheme .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
    .LandingTheme .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.LandingTheme .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.LandingTheme .sr-only-focusable:active, .LandingTheme .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.LandingTheme .shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(27, 22, 66, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(27, 22, 66, 0.075) !important;
}

.LandingTheme .shadow {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06) !important;
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06) !important;
}

.LandingTheme .shadow-lg {
    -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06) !important;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06) !important;
}

.LandingTheme .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.LandingTheme .w-25 {
    width: 25% !important;
}

.LandingTheme .w-50 {
    width: 50% !important;
}

.LandingTheme .w-75 {
    width: 75% !important;
}

.LandingTheme .w-100 {
    width: 100% !important;
}

.LandingTheme .w-auto {
    width: auto !important;
}

.LandingTheme .w-125 {
    width: 125% !important;
}

.LandingTheme .w-150 {
    width: 150% !important;
}

.LandingTheme .h-25 {
    height: 25% !important;
}

.LandingTheme .h-50 {
    height: 50% !important;
}

.LandingTheme .h-75 {
    height: 75% !important;
}

.LandingTheme .h-100 {
    height: 100% !important;
}

.LandingTheme .h-auto {
    height: auto !important;
}

.LandingTheme .h-125 {
    height: 125% !important;
}

.LandingTheme .h-150 {
    height: 150% !important;
}

.LandingTheme .mw-100 {
    max-width: 100% !important;
}

.LandingTheme .mh-100 {
    max-height: 100% !important;
}

.LandingTheme .min-vw-100 {
    min-width: 100vw !important;
}

.LandingTheme .min-vh-100 {
    min-height: 100vh !important;
}

.LandingTheme .vw-100 {
    width: 100vw !important;
}

.LandingTheme .vh-100 {
    height: 100vh !important;
}

.LandingTheme .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.LandingTheme .m-0 {
    margin: 0 !important;
}

.LandingTheme .mt-0, .LandingTheme .my-0 {
    margin-top: 0 !important;
}

.LandingTheme .mr-0, .LandingTheme .mx-0 {
    margin-right: 0 !important;
}

.LandingTheme .mb-0, .LandingTheme .my-0 {
    margin-bottom: 0 !important;
}

.LandingTheme .ml-0, .LandingTheme .mx-0 {
    margin-left: 0 !important;
}

.LandingTheme .m-1 {
    margin: .25rem !important;
}

.LandingTheme .mt-1, .LandingTheme .my-1 {
    margin-top: .25rem !important;
}

.LandingTheme .mr-1, .LandingTheme .mx-1 {
    margin-right: .25rem !important;
}

.LandingTheme .mb-1, .LandingTheme .my-1 {
    margin-bottom: .25rem !important;
}

.LandingTheme .ml-1, .LandingTheme .mx-1 {
    margin-left: .25rem !important;
}

.LandingTheme .m-2 {
    margin: .5rem !important;
}

.LandingTheme .mt-2, .LandingTheme .my-2 {
    margin-top: .5rem !important;
}

.LandingTheme .mr-2, .LandingTheme .mx-2 {
    margin-right: .5rem !important;
}

.LandingTheme .mb-2, .LandingTheme .my-2 {
    margin-bottom: .5rem !important;
}

.LandingTheme .ml-2, .LandingTheme .mx-2 {
    margin-left: .5rem !important;
}

.LandingTheme .m-3 {
    margin: .75rem !important;
}

.LandingTheme .mt-3, .LandingTheme .my-3 {
    margin-top: .75rem !important;
}

.LandingTheme .mr-3, .LandingTheme .mx-3 {
    margin-right: .75rem !important;
}

.LandingTheme .mb-3, .LandingTheme .my-3 {
    margin-bottom: .75rem !important;
}

.LandingTheme .ml-3, .LandingTheme .mx-3 {
    margin-left: .75rem !important;
}

.LandingTheme .m-4 {
    margin: 1rem !important;
}

.LandingTheme .mt-4, .LandingTheme .my-4 {
    margin-top: 1rem !important;
}

.LandingTheme .mr-4, .LandingTheme .mx-4 {
    margin-right: 1rem !important;
}

.LandingTheme .mb-4, .LandingTheme .my-4 {
    margin-bottom: 1rem !important;
}

.LandingTheme .ml-4, .LandingTheme .mx-4 {
    margin-left: 1rem !important;
}

.LandingTheme .m-5 {
    margin: 1.5rem !important;
}

.LandingTheme .mt-5, .LandingTheme .my-5 {
    margin-top: 1.5rem !important;
}

.LandingTheme .mr-5, .LandingTheme .mx-5 {
    margin-right: 1.5rem !important;
}

.LandingTheme .mb-5, .LandingTheme .my-5 {
    margin-bottom: 1.5rem !important;
}

.LandingTheme .ml-5, .LandingTheme .mx-5 {
    margin-left: 1.5rem !important;
}

.LandingTheme .m-6 {
    margin: 2rem !important;
}

.LandingTheme .mt-6, .LandingTheme .my-6 {
    margin-top: 2rem !important;
}

.LandingTheme .mr-6, .LandingTheme .mx-6 {
    margin-right: 2rem !important;
}

.LandingTheme .mb-6, .LandingTheme .my-6 {
    margin-bottom: 2rem !important;
}

.LandingTheme .ml-6, .LandingTheme .mx-6 {
    margin-left: 2rem !important;
}

.LandingTheme .m-7 {
    margin: 2.5rem !important;
}

.LandingTheme .mt-7, .LandingTheme .my-7 {
    margin-top: 2.5rem !important;
}

.LandingTheme .mr-7, .LandingTheme .mx-7 {
    margin-right: 2.5rem !important;
}

.LandingTheme .mb-7, .LandingTheme .my-7 {
    margin-bottom: 2.5rem !important;
}

.LandingTheme .ml-7, .LandingTheme .mx-7 {
    margin-left: 2.5rem !important;
}

.LandingTheme .m-8 {
    margin: 3rem !important;
}

.LandingTheme .mt-8, .LandingTheme .my-8 {
    margin-top: 3rem !important;
}

.LandingTheme .mr-8, .LandingTheme .mx-8 {
    margin-right: 3rem !important;
}

.LandingTheme .mb-8, .LandingTheme .my-8 {
    margin-bottom: 3rem !important;
}

.LandingTheme .ml-8, .LandingTheme .mx-8 {
    margin-left: 3rem !important;
}

.LandingTheme .m-9 {
    margin: 4rem !important;
}

.LandingTheme .mt-9, .LandingTheme .my-9 {
    margin-top: 4rem !important;
}

.LandingTheme .mr-9, .LandingTheme .mx-9 {
    margin-right: 4rem !important;
}

.LandingTheme .mb-9, .LandingTheme .my-9 {
    margin-bottom: 4rem !important;
}

.LandingTheme .ml-9, .LandingTheme .mx-9 {
    margin-left: 4rem !important;
}

.LandingTheme .m-10 {
    margin: 5rem !important;
}

.LandingTheme .mt-10, .LandingTheme .my-10 {
    margin-top: 5rem !important;
}

.LandingTheme .mr-10, .LandingTheme .mx-10 {
    margin-right: 5rem !important;
}

.LandingTheme .mb-10, .LandingTheme .my-10 {
    margin-bottom: 5rem !important;
}

.LandingTheme .ml-10, .LandingTheme .mx-10 {
    margin-left: 5rem !important;
}

.LandingTheme .m-11 {
    margin: 6rem !important;
}

.LandingTheme .mt-11, .LandingTheme .my-11 {
    margin-top: 6rem !important;
}

.LandingTheme .mr-11, .LandingTheme .mx-11 {
    margin-right: 6rem !important;
}

.LandingTheme .mb-11, .LandingTheme .my-11 {
    margin-bottom: 6rem !important;
}

.LandingTheme .ml-11, .LandingTheme .mx-11 {
    margin-left: 6rem !important;
}

.LandingTheme .m-12 {
    margin: 8rem !important;
}

.LandingTheme .mt-12, .LandingTheme .my-12 {
    margin-top: 8rem !important;
}

.LandingTheme .mr-12, .LandingTheme .mx-12 {
    margin-right: 8rem !important;
}

.LandingTheme .mb-12, .LandingTheme .my-12 {
    margin-bottom: 8rem !important;
}

.LandingTheme .ml-12, .LandingTheme .mx-12 {
    margin-left: 8rem !important;
}

.LandingTheme .m-13 {
    margin: 10rem !important;
}

.LandingTheme .mt-13, .LandingTheme .my-13 {
    margin-top: 10rem !important;
}

.LandingTheme .mr-13, .LandingTheme .mx-13 {
    margin-right: 10rem !important;
}

.LandingTheme .mb-13, .LandingTheme .my-13 {
    margin-bottom: 10rem !important;
}

.LandingTheme .ml-13, .LandingTheme .mx-13 {
    margin-left: 10rem !important;
}

.LandingTheme .m-14 {
    margin: 12rem !important;
}

.LandingTheme .mt-14, .LandingTheme .my-14 {
    margin-top: 12rem !important;
}

.LandingTheme .mr-14, .LandingTheme .mx-14 {
    margin-right: 12rem !important;
}

.LandingTheme .mb-14, .LandingTheme .my-14 {
    margin-bottom: 12rem !important;
}

.LandingTheme .ml-14, .LandingTheme .mx-14 {
    margin-left: 12rem !important;
}

.LandingTheme .m-15 {
    margin: 14rem !important;
}

.LandingTheme .mt-15, .LandingTheme .my-15 {
    margin-top: 14rem !important;
}

.LandingTheme .mr-15, .LandingTheme .mx-15 {
    margin-right: 14rem !important;
}

.LandingTheme .mb-15, .LandingTheme .my-15 {
    margin-bottom: 14rem !important;
}

.LandingTheme .ml-15, .LandingTheme .mx-15 {
    margin-left: 14rem !important;
}

.LandingTheme .m-16 {
    margin: 16rem !important;
}

.LandingTheme .mt-16, .LandingTheme .my-16 {
    margin-top: 16rem !important;
}

.LandingTheme .mr-16, .LandingTheme .mx-16 {
    margin-right: 16rem !important;
}

.LandingTheme .mb-16, .LandingTheme .my-16 {
    margin-bottom: 16rem !important;
}

.LandingTheme .ml-16, .LandingTheme .mx-16 {
    margin-left: 16rem !important;
}

.LandingTheme .m-17 {
    margin: 20rem !important;
}

.LandingTheme .mt-17, .LandingTheme .my-17 {
    margin-top: 20rem !important;
}

.LandingTheme .mr-17, .LandingTheme .mx-17 {
    margin-right: 20rem !important;
}

.LandingTheme .mb-17, .LandingTheme .my-17 {
    margin-bottom: 20rem !important;
}

.LandingTheme .ml-17, .LandingTheme .mx-17 {
    margin-left: 20rem !important;
}

.LandingTheme .m-18 {
    margin: 24rem !important;
}

.LandingTheme .mt-18, .LandingTheme .my-18 {
    margin-top: 24rem !important;
}

.LandingTheme .mr-18, .LandingTheme .mx-18 {
    margin-right: 24rem !important;
}

.LandingTheme .mb-18, .LandingTheme .my-18 {
    margin-bottom: 24rem !important;
}

.LandingTheme .ml-18, .LandingTheme .mx-18 {
    margin-left: 24rem !important;
}

.LandingTheme .p-0 {
    padding: 0 !important;
}

.LandingTheme .pt-0, .LandingTheme .py-0 {
    padding-top: 0 !important;
}

.LandingTheme .pr-0, .LandingTheme .px-0 {
    padding-right: 0 !important;
}

.LandingTheme .pb-0, .LandingTheme .py-0 {
    padding-bottom: 0 !important;
}

.LandingTheme .pl-0, .LandingTheme .px-0 {
    padding-left: 0 !important;
}

.LandingTheme .p-1 {
    padding: .25rem !important;
}

.LandingTheme .pt-1, .LandingTheme .py-1 {
    padding-top: .25rem !important;
}

.LandingTheme .pr-1, .LandingTheme .px-1 {
    padding-right: .25rem !important;
}

.LandingTheme .pb-1, .LandingTheme .py-1 {
    padding-bottom: .25rem !important;
}

.LandingTheme .pl-1, .LandingTheme .px-1 {
    padding-left: .25rem !important;
}

.LandingTheme .p-2 {
    padding: .5rem !important;
}

.LandingTheme .pt-2, .LandingTheme .py-2 {
    padding-top: .5rem !important;
}

.LandingTheme .pr-2, .LandingTheme .px-2 {
    padding-right: .5rem !important;
}

.LandingTheme .pb-2, .LandingTheme .py-2 {
    padding-bottom: .5rem !important;
}

.LandingTheme .pl-2, .LandingTheme .px-2 {
    padding-left: .5rem !important;
}

.LandingTheme .p-3 {
    padding: .75rem !important;
}

.LandingTheme .pt-3, .LandingTheme .py-3 {
    padding-top: .75rem !important;
}

.LandingTheme .pr-3, .LandingTheme .px-3 {
    padding-right: .75rem !important;
}

.LandingTheme .pb-3, .LandingTheme .py-3 {
    padding-bottom: .75rem !important;
}

.LandingTheme .pl-3, .LandingTheme .px-3 {
    padding-left: .75rem !important;
}

.LandingTheme .p-4 {
    padding: 1rem !important;
}

.LandingTheme .pt-4, .LandingTheme .py-4 {
    padding-top: 1rem !important;
}

.LandingTheme .pr-4, .LandingTheme .px-4 {
    padding-right: 1rem !important;
}

.LandingTheme .pb-4, .LandingTheme .py-4 {
    padding-bottom: 1rem !important;
}

.LandingTheme .pl-4, .LandingTheme .px-4 {
    padding-left: 1rem !important;
}

.LandingTheme .p-5 {
    padding: 1.5rem !important;
}

.LandingTheme .pt-5, .LandingTheme .py-5 {
    padding-top: 1.5rem !important;
}

.LandingTheme .pr-5, .LandingTheme .px-5 {
    padding-right: 1.5rem !important;
}

.LandingTheme .pb-5, .LandingTheme .py-5 {
    padding-bottom: 1.5rem !important;
}

.LandingTheme .pl-5, .LandingTheme .px-5 {
    padding-left: 1.5rem !important;
}

.LandingTheme .p-6 {
    padding: 2rem !important;
}

.LandingTheme .pt-6, .LandingTheme .py-6 {
    padding-top: 2rem !important;
}

.LandingTheme .pr-6, .LandingTheme .px-6 {
    padding-right: 2rem !important;
}

.LandingTheme .pb-6, .LandingTheme .py-6 {
    padding-bottom: 2rem !important;
}

.LandingTheme .pl-6, .LandingTheme .px-6 {
    padding-left: 2rem !important;
}

.LandingTheme .p-7 {
    padding: 2.5rem !important;
}

.LandingTheme .pt-7, .LandingTheme .py-7 {
    padding-top: 2.5rem !important;
}

.LandingTheme .pr-7, .LandingTheme .px-7 {
    padding-right: 2.5rem !important;
}

.LandingTheme .pb-7, .LandingTheme .py-7 {
    padding-bottom: 2.5rem !important;
}

.LandingTheme .pl-7, .LandingTheme .px-7 {
    padding-left: 2.5rem !important;
}

.LandingTheme .p-8 {
    padding: 3rem !important;
}

.LandingTheme .pt-8, .LandingTheme .py-8 {
    padding-top: 3rem !important;
}

.LandingTheme .pr-8, .LandingTheme .px-8 {
    padding-right: 3rem !important;
}

.LandingTheme .pb-8, .LandingTheme .py-8 {
    padding-bottom: 3rem !important;
}

.LandingTheme .pl-8, .LandingTheme .px-8 {
    padding-left: 3rem !important;
}

.LandingTheme .p-9 {
    padding: 4rem !important;
}

.LandingTheme .pt-9, .LandingTheme .py-9 {
    padding-top: 4rem !important;
}

.LandingTheme .pr-9, .LandingTheme .px-9 {
    padding-right: 4rem !important;
}

.LandingTheme .pb-9, .LandingTheme .py-9 {
    padding-bottom: 4rem !important;
}

.LandingTheme .pl-9, .LandingTheme .px-9 {
    padding-left: 4rem !important;
}

.LandingTheme .p-10 {
    padding: 5rem !important;
}

.LandingTheme .pt-10, .LandingTheme .py-10 {
    padding-top: 5rem !important;
}

.LandingTheme .pr-10, .LandingTheme .px-10 {
    padding-right: 5rem !important;
}

.LandingTheme .pb-10, .LandingTheme .py-10 {
    padding-bottom: 5rem !important;
}

.LandingTheme .pl-10, .LandingTheme .px-10 {
    padding-left: 5rem !important;
}

.LandingTheme .p-11 {
    padding: 6rem !important;
}

.LandingTheme .pt-11, .LandingTheme .py-11 {
    padding-top: 6rem !important;
}

.LandingTheme .pr-11, .LandingTheme .px-11 {
    padding-right: 6rem !important;
}

.LandingTheme .pb-11, .LandingTheme .py-11 {
    padding-bottom: 6rem !important;
}

.LandingTheme .pl-11, .LandingTheme .px-11 {
    padding-left: 6rem !important;
}

.LandingTheme .p-12 {
    padding: 8rem !important;
}

.LandingTheme .pt-12, .LandingTheme .py-12 {
    padding-top: 8rem !important;
}

.LandingTheme .pr-12, .LandingTheme .px-12 {
    padding-right: 8rem !important;
}

.LandingTheme .pb-12, .LandingTheme .py-12 {
    padding-bottom: 8rem !important;
}

.LandingTheme .pl-12, .LandingTheme .px-12 {
    padding-left: 8rem !important;
}

.LandingTheme .p-13 {
    padding: 10rem !important;
}

.LandingTheme .pt-13, .LandingTheme .py-13 {
    padding-top: 10rem !important;
}

.LandingTheme .pr-13, .LandingTheme .px-13 {
    padding-right: 10rem !important;
}

.LandingTheme .pb-13, .LandingTheme .py-13 {
    padding-bottom: 10rem !important;
}

.LandingTheme .pl-13, .LandingTheme .px-13 {
    padding-left: 10rem !important;
}

.LandingTheme .p-14 {
    padding: 12rem !important;
}

.LandingTheme .pt-14, .LandingTheme .py-14 {
    padding-top: 12rem !important;
}

.LandingTheme .pr-14, .LandingTheme .px-14 {
    padding-right: 12rem !important;
}

.LandingTheme .pb-14, .LandingTheme .py-14 {
    padding-bottom: 12rem !important;
}

.LandingTheme .pl-14, .LandingTheme .px-14 {
    padding-left: 12rem !important;
}

.LandingTheme .p-15 {
    padding: 14rem !important;
}

.LandingTheme .pt-15, .LandingTheme .py-15 {
    padding-top: 14rem !important;
}

.LandingTheme .pr-15, .LandingTheme .px-15 {
    padding-right: 14rem !important;
}

.LandingTheme .pb-15, .LandingTheme .py-15 {
    padding-bottom: 14rem !important;
}

.LandingTheme .pl-15, .LandingTheme .px-15 {
    padding-left: 14rem !important;
}

.LandingTheme .p-16 {
    padding: 16rem !important;
}

.LandingTheme .pt-16, .LandingTheme .py-16 {
    padding-top: 16rem !important;
}

.LandingTheme .pr-16, .LandingTheme .px-16 {
    padding-right: 16rem !important;
}

.LandingTheme .pb-16, .LandingTheme .py-16 {
    padding-bottom: 16rem !important;
}

.LandingTheme .pl-16, .LandingTheme .px-16 {
    padding-left: 16rem !important;
}

.LandingTheme .p-17 {
    padding: 20rem !important;
}

.LandingTheme .pt-17, .LandingTheme .py-17 {
    padding-top: 20rem !important;
}

.LandingTheme .pr-17, .LandingTheme .px-17 {
    padding-right: 20rem !important;
}

.LandingTheme .pb-17, .LandingTheme .py-17 {
    padding-bottom: 20rem !important;
}

.LandingTheme .pl-17, .LandingTheme .px-17 {
    padding-left: 20rem !important;
}

.LandingTheme .p-18 {
    padding: 24rem !important;
}

.LandingTheme .pt-18, .LandingTheme .py-18 {
    padding-top: 24rem !important;
}

.LandingTheme .pr-18, .LandingTheme .px-18 {
    padding-right: 24rem !important;
}

.LandingTheme .pb-18, .LandingTheme .py-18 {
    padding-bottom: 24rem !important;
}

.LandingTheme .pl-18, .LandingTheme .px-18 {
    padding-left: 24rem !important;
}

.LandingTheme .m-n1 {
    margin: -.25rem !important;
}

.LandingTheme .mt-n1, .LandingTheme .my-n1 {
    margin-top: -.25rem !important;
}

.LandingTheme .mr-n1, .LandingTheme .mx-n1 {
    margin-right: -.25rem !important;
}

.LandingTheme .mb-n1, .LandingTheme .my-n1 {
    margin-bottom: -.25rem !important;
}

.LandingTheme .ml-n1, .LandingTheme .mx-n1 {
    margin-left: -.25rem !important;
}

.LandingTheme .m-n2 {
    margin: -.5rem !important;
}

.LandingTheme .mt-n2, .LandingTheme .my-n2 {
    margin-top: -.5rem !important;
}

.LandingTheme .mr-n2, .LandingTheme .mx-n2 {
    margin-right: -.5rem !important;
}

.LandingTheme .mb-n2, .LandingTheme .my-n2 {
    margin-bottom: -.5rem !important;
}

.LandingTheme .ml-n2, .LandingTheme .mx-n2 {
    margin-left: -.5rem !important;
}

.LandingTheme .m-n3 {
    margin: -.75rem !important;
}

.LandingTheme .mt-n3, .LandingTheme .my-n3 {
    margin-top: -.75rem !important;
}

.LandingTheme .mr-n3, .LandingTheme .mx-n3 {
    margin-right: -.75rem !important;
}

.LandingTheme .mb-n3, .LandingTheme .my-n3 {
    margin-bottom: -.75rem !important;
}

.LandingTheme .ml-n3, .LandingTheme .mx-n3 {
    margin-left: -.75rem !important;
}

.LandingTheme .m-n4 {
    margin: -1rem !important;
}

.LandingTheme .mt-n4, .LandingTheme .my-n4 {
    margin-top: -1rem !important;
}

.LandingTheme .mr-n4, .LandingTheme .mx-n4 {
    margin-right: -1rem !important;
}

.LandingTheme .mb-n4, .LandingTheme .my-n4 {
    margin-bottom: -1rem !important;
}

.LandingTheme .ml-n4, .LandingTheme .mx-n4 {
    margin-left: -1rem !important;
}

.LandingTheme .m-n5 {
    margin: -1.5rem !important;
}

.LandingTheme .mt-n5, .LandingTheme .my-n5 {
    margin-top: -1.5rem !important;
}

.LandingTheme .mr-n5, .LandingTheme .mx-n5 {
    margin-right: -1.5rem !important;
}

.LandingTheme .mb-n5, .LandingTheme .my-n5 {
    margin-bottom: -1.5rem !important;
}

.LandingTheme .ml-n5, .LandingTheme .mx-n5 {
    margin-left: -1.5rem !important;
}

.LandingTheme .m-n6 {
    margin: -2rem !important;
}

.LandingTheme .mt-n6, .LandingTheme .my-n6 {
    margin-top: -2rem !important;
}

.LandingTheme .mr-n6, .LandingTheme .mx-n6 {
    margin-right: -2rem !important;
}

.LandingTheme .mb-n6, .LandingTheme .my-n6 {
    margin-bottom: -2rem !important;
}

.LandingTheme .ml-n6, .LandingTheme .mx-n6 {
    margin-left: -2rem !important;
}

.LandingTheme .m-n7 {
    margin: -2.5rem !important;
}

.LandingTheme .mt-n7, .LandingTheme .my-n7 {
    margin-top: -2.5rem !important;
}

.LandingTheme .mr-n7, .LandingTheme .mx-n7 {
    margin-right: -2.5rem !important;
}

.LandingTheme .mb-n7, .LandingTheme .my-n7 {
    margin-bottom: -2.5rem !important;
}

.LandingTheme .ml-n7, .LandingTheme .mx-n7 {
    margin-left: -2.5rem !important;
}

.LandingTheme .m-n8 {
    margin: -3rem !important;
}

.LandingTheme .mt-n8, .LandingTheme .my-n8 {
    margin-top: -3rem !important;
}

.LandingTheme .mr-n8, .LandingTheme .mx-n8 {
    margin-right: -3rem !important;
}

.LandingTheme .mb-n8, .LandingTheme .my-n8 {
    margin-bottom: -3rem !important;
}

.LandingTheme .ml-n8, .LandingTheme .mx-n8 {
    margin-left: -3rem !important;
}

.LandingTheme .m-n9 {
    margin: -4rem !important;
}

.LandingTheme .mt-n9, .LandingTheme .my-n9 {
    margin-top: -4rem !important;
}

.LandingTheme .mr-n9, .LandingTheme .mx-n9 {
    margin-right: -4rem !important;
}

.LandingTheme .mb-n9, .LandingTheme .my-n9 {
    margin-bottom: -4rem !important;
}

.LandingTheme .ml-n9, .LandingTheme .mx-n9 {
    margin-left: -4rem !important;
}

.LandingTheme .m-n10 {
    margin: -5rem !important;
}

.LandingTheme .mt-n10, .LandingTheme .my-n10 {
    margin-top: -5rem !important;
}

.LandingTheme .mr-n10, .LandingTheme .mx-n10 {
    margin-right: -5rem !important;
}

.LandingTheme .mb-n10, .LandingTheme .my-n10 {
    margin-bottom: -5rem !important;
}

.LandingTheme .ml-n10, .LandingTheme .mx-n10 {
    margin-left: -5rem !important;
}

.LandingTheme .m-n11 {
    margin: -6rem !important;
}

.LandingTheme .mt-n11, .LandingTheme .my-n11 {
    margin-top: -6rem !important;
}

.LandingTheme .mr-n11, .LandingTheme .mx-n11 {
    margin-right: -6rem !important;
}

.LandingTheme .mb-n11, .LandingTheme .my-n11 {
    margin-bottom: -6rem !important;
}

.LandingTheme .ml-n11, .LandingTheme .mx-n11 {
    margin-left: -6rem !important;
}

.LandingTheme .m-n12 {
    margin: -8rem !important;
}

.LandingTheme .mt-n12, .LandingTheme .my-n12 {
    margin-top: -8rem !important;
}

.LandingTheme .mr-n12, .LandingTheme .mx-n12 {
    margin-right: -8rem !important;
}

.LandingTheme .mb-n12, .LandingTheme .my-n12 {
    margin-bottom: -8rem !important;
}

.LandingTheme .ml-n12, .LandingTheme .mx-n12 {
    margin-left: -8rem !important;
}

.LandingTheme .m-n13 {
    margin: -10rem !important;
}

.LandingTheme .mt-n13, .LandingTheme .my-n13 {
    margin-top: -10rem !important;
}

.LandingTheme .mr-n13, .LandingTheme .mx-n13 {
    margin-right: -10rem !important;
}

.LandingTheme .mb-n13, .LandingTheme .my-n13 {
    margin-bottom: -10rem !important;
}

.LandingTheme .ml-n13, .LandingTheme .mx-n13 {
    margin-left: -10rem !important;
}

.LandingTheme .m-n14 {
    margin: -12rem !important;
}

.LandingTheme .mt-n14, .LandingTheme .my-n14 {
    margin-top: -12rem !important;
}

.LandingTheme .mr-n14, .LandingTheme .mx-n14 {
    margin-right: -12rem !important;
}

.LandingTheme .mb-n14, .LandingTheme .my-n14 {
    margin-bottom: -12rem !important;
}

.LandingTheme .ml-n14, .LandingTheme .mx-n14 {
    margin-left: -12rem !important;
}

.LandingTheme .m-n15 {
    margin: -14rem !important;
}

.LandingTheme .mt-n15, .LandingTheme .my-n15 {
    margin-top: -14rem !important;
}

.LandingTheme .mr-n15, .LandingTheme .mx-n15 {
    margin-right: -14rem !important;
}

.LandingTheme .mb-n15, .LandingTheme .my-n15 {
    margin-bottom: -14rem !important;
}

.LandingTheme .ml-n15, .LandingTheme .mx-n15 {
    margin-left: -14rem !important;
}

.LandingTheme .m-n16 {
    margin: -16rem !important;
}

.LandingTheme .mt-n16, .LandingTheme .my-n16 {
    margin-top: -16rem !important;
}

.LandingTheme .mr-n16, .LandingTheme .mx-n16 {
    margin-right: -16rem !important;
}

.LandingTheme .mb-n16, .LandingTheme .my-n16 {
    margin-bottom: -16rem !important;
}

.LandingTheme .ml-n16, .LandingTheme .mx-n16 {
    margin-left: -16rem !important;
}

.LandingTheme .m-n17 {
    margin: -20rem !important;
}

.LandingTheme .mt-n17, .LandingTheme .my-n17 {
    margin-top: -20rem !important;
}

.LandingTheme .mr-n17, .LandingTheme .mx-n17 {
    margin-right: -20rem !important;
}

.LandingTheme .mb-n17, .LandingTheme .my-n17 {
    margin-bottom: -20rem !important;
}

.LandingTheme .ml-n17, .LandingTheme .mx-n17 {
    margin-left: -20rem !important;
}

.LandingTheme .m-n18 {
    margin: -24rem !important;
}

.LandingTheme .mt-n18, .LandingTheme .my-n18 {
    margin-top: -24rem !important;
}

.LandingTheme .mr-n18, .LandingTheme .mx-n18 {
    margin-right: -24rem !important;
}

.LandingTheme .mb-n18, .LandingTheme .my-n18 {
    margin-bottom: -24rem !important;
}

.LandingTheme .ml-n18, .LandingTheme .mx-n18 {
    margin-left: -24rem !important;
}

.LandingTheme .m-auto {
    margin: auto !important;
}

.LandingTheme .mt-auto, .LandingTheme .my-auto {
    margin-top: auto !important;
}

.LandingTheme .mr-auto, .LandingTheme .mx-auto {
    margin-right: auto !important;
}

.LandingTheme .mb-auto, .LandingTheme .my-auto {
    margin-bottom: auto !important;
}

.LandingTheme .ml-auto, .LandingTheme .mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .LandingTheme .m-sm-0 {
        margin: 0 !important;
    }
    .LandingTheme .mt-sm-0, .LandingTheme .my-sm-0 {
        margin-top: 0 !important;
    }
    .LandingTheme .mr-sm-0, .LandingTheme .mx-sm-0 {
        margin-right: 0 !important;
    }
    .LandingTheme .mb-sm-0, .LandingTheme .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .LandingTheme .ml-sm-0, .LandingTheme .mx-sm-0 {
        margin-left: 0 !important;
    }
    .LandingTheme .m-sm-1 {
        margin: .25rem !important;
    }
    .LandingTheme .mt-sm-1, .LandingTheme .my-sm-1 {
        margin-top: .25rem !important;
    }
    .LandingTheme .mr-sm-1, .LandingTheme .mx-sm-1 {
        margin-right: .25rem !important;
    }
    .LandingTheme .mb-sm-1, .LandingTheme .my-sm-1 {
        margin-bottom: .25rem !important;
    }
    .LandingTheme .ml-sm-1, .LandingTheme .mx-sm-1 {
        margin-left: .25rem !important;
    }
    .LandingTheme .m-sm-2 {
        margin: .5rem !important;
    }
    .LandingTheme .mt-sm-2, .LandingTheme .my-sm-2 {
        margin-top: .5rem !important;
    }
    .LandingTheme .mr-sm-2, .LandingTheme .mx-sm-2 {
        margin-right: .5rem !important;
    }
    .LandingTheme .mb-sm-2, .LandingTheme .my-sm-2 {
        margin-bottom: .5rem !important;
    }
    .LandingTheme .ml-sm-2, .LandingTheme .mx-sm-2 {
        margin-left: .5rem !important;
    }
    .LandingTheme .m-sm-3 {
        margin: .75rem !important;
    }
    .LandingTheme .mt-sm-3, .LandingTheme .my-sm-3 {
        margin-top: .75rem !important;
    }
    .LandingTheme .mr-sm-3, .LandingTheme .mx-sm-3 {
        margin-right: .75rem !important;
    }
    .LandingTheme .mb-sm-3, .LandingTheme .my-sm-3 {
        margin-bottom: .75rem !important;
    }
    .LandingTheme .ml-sm-3, .LandingTheme .mx-sm-3 {
        margin-left: .75rem !important;
    }
    .LandingTheme .m-sm-4 {
        margin: 1rem !important;
    }
    .LandingTheme .mt-sm-4, .LandingTheme .my-sm-4 {
        margin-top: 1rem !important;
    }
    .LandingTheme .mr-sm-4, .LandingTheme .mx-sm-4 {
        margin-right: 1rem !important;
    }
    .LandingTheme .mb-sm-4, .LandingTheme .my-sm-4 {
        margin-bottom: 1rem !important;
    }
    .LandingTheme .ml-sm-4, .LandingTheme .mx-sm-4 {
        margin-left: 1rem !important;
    }
    .LandingTheme .m-sm-5 {
        margin: 1.5rem !important;
    }
    .LandingTheme .mt-sm-5, .LandingTheme .my-sm-5 {
        margin-top: 1.5rem !important;
    }
    .LandingTheme .mr-sm-5, .LandingTheme .mx-sm-5 {
        margin-right: 1.5rem !important;
    }
    .LandingTheme .mb-sm-5, .LandingTheme .my-sm-5 {
        margin-bottom: 1.5rem !important;
    }
    .LandingTheme .ml-sm-5, .LandingTheme .mx-sm-5 {
        margin-left: 1.5rem !important;
    }
    .LandingTheme .m-sm-6 {
        margin: 2rem !important;
    }
    .LandingTheme .mt-sm-6, .LandingTheme .my-sm-6 {
        margin-top: 2rem !important;
    }
    .LandingTheme .mr-sm-6, .LandingTheme .mx-sm-6 {
        margin-right: 2rem !important;
    }
    .LandingTheme .mb-sm-6, .LandingTheme .my-sm-6 {
        margin-bottom: 2rem !important;
    }
    .LandingTheme .ml-sm-6, .LandingTheme .mx-sm-6 {
        margin-left: 2rem !important;
    }
    .LandingTheme .m-sm-7 {
        margin: 2.5rem !important;
    }
    .LandingTheme .mt-sm-7, .LandingTheme .my-sm-7 {
        margin-top: 2.5rem !important;
    }
    .LandingTheme .mr-sm-7, .LandingTheme .mx-sm-7 {
        margin-right: 2.5rem !important;
    }
    .LandingTheme .mb-sm-7, .LandingTheme .my-sm-7 {
        margin-bottom: 2.5rem !important;
    }
    .LandingTheme .ml-sm-7, .LandingTheme .mx-sm-7 {
        margin-left: 2.5rem !important;
    }
    .LandingTheme .m-sm-8 {
        margin: 3rem !important;
    }
    .LandingTheme .mt-sm-8, .LandingTheme .my-sm-8 {
        margin-top: 3rem !important;
    }
    .LandingTheme .mr-sm-8, .LandingTheme .mx-sm-8 {
        margin-right: 3rem !important;
    }
    .LandingTheme .mb-sm-8, .LandingTheme .my-sm-8 {
        margin-bottom: 3rem !important;
    }
    .LandingTheme .ml-sm-8, .LandingTheme .mx-sm-8 {
        margin-left: 3rem !important;
    }
    .LandingTheme .m-sm-9 {
        margin: 4rem !important;
    }
    .LandingTheme .mt-sm-9, .LandingTheme .my-sm-9 {
        margin-top: 4rem !important;
    }
    .LandingTheme .mr-sm-9, .LandingTheme .mx-sm-9 {
        margin-right: 4rem !important;
    }
    .LandingTheme .mb-sm-9, .LandingTheme .my-sm-9 {
        margin-bottom: 4rem !important;
    }
    .LandingTheme .ml-sm-9, .LandingTheme .mx-sm-9 {
        margin-left: 4rem !important;
    }
    .LandingTheme .m-sm-10 {
        margin: 5rem !important;
    }
    .LandingTheme .mt-sm-10, .LandingTheme .my-sm-10 {
        margin-top: 5rem !important;
    }
    .LandingTheme .mr-sm-10, .LandingTheme .mx-sm-10 {
        margin-right: 5rem !important;
    }
    .LandingTheme .mb-sm-10, .LandingTheme .my-sm-10 {
        margin-bottom: 5rem !important;
    }
    .LandingTheme .ml-sm-10, .LandingTheme .mx-sm-10 {
        margin-left: 5rem !important;
    }
    .LandingTheme .m-sm-11 {
        margin: 6rem !important;
    }
    .LandingTheme .mt-sm-11, .LandingTheme .my-sm-11 {
        margin-top: 6rem !important;
    }
    .LandingTheme .mr-sm-11, .LandingTheme .mx-sm-11 {
        margin-right: 6rem !important;
    }
    .LandingTheme .mb-sm-11, .LandingTheme .my-sm-11 {
        margin-bottom: 6rem !important;
    }
    .LandingTheme .ml-sm-11, .LandingTheme .mx-sm-11 {
        margin-left: 6rem !important;
    }
    .LandingTheme .m-sm-12 {
        margin: 8rem !important;
    }
    .LandingTheme .mt-sm-12, .LandingTheme .my-sm-12 {
        margin-top: 8rem !important;
    }
    .LandingTheme .mr-sm-12, .LandingTheme .mx-sm-12 {
        margin-right: 8rem !important;
    }
    .LandingTheme .mb-sm-12, .LandingTheme .my-sm-12 {
        margin-bottom: 8rem !important;
    }
    .LandingTheme .ml-sm-12, .LandingTheme .mx-sm-12 {
        margin-left: 8rem !important;
    }
    .LandingTheme .m-sm-13 {
        margin: 10rem !important;
    }
    .LandingTheme .mt-sm-13, .LandingTheme .my-sm-13 {
        margin-top: 10rem !important;
    }
    .LandingTheme .mr-sm-13, .LandingTheme .mx-sm-13 {
        margin-right: 10rem !important;
    }
    .LandingTheme .mb-sm-13, .LandingTheme .my-sm-13 {
        margin-bottom: 10rem !important;
    }
    .LandingTheme .ml-sm-13, .LandingTheme .mx-sm-13 {
        margin-left: 10rem !important;
    }
    .LandingTheme .m-sm-14 {
        margin: 12rem !important;
    }
    .LandingTheme .mt-sm-14, .LandingTheme .my-sm-14 {
        margin-top: 12rem !important;
    }
    .LandingTheme .mr-sm-14, .LandingTheme .mx-sm-14 {
        margin-right: 12rem !important;
    }
    .LandingTheme .mb-sm-14, .LandingTheme .my-sm-14 {
        margin-bottom: 12rem !important;
    }
    .LandingTheme .ml-sm-14, .LandingTheme .mx-sm-14 {
        margin-left: 12rem !important;
    }
    .LandingTheme .m-sm-15 {
        margin: 14rem !important;
    }
    .LandingTheme .mt-sm-15, .LandingTheme .my-sm-15 {
        margin-top: 14rem !important;
    }
    .LandingTheme .mr-sm-15, .LandingTheme .mx-sm-15 {
        margin-right: 14rem !important;
    }
    .LandingTheme .mb-sm-15, .LandingTheme .my-sm-15 {
        margin-bottom: 14rem !important;
    }
    .LandingTheme .ml-sm-15, .LandingTheme .mx-sm-15 {
        margin-left: 14rem !important;
    }
    .LandingTheme .m-sm-16 {
        margin: 16rem !important;
    }
    .LandingTheme .mt-sm-16, .LandingTheme .my-sm-16 {
        margin-top: 16rem !important;
    }
    .LandingTheme .mr-sm-16, .LandingTheme .mx-sm-16 {
        margin-right: 16rem !important;
    }
    .LandingTheme .mb-sm-16, .LandingTheme .my-sm-16 {
        margin-bottom: 16rem !important;
    }
    .LandingTheme .ml-sm-16, .LandingTheme .mx-sm-16 {
        margin-left: 16rem !important;
    }
    .LandingTheme .m-sm-17 {
        margin: 20rem !important;
    }
    .LandingTheme .mt-sm-17, .LandingTheme .my-sm-17 {
        margin-top: 20rem !important;
    }
    .LandingTheme .mr-sm-17, .LandingTheme .mx-sm-17 {
        margin-right: 20rem !important;
    }
    .LandingTheme .mb-sm-17, .LandingTheme .my-sm-17 {
        margin-bottom: 20rem !important;
    }
    .LandingTheme .ml-sm-17, .LandingTheme .mx-sm-17 {
        margin-left: 20rem !important;
    }
    .LandingTheme .m-sm-18 {
        margin: 24rem !important;
    }
    .LandingTheme .mt-sm-18, .LandingTheme .my-sm-18 {
        margin-top: 24rem !important;
    }
    .LandingTheme .mr-sm-18, .LandingTheme .mx-sm-18 {
        margin-right: 24rem !important;
    }
    .LandingTheme .mb-sm-18, .LandingTheme .my-sm-18 {
        margin-bottom: 24rem !important;
    }
    .LandingTheme .ml-sm-18, .LandingTheme .mx-sm-18 {
        margin-left: 24rem !important;
    }
    .LandingTheme .p-sm-0 {
        padding: 0 !important;
    }
    .LandingTheme .pt-sm-0, .LandingTheme .py-sm-0 {
        padding-top: 0 !important;
    }
    .LandingTheme .pr-sm-0, .LandingTheme .px-sm-0 {
        padding-right: 0 !important;
    }
    .LandingTheme .pb-sm-0, .LandingTheme .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .LandingTheme .pl-sm-0, .LandingTheme .px-sm-0 {
        padding-left: 0 !important;
    }
    .LandingTheme .p-sm-1 {
        padding: .25rem !important;
    }
    .LandingTheme .pt-sm-1, .LandingTheme .py-sm-1 {
        padding-top: .25rem !important;
    }
    .LandingTheme .pr-sm-1, .LandingTheme .px-sm-1 {
        padding-right: .25rem !important;
    }
    .LandingTheme .pb-sm-1, .LandingTheme .py-sm-1 {
        padding-bottom: .25rem !important;
    }
    .LandingTheme .pl-sm-1, .LandingTheme .px-sm-1 {
        padding-left: .25rem !important;
    }
    .LandingTheme .p-sm-2 {
        padding: .5rem !important;
    }
    .LandingTheme .pt-sm-2, .LandingTheme .py-sm-2 {
        padding-top: .5rem !important;
    }
    .LandingTheme .pr-sm-2, .LandingTheme .px-sm-2 {
        padding-right: .5rem !important;
    }
    .LandingTheme .pb-sm-2, .LandingTheme .py-sm-2 {
        padding-bottom: .5rem !important;
    }
    .LandingTheme .pl-sm-2, .LandingTheme .px-sm-2 {
        padding-left: .5rem !important;
    }
    .LandingTheme .p-sm-3 {
        padding: .75rem !important;
    }
    .LandingTheme .pt-sm-3, .LandingTheme .py-sm-3 {
        padding-top: .75rem !important;
    }
    .LandingTheme .pr-sm-3, .LandingTheme .px-sm-3 {
        padding-right: .75rem !important;
    }
    .LandingTheme .pb-sm-3, .LandingTheme .py-sm-3 {
        padding-bottom: .75rem !important;
    }
    .LandingTheme .pl-sm-3, .LandingTheme .px-sm-3 {
        padding-left: .75rem !important;
    }
    .LandingTheme .p-sm-4 {
        padding: 1rem !important;
    }
    .LandingTheme .pt-sm-4, .LandingTheme .py-sm-4 {
        padding-top: 1rem !important;
    }
    .LandingTheme .pr-sm-4, .LandingTheme .px-sm-4 {
        padding-right: 1rem !important;
    }
    .LandingTheme .pb-sm-4, .LandingTheme .py-sm-4 {
        padding-bottom: 1rem !important;
    }
    .LandingTheme .pl-sm-4, .LandingTheme .px-sm-4 {
        padding-left: 1rem !important;
    }
    .LandingTheme .p-sm-5 {
        padding: 1.5rem !important;
    }
    .LandingTheme .pt-sm-5, .LandingTheme .py-sm-5 {
        padding-top: 1.5rem !important;
    }
    .LandingTheme .pr-sm-5, .LandingTheme .px-sm-5 {
        padding-right: 1.5rem !important;
    }
    .LandingTheme .pb-sm-5, .LandingTheme .py-sm-5 {
        padding-bottom: 1.5rem !important;
    }
    .LandingTheme .pl-sm-5, .LandingTheme .px-sm-5 {
        padding-left: 1.5rem !important;
    }
    .LandingTheme .p-sm-6 {
        padding: 2rem !important;
    }
    .LandingTheme .pt-sm-6, .LandingTheme .py-sm-6 {
        padding-top: 2rem !important;
    }
    .LandingTheme .pr-sm-6, .LandingTheme .px-sm-6 {
        padding-right: 2rem !important;
    }
    .LandingTheme .pb-sm-6, .LandingTheme .py-sm-6 {
        padding-bottom: 2rem !important;
    }
    .LandingTheme .pl-sm-6, .LandingTheme .px-sm-6 {
        padding-left: 2rem !important;
    }
    .LandingTheme .p-sm-7 {
        padding: 2.5rem !important;
    }
    .LandingTheme .pt-sm-7, .LandingTheme .py-sm-7 {
        padding-top: 2.5rem !important;
    }
    .LandingTheme .pr-sm-7, .LandingTheme .px-sm-7 {
        padding-right: 2.5rem !important;
    }
    .LandingTheme .pb-sm-7, .LandingTheme .py-sm-7 {
        padding-bottom: 2.5rem !important;
    }
    .LandingTheme .pl-sm-7, .LandingTheme .px-sm-7 {
        padding-left: 2.5rem !important;
    }
    .LandingTheme .p-sm-8 {
        padding: 3rem !important;
    }
    .LandingTheme .pt-sm-8, .LandingTheme .py-sm-8 {
        padding-top: 3rem !important;
    }
    .LandingTheme .pr-sm-8, .LandingTheme .px-sm-8 {
        padding-right: 3rem !important;
    }
    .LandingTheme .pb-sm-8, .LandingTheme .py-sm-8 {
        padding-bottom: 3rem !important;
    }
    .LandingTheme .pl-sm-8, .LandingTheme .px-sm-8 {
        padding-left: 3rem !important;
    }
    .LandingTheme .p-sm-9 {
        padding: 4rem !important;
    }
    .LandingTheme .pt-sm-9, .LandingTheme .py-sm-9 {
        padding-top: 4rem !important;
    }
    .LandingTheme .pr-sm-9, .LandingTheme .px-sm-9 {
        padding-right: 4rem !important;
    }
    .LandingTheme .pb-sm-9, .LandingTheme .py-sm-9 {
        padding-bottom: 4rem !important;
    }
    .LandingTheme .pl-sm-9, .LandingTheme .px-sm-9 {
        padding-left: 4rem !important;
    }
    .LandingTheme .p-sm-10 {
        padding: 5rem !important;
    }
    .LandingTheme .pt-sm-10, .LandingTheme .py-sm-10 {
        padding-top: 5rem !important;
    }
    .LandingTheme .pr-sm-10, .LandingTheme .px-sm-10 {
        padding-right: 5rem !important;
    }
    .LandingTheme .pb-sm-10, .LandingTheme .py-sm-10 {
        padding-bottom: 5rem !important;
    }
    .LandingTheme .pl-sm-10, .LandingTheme .px-sm-10 {
        padding-left: 5rem !important;
    }
    .LandingTheme .p-sm-11 {
        padding: 6rem !important;
    }
    .LandingTheme .pt-sm-11, .LandingTheme .py-sm-11 {
        padding-top: 6rem !important;
    }
    .LandingTheme .pr-sm-11, .LandingTheme .px-sm-11 {
        padding-right: 6rem !important;
    }
    .LandingTheme .pb-sm-11, .LandingTheme .py-sm-11 {
        padding-bottom: 6rem !important;
    }
    .LandingTheme .pl-sm-11, .LandingTheme .px-sm-11 {
        padding-left: 6rem !important;
    }
    .LandingTheme .p-sm-12 {
        padding: 8rem !important;
    }
    .LandingTheme .pt-sm-12, .LandingTheme .py-sm-12 {
        padding-top: 8rem !important;
    }
    .LandingTheme .pr-sm-12, .LandingTheme .px-sm-12 {
        padding-right: 8rem !important;
    }
    .LandingTheme .pb-sm-12, .LandingTheme .py-sm-12 {
        padding-bottom: 8rem !important;
    }
    .LandingTheme .pl-sm-12, .LandingTheme .px-sm-12 {
        padding-left: 8rem !important;
    }
    .LandingTheme .p-sm-13 {
        padding: 10rem !important;
    }
    .LandingTheme .pt-sm-13, .LandingTheme .py-sm-13 {
        padding-top: 10rem !important;
    }
    .LandingTheme .pr-sm-13, .LandingTheme .px-sm-13 {
        padding-right: 10rem !important;
    }
    .LandingTheme .pb-sm-13, .LandingTheme .py-sm-13 {
        padding-bottom: 10rem !important;
    }
    .LandingTheme .pl-sm-13, .LandingTheme .px-sm-13 {
        padding-left: 10rem !important;
    }
    .LandingTheme .p-sm-14 {
        padding: 12rem !important;
    }
    .LandingTheme .pt-sm-14, .LandingTheme .py-sm-14 {
        padding-top: 12rem !important;
    }
    .LandingTheme .pr-sm-14, .LandingTheme .px-sm-14 {
        padding-right: 12rem !important;
    }
    .LandingTheme .pb-sm-14, .LandingTheme .py-sm-14 {
        padding-bottom: 12rem !important;
    }
    .LandingTheme .pl-sm-14, .LandingTheme .px-sm-14 {
        padding-left: 12rem !important;
    }
    .LandingTheme .p-sm-15 {
        padding: 14rem !important;
    }
    .LandingTheme .pt-sm-15, .LandingTheme .py-sm-15 {
        padding-top: 14rem !important;
    }
    .LandingTheme .pr-sm-15, .LandingTheme .px-sm-15 {
        padding-right: 14rem !important;
    }
    .LandingTheme .pb-sm-15, .LandingTheme .py-sm-15 {
        padding-bottom: 14rem !important;
    }
    .LandingTheme .pl-sm-15, .LandingTheme .px-sm-15 {
        padding-left: 14rem !important;
    }
    .LandingTheme .p-sm-16 {
        padding: 16rem !important;
    }
    .LandingTheme .pt-sm-16, .LandingTheme .py-sm-16 {
        padding-top: 16rem !important;
    }
    .LandingTheme .pr-sm-16, .LandingTheme .px-sm-16 {
        padding-right: 16rem !important;
    }
    .LandingTheme .pb-sm-16, .LandingTheme .py-sm-16 {
        padding-bottom: 16rem !important;
    }
    .LandingTheme .pl-sm-16, .LandingTheme .px-sm-16 {
        padding-left: 16rem !important;
    }
    .LandingTheme .p-sm-17 {
        padding: 20rem !important;
    }
    .LandingTheme .pt-sm-17, .LandingTheme .py-sm-17 {
        padding-top: 20rem !important;
    }
    .LandingTheme .pr-sm-17, .LandingTheme .px-sm-17 {
        padding-right: 20rem !important;
    }
    .LandingTheme .pb-sm-17, .LandingTheme .py-sm-17 {
        padding-bottom: 20rem !important;
    }
    .LandingTheme .pl-sm-17, .LandingTheme .px-sm-17 {
        padding-left: 20rem !important;
    }
    .LandingTheme .p-sm-18 {
        padding: 24rem !important;
    }
    .LandingTheme .pt-sm-18, .LandingTheme .py-sm-18 {
        padding-top: 24rem !important;
    }
    .LandingTheme .pr-sm-18, .LandingTheme .px-sm-18 {
        padding-right: 24rem !important;
    }
    .LandingTheme .pb-sm-18, .LandingTheme .py-sm-18 {
        padding-bottom: 24rem !important;
    }
    .LandingTheme .pl-sm-18, .LandingTheme .px-sm-18 {
        padding-left: 24rem !important;
    }
    .LandingTheme .m-sm-n1 {
        margin: -.25rem !important;
    }
    .LandingTheme .mt-sm-n1, .LandingTheme .my-sm-n1 {
        margin-top: -.25rem !important;
    }
    .LandingTheme .mr-sm-n1, .LandingTheme .mx-sm-n1 {
        margin-right: -.25rem !important;
    }
    .LandingTheme .mb-sm-n1, .LandingTheme .my-sm-n1 {
        margin-bottom: -.25rem !important;
    }
    .LandingTheme .ml-sm-n1, .LandingTheme .mx-sm-n1 {
        margin-left: -.25rem !important;
    }
    .LandingTheme .m-sm-n2 {
        margin: -.5rem !important;
    }
    .LandingTheme .mt-sm-n2, .LandingTheme .my-sm-n2 {
        margin-top: -.5rem !important;
    }
    .LandingTheme .mr-sm-n2, .LandingTheme .mx-sm-n2 {
        margin-right: -.5rem !important;
    }
    .LandingTheme .mb-sm-n2, .LandingTheme .my-sm-n2 {
        margin-bottom: -.5rem !important;
    }
    .LandingTheme .ml-sm-n2, .LandingTheme .mx-sm-n2 {
        margin-left: -.5rem !important;
    }
    .LandingTheme .m-sm-n3 {
        margin: -.75rem !important;
    }
    .LandingTheme .mt-sm-n3, .LandingTheme .my-sm-n3 {
        margin-top: -.75rem !important;
    }
    .LandingTheme .mr-sm-n3, .LandingTheme .mx-sm-n3 {
        margin-right: -.75rem !important;
    }
    .LandingTheme .mb-sm-n3, .LandingTheme .my-sm-n3 {
        margin-bottom: -.75rem !important;
    }
    .LandingTheme .ml-sm-n3, .LandingTheme .mx-sm-n3 {
        margin-left: -.75rem !important;
    }
    .LandingTheme .m-sm-n4 {
        margin: -1rem !important;
    }
    .LandingTheme .mt-sm-n4, .LandingTheme .my-sm-n4 {
        margin-top: -1rem !important;
    }
    .LandingTheme .mr-sm-n4, .LandingTheme .mx-sm-n4 {
        margin-right: -1rem !important;
    }
    .LandingTheme .mb-sm-n4, .LandingTheme .my-sm-n4 {
        margin-bottom: -1rem !important;
    }
    .LandingTheme .ml-sm-n4, .LandingTheme .mx-sm-n4 {
        margin-left: -1rem !important;
    }
    .LandingTheme .m-sm-n5 {
        margin: -1.5rem !important;
    }
    .LandingTheme .mt-sm-n5, .LandingTheme .my-sm-n5 {
        margin-top: -1.5rem !important;
    }
    .LandingTheme .mr-sm-n5, .LandingTheme .mx-sm-n5 {
        margin-right: -1.5rem !important;
    }
    .LandingTheme .mb-sm-n5, .LandingTheme .my-sm-n5 {
        margin-bottom: -1.5rem !important;
    }
    .LandingTheme .ml-sm-n5, .LandingTheme .mx-sm-n5 {
        margin-left: -1.5rem !important;
    }
    .LandingTheme .m-sm-n6 {
        margin: -2rem !important;
    }
    .LandingTheme .mt-sm-n6, .LandingTheme .my-sm-n6 {
        margin-top: -2rem !important;
    }
    .LandingTheme .mr-sm-n6, .LandingTheme .mx-sm-n6 {
        margin-right: -2rem !important;
    }
    .LandingTheme .mb-sm-n6, .LandingTheme .my-sm-n6 {
        margin-bottom: -2rem !important;
    }
    .LandingTheme .ml-sm-n6, .LandingTheme .mx-sm-n6 {
        margin-left: -2rem !important;
    }
    .LandingTheme .m-sm-n7 {
        margin: -2.5rem !important;
    }
    .LandingTheme .mt-sm-n7, .LandingTheme .my-sm-n7 {
        margin-top: -2.5rem !important;
    }
    .LandingTheme .mr-sm-n7, .LandingTheme .mx-sm-n7 {
        margin-right: -2.5rem !important;
    }
    .LandingTheme .mb-sm-n7, .LandingTheme .my-sm-n7 {
        margin-bottom: -2.5rem !important;
    }
    .LandingTheme .ml-sm-n7, .LandingTheme .mx-sm-n7 {
        margin-left: -2.5rem !important;
    }
    .LandingTheme .m-sm-n8 {
        margin: -3rem !important;
    }
    .LandingTheme .mt-sm-n8, .LandingTheme .my-sm-n8 {
        margin-top: -3rem !important;
    }
    .LandingTheme .mr-sm-n8, .LandingTheme .mx-sm-n8 {
        margin-right: -3rem !important;
    }
    .LandingTheme .mb-sm-n8, .LandingTheme .my-sm-n8 {
        margin-bottom: -3rem !important;
    }
    .LandingTheme .ml-sm-n8, .LandingTheme .mx-sm-n8 {
        margin-left: -3rem !important;
    }
    .LandingTheme .m-sm-n9 {
        margin: -4rem !important;
    }
    .LandingTheme .mt-sm-n9, .LandingTheme .my-sm-n9 {
        margin-top: -4rem !important;
    }
    .LandingTheme .mr-sm-n9, .LandingTheme .mx-sm-n9 {
        margin-right: -4rem !important;
    }
    .LandingTheme .mb-sm-n9, .LandingTheme .my-sm-n9 {
        margin-bottom: -4rem !important;
    }
    .LandingTheme .ml-sm-n9, .LandingTheme .mx-sm-n9 {
        margin-left: -4rem !important;
    }
    .LandingTheme .m-sm-n10 {
        margin: -5rem !important;
    }
    .LandingTheme .mt-sm-n10, .LandingTheme .my-sm-n10 {
        margin-top: -5rem !important;
    }
    .LandingTheme .mr-sm-n10, .LandingTheme .mx-sm-n10 {
        margin-right: -5rem !important;
    }
    .LandingTheme .mb-sm-n10, .LandingTheme .my-sm-n10 {
        margin-bottom: -5rem !important;
    }
    .LandingTheme .ml-sm-n10, .LandingTheme .mx-sm-n10 {
        margin-left: -5rem !important;
    }
    .LandingTheme .m-sm-n11 {
        margin: -6rem !important;
    }
    .LandingTheme .mt-sm-n11, .LandingTheme .my-sm-n11 {
        margin-top: -6rem !important;
    }
    .LandingTheme .mr-sm-n11, .LandingTheme .mx-sm-n11 {
        margin-right: -6rem !important;
    }
    .LandingTheme .mb-sm-n11, .LandingTheme .my-sm-n11 {
        margin-bottom: -6rem !important;
    }
    .LandingTheme .ml-sm-n11, .LandingTheme .mx-sm-n11 {
        margin-left: -6rem !important;
    }
    .LandingTheme .m-sm-n12 {
        margin: -8rem !important;
    }
    .LandingTheme .mt-sm-n12, .LandingTheme .my-sm-n12 {
        margin-top: -8rem !important;
    }
    .LandingTheme .mr-sm-n12, .LandingTheme .mx-sm-n12 {
        margin-right: -8rem !important;
    }
    .LandingTheme .mb-sm-n12, .LandingTheme .my-sm-n12 {
        margin-bottom: -8rem !important;
    }
    .LandingTheme .ml-sm-n12, .LandingTheme .mx-sm-n12 {
        margin-left: -8rem !important;
    }
    .LandingTheme .m-sm-n13 {
        margin: -10rem !important;
    }
    .LandingTheme .mt-sm-n13, .LandingTheme .my-sm-n13 {
        margin-top: -10rem !important;
    }
    .LandingTheme .mr-sm-n13, .LandingTheme .mx-sm-n13 {
        margin-right: -10rem !important;
    }
    .LandingTheme .mb-sm-n13, .LandingTheme .my-sm-n13 {
        margin-bottom: -10rem !important;
    }
    .LandingTheme .ml-sm-n13, .LandingTheme .mx-sm-n13 {
        margin-left: -10rem !important;
    }
    .LandingTheme .m-sm-n14 {
        margin: -12rem !important;
    }
    .LandingTheme .mt-sm-n14, .LandingTheme .my-sm-n14 {
        margin-top: -12rem !important;
    }
    .LandingTheme .mr-sm-n14, .LandingTheme .mx-sm-n14 {
        margin-right: -12rem !important;
    }
    .LandingTheme .mb-sm-n14, .LandingTheme .my-sm-n14 {
        margin-bottom: -12rem !important;
    }
    .LandingTheme .ml-sm-n14, .LandingTheme .mx-sm-n14 {
        margin-left: -12rem !important;
    }
    .LandingTheme .m-sm-n15 {
        margin: -14rem !important;
    }
    .LandingTheme .mt-sm-n15, .LandingTheme .my-sm-n15 {
        margin-top: -14rem !important;
    }
    .LandingTheme .mr-sm-n15, .LandingTheme .mx-sm-n15 {
        margin-right: -14rem !important;
    }
    .LandingTheme .mb-sm-n15, .LandingTheme .my-sm-n15 {
        margin-bottom: -14rem !important;
    }
    .LandingTheme .ml-sm-n15, .LandingTheme .mx-sm-n15 {
        margin-left: -14rem !important;
    }
    .LandingTheme .m-sm-n16 {
        margin: -16rem !important;
    }
    .LandingTheme .mt-sm-n16, .LandingTheme .my-sm-n16 {
        margin-top: -16rem !important;
    }
    .LandingTheme .mr-sm-n16, .LandingTheme .mx-sm-n16 {
        margin-right: -16rem !important;
    }
    .LandingTheme .mb-sm-n16, .LandingTheme .my-sm-n16 {
        margin-bottom: -16rem !important;
    }
    .LandingTheme .ml-sm-n16, .LandingTheme .mx-sm-n16 {
        margin-left: -16rem !important;
    }
    .LandingTheme .m-sm-n17 {
        margin: -20rem !important;
    }
    .LandingTheme .mt-sm-n17, .LandingTheme .my-sm-n17 {
        margin-top: -20rem !important;
    }
    .LandingTheme .mr-sm-n17, .LandingTheme .mx-sm-n17 {
        margin-right: -20rem !important;
    }
    .LandingTheme .mb-sm-n17, .LandingTheme .my-sm-n17 {
        margin-bottom: -20rem !important;
    }
    .LandingTheme .ml-sm-n17, .LandingTheme .mx-sm-n17 {
        margin-left: -20rem !important;
    }
    .LandingTheme .m-sm-n18 {
        margin: -24rem !important;
    }
    .LandingTheme .mt-sm-n18, .LandingTheme .my-sm-n18 {
        margin-top: -24rem !important;
    }
    .LandingTheme .mr-sm-n18, .LandingTheme .mx-sm-n18 {
        margin-right: -24rem !important;
    }
    .LandingTheme .mb-sm-n18, .LandingTheme .my-sm-n18 {
        margin-bottom: -24rem !important;
    }
    .LandingTheme .ml-sm-n18, .LandingTheme .mx-sm-n18 {
        margin-left: -24rem !important;
    }
    .LandingTheme .m-sm-auto {
        margin: auto !important;
    }
    .LandingTheme .mt-sm-auto, .LandingTheme .my-sm-auto {
        margin-top: auto !important;
    }
    .LandingTheme .mr-sm-auto, .LandingTheme .mx-sm-auto {
        margin-right: auto !important;
    }
    .LandingTheme .mb-sm-auto, .LandingTheme .my-sm-auto {
        margin-bottom: auto !important;
    }
    .LandingTheme .ml-sm-auto, .LandingTheme .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .m-md-0 {
        margin: 0 !important;
    }
    .LandingTheme .mt-md-0, .LandingTheme .my-md-0 {
        margin-top: 0 !important;
    }
    .LandingTheme .mr-md-0, .LandingTheme .mx-md-0 {
        margin-right: 0 !important;
    }
    .LandingTheme .mb-md-0, .LandingTheme .my-md-0 {
        margin-bottom: 0 !important;
    }
    .LandingTheme .ml-md-0, .LandingTheme .mx-md-0 {
        margin-left: 0 !important;
    }
    .LandingTheme .m-md-1 {
        margin: .25rem !important;
    }
    .LandingTheme .mt-md-1, .LandingTheme .my-md-1 {
        margin-top: .25rem !important;
    }
    .LandingTheme .mr-md-1, .LandingTheme .mx-md-1 {
        margin-right: .25rem !important;
    }
    .LandingTheme .mb-md-1, .LandingTheme .my-md-1 {
        margin-bottom: .25rem !important;
    }
    .LandingTheme .ml-md-1, .LandingTheme .mx-md-1 {
        margin-left: .25rem !important;
    }
    .LandingTheme .m-md-2 {
        margin: .5rem !important;
    }
    .LandingTheme .mt-md-2, .LandingTheme .my-md-2 {
        margin-top: .5rem !important;
    }
    .LandingTheme .mr-md-2, .LandingTheme .mx-md-2 {
        margin-right: .5rem !important;
    }
    .LandingTheme .mb-md-2, .LandingTheme .my-md-2 {
        margin-bottom: .5rem !important;
    }
    .LandingTheme .ml-md-2, .LandingTheme .mx-md-2 {
        margin-left: .5rem !important;
    }
    .LandingTheme .m-md-3 {
        margin: .75rem !important;
    }
    .LandingTheme .mt-md-3, .LandingTheme .my-md-3 {
        margin-top: .75rem !important;
    }
    .LandingTheme .mr-md-3, .LandingTheme .mx-md-3 {
        margin-right: .75rem !important;
    }
    .LandingTheme .mb-md-3, .LandingTheme .my-md-3 {
        margin-bottom: .75rem !important;
    }
    .LandingTheme .ml-md-3, .LandingTheme .mx-md-3 {
        margin-left: .75rem !important;
    }
    .LandingTheme .m-md-4 {
        margin: 1rem !important;
    }
    .LandingTheme .mt-md-4, .LandingTheme .my-md-4 {
        margin-top: 1rem !important;
    }
    .LandingTheme .mr-md-4, .LandingTheme .mx-md-4 {
        margin-right: 1rem !important;
    }
    .LandingTheme .mb-md-4, .LandingTheme .my-md-4 {
        margin-bottom: 1rem !important;
    }
    .LandingTheme .ml-md-4, .LandingTheme .mx-md-4 {
        margin-left: 1rem !important;
    }
    .LandingTheme .m-md-5 {
        margin: 1.5rem !important;
    }
    .LandingTheme .mt-md-5, .LandingTheme .my-md-5 {
        margin-top: 1.5rem !important;
    }
    .LandingTheme .mr-md-5, .LandingTheme .mx-md-5 {
        margin-right: 1.5rem !important;
    }
    .LandingTheme .mb-md-5, .LandingTheme .my-md-5 {
        margin-bottom: 1.5rem !important;
    }
    .LandingTheme .ml-md-5, .LandingTheme .mx-md-5 {
        margin-left: 1.5rem !important;
    }
    .LandingTheme .m-md-6 {
        margin: 2rem !important;
    }
    .LandingTheme .mt-md-6, .LandingTheme .my-md-6 {
        margin-top: 2rem !important;
    }
    .LandingTheme .mr-md-6, .LandingTheme .mx-md-6 {
        margin-right: 2rem !important;
    }
    .LandingTheme .mb-md-6, .LandingTheme .my-md-6 {
        margin-bottom: 2rem !important;
    }
    .LandingTheme .ml-md-6, .LandingTheme .mx-md-6 {
        margin-left: 2rem !important;
    }
    .LandingTheme .m-md-7 {
        margin: 2.5rem !important;
    }
    .LandingTheme .mt-md-7, .LandingTheme .my-md-7 {
        margin-top: 2.5rem !important;
    }
    .LandingTheme .mr-md-7, .LandingTheme .mx-md-7 {
        margin-right: 2.5rem !important;
    }
    .LandingTheme .mb-md-7, .LandingTheme .my-md-7 {
        margin-bottom: 2.5rem !important;
    }
    .LandingTheme .ml-md-7, .LandingTheme .mx-md-7 {
        margin-left: 2.5rem !important;
    }
    .LandingTheme .m-md-8 {
        margin: 3rem !important;
    }
    .LandingTheme .mt-md-8, .LandingTheme .my-md-8 {
        margin-top: 3rem !important;
    }
    .LandingTheme .mr-md-8, .LandingTheme .mx-md-8 {
        margin-right: 3rem !important;
    }
    .LandingTheme .mb-md-8, .LandingTheme .my-md-8 {
        margin-bottom: 3rem !important;
    }
    .LandingTheme .ml-md-8, .LandingTheme .mx-md-8 {
        margin-left: 3rem !important;
    }
    .LandingTheme .m-md-9 {
        margin: 4rem !important;
    }
    .LandingTheme .mt-md-9, .LandingTheme .my-md-9 {
        margin-top: 4rem !important;
    }
    .LandingTheme .mr-md-9, .LandingTheme .mx-md-9 {
        margin-right: 4rem !important;
    }
    .LandingTheme .mb-md-9, .LandingTheme .my-md-9 {
        margin-bottom: 4rem !important;
    }
    .LandingTheme .ml-md-9, .LandingTheme .mx-md-9 {
        margin-left: 4rem !important;
    }
    .LandingTheme .m-md-10 {
        margin: 5rem !important;
    }
    .LandingTheme .mt-md-10, .LandingTheme .my-md-10 {
        margin-top: 5rem !important;
    }
    .LandingTheme .mr-md-10, .LandingTheme .mx-md-10 {
        margin-right: 5rem !important;
    }
    .LandingTheme .mb-md-10, .LandingTheme .my-md-10 {
        margin-bottom: 5rem !important;
    }
    .LandingTheme .ml-md-10, .LandingTheme .mx-md-10 {
        margin-left: 5rem !important;
    }
    .LandingTheme .m-md-11 {
        margin: 6rem !important;
    }
    .LandingTheme .mt-md-11, .LandingTheme .my-md-11 {
        margin-top: 6rem !important;
    }
    .LandingTheme .mr-md-11, .LandingTheme .mx-md-11 {
        margin-right: 6rem !important;
    }
    .LandingTheme .mb-md-11, .LandingTheme .my-md-11 {
        margin-bottom: 6rem !important;
    }
    .LandingTheme .ml-md-11, .LandingTheme .mx-md-11 {
        margin-left: 6rem !important;
    }
    .LandingTheme .m-md-12 {
        margin: 8rem !important;
    }
    .LandingTheme .mt-md-12, .LandingTheme .my-md-12 {
        margin-top: 8rem !important;
    }
    .LandingTheme .mr-md-12, .LandingTheme .mx-md-12 {
        margin-right: 8rem !important;
    }
    .LandingTheme .mb-md-12, .LandingTheme .my-md-12 {
        margin-bottom: 8rem !important;
    }
    .LandingTheme .ml-md-12, .LandingTheme .mx-md-12 {
        margin-left: 8rem !important;
    }
    .LandingTheme .m-md-13 {
        margin: 10rem !important;
    }
    .LandingTheme .mt-md-13, .LandingTheme .my-md-13 {
        margin-top: 10rem !important;
    }
    .LandingTheme .mr-md-13, .LandingTheme .mx-md-13 {
        margin-right: 10rem !important;
    }
    .LandingTheme .mb-md-13, .LandingTheme .my-md-13 {
        margin-bottom: 10rem !important;
    }
    .LandingTheme .ml-md-13, .LandingTheme .mx-md-13 {
        margin-left: 10rem !important;
    }
    .LandingTheme .m-md-14 {
        margin: 12rem !important;
    }
    .LandingTheme .mt-md-14, .LandingTheme .my-md-14 {
        margin-top: 12rem !important;
    }
    .LandingTheme .mr-md-14, .LandingTheme .mx-md-14 {
        margin-right: 12rem !important;
    }
    .LandingTheme .mb-md-14, .LandingTheme .my-md-14 {
        margin-bottom: 12rem !important;
    }
    .LandingTheme .ml-md-14, .LandingTheme .mx-md-14 {
        margin-left: 12rem !important;
    }
    .LandingTheme .m-md-15 {
        margin: 14rem !important;
    }
    .LandingTheme .mt-md-15, .LandingTheme .my-md-15 {
        margin-top: 14rem !important;
    }
    .LandingTheme .mr-md-15, .LandingTheme .mx-md-15 {
        margin-right: 14rem !important;
    }
    .LandingTheme .mb-md-15, .LandingTheme .my-md-15 {
        margin-bottom: 14rem !important;
    }
    .LandingTheme .ml-md-15, .LandingTheme .mx-md-15 {
        margin-left: 14rem !important;
    }
    .LandingTheme .m-md-16 {
        margin: 16rem !important;
    }
    .LandingTheme .mt-md-16, .LandingTheme .my-md-16 {
        margin-top: 16rem !important;
    }
    .LandingTheme .mr-md-16, .LandingTheme .mx-md-16 {
        margin-right: 16rem !important;
    }
    .LandingTheme .mb-md-16, .LandingTheme .my-md-16 {
        margin-bottom: 16rem !important;
    }
    .LandingTheme .ml-md-16, .LandingTheme .mx-md-16 {
        margin-left: 16rem !important;
    }
    .LandingTheme .m-md-17 {
        margin: 20rem !important;
    }
    .LandingTheme .mt-md-17, .LandingTheme .my-md-17 {
        margin-top: 20rem !important;
    }
    .LandingTheme .mr-md-17, .LandingTheme .mx-md-17 {
        margin-right: 20rem !important;
    }
    .LandingTheme .mb-md-17, .LandingTheme .my-md-17 {
        margin-bottom: 20rem !important;
    }
    .LandingTheme .ml-md-17, .LandingTheme .mx-md-17 {
        margin-left: 20rem !important;
    }
    .LandingTheme .m-md-18 {
        margin: 24rem !important;
    }
    .LandingTheme .mt-md-18, .LandingTheme .my-md-18 {
        margin-top: 24rem !important;
    }
    .LandingTheme .mr-md-18, .LandingTheme .mx-md-18 {
        margin-right: 24rem !important;
    }
    .LandingTheme .mb-md-18, .LandingTheme .my-md-18 {
        margin-bottom: 24rem !important;
    }
    .LandingTheme .ml-md-18, .LandingTheme .mx-md-18 {
        margin-left: 24rem !important;
    }
    .LandingTheme .p-md-0 {
        padding: 0 !important;
    }
    .LandingTheme .pt-md-0, .LandingTheme .py-md-0 {
        padding-top: 0 !important;
    }
    .LandingTheme .pr-md-0, .LandingTheme .px-md-0 {
        padding-right: 0 !important;
    }
    .LandingTheme .pb-md-0, .LandingTheme .py-md-0 {
        padding-bottom: 0 !important;
    }
    .LandingTheme .pl-md-0, .LandingTheme .px-md-0 {
        padding-left: 0 !important;
    }
    .LandingTheme .p-md-1 {
        padding: .25rem !important;
    }
    .LandingTheme .pt-md-1, .LandingTheme .py-md-1 {
        padding-top: .25rem !important;
    }
    .LandingTheme .pr-md-1, .LandingTheme .px-md-1 {
        padding-right: .25rem !important;
    }
    .LandingTheme .pb-md-1, .LandingTheme .py-md-1 {
        padding-bottom: .25rem !important;
    }
    .LandingTheme .pl-md-1, .LandingTheme .px-md-1 {
        padding-left: .25rem !important;
    }
    .LandingTheme .p-md-2 {
        padding: .5rem !important;
    }
    .LandingTheme .pt-md-2, .LandingTheme .py-md-2 {
        padding-top: .5rem !important;
    }
    .LandingTheme .pr-md-2, .LandingTheme .px-md-2 {
        padding-right: .5rem !important;
    }
    .LandingTheme .pb-md-2, .LandingTheme .py-md-2 {
        padding-bottom: .5rem !important;
    }
    .LandingTheme .pl-md-2, .LandingTheme .px-md-2 {
        padding-left: .5rem !important;
    }
    .LandingTheme .p-md-3 {
        padding: .75rem !important;
    }
    .LandingTheme .pt-md-3, .LandingTheme .py-md-3 {
        padding-top: .75rem !important;
    }
    .LandingTheme .pr-md-3, .LandingTheme .px-md-3 {
        padding-right: .75rem !important;
    }
    .LandingTheme .pb-md-3, .LandingTheme .py-md-3 {
        padding-bottom: .75rem !important;
    }
    .LandingTheme .pl-md-3, .LandingTheme .px-md-3 {
        padding-left: .75rem !important;
    }
    .LandingTheme .p-md-4 {
        padding: 1rem !important;
    }
    .LandingTheme .pt-md-4, .LandingTheme .py-md-4 {
        padding-top: 1rem !important;
    }
    .LandingTheme .pr-md-4, .LandingTheme .px-md-4 {
        padding-right: 1rem !important;
    }
    .LandingTheme .pb-md-4, .LandingTheme .py-md-4 {
        padding-bottom: 1rem !important;
    }
    .LandingTheme .pl-md-4, .LandingTheme .px-md-4 {
        padding-left: 1rem !important;
    }
    .LandingTheme .p-md-5 {
        padding: 1.5rem !important;
    }
    .LandingTheme .pt-md-5, .LandingTheme .py-md-5 {
        padding-top: 1.5rem !important;
    }
    .LandingTheme .pr-md-5, .LandingTheme .px-md-5 {
        padding-right: 1.5rem !important;
    }
    .LandingTheme .pb-md-5, .LandingTheme .py-md-5 {
        padding-bottom: 1.5rem !important;
    }
    .LandingTheme .pl-md-5, .LandingTheme .px-md-5 {
        padding-left: 1.5rem !important;
    }
    .LandingTheme .p-md-6 {
        padding: 2rem !important;
    }
    .LandingTheme .pt-md-6, .LandingTheme .py-md-6 {
        padding-top: 2rem !important;
    }
    .LandingTheme .pr-md-6, .LandingTheme .px-md-6 {
        padding-right: 2rem !important;
    }
    .LandingTheme .pb-md-6, .LandingTheme .py-md-6 {
        padding-bottom: 2rem !important;
    }
    .LandingTheme .pl-md-6, .LandingTheme .px-md-6 {
        padding-left: 2rem !important;
    }
    .LandingTheme .p-md-7 {
        padding: 2.5rem !important;
    }
    .LandingTheme .pt-md-7, .LandingTheme .py-md-7 {
        padding-top: 2.5rem !important;
    }
    .LandingTheme .pr-md-7, .LandingTheme .px-md-7 {
        padding-right: 2.5rem !important;
    }
    .LandingTheme .pb-md-7, .LandingTheme .py-md-7 {
        padding-bottom: 2.5rem !important;
    }
    .LandingTheme .pl-md-7, .LandingTheme .px-md-7 {
        padding-left: 2.5rem !important;
    }
    .LandingTheme .p-md-8 {
        padding: 3rem !important;
    }
    .LandingTheme .pt-md-8, .LandingTheme .py-md-8 {
        padding-top: 3rem !important;
    }
    .LandingTheme .pr-md-8, .LandingTheme .px-md-8 {
        padding-right: 3rem !important;
    }
    .LandingTheme .pb-md-8, .LandingTheme .py-md-8 {
        padding-bottom: 3rem !important;
    }
    .LandingTheme .pl-md-8, .LandingTheme .px-md-8 {
        padding-left: 3rem !important;
    }
    .LandingTheme .p-md-9 {
        padding: 4rem !important;
    }
    .LandingTheme .pt-md-9, .LandingTheme .py-md-9 {
        padding-top: 4rem !important;
    }
    .LandingTheme .pr-md-9, .LandingTheme .px-md-9 {
        padding-right: 4rem !important;
    }
    .LandingTheme .pb-md-9, .LandingTheme .py-md-9 {
        padding-bottom: 4rem !important;
    }
    .LandingTheme .pl-md-9, .LandingTheme .px-md-9 {
        padding-left: 4rem !important;
    }
    .LandingTheme .p-md-10 {
        padding: 5rem !important;
    }
    .LandingTheme .pt-md-10, .LandingTheme .py-md-10 {
        padding-top: 5rem !important;
    }
    .LandingTheme .pr-md-10, .LandingTheme .px-md-10 {
        padding-right: 5rem !important;
    }
    .LandingTheme .pb-md-10, .LandingTheme .py-md-10 {
        padding-bottom: 5rem !important;
    }
    .LandingTheme .pl-md-10, .LandingTheme .px-md-10 {
        padding-left: 5rem !important;
    }
    .LandingTheme .p-md-11 {
        padding: 6rem !important;
    }
    .LandingTheme .pt-md-11, .LandingTheme .py-md-11 {
        padding-top: 6rem !important;
    }
    .LandingTheme .pr-md-11, .LandingTheme .px-md-11 {
        padding-right: 6rem !important;
    }
    .LandingTheme .pb-md-11, .LandingTheme .py-md-11 {
        padding-bottom: 6rem !important;
    }
    .LandingTheme .pl-md-11, .LandingTheme .px-md-11 {
        padding-left: 6rem !important;
    }
    .LandingTheme .p-md-12 {
        padding: 8rem !important;
    }
    .LandingTheme .pt-md-12, .LandingTheme .py-md-12 {
        padding-top: 8rem !important;
    }
    .LandingTheme .pr-md-12, .LandingTheme .px-md-12 {
        padding-right: 8rem !important;
    }
    .LandingTheme .pb-md-12, .LandingTheme .py-md-12 {
        padding-bottom: 8rem !important;
    }
    .LandingTheme .pl-md-12, .LandingTheme .px-md-12 {
        padding-left: 8rem !important;
    }
    .LandingTheme .p-md-13 {
        padding: 10rem !important;
    }
    .LandingTheme .pt-md-13, .LandingTheme .py-md-13 {
        padding-top: 10rem !important;
    }
    .LandingTheme .pr-md-13, .LandingTheme .px-md-13 {
        padding-right: 10rem !important;
    }
    .LandingTheme .pb-md-13, .LandingTheme .py-md-13 {
        padding-bottom: 10rem !important;
    }
    .LandingTheme .pl-md-13, .LandingTheme .px-md-13 {
        padding-left: 10rem !important;
    }
    .LandingTheme .p-md-14 {
        padding: 12rem !important;
    }
    .LandingTheme .pt-md-14, .LandingTheme .py-md-14 {
        padding-top: 12rem !important;
    }
    .LandingTheme .pr-md-14, .LandingTheme .px-md-14 {
        padding-right: 12rem !important;
    }
    .LandingTheme .pb-md-14, .LandingTheme .py-md-14 {
        padding-bottom: 12rem !important;
    }
    .LandingTheme .pl-md-14, .LandingTheme .px-md-14 {
        padding-left: 12rem !important;
    }
    .LandingTheme .p-md-15 {
        padding: 14rem !important;
    }
    .LandingTheme .pt-md-15, .LandingTheme .py-md-15 {
        padding-top: 14rem !important;
    }
    .LandingTheme .pr-md-15, .LandingTheme .px-md-15 {
        padding-right: 14rem !important;
    }
    .LandingTheme .pb-md-15, .LandingTheme .py-md-15 {
        padding-bottom: 14rem !important;
    }
    .LandingTheme .pl-md-15, .LandingTheme .px-md-15 {
        padding-left: 14rem !important;
    }
    .LandingTheme .p-md-16 {
        padding: 16rem !important;
    }
    .LandingTheme .pt-md-16, .LandingTheme .py-md-16 {
        padding-top: 16rem !important;
    }
    .LandingTheme .pr-md-16, .LandingTheme .px-md-16 {
        padding-right: 16rem !important;
    }
    .LandingTheme .pb-md-16, .LandingTheme .py-md-16 {
        padding-bottom: 16rem !important;
    }
    .LandingTheme .pl-md-16, .LandingTheme .px-md-16 {
        padding-left: 16rem !important;
    }
    .LandingTheme .p-md-17 {
        padding: 20rem !important;
    }
    .LandingTheme .pt-md-17, .LandingTheme .py-md-17 {
        padding-top: 20rem !important;
    }
    .LandingTheme .pr-md-17, .LandingTheme .px-md-17 {
        padding-right: 20rem !important;
    }
    .LandingTheme .pb-md-17, .LandingTheme .py-md-17 {
        padding-bottom: 20rem !important;
    }
    .LandingTheme .pl-md-17, .LandingTheme .px-md-17 {
        padding-left: 20rem !important;
    }
    .LandingTheme .p-md-18 {
        padding: 24rem !important;
    }
    .LandingTheme .pt-md-18, .LandingTheme .py-md-18 {
        padding-top: 24rem !important;
    }
    .LandingTheme .pr-md-18, .LandingTheme .px-md-18 {
        padding-right: 24rem !important;
    }
    .LandingTheme .pb-md-18, .LandingTheme .py-md-18 {
        padding-bottom: 24rem !important;
    }
    .LandingTheme .pl-md-18, .LandingTheme .px-md-18 {
        padding-left: 24rem !important;
    }
    .LandingTheme .m-md-n1 {
        margin: -.25rem !important;
    }
    .LandingTheme .mt-md-n1, .LandingTheme .my-md-n1 {
        margin-top: -.25rem !important;
    }
    .LandingTheme .mr-md-n1, .LandingTheme .mx-md-n1 {
        margin-right: -.25rem !important;
    }
    .LandingTheme .mb-md-n1, .LandingTheme .my-md-n1 {
        margin-bottom: -.25rem !important;
    }
    .LandingTheme .ml-md-n1, .LandingTheme .mx-md-n1 {
        margin-left: -.25rem !important;
    }
    .LandingTheme .m-md-n2 {
        margin: -.5rem !important;
    }
    .LandingTheme .mt-md-n2, .LandingTheme .my-md-n2 {
        margin-top: -.5rem !important;
    }
    .LandingTheme .mr-md-n2, .LandingTheme .mx-md-n2 {
        margin-right: -.5rem !important;
    }
    .LandingTheme .mb-md-n2, .LandingTheme .my-md-n2 {
        margin-bottom: -.5rem !important;
    }
    .LandingTheme .ml-md-n2, .LandingTheme .mx-md-n2 {
        margin-left: -.5rem !important;
    }
    .LandingTheme .m-md-n3 {
        margin: -.75rem !important;
    }
    .LandingTheme .mt-md-n3, .LandingTheme .my-md-n3 {
        margin-top: -.75rem !important;
    }
    .LandingTheme .mr-md-n3, .LandingTheme .mx-md-n3 {
        margin-right: -.75rem !important;
    }
    .LandingTheme .mb-md-n3, .LandingTheme .my-md-n3 {
        margin-bottom: -.75rem !important;
    }
    .LandingTheme .ml-md-n3, .LandingTheme .mx-md-n3 {
        margin-left: -.75rem !important;
    }
    .LandingTheme .m-md-n4 {
        margin: -1rem !important;
    }
    .LandingTheme .mt-md-n4, .LandingTheme .my-md-n4 {
        margin-top: -1rem !important;
    }
    .LandingTheme .mr-md-n4, .LandingTheme .mx-md-n4 {
        margin-right: -1rem !important;
    }
    .LandingTheme .mb-md-n4, .LandingTheme .my-md-n4 {
        margin-bottom: -1rem !important;
    }
    .LandingTheme .ml-md-n4, .LandingTheme .mx-md-n4 {
        margin-left: -1rem !important;
    }
    .LandingTheme .m-md-n5 {
        margin: -1.5rem !important;
    }
    .LandingTheme .mt-md-n5, .LandingTheme .my-md-n5 {
        margin-top: -1.5rem !important;
    }
    .LandingTheme .mr-md-n5, .LandingTheme .mx-md-n5 {
        margin-right: -1.5rem !important;
    }
    .LandingTheme .mb-md-n5, .LandingTheme .my-md-n5 {
        margin-bottom: -1.5rem !important;
    }
    .LandingTheme .ml-md-n5, .LandingTheme .mx-md-n5 {
        margin-left: -1.5rem !important;
    }
    .LandingTheme .m-md-n6 {
        margin: -2rem !important;
    }
    .LandingTheme .mt-md-n6, .LandingTheme .my-md-n6 {
        margin-top: -2rem !important;
    }
    .LandingTheme .mr-md-n6, .LandingTheme .mx-md-n6 {
        margin-right: -2rem !important;
    }
    .LandingTheme .mb-md-n6, .LandingTheme .my-md-n6 {
        margin-bottom: -2rem !important;
    }
    .LandingTheme .ml-md-n6, .LandingTheme .mx-md-n6 {
        margin-left: -2rem !important;
    }
    .LandingTheme .m-md-n7 {
        margin: -2.5rem !important;
    }
    .LandingTheme .mt-md-n7, .LandingTheme .my-md-n7 {
        margin-top: -2.5rem !important;
    }
    .LandingTheme .mr-md-n7, .LandingTheme .mx-md-n7 {
        margin-right: -2.5rem !important;
    }
    .LandingTheme .mb-md-n7, .LandingTheme .my-md-n7 {
        margin-bottom: -2.5rem !important;
    }
    .LandingTheme .ml-md-n7, .LandingTheme .mx-md-n7 {
        margin-left: -2.5rem !important;
    }
    .LandingTheme .m-md-n8 {
        margin: -3rem !important;
    }
    .LandingTheme .mt-md-n8, .LandingTheme .my-md-n8 {
        margin-top: -3rem !important;
    }
    .LandingTheme .mr-md-n8, .LandingTheme .mx-md-n8 {
        margin-right: -3rem !important;
    }
    .LandingTheme .mb-md-n8, .LandingTheme .my-md-n8 {
        margin-bottom: -3rem !important;
    }
    .LandingTheme .ml-md-n8, .LandingTheme .mx-md-n8 {
        margin-left: -3rem !important;
    }
    .LandingTheme .m-md-n9 {
        margin: -4rem !important;
    }
    .LandingTheme .mt-md-n9, .LandingTheme .my-md-n9 {
        margin-top: -4rem !important;
    }
    .LandingTheme .mr-md-n9, .LandingTheme .mx-md-n9 {
        margin-right: -4rem !important;
    }
    .LandingTheme .mb-md-n9, .LandingTheme .my-md-n9 {
        margin-bottom: -4rem !important;
    }
    .LandingTheme .ml-md-n9, .LandingTheme .mx-md-n9 {
        margin-left: -4rem !important;
    }
    .LandingTheme .m-md-n10 {
        margin: -5rem !important;
    }
    .LandingTheme .mt-md-n10, .LandingTheme .my-md-n10 {
        margin-top: -5rem !important;
    }
    .LandingTheme .mr-md-n10, .LandingTheme .mx-md-n10 {
        margin-right: -5rem !important;
    }
    .LandingTheme .mb-md-n10, .LandingTheme .my-md-n10 {
        margin-bottom: -5rem !important;
    }
    .LandingTheme .ml-md-n10, .LandingTheme .mx-md-n10 {
        margin-left: -5rem !important;
    }
    .LandingTheme .m-md-n11 {
        margin: -6rem !important;
    }
    .LandingTheme .mt-md-n11, .LandingTheme .my-md-n11 {
        margin-top: -6rem !important;
    }
    .LandingTheme .mr-md-n11, .LandingTheme .mx-md-n11 {
        margin-right: -6rem !important;
    }
    .LandingTheme .mb-md-n11, .LandingTheme .my-md-n11 {
        margin-bottom: -6rem !important;
    }
    .LandingTheme .ml-md-n11, .LandingTheme .mx-md-n11 {
        margin-left: -6rem !important;
    }
    .LandingTheme .m-md-n12 {
        margin: -8rem !important;
    }
    .LandingTheme .mt-md-n12, .LandingTheme .my-md-n12 {
        margin-top: -8rem !important;
    }
    .LandingTheme .mr-md-n12, .LandingTheme .mx-md-n12 {
        margin-right: -8rem !important;
    }
    .LandingTheme .mb-md-n12, .LandingTheme .my-md-n12 {
        margin-bottom: -8rem !important;
    }
    .LandingTheme .ml-md-n12, .LandingTheme .mx-md-n12 {
        margin-left: -8rem !important;
    }
    .LandingTheme .m-md-n13 {
        margin: -10rem !important;
    }
    .LandingTheme .mt-md-n13, .LandingTheme .my-md-n13 {
        margin-top: -10rem !important;
    }
    .LandingTheme .mr-md-n13, .LandingTheme .mx-md-n13 {
        margin-right: -10rem !important;
    }
    .LandingTheme .mb-md-n13, .LandingTheme .my-md-n13 {
        margin-bottom: -10rem !important;
    }
    .LandingTheme .ml-md-n13, .LandingTheme .mx-md-n13 {
        margin-left: -10rem !important;
    }
    .LandingTheme .m-md-n14 {
        margin: -12rem !important;
    }
    .LandingTheme .mt-md-n14, .LandingTheme .my-md-n14 {
        margin-top: -12rem !important;
    }
    .LandingTheme .mr-md-n14, .LandingTheme .mx-md-n14 {
        margin-right: -12rem !important;
    }
    .LandingTheme .mb-md-n14, .LandingTheme .my-md-n14 {
        margin-bottom: -12rem !important;
    }
    .LandingTheme .ml-md-n14, .LandingTheme .mx-md-n14 {
        margin-left: -12rem !important;
    }
    .LandingTheme .m-md-n15 {
        margin: -14rem !important;
    }
    .LandingTheme .mt-md-n15, .LandingTheme .my-md-n15 {
        margin-top: -14rem !important;
    }
    .LandingTheme .mr-md-n15, .LandingTheme .mx-md-n15 {
        margin-right: -14rem !important;
    }
    .LandingTheme .mb-md-n15, .LandingTheme .my-md-n15 {
        margin-bottom: -14rem !important;
    }
    .LandingTheme .ml-md-n15, .LandingTheme .mx-md-n15 {
        margin-left: -14rem !important;
    }
    .LandingTheme .m-md-n16 {
        margin: -16rem !important;
    }
    .LandingTheme .mt-md-n16, .LandingTheme .my-md-n16 {
        margin-top: -16rem !important;
    }
    .LandingTheme .mr-md-n16, .LandingTheme .mx-md-n16 {
        margin-right: -16rem !important;
    }
    .LandingTheme .mb-md-n16, .LandingTheme .my-md-n16 {
        margin-bottom: -16rem !important;
    }
    .LandingTheme .ml-md-n16, .LandingTheme .mx-md-n16 {
        margin-left: -16rem !important;
    }
    .LandingTheme .m-md-n17 {
        margin: -20rem !important;
    }
    .LandingTheme .mt-md-n17, .LandingTheme .my-md-n17 {
        margin-top: -20rem !important;
    }
    .LandingTheme .mr-md-n17, .LandingTheme .mx-md-n17 {
        margin-right: -20rem !important;
    }
    .LandingTheme .mb-md-n17, .LandingTheme .my-md-n17 {
        margin-bottom: -20rem !important;
    }
    .LandingTheme .ml-md-n17, .LandingTheme .mx-md-n17 {
        margin-left: -20rem !important;
    }
    .LandingTheme .m-md-n18 {
        margin: -24rem !important;
    }
    .LandingTheme .mt-md-n18, .LandingTheme .my-md-n18 {
        margin-top: -24rem !important;
    }
    .LandingTheme .mr-md-n18, .LandingTheme .mx-md-n18 {
        margin-right: -24rem !important;
    }
    .LandingTheme .mb-md-n18, .LandingTheme .my-md-n18 {
        margin-bottom: -24rem !important;
    }
    .LandingTheme .ml-md-n18, .LandingTheme .mx-md-n18 {
        margin-left: -24rem !important;
    }
    .LandingTheme .m-md-auto {
        margin: auto !important;
    }
    .LandingTheme .mt-md-auto, .LandingTheme .my-md-auto {
        margin-top: auto !important;
    }
    .LandingTheme .mr-md-auto, .LandingTheme .mx-md-auto {
        margin-right: auto !important;
    }
    .LandingTheme .mb-md-auto, .LandingTheme .my-md-auto {
        margin-bottom: auto !important;
    }
    .LandingTheme .ml-md-auto, .LandingTheme .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .m-lg-0 {
        margin: 0 !important;
    }
    .LandingTheme .mt-lg-0, .LandingTheme .my-lg-0 {
        margin-top: 0 !important;
    }
    .LandingTheme .mr-lg-0, .LandingTheme .mx-lg-0 {
        margin-right: 0 !important;
    }
    .LandingTheme .mb-lg-0, .LandingTheme .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .LandingTheme .ml-lg-0, .LandingTheme .mx-lg-0 {
        margin-left: 0 !important;
    }
    .LandingTheme .m-lg-1 {
        margin: .25rem !important;
    }
    .LandingTheme .mt-lg-1, .LandingTheme .my-lg-1 {
        margin-top: .25rem !important;
    }
    .LandingTheme .mr-lg-1, .LandingTheme .mx-lg-1 {
        margin-right: .25rem !important;
    }
    .LandingTheme .mb-lg-1, .LandingTheme .my-lg-1 {
        margin-bottom: .25rem !important;
    }
    .LandingTheme .ml-lg-1, .LandingTheme .mx-lg-1 {
        margin-left: .25rem !important;
    }
    .LandingTheme .m-lg-2 {
        margin: .5rem !important;
    }
    .LandingTheme .mt-lg-2, .LandingTheme .my-lg-2 {
        margin-top: .5rem !important;
    }
    .LandingTheme .mr-lg-2, .LandingTheme .mx-lg-2 {
        margin-right: .5rem !important;
    }
    .LandingTheme .mb-lg-2, .LandingTheme .my-lg-2 {
        margin-bottom: .5rem !important;
    }
    .LandingTheme .ml-lg-2, .LandingTheme .mx-lg-2 {
        margin-left: .5rem !important;
    }
    .LandingTheme .m-lg-3 {
        margin: .75rem !important;
    }
    .LandingTheme .mt-lg-3, .LandingTheme .my-lg-3 {
        margin-top: .75rem !important;
    }
    .LandingTheme .mr-lg-3, .LandingTheme .mx-lg-3 {
        margin-right: .75rem !important;
    }
    .LandingTheme .mb-lg-3, .LandingTheme .my-lg-3 {
        margin-bottom: .75rem !important;
    }
    .LandingTheme .ml-lg-3, .LandingTheme .mx-lg-3 {
        margin-left: .75rem !important;
    }
    .LandingTheme .m-lg-4 {
        margin: 1rem !important;
    }
    .LandingTheme .mt-lg-4, .LandingTheme .my-lg-4 {
        margin-top: 1rem !important;
    }
    .LandingTheme .mr-lg-4, .LandingTheme .mx-lg-4 {
        margin-right: 1rem !important;
    }
    .LandingTheme .mb-lg-4, .LandingTheme .my-lg-4 {
        margin-bottom: 1rem !important;
    }
    .LandingTheme .ml-lg-4, .LandingTheme .mx-lg-4 {
        margin-left: 1rem !important;
    }
    .LandingTheme .m-lg-5 {
        margin: 1.5rem !important;
    }
    .LandingTheme .mt-lg-5, .LandingTheme .my-lg-5 {
        margin-top: 1.5rem !important;
    }
    .LandingTheme .mr-lg-5, .LandingTheme .mx-lg-5 {
        margin-right: 1.5rem !important;
    }
    .LandingTheme .mb-lg-5, .LandingTheme .my-lg-5 {
        margin-bottom: 1.5rem !important;
    }
    .LandingTheme .ml-lg-5, .LandingTheme .mx-lg-5 {
        margin-left: 1.5rem !important;
    }
    .LandingTheme .m-lg-6 {
        margin: 2rem !important;
    }
    .LandingTheme .mt-lg-6, .LandingTheme .my-lg-6 {
        margin-top: 2rem !important;
    }
    .LandingTheme .mr-lg-6, .LandingTheme .mx-lg-6 {
        margin-right: 2rem !important;
    }
    .LandingTheme .mb-lg-6, .LandingTheme .my-lg-6 {
        margin-bottom: 2rem !important;
    }
    .LandingTheme .ml-lg-6, .LandingTheme .mx-lg-6 {
        margin-left: 2rem !important;
    }
    .LandingTheme .m-lg-7 {
        margin: 2.5rem !important;
    }
    .LandingTheme .mt-lg-7, .LandingTheme .my-lg-7 {
        margin-top: 2.5rem !important;
    }
    .LandingTheme .mr-lg-7, .LandingTheme .mx-lg-7 {
        margin-right: 2.5rem !important;
    }
    .LandingTheme .mb-lg-7, .LandingTheme .my-lg-7 {
        margin-bottom: 2.5rem !important;
    }
    .LandingTheme .ml-lg-7, .LandingTheme .mx-lg-7 {
        margin-left: 2.5rem !important;
    }
    .LandingTheme .m-lg-8 {
        margin: 3rem !important;
    }
    .LandingTheme .mt-lg-8, .LandingTheme .my-lg-8 {
        margin-top: 3rem !important;
    }
    .LandingTheme .mr-lg-8, .LandingTheme .mx-lg-8 {
        margin-right: 3rem !important;
    }
    .LandingTheme .mb-lg-8, .LandingTheme .my-lg-8 {
        margin-bottom: 3rem !important;
    }
    .LandingTheme .ml-lg-8, .LandingTheme .mx-lg-8 {
        margin-left: 3rem !important;
    }
    .LandingTheme .m-lg-9 {
        margin: 4rem !important;
    }
    .LandingTheme .mt-lg-9, .LandingTheme .my-lg-9 {
        margin-top: 4rem !important;
    }
    .LandingTheme .mr-lg-9, .LandingTheme .mx-lg-9 {
        margin-right: 4rem !important;
    }
    .LandingTheme .mb-lg-9, .LandingTheme .my-lg-9 {
        margin-bottom: 4rem !important;
    }
    .LandingTheme .ml-lg-9, .LandingTheme .mx-lg-9 {
        margin-left: 4rem !important;
    }
    .LandingTheme .m-lg-10 {
        margin: 5rem !important;
    }
    .LandingTheme .mt-lg-10, .LandingTheme .my-lg-10 {
        margin-top: 5rem !important;
    }
    .LandingTheme .mr-lg-10, .LandingTheme .mx-lg-10 {
        margin-right: 5rem !important;
    }
    .LandingTheme .mb-lg-10, .LandingTheme .my-lg-10 {
        margin-bottom: 5rem !important;
    }
    .LandingTheme .ml-lg-10, .LandingTheme .mx-lg-10 {
        margin-left: 5rem !important;
    }
    .LandingTheme .m-lg-11 {
        margin: 6rem !important;
    }
    .LandingTheme .mt-lg-11, .LandingTheme .my-lg-11 {
        margin-top: 6rem !important;
    }
    .LandingTheme .mr-lg-11, .LandingTheme .mx-lg-11 {
        margin-right: 6rem !important;
    }
    .LandingTheme .mb-lg-11, .LandingTheme .my-lg-11 {
        margin-bottom: 6rem !important;
    }
    .LandingTheme .ml-lg-11, .LandingTheme .mx-lg-11 {
        margin-left: 6rem !important;
    }
    .LandingTheme .m-lg-12 {
        margin: 8rem !important;
    }
    .LandingTheme .mt-lg-12, .LandingTheme .my-lg-12 {
        margin-top: 8rem !important;
    }
    .LandingTheme .mr-lg-12, .LandingTheme .mx-lg-12 {
        margin-right: 8rem !important;
    }
    .LandingTheme .mb-lg-12, .LandingTheme .my-lg-12 {
        margin-bottom: 8rem !important;
    }
    .LandingTheme .ml-lg-12, .LandingTheme .mx-lg-12 {
        margin-left: 8rem !important;
    }
    .LandingTheme .m-lg-13 {
        margin: 10rem !important;
    }
    .LandingTheme .mt-lg-13, .LandingTheme .my-lg-13 {
        margin-top: 10rem !important;
    }
    .LandingTheme .mr-lg-13, .LandingTheme .mx-lg-13 {
        margin-right: 10rem !important;
    }
    .LandingTheme .mb-lg-13, .LandingTheme .my-lg-13 {
        margin-bottom: 10rem !important;
    }
    .LandingTheme .ml-lg-13, .LandingTheme .mx-lg-13 {
        margin-left: 10rem !important;
    }
    .LandingTheme .m-lg-14 {
        margin: 12rem !important;
    }
    .LandingTheme .mt-lg-14, .LandingTheme .my-lg-14 {
        margin-top: 12rem !important;
    }
    .LandingTheme .mr-lg-14, .LandingTheme .mx-lg-14 {
        margin-right: 12rem !important;
    }
    .LandingTheme .mb-lg-14, .LandingTheme .my-lg-14 {
        margin-bottom: 12rem !important;
    }
    .LandingTheme .ml-lg-14, .LandingTheme .mx-lg-14 {
        margin-left: 12rem !important;
    }
    .LandingTheme .m-lg-15 {
        margin: 14rem !important;
    }
    .LandingTheme .mt-lg-15, .LandingTheme .my-lg-15 {
        margin-top: 14rem !important;
    }
    .LandingTheme .mr-lg-15, .LandingTheme .mx-lg-15 {
        margin-right: 14rem !important;
    }
    .LandingTheme .mb-lg-15, .LandingTheme .my-lg-15 {
        margin-bottom: 14rem !important;
    }
    .LandingTheme .ml-lg-15, .LandingTheme .mx-lg-15 {
        margin-left: 14rem !important;
    }
    .LandingTheme .m-lg-16 {
        margin: 16rem !important;
    }
    .LandingTheme .mt-lg-16, .LandingTheme .my-lg-16 {
        margin-top: 16rem !important;
    }
    .LandingTheme .mr-lg-16, .LandingTheme .mx-lg-16 {
        margin-right: 16rem !important;
    }
    .LandingTheme .mb-lg-16, .LandingTheme .my-lg-16 {
        margin-bottom: 16rem !important;
    }
    .LandingTheme .ml-lg-16, .LandingTheme .mx-lg-16 {
        margin-left: 16rem !important;
    }
    .LandingTheme .m-lg-17 {
        margin: 20rem !important;
    }
    .LandingTheme .mt-lg-17, .LandingTheme .my-lg-17 {
        margin-top: 20rem !important;
    }
    .LandingTheme .mr-lg-17, .LandingTheme .mx-lg-17 {
        margin-right: 20rem !important;
    }
    .LandingTheme .mb-lg-17, .LandingTheme .my-lg-17 {
        margin-bottom: 20rem !important;
    }
    .LandingTheme .ml-lg-17, .LandingTheme .mx-lg-17 {
        margin-left: 20rem !important;
    }
    .LandingTheme .m-lg-18 {
        margin: 24rem !important;
    }
    .LandingTheme .mt-lg-18, .LandingTheme .my-lg-18 {
        margin-top: 24rem !important;
    }
    .LandingTheme .mr-lg-18, .LandingTheme .mx-lg-18 {
        margin-right: 24rem !important;
    }
    .LandingTheme .mb-lg-18, .LandingTheme .my-lg-18 {
        margin-bottom: 24rem !important;
    }
    .LandingTheme .ml-lg-18, .LandingTheme .mx-lg-18 {
        margin-left: 24rem !important;
    }
    .LandingTheme .p-lg-0 {
        padding: 0 !important;
    }
    .LandingTheme .pt-lg-0, .LandingTheme .py-lg-0 {
        padding-top: 0 !important;
    }
    .LandingTheme .pr-lg-0, .LandingTheme .px-lg-0 {
        padding-right: 0 !important;
    }
    .LandingTheme .pb-lg-0, .LandingTheme .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .LandingTheme .pl-lg-0, .LandingTheme .px-lg-0 {
        padding-left: 0 !important;
    }
    .LandingTheme .p-lg-1 {
        padding: .25rem !important;
    }
    .LandingTheme .pt-lg-1, .LandingTheme .py-lg-1 {
        padding-top: .25rem !important;
    }
    .LandingTheme .pr-lg-1, .LandingTheme .px-lg-1 {
        padding-right: .25rem !important;
    }
    .LandingTheme .pb-lg-1, .LandingTheme .py-lg-1 {
        padding-bottom: .25rem !important;
    }
    .LandingTheme .pl-lg-1, .LandingTheme .px-lg-1 {
        padding-left: .25rem !important;
    }
    .LandingTheme .p-lg-2 {
        padding: .5rem !important;
    }
    .LandingTheme .pt-lg-2, .LandingTheme .py-lg-2 {
        padding-top: .5rem !important;
    }
    .LandingTheme .pr-lg-2, .LandingTheme .px-lg-2 {
        padding-right: .5rem !important;
    }
    .LandingTheme .pb-lg-2, .LandingTheme .py-lg-2 {
        padding-bottom: .5rem !important;
    }
    .LandingTheme .pl-lg-2, .LandingTheme .px-lg-2 {
        padding-left: .5rem !important;
    }
    .LandingTheme .p-lg-3 {
        padding: .75rem !important;
    }
    .LandingTheme .pt-lg-3, .LandingTheme .py-lg-3 {
        padding-top: .75rem !important;
    }
    .LandingTheme .pr-lg-3, .LandingTheme .px-lg-3 {
        padding-right: .75rem !important;
    }
    .LandingTheme .pb-lg-3, .LandingTheme .py-lg-3 {
        padding-bottom: .75rem !important;
    }
    .LandingTheme .pl-lg-3, .LandingTheme .px-lg-3 {
        padding-left: .75rem !important;
    }
    .LandingTheme .p-lg-4 {
        padding: 1rem !important;
    }
    .LandingTheme .pt-lg-4, .LandingTheme .py-lg-4 {
        padding-top: 1rem !important;
    }
    .LandingTheme .pr-lg-4, .LandingTheme .px-lg-4 {
        padding-right: 1rem !important;
    }
    .LandingTheme .pb-lg-4, .LandingTheme .py-lg-4 {
        padding-bottom: 1rem !important;
    }
    .LandingTheme .pl-lg-4, .LandingTheme .px-lg-4 {
        padding-left: 1rem !important;
    }
    .LandingTheme .p-lg-5 {
        padding: 1.5rem !important;
    }
    .LandingTheme .pt-lg-5, .LandingTheme .py-lg-5 {
        padding-top: 1.5rem !important;
    }
    .LandingTheme .pr-lg-5, .LandingTheme .px-lg-5 {
        padding-right: 1.5rem !important;
    }
    .LandingTheme .pb-lg-5, .LandingTheme .py-lg-5 {
        padding-bottom: 1.5rem !important;
    }
    .LandingTheme .pl-lg-5, .LandingTheme .px-lg-5 {
        padding-left: 1.5rem !important;
    }
    .LandingTheme .p-lg-6 {
        padding: 2rem !important;
    }
    .LandingTheme .pt-lg-6, .LandingTheme .py-lg-6 {
        padding-top: 2rem !important;
    }
    .LandingTheme .pr-lg-6, .LandingTheme .px-lg-6 {
        padding-right: 2rem !important;
    }
    .LandingTheme .pb-lg-6, .LandingTheme .py-lg-6 {
        padding-bottom: 2rem !important;
    }
    .LandingTheme .pl-lg-6, .LandingTheme .px-lg-6 {
        padding-left: 2rem !important;
    }
    .LandingTheme .p-lg-7 {
        padding: 2.5rem !important;
    }
    .LandingTheme .pt-lg-7, .LandingTheme .py-lg-7 {
        padding-top: 2.5rem !important;
    }
    .LandingTheme .pr-lg-7, .LandingTheme .px-lg-7 {
        padding-right: 2.5rem !important;
    }
    .LandingTheme .pb-lg-7, .LandingTheme .py-lg-7 {
        padding-bottom: 2.5rem !important;
    }
    .LandingTheme .pl-lg-7, .LandingTheme .px-lg-7 {
        padding-left: 2.5rem !important;
    }
    .LandingTheme .p-lg-8 {
        padding: 3rem !important;
    }
    .LandingTheme .pt-lg-8, .LandingTheme .py-lg-8 {
        padding-top: 3rem !important;
    }
    .LandingTheme .pr-lg-8, .LandingTheme .px-lg-8 {
        padding-right: 3rem !important;
    }
    .LandingTheme .pb-lg-8, .LandingTheme .py-lg-8 {
        padding-bottom: 3rem !important;
    }
    .LandingTheme .pl-lg-8, .LandingTheme .px-lg-8 {
        padding-left: 3rem !important;
    }
    .LandingTheme .p-lg-9 {
        padding: 4rem !important;
    }
    .LandingTheme .pt-lg-9, .LandingTheme .py-lg-9 {
        padding-top: 4rem !important;
    }
    .LandingTheme .pr-lg-9, .LandingTheme .px-lg-9 {
        padding-right: 4rem !important;
    }
    .LandingTheme .pb-lg-9, .LandingTheme .py-lg-9 {
        padding-bottom: 4rem !important;
    }
    .LandingTheme .pl-lg-9, .LandingTheme .px-lg-9 {
        padding-left: 4rem !important;
    }
    .LandingTheme .p-lg-10 {
        padding: 5rem !important;
    }
    .LandingTheme .pt-lg-10, .LandingTheme .py-lg-10 {
        padding-top: 5rem !important;
    }
    .LandingTheme .pr-lg-10, .LandingTheme .px-lg-10 {
        padding-right: 5rem !important;
    }
    .LandingTheme .pb-lg-10, .LandingTheme .py-lg-10 {
        padding-bottom: 5rem !important;
    }
    .LandingTheme .pl-lg-10, .LandingTheme .px-lg-10 {
        padding-left: 5rem !important;
    }
    .LandingTheme .p-lg-11 {
        padding: 6rem !important;
    }
    .LandingTheme .pt-lg-11, .LandingTheme .py-lg-11 {
        padding-top: 6rem !important;
    }
    .LandingTheme .pr-lg-11, .LandingTheme .px-lg-11 {
        padding-right: 6rem !important;
    }
    .LandingTheme .pb-lg-11, .LandingTheme .py-lg-11 {
        padding-bottom: 6rem !important;
    }
    .LandingTheme .pl-lg-11, .LandingTheme .px-lg-11 {
        padding-left: 6rem !important;
    }
    .LandingTheme .p-lg-12 {
        padding: 8rem !important;
    }
    .LandingTheme .pt-lg-12, .LandingTheme .py-lg-12 {
        padding-top: 8rem !important;
    }
    .LandingTheme .pr-lg-12, .LandingTheme .px-lg-12 {
        padding-right: 8rem !important;
    }
    .LandingTheme .pb-lg-12, .LandingTheme .py-lg-12 {
        padding-bottom: 8rem !important;
    }
    .LandingTheme .pl-lg-12, .LandingTheme .px-lg-12 {
        padding-left: 8rem !important;
    }
    .LandingTheme .p-lg-13 {
        padding: 10rem !important;
    }
    .LandingTheme .pt-lg-13, .LandingTheme .py-lg-13 {
        padding-top: 10rem !important;
    }
    .LandingTheme .pr-lg-13, .LandingTheme .px-lg-13 {
        padding-right: 10rem !important;
    }
    .LandingTheme .pb-lg-13, .LandingTheme .py-lg-13 {
        padding-bottom: 10rem !important;
    }
    .LandingTheme .pl-lg-13, .LandingTheme .px-lg-13 {
        padding-left: 10rem !important;
    }
    .LandingTheme .p-lg-14 {
        padding: 12rem !important;
    }
    .LandingTheme .pt-lg-14, .LandingTheme .py-lg-14 {
        padding-top: 12rem !important;
    }
    .LandingTheme .pr-lg-14, .LandingTheme .px-lg-14 {
        padding-right: 12rem !important;
    }
    .LandingTheme .pb-lg-14, .LandingTheme .py-lg-14 {
        padding-bottom: 12rem !important;
    }
    .LandingTheme .pl-lg-14, .LandingTheme .px-lg-14 {
        padding-left: 12rem !important;
    }
    .LandingTheme .p-lg-15 {
        padding: 14rem !important;
    }
    .LandingTheme .pt-lg-15, .LandingTheme .py-lg-15 {
        padding-top: 14rem !important;
    }
    .LandingTheme .pr-lg-15, .LandingTheme .px-lg-15 {
        padding-right: 14rem !important;
    }
    .LandingTheme .pb-lg-15, .LandingTheme .py-lg-15 {
        padding-bottom: 14rem !important;
    }
    .LandingTheme .pl-lg-15, .LandingTheme .px-lg-15 {
        padding-left: 14rem !important;
    }
    .LandingTheme .p-lg-16 {
        padding: 16rem !important;
    }
    .LandingTheme .pt-lg-16, .LandingTheme .py-lg-16 {
        padding-top: 16rem !important;
    }
    .LandingTheme .pr-lg-16, .LandingTheme .px-lg-16 {
        padding-right: 16rem !important;
    }
    .LandingTheme .pb-lg-16, .LandingTheme .py-lg-16 {
        padding-bottom: 16rem !important;
    }
    .LandingTheme .pl-lg-16, .LandingTheme .px-lg-16 {
        padding-left: 16rem !important;
    }
    .LandingTheme .p-lg-17 {
        padding: 20rem !important;
    }
    .LandingTheme .pt-lg-17, .LandingTheme .py-lg-17 {
        padding-top: 20rem !important;
    }
    .LandingTheme .pr-lg-17, .LandingTheme .px-lg-17 {
        padding-right: 20rem !important;
    }
    .LandingTheme .pb-lg-17, .LandingTheme .py-lg-17 {
        padding-bottom: 20rem !important;
    }
    .LandingTheme .pl-lg-17, .LandingTheme .px-lg-17 {
        padding-left: 20rem !important;
    }
    .LandingTheme .p-lg-18 {
        padding: 24rem !important;
    }
    .LandingTheme .pt-lg-18, .LandingTheme .py-lg-18 {
        padding-top: 24rem !important;
    }
    .LandingTheme .pr-lg-18, .LandingTheme .px-lg-18 {
        padding-right: 24rem !important;
    }
    .LandingTheme .pb-lg-18, .LandingTheme .py-lg-18 {
        padding-bottom: 24rem !important;
    }
    .LandingTheme .pl-lg-18, .LandingTheme .px-lg-18 {
        padding-left: 24rem !important;
    }
    .LandingTheme .m-lg-n1 {
        margin: -.25rem !important;
    }
    .LandingTheme .mt-lg-n1, .LandingTheme .my-lg-n1 {
        margin-top: -.25rem !important;
    }
    .LandingTheme .mr-lg-n1, .LandingTheme .mx-lg-n1 {
        margin-right: -.25rem !important;
    }
    .LandingTheme .mb-lg-n1, .LandingTheme .my-lg-n1 {
        margin-bottom: -.25rem !important;
    }
    .LandingTheme .ml-lg-n1, .LandingTheme .mx-lg-n1 {
        margin-left: -.25rem !important;
    }
    .LandingTheme .m-lg-n2 {
        margin: -.5rem !important;
    }
    .LandingTheme .mt-lg-n2, .LandingTheme .my-lg-n2 {
        margin-top: -.5rem !important;
    }
    .LandingTheme .mr-lg-n2, .LandingTheme .mx-lg-n2 {
        margin-right: -.5rem !important;
    }
    .LandingTheme .mb-lg-n2, .LandingTheme .my-lg-n2 {
        margin-bottom: -.5rem !important;
    }
    .LandingTheme .ml-lg-n2, .LandingTheme .mx-lg-n2 {
        margin-left: -.5rem !important;
    }
    .LandingTheme .m-lg-n3 {
        margin: -.75rem !important;
    }
    .LandingTheme .mt-lg-n3, .LandingTheme .my-lg-n3 {
        margin-top: -.75rem !important;
    }
    .LandingTheme .mr-lg-n3, .LandingTheme .mx-lg-n3 {
        margin-right: -.75rem !important;
    }
    .LandingTheme .mb-lg-n3, .LandingTheme .my-lg-n3 {
        margin-bottom: -.75rem !important;
    }
    .LandingTheme .ml-lg-n3, .LandingTheme .mx-lg-n3 {
        margin-left: -.75rem !important;
    }
    .LandingTheme .m-lg-n4 {
        margin: -1rem !important;
    }
    .LandingTheme .mt-lg-n4, .LandingTheme .my-lg-n4 {
        margin-top: -1rem !important;
    }
    .LandingTheme .mr-lg-n4, .LandingTheme .mx-lg-n4 {
        margin-right: -1rem !important;
    }
    .LandingTheme .mb-lg-n4, .LandingTheme .my-lg-n4 {
        margin-bottom: -1rem !important;
    }
    .LandingTheme .ml-lg-n4, .LandingTheme .mx-lg-n4 {
        margin-left: -1rem !important;
    }
    .LandingTheme .m-lg-n5 {
        margin: -1.5rem !important;
    }
    .LandingTheme .mt-lg-n5, .LandingTheme .my-lg-n5 {
        margin-top: -1.5rem !important;
    }
    .LandingTheme .mr-lg-n5, .LandingTheme .mx-lg-n5 {
        margin-right: -1.5rem !important;
    }
    .LandingTheme .mb-lg-n5, .LandingTheme .my-lg-n5 {
        margin-bottom: -1.5rem !important;
    }
    .LandingTheme .ml-lg-n5, .LandingTheme .mx-lg-n5 {
        margin-left: -1.5rem !important;
    }
    .LandingTheme .m-lg-n6 {
        margin: -2rem !important;
    }
    .LandingTheme .mt-lg-n6, .LandingTheme .my-lg-n6 {
        margin-top: -2rem !important;
    }
    .LandingTheme .mr-lg-n6, .LandingTheme .mx-lg-n6 {
        margin-right: -2rem !important;
    }
    .LandingTheme .mb-lg-n6, .LandingTheme .my-lg-n6 {
        margin-bottom: -2rem !important;
    }
    .LandingTheme .ml-lg-n6, .LandingTheme .mx-lg-n6 {
        margin-left: -2rem !important;
    }
    .LandingTheme .m-lg-n7 {
        margin: -2.5rem !important;
    }
    .LandingTheme .mt-lg-n7, .LandingTheme .my-lg-n7 {
        margin-top: -2.5rem !important;
    }
    .LandingTheme .mr-lg-n7, .LandingTheme .mx-lg-n7 {
        margin-right: -2.5rem !important;
    }
    .LandingTheme .mb-lg-n7, .LandingTheme .my-lg-n7 {
        margin-bottom: -2.5rem !important;
    }
    .LandingTheme .ml-lg-n7, .LandingTheme .mx-lg-n7 {
        margin-left: -2.5rem !important;
    }
    .LandingTheme .m-lg-n8 {
        margin: -3rem !important;
    }
    .LandingTheme .mt-lg-n8, .LandingTheme .my-lg-n8 {
        margin-top: -3rem !important;
    }
    .LandingTheme .mr-lg-n8, .LandingTheme .mx-lg-n8 {
        margin-right: -3rem !important;
    }
    .LandingTheme .mb-lg-n8, .LandingTheme .my-lg-n8 {
        margin-bottom: -3rem !important;
    }
    .LandingTheme .ml-lg-n8, .LandingTheme .mx-lg-n8 {
        margin-left: -3rem !important;
    }
    .LandingTheme .m-lg-n9 {
        margin: -4rem !important;
    }
    .LandingTheme .mt-lg-n9, .LandingTheme .my-lg-n9 {
        margin-top: -4rem !important;
    }
    .LandingTheme .mr-lg-n9, .LandingTheme .mx-lg-n9 {
        margin-right: -4rem !important;
    }
    .LandingTheme .mb-lg-n9, .LandingTheme .my-lg-n9 {
        margin-bottom: -4rem !important;
    }
    .LandingTheme .ml-lg-n9, .LandingTheme .mx-lg-n9 {
        margin-left: -4rem !important;
    }
    .LandingTheme .m-lg-n10 {
        margin: -5rem !important;
    }
    .LandingTheme .mt-lg-n10, .LandingTheme .my-lg-n10 {
        margin-top: -5rem !important;
    }
    .LandingTheme .mr-lg-n10, .LandingTheme .mx-lg-n10 {
        margin-right: -5rem !important;
    }
    .LandingTheme .mb-lg-n10, .LandingTheme .my-lg-n10 {
        margin-bottom: -5rem !important;
    }
    .LandingTheme .ml-lg-n10, .LandingTheme .mx-lg-n10 {
        margin-left: -5rem !important;
    }
    .LandingTheme .m-lg-n11 {
        margin: -6rem !important;
    }
    .LandingTheme .mt-lg-n11, .LandingTheme .my-lg-n11 {
        margin-top: -6rem !important;
    }
    .LandingTheme .mr-lg-n11, .LandingTheme .mx-lg-n11 {
        margin-right: -6rem !important;
    }
    .LandingTheme .mb-lg-n11, .LandingTheme .my-lg-n11 {
        margin-bottom: -6rem !important;
    }
    .LandingTheme .ml-lg-n11, .LandingTheme .mx-lg-n11 {
        margin-left: -6rem !important;
    }
    .LandingTheme .m-lg-n12 {
        margin: -8rem !important;
    }
    .LandingTheme .mt-lg-n12, .LandingTheme .my-lg-n12 {
        margin-top: -8rem !important;
    }
    .LandingTheme .mr-lg-n12, .LandingTheme .mx-lg-n12 {
        margin-right: -8rem !important;
    }
    .LandingTheme .mb-lg-n12, .LandingTheme .my-lg-n12 {
        margin-bottom: -8rem !important;
    }
    .LandingTheme .ml-lg-n12, .LandingTheme .mx-lg-n12 {
        margin-left: -8rem !important;
    }
    .LandingTheme .m-lg-n13 {
        margin: -10rem !important;
    }
    .LandingTheme .mt-lg-n13, .LandingTheme .my-lg-n13 {
        margin-top: -10rem !important;
    }
    .LandingTheme .mr-lg-n13, .LandingTheme .mx-lg-n13 {
        margin-right: -10rem !important;
    }
    .LandingTheme .mb-lg-n13, .LandingTheme .my-lg-n13 {
        margin-bottom: -10rem !important;
    }
    .LandingTheme .ml-lg-n13, .LandingTheme .mx-lg-n13 {
        margin-left: -10rem !important;
    }
    .LandingTheme .m-lg-n14 {
        margin: -12rem !important;
    }
    .LandingTheme .mt-lg-n14, .LandingTheme .my-lg-n14 {
        margin-top: -12rem !important;
    }
    .LandingTheme .mr-lg-n14, .LandingTheme .mx-lg-n14 {
        margin-right: -12rem !important;
    }
    .LandingTheme .mb-lg-n14, .LandingTheme .my-lg-n14 {
        margin-bottom: -12rem !important;
    }
    .LandingTheme .ml-lg-n14, .LandingTheme .mx-lg-n14 {
        margin-left: -12rem !important;
    }
    .LandingTheme .m-lg-n15 {
        margin: -14rem !important;
    }
    .LandingTheme .mt-lg-n15, .LandingTheme .my-lg-n15 {
        margin-top: -14rem !important;
    }
    .LandingTheme .mr-lg-n15, .LandingTheme .mx-lg-n15 {
        margin-right: -14rem !important;
    }
    .LandingTheme .mb-lg-n15, .LandingTheme .my-lg-n15 {
        margin-bottom: -14rem !important;
    }
    .LandingTheme .ml-lg-n15, .LandingTheme .mx-lg-n15 {
        margin-left: -14rem !important;
    }
    .LandingTheme .m-lg-n16 {
        margin: -16rem !important;
    }
    .LandingTheme .mt-lg-n16, .LandingTheme .my-lg-n16 {
        margin-top: -16rem !important;
    }
    .LandingTheme .mr-lg-n16, .LandingTheme .mx-lg-n16 {
        margin-right: -16rem !important;
    }
    .LandingTheme .mb-lg-n16, .LandingTheme .my-lg-n16 {
        margin-bottom: -16rem !important;
    }
    .LandingTheme .ml-lg-n16, .LandingTheme .mx-lg-n16 {
        margin-left: -16rem !important;
    }
    .LandingTheme .m-lg-n17 {
        margin: -20rem !important;
    }
    .LandingTheme .mt-lg-n17, .LandingTheme .my-lg-n17 {
        margin-top: -20rem !important;
    }
    .LandingTheme .mr-lg-n17, .LandingTheme .mx-lg-n17 {
        margin-right: -20rem !important;
    }
    .LandingTheme .mb-lg-n17, .LandingTheme .my-lg-n17 {
        margin-bottom: -20rem !important;
    }
    .LandingTheme .ml-lg-n17, .LandingTheme .mx-lg-n17 {
        margin-left: -20rem !important;
    }
    .LandingTheme .m-lg-n18 {
        margin: -24rem !important;
    }
    .LandingTheme .mt-lg-n18, .LandingTheme .my-lg-n18 {
        margin-top: -24rem !important;
    }
    .LandingTheme .mr-lg-n18, .LandingTheme .mx-lg-n18 {
        margin-right: -24rem !important;
    }
    .LandingTheme .mb-lg-n18, .LandingTheme .my-lg-n18 {
        margin-bottom: -24rem !important;
    }
    .LandingTheme .ml-lg-n18, .LandingTheme .mx-lg-n18 {
        margin-left: -24rem !important;
    }
    .LandingTheme .m-lg-auto {
        margin: auto !important;
    }
    .LandingTheme .mt-lg-auto, .LandingTheme .my-lg-auto {
        margin-top: auto !important;
    }
    .LandingTheme .mr-lg-auto, .LandingTheme .mx-lg-auto {
        margin-right: auto !important;
    }
    .LandingTheme .mb-lg-auto, .LandingTheme .my-lg-auto {
        margin-bottom: auto !important;
    }
    .LandingTheme .ml-lg-auto, .LandingTheme .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .m-xl-0 {
        margin: 0 !important;
    }
    .LandingTheme .mt-xl-0, .LandingTheme .my-xl-0 {
        margin-top: 0 !important;
    }
    .LandingTheme .mr-xl-0, .LandingTheme .mx-xl-0 {
        margin-right: 0 !important;
    }
    .LandingTheme .mb-xl-0, .LandingTheme .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .LandingTheme .ml-xl-0, .LandingTheme .mx-xl-0 {
        margin-left: 0 !important;
    }
    .LandingTheme .m-xl-1 {
        margin: .25rem !important;
    }
    .LandingTheme .mt-xl-1, .LandingTheme .my-xl-1 {
        margin-top: .25rem !important;
    }
    .LandingTheme .mr-xl-1, .LandingTheme .mx-xl-1 {
        margin-right: .25rem !important;
    }
    .LandingTheme .mb-xl-1, .LandingTheme .my-xl-1 {
        margin-bottom: .25rem !important;
    }
    .LandingTheme .ml-xl-1, .LandingTheme .mx-xl-1 {
        margin-left: .25rem !important;
    }
    .LandingTheme .m-xl-2 {
        margin: .5rem !important;
    }
    .LandingTheme .mt-xl-2, .LandingTheme .my-xl-2 {
        margin-top: .5rem !important;
    }
    .LandingTheme .mr-xl-2, .LandingTheme .mx-xl-2 {
        margin-right: .5rem !important;
    }
    .LandingTheme .mb-xl-2, .LandingTheme .my-xl-2 {
        margin-bottom: .5rem !important;
    }
    .LandingTheme .ml-xl-2, .LandingTheme .mx-xl-2 {
        margin-left: .5rem !important;
    }
    .LandingTheme .m-xl-3 {
        margin: .75rem !important;
    }
    .LandingTheme .mt-xl-3, .LandingTheme .my-xl-3 {
        margin-top: .75rem !important;
    }
    .LandingTheme .mr-xl-3, .LandingTheme .mx-xl-3 {
        margin-right: .75rem !important;
    }
    .LandingTheme .mb-xl-3, .LandingTheme .my-xl-3 {
        margin-bottom: .75rem !important;
    }
    .LandingTheme .ml-xl-3, .LandingTheme .mx-xl-3 {
        margin-left: .75rem !important;
    }
    .LandingTheme .m-xl-4 {
        margin: 1rem !important;
    }
    .LandingTheme .mt-xl-4, .LandingTheme .my-xl-4 {
        margin-top: 1rem !important;
    }
    .LandingTheme .mr-xl-4, .LandingTheme .mx-xl-4 {
        margin-right: 1rem !important;
    }
    .LandingTheme .mb-xl-4, .LandingTheme .my-xl-4 {
        margin-bottom: 1rem !important;
    }
    .LandingTheme .ml-xl-4, .LandingTheme .mx-xl-4 {
        margin-left: 1rem !important;
    }
    .LandingTheme .m-xl-5 {
        margin: 1.5rem !important;
    }
    .LandingTheme .mt-xl-5, .LandingTheme .my-xl-5 {
        margin-top: 1.5rem !important;
    }
    .LandingTheme .mr-xl-5, .LandingTheme .mx-xl-5 {
        margin-right: 1.5rem !important;
    }
    .LandingTheme .mb-xl-5, .LandingTheme .my-xl-5 {
        margin-bottom: 1.5rem !important;
    }
    .LandingTheme .ml-xl-5, .LandingTheme .mx-xl-5 {
        margin-left: 1.5rem !important;
    }
    .LandingTheme .m-xl-6 {
        margin: 2rem !important;
    }
    .LandingTheme .mt-xl-6, .LandingTheme .my-xl-6 {
        margin-top: 2rem !important;
    }
    .LandingTheme .mr-xl-6, .LandingTheme .mx-xl-6 {
        margin-right: 2rem !important;
    }
    .LandingTheme .mb-xl-6, .LandingTheme .my-xl-6 {
        margin-bottom: 2rem !important;
    }
    .LandingTheme .ml-xl-6, .LandingTheme .mx-xl-6 {
        margin-left: 2rem !important;
    }
    .LandingTheme .m-xl-7 {
        margin: 2.5rem !important;
    }
    .LandingTheme .mt-xl-7, .LandingTheme .my-xl-7 {
        margin-top: 2.5rem !important;
    }
    .LandingTheme .mr-xl-7, .LandingTheme .mx-xl-7 {
        margin-right: 2.5rem !important;
    }
    .LandingTheme .mb-xl-7, .LandingTheme .my-xl-7 {
        margin-bottom: 2.5rem !important;
    }
    .LandingTheme .ml-xl-7, .LandingTheme .mx-xl-7 {
        margin-left: 2.5rem !important;
    }
    .LandingTheme .m-xl-8 {
        margin: 3rem !important;
    }
    .LandingTheme .mt-xl-8, .LandingTheme .my-xl-8 {
        margin-top: 3rem !important;
    }
    .LandingTheme .mr-xl-8, .LandingTheme .mx-xl-8 {
        margin-right: 3rem !important;
    }
    .LandingTheme .mb-xl-8, .LandingTheme .my-xl-8 {
        margin-bottom: 3rem !important;
    }
    .LandingTheme .ml-xl-8, .LandingTheme .mx-xl-8 {
        margin-left: 3rem !important;
    }
    .LandingTheme .m-xl-9 {
        margin: 4rem !important;
    }
    .LandingTheme .mt-xl-9, .LandingTheme .my-xl-9 {
        margin-top: 4rem !important;
    }
    .LandingTheme .mr-xl-9, .LandingTheme .mx-xl-9 {
        margin-right: 4rem !important;
    }
    .LandingTheme .mb-xl-9, .LandingTheme .my-xl-9 {
        margin-bottom: 4rem !important;
    }
    .LandingTheme .ml-xl-9, .LandingTheme .mx-xl-9 {
        margin-left: 4rem !important;
    }
    .LandingTheme .m-xl-10 {
        margin: 5rem !important;
    }
    .LandingTheme .mt-xl-10, .LandingTheme .my-xl-10 {
        margin-top: 5rem !important;
    }
    .LandingTheme .mr-xl-10, .LandingTheme .mx-xl-10 {
        margin-right: 5rem !important;
    }
    .LandingTheme .mb-xl-10, .LandingTheme .my-xl-10 {
        margin-bottom: 5rem !important;
    }
    .LandingTheme .ml-xl-10, .LandingTheme .mx-xl-10 {
        margin-left: 5rem !important;
    }
    .LandingTheme .m-xl-11 {
        margin: 6rem !important;
    }
    .LandingTheme .mt-xl-11, .LandingTheme .my-xl-11 {
        margin-top: 6rem !important;
    }
    .LandingTheme .mr-xl-11, .LandingTheme .mx-xl-11 {
        margin-right: 6rem !important;
    }
    .LandingTheme .mb-xl-11, .LandingTheme .my-xl-11 {
        margin-bottom: 6rem !important;
    }
    .LandingTheme .ml-xl-11, .LandingTheme .mx-xl-11 {
        margin-left: 6rem !important;
    }
    .LandingTheme .m-xl-12 {
        margin: 8rem !important;
    }
    .LandingTheme .mt-xl-12, .LandingTheme .my-xl-12 {
        margin-top: 8rem !important;
    }
    .LandingTheme .mr-xl-12, .LandingTheme .mx-xl-12 {
        margin-right: 8rem !important;
    }
    .LandingTheme .mb-xl-12, .LandingTheme .my-xl-12 {
        margin-bottom: 8rem !important;
    }
    .LandingTheme .ml-xl-12, .LandingTheme .mx-xl-12 {
        margin-left: 8rem !important;
    }
    .LandingTheme .m-xl-13 {
        margin: 10rem !important;
    }
    .LandingTheme .mt-xl-13, .LandingTheme .my-xl-13 {
        margin-top: 10rem !important;
    }
    .LandingTheme .mr-xl-13, .LandingTheme .mx-xl-13 {
        margin-right: 10rem !important;
    }
    .LandingTheme .mb-xl-13, .LandingTheme .my-xl-13 {
        margin-bottom: 10rem !important;
    }
    .LandingTheme .ml-xl-13, .LandingTheme .mx-xl-13 {
        margin-left: 10rem !important;
    }
    .LandingTheme .m-xl-14 {
        margin: 12rem !important;
    }
    .LandingTheme .mt-xl-14, .LandingTheme .my-xl-14 {
        margin-top: 12rem !important;
    }
    .LandingTheme .mr-xl-14, .LandingTheme .mx-xl-14 {
        margin-right: 12rem !important;
    }
    .LandingTheme .mb-xl-14, .LandingTheme .my-xl-14 {
        margin-bottom: 12rem !important;
    }
    .LandingTheme .ml-xl-14, .LandingTheme .mx-xl-14 {
        margin-left: 12rem !important;
    }
    .LandingTheme .m-xl-15 {
        margin: 14rem !important;
    }
    .LandingTheme .mt-xl-15, .LandingTheme .my-xl-15 {
        margin-top: 14rem !important;
    }
    .LandingTheme .mr-xl-15, .LandingTheme .mx-xl-15 {
        margin-right: 14rem !important;
    }
    .LandingTheme .mb-xl-15, .LandingTheme .my-xl-15 {
        margin-bottom: 14rem !important;
    }
    .LandingTheme .ml-xl-15, .LandingTheme .mx-xl-15 {
        margin-left: 14rem !important;
    }
    .LandingTheme .m-xl-16 {
        margin: 16rem !important;
    }
    .LandingTheme .mt-xl-16, .LandingTheme .my-xl-16 {
        margin-top: 16rem !important;
    }
    .LandingTheme .mr-xl-16, .LandingTheme .mx-xl-16 {
        margin-right: 16rem !important;
    }
    .LandingTheme .mb-xl-16, .LandingTheme .my-xl-16 {
        margin-bottom: 16rem !important;
    }
    .LandingTheme .ml-xl-16, .LandingTheme .mx-xl-16 {
        margin-left: 16rem !important;
    }
    .LandingTheme .m-xl-17 {
        margin: 20rem !important;
    }
    .LandingTheme .mt-xl-17, .LandingTheme .my-xl-17 {
        margin-top: 20rem !important;
    }
    .LandingTheme .mr-xl-17, .LandingTheme .mx-xl-17 {
        margin-right: 20rem !important;
    }
    .LandingTheme .mb-xl-17, .LandingTheme .my-xl-17 {
        margin-bottom: 20rem !important;
    }
    .LandingTheme .ml-xl-17, .LandingTheme .mx-xl-17 {
        margin-left: 20rem !important;
    }
    .LandingTheme .m-xl-18 {
        margin: 24rem !important;
    }
    .LandingTheme .mt-xl-18, .LandingTheme .my-xl-18 {
        margin-top: 24rem !important;
    }
    .LandingTheme .mr-xl-18, .LandingTheme .mx-xl-18 {
        margin-right: 24rem !important;
    }
    .LandingTheme .mb-xl-18, .LandingTheme .my-xl-18 {
        margin-bottom: 24rem !important;
    }
    .LandingTheme .ml-xl-18, .LandingTheme .mx-xl-18 {
        margin-left: 24rem !important;
    }
    .LandingTheme .p-xl-0 {
        padding: 0 !important;
    }
    .LandingTheme .pt-xl-0, .LandingTheme .py-xl-0 {
        padding-top: 0 !important;
    }
    .LandingTheme .pr-xl-0, .LandingTheme .px-xl-0 {
        padding-right: 0 !important;
    }
    .LandingTheme .pb-xl-0, .LandingTheme .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .LandingTheme .pl-xl-0, .LandingTheme .px-xl-0 {
        padding-left: 0 !important;
    }
    .LandingTheme .p-xl-1 {
        padding: .25rem !important;
    }
    .LandingTheme .pt-xl-1, .LandingTheme .py-xl-1 {
        padding-top: .25rem !important;
    }
    .LandingTheme .pr-xl-1, .LandingTheme .px-xl-1 {
        padding-right: .25rem !important;
    }
    .LandingTheme .pb-xl-1, .LandingTheme .py-xl-1 {
        padding-bottom: .25rem !important;
    }
    .LandingTheme .pl-xl-1, .LandingTheme .px-xl-1 {
        padding-left: .25rem !important;
    }
    .LandingTheme .p-xl-2 {
        padding: .5rem !important;
    }
    .LandingTheme .pt-xl-2, .LandingTheme .py-xl-2 {
        padding-top: .5rem !important;
    }
    .LandingTheme .pr-xl-2, .LandingTheme .px-xl-2 {
        padding-right: .5rem !important;
    }
    .LandingTheme .pb-xl-2, .LandingTheme .py-xl-2 {
        padding-bottom: .5rem !important;
    }
    .LandingTheme .pl-xl-2, .LandingTheme .px-xl-2 {
        padding-left: .5rem !important;
    }
    .LandingTheme .p-xl-3 {
        padding: .75rem !important;
    }
    .LandingTheme .pt-xl-3, .LandingTheme .py-xl-3 {
        padding-top: .75rem !important;
    }
    .LandingTheme .pr-xl-3, .LandingTheme .px-xl-3 {
        padding-right: .75rem !important;
    }
    .LandingTheme .pb-xl-3, .LandingTheme .py-xl-3 {
        padding-bottom: .75rem !important;
    }
    .LandingTheme .pl-xl-3, .LandingTheme .px-xl-3 {
        padding-left: .75rem !important;
    }
    .LandingTheme .p-xl-4 {
        padding: 1rem !important;
    }
    .LandingTheme .pt-xl-4, .LandingTheme .py-xl-4 {
        padding-top: 1rem !important;
    }
    .LandingTheme .pr-xl-4, .LandingTheme .px-xl-4 {
        padding-right: 1rem !important;
    }
    .LandingTheme .pb-xl-4, .LandingTheme .py-xl-4 {
        padding-bottom: 1rem !important;
    }
    .LandingTheme .pl-xl-4, .LandingTheme .px-xl-4 {
        padding-left: 1rem !important;
    }
    .LandingTheme .p-xl-5 {
        padding: 1.5rem !important;
    }
    .LandingTheme .pt-xl-5, .LandingTheme .py-xl-5 {
        padding-top: 1.5rem !important;
    }
    .LandingTheme .pr-xl-5, .LandingTheme .px-xl-5 {
        padding-right: 1.5rem !important;
    }
    .LandingTheme .pb-xl-5, .LandingTheme .py-xl-5 {
        padding-bottom: 1.5rem !important;
    }
    .LandingTheme .pl-xl-5, .LandingTheme .px-xl-5 {
        padding-left: 1.5rem !important;
    }
    .LandingTheme .p-xl-6 {
        padding: 2rem !important;
    }
    .LandingTheme .pt-xl-6, .LandingTheme .py-xl-6 {
        padding-top: 2rem !important;
    }
    .LandingTheme .pr-xl-6, .LandingTheme .px-xl-6 {
        padding-right: 2rem !important;
    }
    .LandingTheme .pb-xl-6, .LandingTheme .py-xl-6 {
        padding-bottom: 2rem !important;
    }
    .LandingTheme .pl-xl-6, .LandingTheme .px-xl-6 {
        padding-left: 2rem !important;
    }
    .LandingTheme .p-xl-7 {
        padding: 2.5rem !important;
    }
    .LandingTheme .pt-xl-7, .LandingTheme .py-xl-7 {
        padding-top: 2.5rem !important;
    }
    .LandingTheme .pr-xl-7, .LandingTheme .px-xl-7 {
        padding-right: 2.5rem !important;
    }
    .LandingTheme .pb-xl-7, .LandingTheme .py-xl-7 {
        padding-bottom: 2.5rem !important;
    }
    .LandingTheme .pl-xl-7, .LandingTheme .px-xl-7 {
        padding-left: 2.5rem !important;
    }
    .LandingTheme .p-xl-8 {
        padding: 3rem !important;
    }
    .LandingTheme .pt-xl-8, .LandingTheme .py-xl-8 {
        padding-top: 3rem !important;
    }
    .LandingTheme .pr-xl-8, .LandingTheme .px-xl-8 {
        padding-right: 3rem !important;
    }
    .LandingTheme .pb-xl-8, .LandingTheme .py-xl-8 {
        padding-bottom: 3rem !important;
    }
    .LandingTheme .pl-xl-8, .LandingTheme .px-xl-8 {
        padding-left: 3rem !important;
    }
    .LandingTheme .p-xl-9 {
        padding: 4rem !important;
    }
    .LandingTheme .pt-xl-9, .LandingTheme .py-xl-9 {
        padding-top: 4rem !important;
    }
    .LandingTheme .pr-xl-9, .LandingTheme .px-xl-9 {
        padding-right: 4rem !important;
    }
    .LandingTheme .pb-xl-9, .LandingTheme .py-xl-9 {
        padding-bottom: 4rem !important;
    }
    .LandingTheme .pl-xl-9, .LandingTheme .px-xl-9 {
        padding-left: 4rem !important;
    }
    .LandingTheme .p-xl-10 {
        padding: 5rem !important;
    }
    .LandingTheme .pt-xl-10, .LandingTheme .py-xl-10 {
        padding-top: 5rem !important;
    }
    .LandingTheme .pr-xl-10, .LandingTheme .px-xl-10 {
        padding-right: 5rem !important;
    }
    .LandingTheme .pb-xl-10, .LandingTheme .py-xl-10 {
        padding-bottom: 5rem !important;
    }
    .LandingTheme .pl-xl-10, .LandingTheme .px-xl-10 {
        padding-left: 5rem !important;
    }
    .LandingTheme .p-xl-11 {
        padding: 6rem !important;
    }
    .LandingTheme .pt-xl-11, .LandingTheme .py-xl-11 {
        padding-top: 6rem !important;
    }
    .LandingTheme .pr-xl-11, .LandingTheme .px-xl-11 {
        padding-right: 6rem !important;
    }
    .LandingTheme .pb-xl-11, .LandingTheme .py-xl-11 {
        padding-bottom: 6rem !important;
    }
    .LandingTheme .pl-xl-11, .LandingTheme .px-xl-11 {
        padding-left: 6rem !important;
    }
    .LandingTheme .p-xl-12 {
        padding: 8rem !important;
    }
    .LandingTheme .pt-xl-12, .LandingTheme .py-xl-12 {
        padding-top: 8rem !important;
    }
    .LandingTheme .pr-xl-12, .LandingTheme .px-xl-12 {
        padding-right: 8rem !important;
    }
    .LandingTheme .pb-xl-12, .LandingTheme .py-xl-12 {
        padding-bottom: 8rem !important;
    }
    .LandingTheme .pl-xl-12, .LandingTheme .px-xl-12 {
        padding-left: 8rem !important;
    }
    .LandingTheme .p-xl-13 {
        padding: 10rem !important;
    }
    .LandingTheme .pt-xl-13, .LandingTheme .py-xl-13 {
        padding-top: 10rem !important;
    }
    .LandingTheme .pr-xl-13, .LandingTheme .px-xl-13 {
        padding-right: 10rem !important;
    }
    .LandingTheme .pb-xl-13, .LandingTheme .py-xl-13 {
        padding-bottom: 10rem !important;
    }
    .LandingTheme .pl-xl-13, .LandingTheme .px-xl-13 {
        padding-left: 10rem !important;
    }
    .LandingTheme .p-xl-14 {
        padding: 12rem !important;
    }
    .LandingTheme .pt-xl-14, .LandingTheme .py-xl-14 {
        padding-top: 12rem !important;
    }
    .LandingTheme .pr-xl-14, .LandingTheme .px-xl-14 {
        padding-right: 12rem !important;
    }
    .LandingTheme .pb-xl-14, .LandingTheme .py-xl-14 {
        padding-bottom: 12rem !important;
    }
    .LandingTheme .pl-xl-14, .LandingTheme .px-xl-14 {
        padding-left: 12rem !important;
    }
    .LandingTheme .p-xl-15 {
        padding: 14rem !important;
    }
    .LandingTheme .pt-xl-15, .LandingTheme .py-xl-15 {
        padding-top: 14rem !important;
    }
    .LandingTheme .pr-xl-15, .LandingTheme .px-xl-15 {
        padding-right: 14rem !important;
    }
    .LandingTheme .pb-xl-15, .LandingTheme .py-xl-15 {
        padding-bottom: 14rem !important;
    }
    .LandingTheme .pl-xl-15, .LandingTheme .px-xl-15 {
        padding-left: 14rem !important;
    }
    .LandingTheme .p-xl-16 {
        padding: 16rem !important;
    }
    .LandingTheme .pt-xl-16, .LandingTheme .py-xl-16 {
        padding-top: 16rem !important;
    }
    .LandingTheme .pr-xl-16, .LandingTheme .px-xl-16 {
        padding-right: 16rem !important;
    }
    .LandingTheme .pb-xl-16, .LandingTheme .py-xl-16 {
        padding-bottom: 16rem !important;
    }
    .LandingTheme .pl-xl-16, .LandingTheme .px-xl-16 {
        padding-left: 16rem !important;
    }
    .LandingTheme .p-xl-17 {
        padding: 20rem !important;
    }
    .LandingTheme .pt-xl-17, .LandingTheme .py-xl-17 {
        padding-top: 20rem !important;
    }
    .LandingTheme .pr-xl-17, .LandingTheme .px-xl-17 {
        padding-right: 20rem !important;
    }
    .LandingTheme .pb-xl-17, .LandingTheme .py-xl-17 {
        padding-bottom: 20rem !important;
    }
    .LandingTheme .pl-xl-17, .LandingTheme .px-xl-17 {
        padding-left: 20rem !important;
    }
    .LandingTheme .p-xl-18 {
        padding: 24rem !important;
    }
    .LandingTheme .pt-xl-18, .LandingTheme .py-xl-18 {
        padding-top: 24rem !important;
    }
    .LandingTheme .pr-xl-18, .LandingTheme .px-xl-18 {
        padding-right: 24rem !important;
    }
    .LandingTheme .pb-xl-18, .LandingTheme .py-xl-18 {
        padding-bottom: 24rem !important;
    }
    .LandingTheme .pl-xl-18, .LandingTheme .px-xl-18 {
        padding-left: 24rem !important;
    }
    .LandingTheme .m-xl-n1 {
        margin: -.25rem !important;
    }
    .LandingTheme .mt-xl-n1, .LandingTheme .my-xl-n1 {
        margin-top: -.25rem !important;
    }
    .LandingTheme .mr-xl-n1, .LandingTheme .mx-xl-n1 {
        margin-right: -.25rem !important;
    }
    .LandingTheme .mb-xl-n1, .LandingTheme .my-xl-n1 {
        margin-bottom: -.25rem !important;
    }
    .LandingTheme .ml-xl-n1, .LandingTheme .mx-xl-n1 {
        margin-left: -.25rem !important;
    }
    .LandingTheme .m-xl-n2 {
        margin: -.5rem !important;
    }
    .LandingTheme .mt-xl-n2, .LandingTheme .my-xl-n2 {
        margin-top: -.5rem !important;
    }
    .LandingTheme .mr-xl-n2, .LandingTheme .mx-xl-n2 {
        margin-right: -.5rem !important;
    }
    .LandingTheme .mb-xl-n2, .LandingTheme .my-xl-n2 {
        margin-bottom: -.5rem !important;
    }
    .LandingTheme .ml-xl-n2, .LandingTheme .mx-xl-n2 {
        margin-left: -.5rem !important;
    }
    .LandingTheme .m-xl-n3 {
        margin: -.75rem !important;
    }
    .LandingTheme .mt-xl-n3, .LandingTheme .my-xl-n3 {
        margin-top: -.75rem !important;
    }
    .LandingTheme .mr-xl-n3, .LandingTheme .mx-xl-n3 {
        margin-right: -.75rem !important;
    }
    .LandingTheme .mb-xl-n3, .LandingTheme .my-xl-n3 {
        margin-bottom: -.75rem !important;
    }
    .LandingTheme .ml-xl-n3, .LandingTheme .mx-xl-n3 {
        margin-left: -.75rem !important;
    }
    .LandingTheme .m-xl-n4 {
        margin: -1rem !important;
    }
    .LandingTheme .mt-xl-n4, .LandingTheme .my-xl-n4 {
        margin-top: -1rem !important;
    }
    .LandingTheme .mr-xl-n4, .LandingTheme .mx-xl-n4 {
        margin-right: -1rem !important;
    }
    .LandingTheme .mb-xl-n4, .LandingTheme .my-xl-n4 {
        margin-bottom: -1rem !important;
    }
    .LandingTheme .ml-xl-n4, .LandingTheme .mx-xl-n4 {
        margin-left: -1rem !important;
    }
    .LandingTheme .m-xl-n5 {
        margin: -1.5rem !important;
    }
    .LandingTheme .mt-xl-n5, .LandingTheme .my-xl-n5 {
        margin-top: -1.5rem !important;
    }
    .LandingTheme .mr-xl-n5, .LandingTheme .mx-xl-n5 {
        margin-right: -1.5rem !important;
    }
    .LandingTheme .mb-xl-n5, .LandingTheme .my-xl-n5 {
        margin-bottom: -1.5rem !important;
    }
    .LandingTheme .ml-xl-n5, .LandingTheme .mx-xl-n5 {
        margin-left: -1.5rem !important;
    }
    .LandingTheme .m-xl-n6 {
        margin: -2rem !important;
    }
    .LandingTheme .mt-xl-n6, .LandingTheme .my-xl-n6 {
        margin-top: -2rem !important;
    }
    .LandingTheme .mr-xl-n6, .LandingTheme .mx-xl-n6 {
        margin-right: -2rem !important;
    }
    .LandingTheme .mb-xl-n6, .LandingTheme .my-xl-n6 {
        margin-bottom: -2rem !important;
    }
    .LandingTheme .ml-xl-n6, .LandingTheme .mx-xl-n6 {
        margin-left: -2rem !important;
    }
    .LandingTheme .m-xl-n7 {
        margin: -2.5rem !important;
    }
    .LandingTheme .mt-xl-n7, .LandingTheme .my-xl-n7 {
        margin-top: -2.5rem !important;
    }
    .LandingTheme .mr-xl-n7, .LandingTheme .mx-xl-n7 {
        margin-right: -2.5rem !important;
    }
    .LandingTheme .mb-xl-n7, .LandingTheme .my-xl-n7 {
        margin-bottom: -2.5rem !important;
    }
    .LandingTheme .ml-xl-n7, .LandingTheme .mx-xl-n7 {
        margin-left: -2.5rem !important;
    }
    .LandingTheme .m-xl-n8 {
        margin: -3rem !important;
    }
    .LandingTheme .mt-xl-n8, .LandingTheme .my-xl-n8 {
        margin-top: -3rem !important;
    }
    .LandingTheme .mr-xl-n8, .LandingTheme .mx-xl-n8 {
        margin-right: -3rem !important;
    }
    .LandingTheme .mb-xl-n8, .LandingTheme .my-xl-n8 {
        margin-bottom: -3rem !important;
    }
    .LandingTheme .ml-xl-n8, .LandingTheme .mx-xl-n8 {
        margin-left: -3rem !important;
    }
    .LandingTheme .m-xl-n9 {
        margin: -4rem !important;
    }
    .LandingTheme .mt-xl-n9, .LandingTheme .my-xl-n9 {
        margin-top: -4rem !important;
    }
    .LandingTheme .mr-xl-n9, .LandingTheme .mx-xl-n9 {
        margin-right: -4rem !important;
    }
    .LandingTheme .mb-xl-n9, .LandingTheme .my-xl-n9 {
        margin-bottom: -4rem !important;
    }
    .LandingTheme .ml-xl-n9, .LandingTheme .mx-xl-n9 {
        margin-left: -4rem !important;
    }
    .LandingTheme .m-xl-n10 {
        margin: -5rem !important;
    }
    .LandingTheme .mt-xl-n10, .LandingTheme .my-xl-n10 {
        margin-top: -5rem !important;
    }
    .LandingTheme .mr-xl-n10, .LandingTheme .mx-xl-n10 {
        margin-right: -5rem !important;
    }
    .LandingTheme .mb-xl-n10, .LandingTheme .my-xl-n10 {
        margin-bottom: -5rem !important;
    }
    .LandingTheme .ml-xl-n10, .LandingTheme .mx-xl-n10 {
        margin-left: -5rem !important;
    }
    .LandingTheme .m-xl-n11 {
        margin: -6rem !important;
    }
    .LandingTheme .mt-xl-n11, .LandingTheme .my-xl-n11 {
        margin-top: -6rem !important;
    }
    .LandingTheme .mr-xl-n11, .LandingTheme .mx-xl-n11 {
        margin-right: -6rem !important;
    }
    .LandingTheme .mb-xl-n11, .LandingTheme .my-xl-n11 {
        margin-bottom: -6rem !important;
    }
    .LandingTheme .ml-xl-n11, .LandingTheme .mx-xl-n11 {
        margin-left: -6rem !important;
    }
    .LandingTheme .m-xl-n12 {
        margin: -8rem !important;
    }
    .LandingTheme .mt-xl-n12, .LandingTheme .my-xl-n12 {
        margin-top: -8rem !important;
    }
    .LandingTheme .mr-xl-n12, .LandingTheme .mx-xl-n12 {
        margin-right: -8rem !important;
    }
    .LandingTheme .mb-xl-n12, .LandingTheme .my-xl-n12 {
        margin-bottom: -8rem !important;
    }
    .LandingTheme .ml-xl-n12, .LandingTheme .mx-xl-n12 {
        margin-left: -8rem !important;
    }
    .LandingTheme .m-xl-n13 {
        margin: -10rem !important;
    }
    .LandingTheme .mt-xl-n13, .LandingTheme .my-xl-n13 {
        margin-top: -10rem !important;
    }
    .LandingTheme .mr-xl-n13, .LandingTheme .mx-xl-n13 {
        margin-right: -10rem !important;
    }
    .LandingTheme .mb-xl-n13, .LandingTheme .my-xl-n13 {
        margin-bottom: -10rem !important;
    }
    .LandingTheme .ml-xl-n13, .LandingTheme .mx-xl-n13 {
        margin-left: -10rem !important;
    }
    .LandingTheme .m-xl-n14 {
        margin: -12rem !important;
    }
    .LandingTheme .mt-xl-n14, .LandingTheme .my-xl-n14 {
        margin-top: -12rem !important;
    }
    .LandingTheme .mr-xl-n14, .LandingTheme .mx-xl-n14 {
        margin-right: -12rem !important;
    }
    .LandingTheme .mb-xl-n14, .LandingTheme .my-xl-n14 {
        margin-bottom: -12rem !important;
    }
    .LandingTheme .ml-xl-n14, .LandingTheme .mx-xl-n14 {
        margin-left: -12rem !important;
    }
    .LandingTheme .m-xl-n15 {
        margin: -14rem !important;
    }
    .LandingTheme .mt-xl-n15, .LandingTheme .my-xl-n15 {
        margin-top: -14rem !important;
    }
    .LandingTheme .mr-xl-n15, .LandingTheme .mx-xl-n15 {
        margin-right: -14rem !important;
    }
    .LandingTheme .mb-xl-n15, .LandingTheme .my-xl-n15 {
        margin-bottom: -14rem !important;
    }
    .LandingTheme .ml-xl-n15, .LandingTheme .mx-xl-n15 {
        margin-left: -14rem !important;
    }
    .LandingTheme .m-xl-n16 {
        margin: -16rem !important;
    }
    .LandingTheme .mt-xl-n16, .LandingTheme .my-xl-n16 {
        margin-top: -16rem !important;
    }
    .LandingTheme .mr-xl-n16, .LandingTheme .mx-xl-n16 {
        margin-right: -16rem !important;
    }
    .LandingTheme .mb-xl-n16, .LandingTheme .my-xl-n16 {
        margin-bottom: -16rem !important;
    }
    .LandingTheme .ml-xl-n16, .LandingTheme .mx-xl-n16 {
        margin-left: -16rem !important;
    }
    .LandingTheme .m-xl-n17 {
        margin: -20rem !important;
    }
    .LandingTheme .mt-xl-n17, .LandingTheme .my-xl-n17 {
        margin-top: -20rem !important;
    }
    .LandingTheme .mr-xl-n17, .LandingTheme .mx-xl-n17 {
        margin-right: -20rem !important;
    }
    .LandingTheme .mb-xl-n17, .LandingTheme .my-xl-n17 {
        margin-bottom: -20rem !important;
    }
    .LandingTheme .ml-xl-n17, .LandingTheme .mx-xl-n17 {
        margin-left: -20rem !important;
    }
    .LandingTheme .m-xl-n18 {
        margin: -24rem !important;
    }
    .LandingTheme .mt-xl-n18, .LandingTheme .my-xl-n18 {
        margin-top: -24rem !important;
    }
    .LandingTheme .mr-xl-n18, .LandingTheme .mx-xl-n18 {
        margin-right: -24rem !important;
    }
    .LandingTheme .mb-xl-n18, .LandingTheme .my-xl-n18 {
        margin-bottom: -24rem !important;
    }
    .LandingTheme .ml-xl-n18, .LandingTheme .mx-xl-n18 {
        margin-left: -24rem !important;
    }
    .LandingTheme .m-xl-auto {
        margin: auto !important;
    }
    .LandingTheme .mt-xl-auto, .LandingTheme .my-xl-auto {
        margin-top: auto !important;
    }
    .LandingTheme .mr-xl-auto, .LandingTheme .mx-xl-auto {
        margin-right: auto !important;
    }
    .LandingTheme .mb-xl-auto, .LandingTheme .my-xl-auto {
        margin-bottom: auto !important;
    }
    .LandingTheme .ml-xl-auto, .LandingTheme .mx-xl-auto {
        margin-left: auto !important;
    }
}

.LandingTheme .text-monospace {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
}

.LandingTheme .text-justify {
    text-align: justify !important;
}

.LandingTheme .text-wrap {
    white-space: normal !important;
}

.LandingTheme .text-nowrap {
    white-space: nowrap !important;
}

.LandingTheme .text-truncate {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.LandingTheme .text-left {
    text-align: left !important;
}

.LandingTheme .text-right {
    text-align: right !important;
}

.LandingTheme .text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .LandingTheme .text-sm-left {
        text-align: left !important;
    }
    .LandingTheme .text-sm-right {
        text-align: right !important;
    }
    .LandingTheme .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .text-md-left {
        text-align: left !important;
    }
    .LandingTheme .text-md-right {
        text-align: right !important;
    }
    .LandingTheme .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .text-lg-left {
        text-align: left !important;
    }
    .LandingTheme .text-lg-right {
        text-align: right !important;
    }
    .LandingTheme .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .text-xl-left {
        text-align: left !important;
    }
    .LandingTheme .text-xl-right {
        text-align: right !important;
    }
    .LandingTheme .text-xl-center {
        text-align: center !important;
    }
}

.LandingTheme .text-lowercase {
    text-transform: lowercase !important;
}

.LandingTheme .text-uppercase {
    text-transform: uppercase !important;
}

.LandingTheme .text-capitalize {
    text-transform: capitalize !important;
}

.LandingTheme .font-weight-light {
    font-weight: 300 !important;
}

.LandingTheme .font-weight-lighter {
    font-weight: lighter !important;
}

.LandingTheme .font-weight-normal {
    font-weight: 400 !important;
}

.LandingTheme .font-weight-bold {
    font-weight: 500 !important;
}

.LandingTheme .font-weight-bolder {
    font-weight: 700 !important;
}

.LandingTheme .font-italic {
    font-style: italic !important;
}

.LandingTheme .text-white {
    color: #fff !important;
}

.LandingTheme .text-primary {
    color: #6658ea !important;
}

.LandingTheme a.text-primary:focus, .LandingTheme a.text-primary:hover {
    color: #2e1bda !important;
}

.LandingTheme .text-secondary {
    color: #575a7b !important;
}

.LandingTheme a.text-secondary:focus, .LandingTheme a.text-secondary:hover {
    color: #37394e !important;
}

.LandingTheme .text-success {
    color: #2cd4a2 !important;
}

.LandingTheme a.text-success:focus, .LandingTheme a.text-success:hover {
    color: #1e9572 !important;
}

.LandingTheme .text-info {
    color: #17a2b8 !important;
}

.LandingTheme a.text-info:focus, .LandingTheme a.text-info:hover {
    color: #0f6674 !important;
}

.LandingTheme .text-warning {
    color: #f5c070 !important;
}

.LandingTheme a.text-warning:focus, .LandingTheme a.text-warning:hover {
    color: #f0a129 !important;
}

.LandingTheme .text-danger {
    color: #f9655b !important;
}

.LandingTheme a.text-danger:focus, .LandingTheme a.text-danger:hover {
    color: #f62011 !important;
}

.LandingTheme .text-light {
    color: #f3f8ff !important;
}

.LandingTheme a.text-light:focus, .LandingTheme a.text-light:hover {
    color: #a7cbff !important;
}

.LandingTheme .text-dark {
    color: #1b1642 !important;
}

.LandingTheme a.text-dark:focus, .LandingTheme a.text-dark:hover {
    color: #040309 !important;
}

.LandingTheme .text-primary-dark {
    color: #564caf !important;
}

.LandingTheme a.text-primary-dark:focus, .LandingTheme a.text-primary-dark:hover {
    color: #3c357a !important;
}

.LandingTheme .text-primary-light {
    color: #687ded !important;
}

.LandingTheme a.text-primary-light:focus, .LandingTheme a.text-primary-light:hover {
    color: #2442e5 !important;
}

.LandingTheme .text-body {
    color: #1b1642 !important;
}

.LandingTheme .text-muted {
    color: #575a7b !important;
}

.LandingTheme .text-black-50 {
    color: rgba(27, 22, 66, 0.5) !important;
}

.LandingTheme .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.LandingTheme .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.LandingTheme .text-decoration-none {
    text-decoration: none !important;
}

.LandingTheme .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.LandingTheme .text-reset {
    color: inherit !important;
}

.LandingTheme .visible {
    visibility: visible !important;
}

.LandingTheme .invisible {
    visibility: hidden !important;
}

@media print {
    .LandingTheme *, .LandingTheme ::after, .LandingTheme ::before {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .LandingTheme a:not(.btn) {
        text-decoration: underline;
    }
    .LandingTheme abbr[title]::after {
        content: " (" attr(title) ")";
    }
    .LandingTheme pre {
        white-space: pre-wrap !important;
    }
    .LandingTheme blockquote, .LandingTheme pre {
        border: 1px solid #8d9aae;
        page-break-inside: avoid;
    }
    .LandingTheme thead {
        display: table-header-group;
    }
    .LandingTheme img, .LandingTheme tr {
        page-break-inside: avoid;
    }
    .LandingTheme h2, .LandingTheme h3, .LandingTheme p {
        orphans: 3;
        widows: 3;
    }
    .LandingTheme h2, .LandingTheme h3 {
        page-break-after: avoid;
    }
    @page {
        .LandingTheme {
            size: a3;
        }
    }
    .LandingTheme body {
        min-width: 992px !important;
    }
    .LandingTheme .container {
        min-width: 992px !important;
    }
    .LandingTheme .navbar {
        display: none;
    }
    .LandingTheme .badge {
        border: 1px solid #1b1642;
    }
    .LandingTheme .table {
        border-collapse: collapse !important;
    }
    .LandingTheme .table td, .LandingTheme .table th {
        background-color: #fff !important;
    }
    .LandingTheme .table-bordered td, .LandingTheme .table-bordered th {
        border: 1px solid #dce0f3 !important;
    }
    .LandingTheme .table-dark {
        color: inherit;
    }
    .LandingTheme .table-dark tbody + tbody, .LandingTheme .table-dark td, .LandingTheme .table-dark th, .LandingTheme .table-dark thead th {
        border-color: #eaf2fe;
    }
    .LandingTheme .table .thead-dark th {
        color: inherit;
        border-color: #eaf2fe;
    }
}

.LandingTheme .alert {
    font-size: 1rem;
}

.LandingTheme .alert-primary {
    color: #fff;
}

.LandingTheme .alert-secondary {
    color: #fff;
}

.LandingTheme .alert-success {
    color: #fff;
}

.LandingTheme .alert-info {
    color: #fff;
}

.LandingTheme .alert-warning {
    color: #fff;
}

.LandingTheme .alert-danger {
    color: #fff;
}

.LandingTheme .alert-light {
    color: #1b1642;
}

.LandingTheme .alert-dark {
    color: #fff;
}

.LandingTheme .alert-primary-dark {
    color: #fff;
}

.LandingTheme .alert-primary-light {
    color: #fff;
}

.LandingTheme .alert-dismissible .close > .fe {
    line-height: 1.6rem;
}

.LandingTheme .badge-primary-soft {
    background-color: rgba(102, 88, 234, 0.1);
    color: #6658ea;
}

.LandingTheme a.badge-primary-soft:focus, .LandingTheme a.badge-primary-soft:hover {
    background-color: rgba(102, 88, 234, 0.2);
    color: #6658ea;
}

.LandingTheme .badge-secondary-soft {
    background-color: rgba(87, 90, 123, 0.1);
    color: #575a7b;
}

.LandingTheme a.badge-secondary-soft:focus, .LandingTheme a.badge-secondary-soft:hover {
    background-color: rgba(87, 90, 123, 0.2);
    color: #575a7b;
}

.LandingTheme .badge-success-soft {
    background-color: rgba(44, 212, 162, 0.1);
    color: #2cd4a2;
}

.LandingTheme a.badge-success-soft:focus, .LandingTheme a.badge-success-soft:hover {
    background-color: rgba(44, 212, 162, 0.2);
    color: #2cd4a2;
}

.LandingTheme .badge-info-soft {
    background-color: rgba(23, 162, 184, 0.1);
    color: #17a2b8;
}

.LandingTheme a.badge-info-soft:focus, .LandingTheme a.badge-info-soft:hover {
    background-color: rgba(23, 162, 184, 0.2);
    color: #17a2b8;
}

.LandingTheme .badge-warning-soft {
    background-color: rgba(245, 192, 112, 0.1);
    color: #f5c070;
}

.LandingTheme a.badge-warning-soft:focus, .LandingTheme a.badge-warning-soft:hover {
    background-color: rgba(245, 192, 112, 0.2);
    color: #f5c070;
}

.LandingTheme .badge-danger-soft {
    background-color: rgba(249, 101, 91, 0.1);
    color: #f9655b;
}

.LandingTheme a.badge-danger-soft:focus, .LandingTheme a.badge-danger-soft:hover {
    background-color: rgba(249, 101, 91, 0.2);
    color: #f9655b;
}

.LandingTheme .badge-light-soft {
    background-color: rgba(243, 248, 255, 0.1);
    color: #f3f8ff;
}

.LandingTheme a.badge-light-soft:focus, .LandingTheme a.badge-light-soft:hover {
    background-color: rgba(243, 248, 255, 0.2);
    color: #f3f8ff;
}

.LandingTheme .badge-dark-soft {
    background-color: rgba(27, 22, 66, 0.1);
    color: #1b1642;
}

.LandingTheme a.badge-dark-soft:focus, .LandingTheme a.badge-dark-soft:hover {
    background-color: rgba(27, 22, 66, 0.2);
    color: #1b1642;
}

.LandingTheme .badge-primary-dark-soft {
    background-color: rgba(86, 76, 175, 0.1);
    color: #564caf;
}

.LandingTheme a.badge-primary-dark-soft:focus, .LandingTheme a.badge-primary-dark-soft:hover {
    background-color: rgba(86, 76, 175, 0.2);
    color: #564caf;
}

.LandingTheme .badge-primary-light-soft {
    background-color: rgba(104, 125, 237, 0.1);
    color: #687ded;
}

.LandingTheme a.badge-primary-light-soft:focus, .LandingTheme a.badge-primary-light-soft:hover {
    background-color: rgba(104, 125, 237, 0.2);
    color: #687ded;
}

.LandingTheme .breadcrumb-item + .breadcrumb-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.LandingTheme .breadcrumb-item + .breadcrumb-item::before {
    font-family: Feather;
}

.LandingTheme .breadcrumb-dark {
    background-color: #1b1642;
}

.LandingTheme .breadcrumb-dark .breadcrumb-item {
    color: #fff;
}

.LandingTheme .breadcrumb-dark .breadcrumb-item > a {
    color: #fff;
}

.LandingTheme .breadcrumb-dark .breadcrumb-item + .breadcrumb-item::before {
    color: #8d9aae;
}

.LandingTheme .btn:not([class*=btn-outline]) {
    border-color: transparent !important;
}

.LandingTheme .btn-primary:focus, .LandingTheme .btn-primary:hover {
    background-color: #5546e8;
}

.LandingTheme .btn-secondary:focus, .LandingTheme .btn-secondary:hover {
    background-color: #4f516f;
}

.LandingTheme .btn-success:focus, .LandingTheme .btn-success:hover {
    background-color: #28c495;
}

.LandingTheme .btn-info:focus, .LandingTheme .btn-info:hover {
    background-color: #1592a6;
}

.LandingTheme .btn-warning:focus, .LandingTheme .btn-warning:hover {
    background-color: #f4b85d;
}

.LandingTheme .btn-danger:focus, .LandingTheme .btn-danger:hover {
    background-color: #f85347;
}

.LandingTheme .btn-light:focus, .LandingTheme .btn-light:hover {
    background-color: #dfecff;
}

.LandingTheme .btn-dark:focus, .LandingTheme .btn-dark:hover {
    background-color: #151133;
}

.LandingTheme .btn-primary-dark:focus, .LandingTheme .btn-primary-dark:hover {
    background-color: #4f46a1;
}

.LandingTheme .btn-primary-light:focus, .LandingTheme .btn-primary-light:hover {
    background-color: #566deb;
}

.LandingTheme .btn-white {
    color: #1b1642;
    background-color: #fff;
    border-color: #fff;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    color: #6658ea;
}

.LandingTheme .btn-white:hover {
    color: #1b1642;
    background-color: #ececec;
    border-color: #e6e6e6;
}

.LandingTheme .btn-white.focus, .LandingTheme .btn-white:focus {
    color: #1b1642;
    background-color: #ececec;
    border-color: #e6e6e6;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(221, 220, 227, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(221, 220, 227, 0.5);
}

.LandingTheme .btn-white.disabled, .LandingTheme .btn-white:disabled {
    color: #1b1642;
    background-color: #fff;
    border-color: #fff;
}

.LandingTheme .btn-white:not(:disabled):not(.disabled).active, .LandingTheme .btn-white:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-white.dropdown-toggle {
    color: #1b1642;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}

.LandingTheme .btn-white:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-white:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(221, 220, 227, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(221, 220, 227, 0.5);
}

.LandingTheme .btn-white:active, .LandingTheme .btn-white:focus, .LandingTheme .btn-white:hover {
    background-color: #fff;
    color: #5546e8;
}

.LandingTheme .btn-white:not(:disabled):not(.disabled).active, .LandingTheme .btn-white:not(:disabled):not(.disabled):active {
    background-color: #fff;
    color: #5546e8;
}

.LandingTheme .btn-gray-200 {
    color: #1b1642;
    background-color: #eaf2fe;
    border-color: #eaf2fe;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    color: #5546e8;
}

.LandingTheme .btn-gray-200:hover {
    color: #1b1642;
    background-color: #c5dbfc;
    border-color: #b9d4fc;
}

.LandingTheme .btn-gray-200.focus, .LandingTheme .btn-gray-200:focus {
    color: #1b1642;
    background-color: #c5dbfc;
    border-color: #b9d4fc;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(203, 209, 226, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(203, 209, 226, 0.5);
}

.LandingTheme .btn-gray-200.disabled, .LandingTheme .btn-gray-200:disabled {
    color: #1b1642;
    background-color: #eaf2fe;
    border-color: #eaf2fe;
}

.LandingTheme .btn-gray-200:not(:disabled):not(.disabled).active, .LandingTheme .btn-gray-200:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-gray-200.dropdown-toggle {
    color: #1b1642;
    background-color: #b9d4fc;
    border-color: #adccfb;
}

.LandingTheme .btn-gray-200:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-gray-200:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-gray-200.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(203, 209, 226, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(203, 209, 226, 0.5);
}

.LandingTheme .btn-gray-200:active, .LandingTheme .btn-gray-200:focus, .LandingTheme .btn-gray-200:hover {
    color: #5546e8;
}

.LandingTheme .btn-gray-200:not(:disabled):not(.disabled).active, .LandingTheme .btn-gray-200:not(:disabled):not(.disabled):active {
    color: #5546e8;
}

.LandingTheme .btn-gray-300 {
    color: #1b1642;
    background-color: #dce0f3;
    border-color: #dce0f3;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    color: #5546e8;
}

.LandingTheme .btn-gray-300:hover {
    color: #1b1642;
    background-color: #c0c7e9;
    border-color: #b6bee6;
}

.LandingTheme .btn-gray-300.focus, .LandingTheme .btn-gray-300:focus {
    color: #1b1642;
    background-color: #c0c7e9;
    border-color: #b6bee6;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(191, 194, 216, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(191, 194, 216, 0.5);
}

.LandingTheme .btn-gray-300.disabled, .LandingTheme .btn-gray-300:disabled {
    color: #1b1642;
    background-color: #dce0f3;
    border-color: #dce0f3;
}

.LandingTheme .btn-gray-300:not(:disabled):not(.disabled).active, .LandingTheme .btn-gray-300:not(:disabled):not(.disabled):active, .LandingTheme .show > .btn-gray-300.dropdown-toggle {
    color: #fff;
    background-color: #b6bee6;
    border-color: #adb6e3;
}

.LandingTheme .btn-gray-300:not(:disabled):not(.disabled).active:focus, .LandingTheme .btn-gray-300:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .btn-gray-300.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(191, 194, 216, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(191, 194, 216, 0.5);
}

.LandingTheme .btn-gray-300:active, .LandingTheme .btn-gray-300:focus, .LandingTheme .btn-gray-300:hover {
    color: #5546e8;
}

.LandingTheme .btn-gray-300:not(:disabled):not(.disabled).active, .LandingTheme .btn-gray-300:not(:disabled):not(.disabled):active {
    color: #5546e8;
}

.LandingTheme .btn-circle {
    padding-left: .75rem;
    padding-right: .75rem;
    border-radius: 50%;
}

.LandingTheme .btn-circle > * {
    display: block;
    width: calc(1.125rem * 1.6);
    line-height: inherit;
}

.LandingTheme .btn-circle.btn-lg, .LandingTheme .btn-group-lg > .btn-circle.btn {
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 50%;
}

.LandingTheme .btn-circle.btn-lg > *, .LandingTheme .btn-group-lg > .btn-circle.btn > * {
    width: calc(1.125rem * 1.6);
}

.LandingTheme .btn-circle.btn-sm, .LandingTheme .btn-group-sm > .btn-circle.btn {
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: 50%;
}

.LandingTheme .btn-circle.btn-sm > *, .LandingTheme .btn-group-sm > .btn-circle.btn > * {
    width: calc(.875rem * 1.6);
}

.LandingTheme .btn-link {
    font-weight: 500;
}

.LandingTheme .btn-group {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .btn-group .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .card {
    -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
}

.LandingTheme .card-footer {
    background-color: inherit;
}

.LandingTheme .card-body {
    padding: 2rem;
}

.LandingTheme .card-footer {
    padding-left: 2rem;
    padding-right: 2rem;
}

.LandingTheme .card-lg .card-body {
    padding: 3rem;
}

.LandingTheme .card-lg .card-footer {
    padding-left: 3rem;
    padding-right: 3rem;
}

@media (min-width: 992px) {
    .LandingTheme .card-body {
        padding: 3rem;
    }
    .LandingTheme .card-footer {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .LandingTheme .card-lg .card-body {
        padding: 5rem;
    }
    .LandingTheme .card-lg .card-footer {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .LandingTheme .card-sm .card-body {
        padding: 2rem;
    }
    .LandingTheme .card-sm .card-footer {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.LandingTheme .card-img-overlay {
    top: auto;
}

.LandingTheme .card-flush {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .card-flush .card-body {
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 768px) {
    .LandingTheme .card-img-slider {
        height: 100%;
        width: 100%;
    }
    .LandingTheme .card-img-slider * {
        height: inherit !important;
        width: inherit !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .card-stack {
        display: grid;
        grid-auto-rows: 1fr;
    }
    .LandingTheme .card-stack-item {
        position: -webkit-sticky;
        position: sticky;
        top: 140px;
    }
    .LandingTheme .card-stack-item > .card {
        height: 100%;
        -webkit-transform-origin: top center;
        -ms-transform-origin: top center;
        transform-origin: top center;
    }
    .LandingTheme .card-stack-item > .card > .row {
        height: inherit;
    }
}

.LandingTheme .collapse-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.LandingTheme .collapse-toggle::after {
    content: "\e9b1";
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-left: auto;
    font-family: Feather;
    color: #f5c070;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.LandingTheme .collapse-toggle[aria-expanded=true]::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.LandingTheme .timeline-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.LandingTheme .timeline-radio-input {
    display: none;
}

.LandingTheme .timeline-radio-input:checked ~ .timeline-radio-label {
    opacity: .2;
}

.LandingTheme .timeline-radio-input:checked + .timeline-radio-label {
    opacity: 1;
}

.LandingTheme .timeline-radio-input:checked + .timeline-radio-label::before {
    -webkit-box-shadow: 0 0 0 5px currentColor;
    box-shadow: 0 0 0 5px currentColor;
}

.LandingTheme .timeline-radio-label {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
    pointer-events: none;
}

.LandingTheme .timeline-radio-label:first-of-type {
    width: 20px;
}

.LandingTheme .timeline-radio-label > small {
    white-space: nowrap;
}

.LandingTheme .timeline-radio-label:not(:first-of-type) > small, .LandingTheme .timeline-radio-label:not(:last-of-type) > small {
    -webkit-transform: translateX(50%) translateX(-10px);
    -ms-transform: translateX(50%) translateX(-10px);
    transform: translateX(50%) translateX(-10px);
}

.LandingTheme .timeline-radio-label::after, .LandingTheme .timeline-radio-label::before {
    content: "";
    display: block;
    cursor: pointer;
}

.LandingTheme .timeline-radio-label::before {
    z-index: 1;
    width: 20px;
    height: 20px;
    margin-bottom: 1rem;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 20px currentColor;
    box-shadow: inset 0 0 0 20px currentColor;
    border-radius: 50%;
    pointer-events: auto;
}

.LandingTheme .timeline-radio-label::after {
    position: absolute;
    top: calc((20px - 5px)/ 2);
    right: 0;
    width: 100%;
    border-top: 5px solid currentColor;
}

.LandingTheme .timeline-radio-primary .timeline-radio-label::after, .LandingTheme .timeline-radio-primary .timeline-radio-label::before {
    color: #6658ea;
}

.LandingTheme .timeline-radio-secondary .timeline-radio-label::after, .LandingTheme .timeline-radio-secondary .timeline-radio-label::before {
    color: #575a7b;
}

.LandingTheme .timeline-radio-success .timeline-radio-label::after, .LandingTheme .timeline-radio-success .timeline-radio-label::before {
    color: #2cd4a2;
}

.LandingTheme .timeline-radio-info .timeline-radio-label::after, .LandingTheme .timeline-radio-info .timeline-radio-label::before {
    color: #17a2b8;
}

.LandingTheme .timeline-radio-warning .timeline-radio-label::after, .LandingTheme .timeline-radio-warning .timeline-radio-label::before {
    color: #f5c070;
}

.LandingTheme .timeline-radio-danger .timeline-radio-label::after, .LandingTheme .timeline-radio-danger .timeline-radio-label::before {
    color: #f9655b;
}

.LandingTheme .timeline-radio-light .timeline-radio-label::after, .LandingTheme .timeline-radio-light .timeline-radio-label::before {
    color: #f3f8ff;
}

.LandingTheme .timeline-radio-dark .timeline-radio-label::after, .LandingTheme .timeline-radio-dark .timeline-radio-label::before {
    color: #1b1642;
}

.LandingTheme .timeline-radio-primary-dark .timeline-radio-label::after, .LandingTheme .timeline-radio-primary-dark .timeline-radio-label::before {
    color: #564caf;
}

.LandingTheme .timeline-radio-primary-light .timeline-radio-label::after, .LandingTheme .timeline-radio-primary-light .timeline-radio-label::before {
    color: #687ded;
}

.LandingTheme .custom-switch .custom-control-label::before {
    top: calc((1.8rem - 1.5rem)/ 2);
    height: 1.5rem;
}

.LandingTheme .custom-switch .custom-control-label::after {
    left: -3.0625rem;
    background-color: #fff;
}

.LandingTheme .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
    background-color: #fff;
}

.LandingTheme .custom-control-dark.custom-switch .custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}

.LandingTheme .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #6658ea;
}

.LandingTheme .dropdown-menu {
    padding: 2rem 2rem;
}

.LandingTheme .dropdown-menu-auto {
    min-width: auto;
}

.LandingTheme .dropdown-menu-lg {
    min-width: 24rem;
}

.LandingTheme .dropdown-menu-xl {
    min-width: 48rem;
}

.LandingTheme .dropdown-menu-flush {
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .dropdown-menu-col {
    background-color: #fff;
    border-radius: 1rem 0 1rem 0;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .dropdown-menu-body {
    padding: 2rem 2rem;
}

.LandingTheme .dropdown-menu-footer {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-top: 1px solid #eaf2fe;
}

.LandingTheme .dropdown-item + .dropdown-item, .LandingTheme .dropdown-item + .dropdown-link, .LandingTheme .dropdown-link + .dropdown-item, .LandingTheme .dropdown-link + .dropdown-link {
    margin-top: .75rem;
}

.LandingTheme .dropdown-header {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
    font-size: .875rem;
}

.LandingTheme .dropdown-item + .dropdown-header, .LandingTheme .dropdown-link + .dropdown-header {
    margin-top: 2.5rem;
}

.LandingTheme .form-control {
    background-clip: border-box;
}

.LandingTheme .form-control-underline {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: #eaf2fe;
}

.LandingTheme .form-control-underline:focus {
    background-color: transparent;
    border-bottom-color: #6658ea;
}

.LandingTheme .form-control.is-invalid, .LandingTheme .form-control.is-valid {
    background-size: 1.5rem 1.5rem;
}

.LandingTheme .custom-select.is-invalid, .LandingTheme .custom-select.is-valid {
    background-size: 14px 8px,1.5rem 1.5rem;
}

.LandingTheme .offset-n1 {
    margin-left: -8.33333%;
}

.LandingTheme .offset-n2 {
    margin-left: -16.66667%;
}

.LandingTheme .offset-n3 {
    margin-left: -25%;
}

.LandingTheme .offset-n4 {
    margin-left: -33.33333%;
}

.LandingTheme .offset-n5 {
    margin-left: -41.66667%;
}

.LandingTheme .offset-n6 {
    margin-left: -50%;
}

.LandingTheme .offset-n7 {
    margin-left: -58.33333%;
}

.LandingTheme .offset-n8 {
    margin-left: -66.66667%;
}

.LandingTheme .offset-n9 {
    margin-left: -75%;
}

.LandingTheme .offset-n10 {
    margin-left: -83.33333%;
}

.LandingTheme .offset-n11 {
    margin-left: -91.66667%;
}

.LandingTheme .offset-n12 {
    margin-left: -100%;
}

@media (min-width: 576px) {
    .LandingTheme .offset-sm-n1 {
        margin-left: -8.33333%;
    }
    .LandingTheme .offset-sm-n2 {
        margin-left: -16.66667%;
    }
    .LandingTheme .offset-sm-n3 {
        margin-left: -25%;
    }
    .LandingTheme .offset-sm-n4 {
        margin-left: -33.33333%;
    }
    .LandingTheme .offset-sm-n5 {
        margin-left: -41.66667%;
    }
    .LandingTheme .offset-sm-n6 {
        margin-left: -50%;
    }
    .LandingTheme .offset-sm-n7 {
        margin-left: -58.33333%;
    }
    .LandingTheme .offset-sm-n8 {
        margin-left: -66.66667%;
    }
    .LandingTheme .offset-sm-n9 {
        margin-left: -75%;
    }
    .LandingTheme .offset-sm-n10 {
        margin-left: -83.33333%;
    }
    .LandingTheme .offset-sm-n11 {
        margin-left: -91.66667%;
    }
    .LandingTheme .offset-sm-n12 {
        margin-left: -100%;
    }
}

@media (min-width: 768px) {
    .LandingTheme .offset-md-n1 {
        margin-left: -8.33333%;
    }
    .LandingTheme .offset-md-n2 {
        margin-left: -16.66667%;
    }
    .LandingTheme .offset-md-n3 {
        margin-left: -25%;
    }
    .LandingTheme .offset-md-n4 {
        margin-left: -33.33333%;
    }
    .LandingTheme .offset-md-n5 {
        margin-left: -41.66667%;
    }
    .LandingTheme .offset-md-n6 {
        margin-left: -50%;
    }
    .LandingTheme .offset-md-n7 {
        margin-left: -58.33333%;
    }
    .LandingTheme .offset-md-n8 {
        margin-left: -66.66667%;
    }
    .LandingTheme .offset-md-n9 {
        margin-left: -75%;
    }
    .LandingTheme .offset-md-n10 {
        margin-left: -83.33333%;
    }
    .LandingTheme .offset-md-n11 {
        margin-left: -91.66667%;
    }
    .LandingTheme .offset-md-n12 {
        margin-left: -100%;
    }
}

@media (min-width: 992px) {
    .LandingTheme .offset-lg-n1 {
        margin-left: -8.33333%;
    }
    .LandingTheme .offset-lg-n2 {
        margin-left: -16.66667%;
    }
    .LandingTheme .offset-lg-n3 {
        margin-left: -25%;
    }
    .LandingTheme .offset-lg-n4 {
        margin-left: -33.33333%;
    }
    .LandingTheme .offset-lg-n5 {
        margin-left: -41.66667%;
    }
    .LandingTheme .offset-lg-n6 {
        margin-left: -50%;
    }
    .LandingTheme .offset-lg-n7 {
        margin-left: -58.33333%;
    }
    .LandingTheme .offset-lg-n8 {
        margin-left: -66.66667%;
    }
    .LandingTheme .offset-lg-n9 {
        margin-left: -75%;
    }
    .LandingTheme .offset-lg-n10 {
        margin-left: -83.33333%;
    }
    .LandingTheme .offset-lg-n11 {
        margin-left: -91.66667%;
    }
    .LandingTheme .offset-lg-n12 {
        margin-left: -100%;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .offset-xl-n1 {
        margin-left: -8.33333%;
    }
    .LandingTheme .offset-xl-n2 {
        margin-left: -16.66667%;
    }
    .LandingTheme .offset-xl-n3 {
        margin-left: -25%;
    }
    .LandingTheme .offset-xl-n4 {
        margin-left: -33.33333%;
    }
    .LandingTheme .offset-xl-n5 {
        margin-left: -41.66667%;
    }
    .LandingTheme .offset-xl-n6 {
        margin-left: -50%;
    }
    .LandingTheme .offset-xl-n7 {
        margin-left: -58.33333%;
    }
    .LandingTheme .offset-xl-n8 {
        margin-left: -66.66667%;
    }
    .LandingTheme .offset-xl-n9 {
        margin-left: -75%;
    }
    .LandingTheme .offset-xl-n10 {
        margin-left: -83.33333%;
    }
    .LandingTheme .offset-xl-n11 {
        margin-left: -91.66667%;
    }
    .LandingTheme .offset-xl-n12 {
        margin-left: -100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .LandingTheme .container-lg {
        max-width: 90%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .LandingTheme .container-lg {
        max-width: 95%;
    }
}

.LandingTheme .img-fluid > * {
    width: 100%;
}

.LandingTheme .img-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(1) {
    width: 60%;
    margin-left: 20%;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(2) {
    width: 15%;
    margin-top: 15%;
    margin-left: -80%;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(3) {
    width: 20%;
    margin-top: 10%;
    margin-left: 60%;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(4) {
    width: 20%;
    margin-top: 40%;
    margin-left: -85%;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(5) {
    width: 15%;
    margin-top: 40%;
    margin-left: 55%;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(6) {
    width: 15%;
    margin-top: 5%;
    margin-left: 25%;
}

.LandingTheme .img-grid-1 .img-grid-item:nth-child(7) {
    width: 20%;
    margin-top: -10%;
    margin-left: 10%;
}

.LandingTheme .img-grid-2 .img-grid-item:nth-child(1) {
    width: 55%;
    margin-top: 0;
    margin-left: 35%;
    z-index: 2;
}

.LandingTheme .img-grid-2 .img-grid-item:nth-child(2) {
    width: 55%;
    margin-top: -30%;
    margin-left: 0;
    z-index: 1;
}

.LandingTheme .img-grid-2 .img-grid-item:nth-child(3) {
    width: 60%;
    margin-top: -35%;
    margin-left: 40%;
    z-index: 0;
}

.LandingTheme .img-grid-3 .img-grid-item:nth-child(1) {
    width: 80%;
    margin-top: 15%;
    margin-left: 10%;
}

.LandingTheme .img-grid-3 .img-grid-item:nth-child(2) {
    width: 30%;
    margin-left: -20%;
}

.LandingTheme .img-grid-3 .img-grid-item:nth-child(3) {
    width: 25%;
    margin-top: 55%;
    margin-left: -40%;
}

.LandingTheme .img-grid-3 .img-grid-item:nth-child(4) {
    width: 100%;
    margin-top: 75%;
    margin-left: -85%;
}

@media (min-width: 768px) {
    .LandingTheme .img-grid-3 .img-grid-item:nth-child(4) {
        width: 40%;
        margin-top: 40%;
    }
}

.LandingTheme .img-grid-4 .img-grid-item:nth-child(1) {
    width: 70%;
    margin-top: 0;
    margin-left: 30%;
    z-index: 2;
}

.LandingTheme .img-grid-4 .img-grid-item:nth-child(2) {
    width: 75%;
    margin-top: -15%;
    margin-left: 0;
    z-index: 0;
}

.LandingTheme .img-grid-4 .img-grid-item:nth-child(3) {
    width: 40%;
    margin-top: -25%;
    margin-left: 50%;
    z-index: 1;
}

.LandingTheme .input-group .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .input-group .input-group-append .btn-white::before {
    content: '';
    position: absolute;
    top: .75rem;
    bottom: .75rem;
    left: 0;
    border-left: 1px solid #dce0f3;
}

.LandingTheme .input-group-prepend .input-group-text {
    padding-right: 0;
}

.LandingTheme .input-group-append .input-group-text {
    padding-left: 0;
}

.LandingTheme .list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
    border-width: 0;
}

.LandingTheme .list-group-flush .list-group-item + .list-group-item {
    border-top-width: 1px;
}

.LandingTheme .list-group-flush .list-group-item.active {
    background-color: transparent;
}

.LandingTheme .list-group-flush .list-group-item + .list-group-item.active {
    margin-top: 0;
}

.LandingTheme .list-group-lg .list-group-item {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.LandingTheme .list-group-sm .list-group-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.LandingTheme .list-group-hover .list-group-item {
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transition-property: border-color;
    -o-transition-property: border-color;
    transition-property: border-color;
}

.LandingTheme .list-group-hover .list-group-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: calc(100% + 5rem);
    border-radius: 1rem 0 1rem 0;
    -webkit-box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
}

.LandingTheme .list-group-hover .list-group-item:hover, .LandingTheme .list-group-hover .list-group-item:hover + .list-group-item[href] {
    border-top-color: transparent;
}

.LandingTheme .list-group-hover .list-group-item:hover::before {
    opacity: 1;
}

.LandingTheme .modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}

.LandingTheme .navbar {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transition-property: -webkit-box-shadow;
    transition-property: -webkit-box-shadow;
    -o-transition-property: box-shadow;
    transition-property: box-shadow;
    transition-property: box-shadow,-webkit-box-shadow;
}

.LandingTheme .navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-width: 0;
    border-top: 4px solid;
    -o-border-image: -o-linear-gradient(left, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1;
    border-image: -webkit-gradient(linear, left top, right top, from(#575a7b), color-stop(20%, #575a7b), color-stop(20%, #f9655b), color-stop(40%, #f9655b), color-stop(40%, #f5c070), color-stop(60%, #f5c070), color-stop(60%, #6658ea), color-stop(80%, #6658ea), color-stop(80%, #fcc)) 1;
    border-image: linear-gradient(to right, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1;
}

.LandingTheme .navbar-brand {
    font-family: "DM Serif Display",serif;
}

.LandingTheme .navbar-toggler {
    padding-left: 0;
    padding-right: 0;
}

.LandingTheme .navbar-nav .dropright > .dropdown-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.LandingTheme .navbar-nav .dropright > .dropdown-toggle::after {
    margin-left: auto;
    font-family: Feather;
    color: #8d9aae;
    content: "\e930";
}

.LandingTheme .navbar-nav .dropdown-positioner {
    position: absolute;
    z-index: 1000;
}

.LandingTheme .navbar-nav .dropdown-positioner > .dropdown-menu {
    position: static;
}

@media (max-width: 991.98px) {
    .LandingTheme .navbar.fixed-top {
        max-height: 100vh;
        overflow: auto;
    }
    .LandingTheme .navbar-nav:first-child {
        margin-top: 2rem;
    }
    .LandingTheme .navbar-nav > .nav-item {
        border-top: 1px solid rgba(27, 22, 66, 0.065);
    }
    .LandingTheme .navbar-nav > .nav-item > .nav-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-top: .75rem;
        padding-bottom: .75rem;
    }
    .LandingTheme .navbar-nav > .dropdown > .dropdown-toggle::after {
        margin-left: auto;
        font-family: Feather;
        color: #8d9aae;
        content: "\e92e";
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
    }
    .LandingTheme .navbar-nav > .dropdown.show > .dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .LandingTheme .navbar-nav > .dropdown .dropdown-menu, .LandingTheme .navbar-nav > .dropdown .dropdown-menu-col {
        min-width: 0;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .LandingTheme .navbar-nav > .dropdown .dropdown-menu {
        padding: .75rem 0 1.5rem;
    }
    .LandingTheme .navbar-nav > .dropdown .dropdown-menu-col {
        background-color: transparent !important;
    }
    .LandingTheme .navbar-nav > .dropdown .dropdown-menu-col .text-white {
        color: #1b1642 !important;
    }
    .LandingTheme .navbar-nav > .dropdown .dropdown-menu-body {
        padding: 0;
    }
    .LandingTheme .navbar-nav .dropright > .dropdown-toggle::after {
        content: "\e92e";
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
    }
    .LandingTheme .navbar-nav .dropright.show > .dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .LandingTheme .navbar-nav .dropright .dropdown-menu {
        padding: .75rem 0 0 .75rem;
    }
    .LandingTheme .navbar-nav .dropdown-positioner {
        position: relative !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .navbar-nav .text-gray-500 {
        color: #575a7b !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .navbar-nav .dropright {
        width: auto;
        margin-left: -2rem;
        margin-right: -2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .LandingTheme .navbar-nav .dropdown-menu {
        display: none;
        opacity: 0;
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        transition-property: opacity,transform,-webkit-transform;
    }
    .LandingTheme .navbar-nav .dropdown-menu.showing {
        display: block;
    }
    .LandingTheme .navbar-nav .dropdown-menu.show {
        display: block;
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.LandingTheme .pagination .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.LandingTheme .pagination .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.LandingTheme .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.LandingTheme .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.LandingTheme body, .LandingTheme html {
    overflow-x: hidden;
}

.LandingTheme html {
    height: 100%;
}

.LandingTheme body {
    min-height: 100%;
}

.LandingTheme .table-flush tbody td:first-child, .LandingTheme .table-flush tbody th:first-child, .LandingTheme .table-flush tfoot td:first-child, .LandingTheme .table-flush thead th:first-child {
    padding-left: 0;
}

.LandingTheme .table-flush tbody td:last-child, .LandingTheme .table-flush tbody th:last-child, .LandingTheme .table-flush tfoot td:last-child, .LandingTheme .table-flush thead th:last-child {
    padding-right: 0;
}

.LandingTheme .table th {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: 400;
}

.LandingTheme .table thead th {
    border-bottom: 0;
    text-transform: uppercase;
    font-size: .875rem;
    color: #8d9aae;
}

.LandingTheme .table tfoot td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.LandingTheme .table-clickable [data-href]:hover {
    cursor: pointer;
}

.LandingTheme .table-clickable [data-href]:hover .table-clickable-hover {
    color: #5546e8;
}

@media (max-width: 767.98px) {
    .LandingTheme .table-features.table-features-alt td:nth-child(2), .LandingTheme .table-features.table-features-alt th:nth-child(2) {
        display: none;
    }
    .LandingTheme .table-features:not(.table-features-alt) td:nth-child(3), .LandingTheme .table-features:not(.table-features-alt) th:nth-child(3) {
        display: none;
    }
}

.LandingTheme .toast-header {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom-width: 1px;
}

.LandingTheme .toast-positioner {
    position: fixed;
    z-index: 1030;
    bottom: 1.5rem;
    width: 100%;
    max-width: 350px;
}

.LandingTheme .toast-positioner-left {
    left: 1.5rem;
}

.LandingTheme .toast-positioner-right {
    right: 1.5rem;
}

@media (max-width: 991.98px) {
    .LandingTheme .h1, .LandingTheme h1 {
        font-size: 1.75rem;
    }
    .LandingTheme .h2, .LandingTheme h2 {
        font-size: 1.5rem;
    }
    .LandingTheme .h3, .LandingTheme h3 {
        font-size: 1.125rem;
    }
    .LandingTheme .display-4 {
        font-size: 2.25rem;
    }
    .LandingTheme .display-3 {
        font-size: 2.5rem;
    }
    .LandingTheme .display-2 {
        font-size: 3rem;
    }
    .LandingTheme .display-1 {
        font-size: 3rem;
    }
    .LandingTheme .lead {
        font-size: 1.25rem;
    }
}

.LandingTheme .h1, .LandingTheme .h2, .LandingTheme h1, .LandingTheme h2 {
    line-height: 1.32;
}

.LandingTheme .h3, .LandingTheme h2 {
    line-height: 1.4;
}

.LandingTheme .h4, .LandingTheme .h5, .LandingTheme .h6, .LandingTheme h4, .LandingTheme h5, .LandingTheme h6 {
    line-height: 1.6;
}

.LandingTheme .h6.text-uppercase, .LandingTheme .table thead th, .LandingTheme h6.text-uppercase {
    font-family: "HKGroteskPro",sans-serif;
    font-weight: 700;
    letter-spacing: .18em;
}

.LandingTheme .display-1.font-family-sans-serif, .LandingTheme .display-2.font-family-sans-serif, .LandingTheme .display-3.font-family-sans-serif, .LandingTheme .display-4.font-family-sans-serif {
    letter-spacing: -.02em;
}

.LandingTheme .h1.font-family-sans-serif, .LandingTheme h1.font-family-sans-serif {
    letter-spacing: -.02em;
}

.LandingTheme .h2.font-family-sans-serif, .LandingTheme .h3.font-family-sans-serif, .LandingTheme .h4.font-family-sans-serif, .LandingTheme h2.font-family-sans-serif, .LandingTheme h3.font-family-sans-serif, .LandingTheme h4.font-family-sans-serif {
    letter-spacing: -.01em;
}

.LandingTheme .list-checked {
    padding-left: 0;
    text-align: left;
    list-style-type: none;
}

.LandingTheme .list-checked > li {
    position: relative;
    padding-left: 2.25rem;
}

.LandingTheme .list-checked > li + li {
    margin-top: .75rem;
}

.LandingTheme .list-checked > li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: Feather;
    content: "\e92b";
}

.LandingTheme .list-checked-primary > li::before {
    color: #6658ea;
}

.LandingTheme .list-checked-secondary > li::before {
    color: #575a7b;
}

.LandingTheme .list-checked-success > li::before {
    color: #2cd4a2;
}

.LandingTheme .list-checked-info > li::before {
    color: #17a2b8;
}

.LandingTheme .list-checked-warning > li::before {
    color: #f5c070;
}

.LandingTheme .list-checked-danger > li::before {
    color: #f9655b;
}

.LandingTheme .list-checked-light > li::before {
    color: #f3f8ff;
}

.LandingTheme .list-checked-dark > li::before {
    color: #1b1642;
}

.LandingTheme .list-checked-primary-dark > li::before {
    color: #564caf;
}

.LandingTheme .list-checked-primary-light > li::before {
    color: #687ded;
}

.LandingTheme .list-checked-circle > li::before {
    top: calc((1.6 * 1rem - 1.25rem));
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    font-size: .75rem;
    border-radius: 50%;
}

.LandingTheme .list-checked-circle.list-checked-primary > li::before {
    background-color: #6658ea;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-secondary > li::before {
    background-color: #575a7b;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-success > li::before {
    background-color: #2cd4a2;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-info > li::before {
    background-color: #17a2b8;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-warning > li::before {
    background-color: #f5c070;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-danger > li::before {
    background-color: #f9655b;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-light > li::before {
    background-color: #f3f8ff;
    color: #1b1642;
}

.LandingTheme .list-checked-circle.list-checked-dark > li::before {
    background-color: #1b1642;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-primary-dark > li::before {
    background-color: #564caf;
    color: #fff;
}

.LandingTheme .list-checked-circle.list-checked-primary-light > li::before {
    background-color: #687ded;
    color: #fff;
}

.LandingTheme .hr-sm {
    max-width: 5rem;
    margin-top: 0;
    border-top-width: 5px;
}

.LandingTheme strong {
    font-weight: 500;
}

.LandingTheme ol, .LandingTheme ul {
    margin-bottom: 2rem;
}

.LandingTheme a, .LandingTheme button {
    outline: 0 !important;
}

.LandingTheme .bg-dark-50 {
    background-color: rgba(27, 22, 66, 0.5) !important;
}

.LandingTheme .bg-gray-900 {
    background-color: #2b265a !important;
}

.LandingTheme .bg-gray-800 {
    background-color: #342e6c !important;
}

.LandingTheme .bg-gray-200 {
    background-color: #eaf2fe !important;
}

.LandingTheme .bg-cover {
    background: no-repeat center center/cover;
}

.LandingTheme .bg-gradient-light {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(243, 248, 255, 0)), to(#f3f8ff));
    background-image: -o-linear-gradient(top, rgba(243, 248, 255, 0), #f3f8ff);
    background-image: linear-gradient(to bottom, rgba(243, 248, 255, 0), #f3f8ff);
}

.LandingTheme .bg-light-boxed-right {
    background: no-repeat left center -webkit-gradient(linear, left top, right top, from(#f3f8ff));
    background: no-repeat left center -o-linear-gradient(left, #f3f8ff);
    background: no-repeat left center linear-gradient(to right, #f3f8ff);
}

@media (min-width: 1200px) {
    .LandingTheme .bg-light-boxed-right {
        background-size: calc(1140px + (100vw - 1140px)/ 2) 100%;
    }
}

.LandingTheme .bg-checkered {
    background-repeat: no-repeat,repeat,repeat;
    background-image: -o-radial-gradient(transparent, transparent 50%, #fff), -o-linear-gradient(left, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), -o-linear-gradient(top, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
    background-image: radial-gradient(transparent, transparent 50%, #fff), linear-gradient(to right, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), linear-gradient(to bottom, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
    background-size: 100% 100%, calc(32px + 1px) calc(32px + 1px), calc(32px + 1px) calc(32px + 1px);
}

.LandingTheme .border-2 {
    border-width: 2px !important;
}

.LandingTheme .border-top-2 {
    border-top-width: 2px !important;
}

.LandingTheme .border-right-2 {
    border-right-width: 2px !important;
}

.LandingTheme .border-bottom-2 {
    border-bottom-width: 2px !important;
}

.LandingTheme .border-left-2 {
    border-left-width: 2px !important;
}

.LandingTheme .border-3 {
    border-width: 3px !important;
}

.LandingTheme .border-top-3 {
    border-top-width: 3px !important;
}

.LandingTheme .border-right-3 {
    border-right-width: 3px !important;
}

.LandingTheme .border-bottom-3 {
    border-bottom-width: 3px !important;
}

.LandingTheme .border-left-3 {
    border-left-width: 3px !important;
}

.LandingTheme .border-4 {
    border-width: 4px !important;
}

.LandingTheme .border-top-4 {
    border-top-width: 4px !important;
}

.LandingTheme .border-right-4 {
    border-right-width: 4px !important;
}

.LandingTheme .border-bottom-4 {
    border-bottom-width: 4px !important;
}

.LandingTheme .border-left-4 {
    border-left-width: 4px !important;
}

.LandingTheme .border-5 {
    border-width: 5px !important;
}

.LandingTheme .border-top-5 {
    border-top-width: 5px !important;
}

.LandingTheme .border-right-5 {
    border-right-width: 5px !important;
}

.LandingTheme .border-bottom-5 {
    border-bottom-width: 5px !important;
}

.LandingTheme .border-left-5 {
    border-left-width: 5px !important;
}

.LandingTheme .border-6 {
    border-width: 6px !important;
}

.LandingTheme .border-top-6 {
    border-top-width: 6px !important;
}

.LandingTheme .border-right-6 {
    border-right-width: 6px !important;
}

.LandingTheme .border-bottom-6 {
    border-bottom-width: 6px !important;
}

.LandingTheme .border-left-6 {
    border-left-width: 6px !important;
}

.LandingTheme .border-7 {
    border-width: 7px !important;
}

.LandingTheme .border-top-7 {
    border-top-width: 7px !important;
}

.LandingTheme .border-right-7 {
    border-right-width: 7px !important;
}

.LandingTheme .border-bottom-7 {
    border-bottom-width: 7px !important;
}

.LandingTheme .border-left-7 {
    border-left-width: 7px !important;
}

@media (min-width: 576px) {
    .LandingTheme .border-sm-0 {
        border-width: 0 !important;
    }
    .LandingTheme .border-top-sm-0 {
        border-top-width: 0 !important;
    }
    .LandingTheme .border-right-sm-0 {
        border-right-width: 0 !important;
    }
    .LandingTheme .border-bottom-sm-0 {
        border-bottom-width: 0 !important;
    }
    .LandingTheme .border-left-sm-0 {
        border-left-width: 0 !important;
    }
    .LandingTheme .border-sm {
        border: 1px solid #eaf2fe;
    }
    .LandingTheme .border-top-sm {
        border-top: 1px solid #eaf2fe;
    }
    .LandingTheme .border-right-sm {
        border-right: 1px solid #eaf2fe;
    }
    .LandingTheme .border-bottom-sm {
        border-bottom: 1px solid #eaf2fe;
    }
    .LandingTheme .border-left-sm {
        border-left: 1px solid #eaf2fe;
    }
    .LandingTheme .border-sm-2 {
        border-width: 2px !important;
    }
    .LandingTheme .border-top-sm-2 {
        border-top-width: 2px !important;
    }
    .LandingTheme .border-right-sm-2 {
        border-right-width: 2px !important;
    }
    .LandingTheme .border-bottom-sm-2 {
        border-bottom-width: 2px !important;
    }
    .LandingTheme .border-left-sm-2 {
        border-left-width: 2px !important;
    }
    .LandingTheme .border-sm-3 {
        border-width: 3px !important;
    }
    .LandingTheme .border-top-sm-3 {
        border-top-width: 3px !important;
    }
    .LandingTheme .border-right-sm-3 {
        border-right-width: 3px !important;
    }
    .LandingTheme .border-bottom-sm-3 {
        border-bottom-width: 3px !important;
    }
    .LandingTheme .border-left-sm-3 {
        border-left-width: 3px !important;
    }
    .LandingTheme .border-sm-4 {
        border-width: 4px !important;
    }
    .LandingTheme .border-top-sm-4 {
        border-top-width: 4px !important;
    }
    .LandingTheme .border-right-sm-4 {
        border-right-width: 4px !important;
    }
    .LandingTheme .border-bottom-sm-4 {
        border-bottom-width: 4px !important;
    }
    .LandingTheme .border-left-sm-4 {
        border-left-width: 4px !important;
    }
    .LandingTheme .border-sm-5 {
        border-width: 5px !important;
    }
    .LandingTheme .border-top-sm-5 {
        border-top-width: 5px !important;
    }
    .LandingTheme .border-right-sm-5 {
        border-right-width: 5px !important;
    }
    .LandingTheme .border-bottom-sm-5 {
        border-bottom-width: 5px !important;
    }
    .LandingTheme .border-left-sm-5 {
        border-left-width: 5px !important;
    }
    .LandingTheme .border-sm-6 {
        border-width: 6px !important;
    }
    .LandingTheme .border-top-sm-6 {
        border-top-width: 6px !important;
    }
    .LandingTheme .border-right-sm-6 {
        border-right-width: 6px !important;
    }
    .LandingTheme .border-bottom-sm-6 {
        border-bottom-width: 6px !important;
    }
    .LandingTheme .border-left-sm-6 {
        border-left-width: 6px !important;
    }
    .LandingTheme .border-sm-7 {
        border-width: 7px !important;
    }
    .LandingTheme .border-top-sm-7 {
        border-top-width: 7px !important;
    }
    .LandingTheme .border-right-sm-7 {
        border-right-width: 7px !important;
    }
    .LandingTheme .border-bottom-sm-7 {
        border-bottom-width: 7px !important;
    }
    .LandingTheme .border-left-sm-7 {
        border-left-width: 7px !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .border-md-0 {
        border-width: 0 !important;
    }
    .LandingTheme .border-top-md-0 {
        border-top-width: 0 !important;
    }
    .LandingTheme .border-right-md-0 {
        border-right-width: 0 !important;
    }
    .LandingTheme .border-bottom-md-0 {
        border-bottom-width: 0 !important;
    }
    .LandingTheme .border-left-md-0 {
        border-left-width: 0 !important;
    }
    .LandingTheme .border-md {
        border: 1px solid #eaf2fe;
    }
    .LandingTheme .border-top-md {
        border-top: 1px solid #eaf2fe;
    }
    .LandingTheme .border-right-md {
        border-right: 1px solid #eaf2fe;
    }
    .LandingTheme .border-bottom-md {
        border-bottom: 1px solid #eaf2fe;
    }
    .LandingTheme .border-left-md {
        border-left: 1px solid #eaf2fe;
    }
    .LandingTheme .border-md-2 {
        border-width: 2px !important;
    }
    .LandingTheme .border-top-md-2 {
        border-top-width: 2px !important;
    }
    .LandingTheme .border-right-md-2 {
        border-right-width: 2px !important;
    }
    .LandingTheme .border-bottom-md-2 {
        border-bottom-width: 2px !important;
    }
    .LandingTheme .border-left-md-2 {
        border-left-width: 2px !important;
    }
    .LandingTheme .border-md-3 {
        border-width: 3px !important;
    }
    .LandingTheme .border-top-md-3 {
        border-top-width: 3px !important;
    }
    .LandingTheme .border-right-md-3 {
        border-right-width: 3px !important;
    }
    .LandingTheme .border-bottom-md-3 {
        border-bottom-width: 3px !important;
    }
    .LandingTheme .border-left-md-3 {
        border-left-width: 3px !important;
    }
    .LandingTheme .border-md-4 {
        border-width: 4px !important;
    }
    .LandingTheme .border-top-md-4 {
        border-top-width: 4px !important;
    }
    .LandingTheme .border-right-md-4 {
        border-right-width: 4px !important;
    }
    .LandingTheme .border-bottom-md-4 {
        border-bottom-width: 4px !important;
    }
    .LandingTheme .border-left-md-4 {
        border-left-width: 4px !important;
    }
    .LandingTheme .border-md-5 {
        border-width: 5px !important;
    }
    .LandingTheme .border-top-md-5 {
        border-top-width: 5px !important;
    }
    .LandingTheme .border-right-md-5 {
        border-right-width: 5px !important;
    }
    .LandingTheme .border-bottom-md-5 {
        border-bottom-width: 5px !important;
    }
    .LandingTheme .border-left-md-5 {
        border-left-width: 5px !important;
    }
    .LandingTheme .border-md-6 {
        border-width: 6px !important;
    }
    .LandingTheme .border-top-md-6 {
        border-top-width: 6px !important;
    }
    .LandingTheme .border-right-md-6 {
        border-right-width: 6px !important;
    }
    .LandingTheme .border-bottom-md-6 {
        border-bottom-width: 6px !important;
    }
    .LandingTheme .border-left-md-6 {
        border-left-width: 6px !important;
    }
    .LandingTheme .border-md-7 {
        border-width: 7px !important;
    }
    .LandingTheme .border-top-md-7 {
        border-top-width: 7px !important;
    }
    .LandingTheme .border-right-md-7 {
        border-right-width: 7px !important;
    }
    .LandingTheme .border-bottom-md-7 {
        border-bottom-width: 7px !important;
    }
    .LandingTheme .border-left-md-7 {
        border-left-width: 7px !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .border-lg-0 {
        border-width: 0 !important;
    }
    .LandingTheme .border-top-lg-0 {
        border-top-width: 0 !important;
    }
    .LandingTheme .border-right-lg-0 {
        border-right-width: 0 !important;
    }
    .LandingTheme .border-bottom-lg-0 {
        border-bottom-width: 0 !important;
    }
    .LandingTheme .border-left-lg-0 {
        border-left-width: 0 !important;
    }
    .LandingTheme .border-lg {
        border: 1px solid #eaf2fe;
    }
    .LandingTheme .border-top-lg {
        border-top: 1px solid #eaf2fe;
    }
    .LandingTheme .border-right-lg {
        border-right: 1px solid #eaf2fe;
    }
    .LandingTheme .border-bottom-lg {
        border-bottom: 1px solid #eaf2fe;
    }
    .LandingTheme .border-left-lg {
        border-left: 1px solid #eaf2fe;
    }
    .LandingTheme .border-lg-2 {
        border-width: 2px !important;
    }
    .LandingTheme .border-top-lg-2 {
        border-top-width: 2px !important;
    }
    .LandingTheme .border-right-lg-2 {
        border-right-width: 2px !important;
    }
    .LandingTheme .border-bottom-lg-2 {
        border-bottom-width: 2px !important;
    }
    .LandingTheme .border-left-lg-2 {
        border-left-width: 2px !important;
    }
    .LandingTheme .border-lg-3 {
        border-width: 3px !important;
    }
    .LandingTheme .border-top-lg-3 {
        border-top-width: 3px !important;
    }
    .LandingTheme .border-right-lg-3 {
        border-right-width: 3px !important;
    }
    .LandingTheme .border-bottom-lg-3 {
        border-bottom-width: 3px !important;
    }
    .LandingTheme .border-left-lg-3 {
        border-left-width: 3px !important;
    }
    .LandingTheme .border-lg-4 {
        border-width: 4px !important;
    }
    .LandingTheme .border-top-lg-4 {
        border-top-width: 4px !important;
    }
    .LandingTheme .border-right-lg-4 {
        border-right-width: 4px !important;
    }
    .LandingTheme .border-bottom-lg-4 {
        border-bottom-width: 4px !important;
    }
    .LandingTheme .border-left-lg-4 {
        border-left-width: 4px !important;
    }
    .LandingTheme .border-lg-5 {
        border-width: 5px !important;
    }
    .LandingTheme .border-top-lg-5 {
        border-top-width: 5px !important;
    }
    .LandingTheme .border-right-lg-5 {
        border-right-width: 5px !important;
    }
    .LandingTheme .border-bottom-lg-5 {
        border-bottom-width: 5px !important;
    }
    .LandingTheme .border-left-lg-5 {
        border-left-width: 5px !important;
    }
    .LandingTheme .border-lg-6 {
        border-width: 6px !important;
    }
    .LandingTheme .border-top-lg-6 {
        border-top-width: 6px !important;
    }
    .LandingTheme .border-right-lg-6 {
        border-right-width: 6px !important;
    }
    .LandingTheme .border-bottom-lg-6 {
        border-bottom-width: 6px !important;
    }
    .LandingTheme .border-left-lg-6 {
        border-left-width: 6px !important;
    }
    .LandingTheme .border-lg-7 {
        border-width: 7px !important;
    }
    .LandingTheme .border-top-lg-7 {
        border-top-width: 7px !important;
    }
    .LandingTheme .border-right-lg-7 {
        border-right-width: 7px !important;
    }
    .LandingTheme .border-bottom-lg-7 {
        border-bottom-width: 7px !important;
    }
    .LandingTheme .border-left-lg-7 {
        border-left-width: 7px !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .border-xl-0 {
        border-width: 0 !important;
    }
    .LandingTheme .border-top-xl-0 {
        border-top-width: 0 !important;
    }
    .LandingTheme .border-right-xl-0 {
        border-right-width: 0 !important;
    }
    .LandingTheme .border-bottom-xl-0 {
        border-bottom-width: 0 !important;
    }
    .LandingTheme .border-left-xl-0 {
        border-left-width: 0 !important;
    }
    .LandingTheme .border-xl {
        border: 1px solid #eaf2fe;
    }
    .LandingTheme .border-top-xl {
        border-top: 1px solid #eaf2fe;
    }
    .LandingTheme .border-right-xl {
        border-right: 1px solid #eaf2fe;
    }
    .LandingTheme .border-bottom-xl {
        border-bottom: 1px solid #eaf2fe;
    }
    .LandingTheme .border-left-xl {
        border-left: 1px solid #eaf2fe;
    }
    .LandingTheme .border-xl-2 {
        border-width: 2px !important;
    }
    .LandingTheme .border-top-xl-2 {
        border-top-width: 2px !important;
    }
    .LandingTheme .border-right-xl-2 {
        border-right-width: 2px !important;
    }
    .LandingTheme .border-bottom-xl-2 {
        border-bottom-width: 2px !important;
    }
    .LandingTheme .border-left-xl-2 {
        border-left-width: 2px !important;
    }
    .LandingTheme .border-xl-3 {
        border-width: 3px !important;
    }
    .LandingTheme .border-top-xl-3 {
        border-top-width: 3px !important;
    }
    .LandingTheme .border-right-xl-3 {
        border-right-width: 3px !important;
    }
    .LandingTheme .border-bottom-xl-3 {
        border-bottom-width: 3px !important;
    }
    .LandingTheme .border-left-xl-3 {
        border-left-width: 3px !important;
    }
    .LandingTheme .border-xl-4 {
        border-width: 4px !important;
    }
    .LandingTheme .border-top-xl-4 {
        border-top-width: 4px !important;
    }
    .LandingTheme .border-right-xl-4 {
        border-right-width: 4px !important;
    }
    .LandingTheme .border-bottom-xl-4 {
        border-bottom-width: 4px !important;
    }
    .LandingTheme .border-left-xl-4 {
        border-left-width: 4px !important;
    }
    .LandingTheme .border-xl-5 {
        border-width: 5px !important;
    }
    .LandingTheme .border-top-xl-5 {
        border-top-width: 5px !important;
    }
    .LandingTheme .border-right-xl-5 {
        border-right-width: 5px !important;
    }
    .LandingTheme .border-bottom-xl-5 {
        border-bottom-width: 5px !important;
    }
    .LandingTheme .border-left-xl-5 {
        border-left-width: 5px !important;
    }
    .LandingTheme .border-xl-6 {
        border-width: 6px !important;
    }
    .LandingTheme .border-top-xl-6 {
        border-top-width: 6px !important;
    }
    .LandingTheme .border-right-xl-6 {
        border-right-width: 6px !important;
    }
    .LandingTheme .border-bottom-xl-6 {
        border-bottom-width: 6px !important;
    }
    .LandingTheme .border-left-xl-6 {
        border-left-width: 6px !important;
    }
    .LandingTheme .border-xl-7 {
        border-width: 7px !important;
    }
    .LandingTheme .border-top-xl-7 {
        border-top-width: 7px !important;
    }
    .LandingTheme .border-right-xl-7 {
        border-right-width: 7px !important;
    }
    .LandingTheme .border-bottom-xl-7 {
        border-bottom-width: 7px !important;
    }
    .LandingTheme .border-left-xl-7 {
        border-left-width: 7px !important;
    }
}

.LandingTheme .border-transparent {
    border-color: transparent !important;
}

.LandingTheme .border-white-5 {
    border-color: rgba(255, 255, 255, 0.05) !important;
}

.LandingTheme .border-gray-300 {
    border-color: #dce0f3 !important;
}

.LandingTheme .border-gray-900 {
    border-color: #2b265a !important;
}

.LandingTheme .border-multicolor {
    border-width: 0;
    border-top: 4px solid;
    -o-border-image: -o-linear-gradient(left, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1;
    border-image: -webkit-gradient(linear, left top, right top, from(#575a7b), color-stop(20%, #575a7b), color-stop(20%, #f9655b), color-stop(40%, #f9655b), color-stop(40%, #f5c070), color-stop(60%, #f5c070), color-stop(60%, #6658ea), color-stop(80%, #6658ea), color-stop(80%, #fcc)) 1;
    border-image: linear-gradient(to right, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1;
}

.LandingTheme .rounded-inherit {
    border-radius: inherit !important;
}

.LandingTheme .rounded-top-right {
    border-top-right-radius: 1rem !important;
}

.LandingTheme .rounded-bottom-right {
    border-bottom-right-radius: 1rem !important;
}

.LandingTheme .rounded-bottom-left {
    border-bottom-left-radius: 1rem !important;
}

.LandingTheme .rounded-top-left {
    border-top-left-radius: 1rem !important;
}

.LandingTheme .rounded-top-right-lg {
    border-top-right-radius: 2rem !important;
}

.LandingTheme .rounded-bottom-right-lg {
    border-bottom-right-radius: 2rem !important;
}

.LandingTheme .rounded-bottom-left-lg {
    border-bottom-left-radius: 2rem !important;
}

.LandingTheme .rounded-top-left-lg {
    border-top-left-radius: 2rem !important;
}

.LandingTheme .rounded-top-right-sm {
    border-top-right-radius: .5rem !important;
}

.LandingTheme .rounded-bottom-right-sm {
    border-bottom-right-radius: .5rem !important;
}

.LandingTheme .rounded-bottom-left-sm {
    border-bottom-left-radius: .5rem !important;
}

.LandingTheme .rounded-top-left-sm {
    border-top-left-radius: .5rem !important;
}

.LandingTheme .rounded-top-right-0 {
    border-top-right-radius: 0 !important;
}

.LandingTheme .rounded-bottom-right-0 {
    border-bottom-right-radius: 0 !important;
}

.LandingTheme .rounded-bottom-left-0 {
    border-bottom-left-radius: 0 !important;
}

.LandingTheme .rounded-top-left-0 {
    border-top-left-radius: 0 !important;
}

.LandingTheme .border-stripe {
    border-color: #6772e5 !important;
}

.LandingTheme .border-digital-ocean {
    border-color: #2485fd !important;
}

.LandingTheme .border-airbnb {
    border-color: #ff385c !important;
}

.LandingTheme .border-coinbase {
    border-color: #0081c9 !important;
}

.LandingTheme .border-netflix {
    border-color: #b81d24 !important;
}

.LandingTheme .border-dribbble {
    border-color: #da5988 !important;
}

.LandingTheme .border-pinterest {
    border-color: #cb2027 !important;
}

.LandingTheme .border-instagram {
    border-color: #3f5d87 !important;
}

.LandingTheme .lift {
    -webkit-transition: -webkit-box-shadow .25s ease,-webkit-transform .25s ease;
    transition: -webkit-box-shadow .25s ease,-webkit-transform .25s ease;
    -o-transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-box-shadow .25s ease,-webkit-transform .25s ease;
}

.LandingTheme .lift:focus, .LandingTheme .lift:hover {
    -webkit-box-shadow: 0 1rem 2.5rem rgba(27, 22, 66, 0.1), 0 0.5rem 1rem -0.75rem rgba(27, 22, 66, 0.1) !important;
    box-shadow: 0 1rem 2.5rem rgba(27, 22, 66, 0.1), 0 0.5rem 1rem -0.75rem rgba(27, 22, 66, 0.1) !important;
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
}

.LandingTheme .lift-lg:focus, .LandingTheme .lift-lg:hover {
    -webkit-box-shadow: 0 1rem 5rem rgba(27, 22, 66, 0.1), 0 2rem 3rem -1rem rgba(27, 22, 66, 0.05) !important;
    box-shadow: 0 1rem 5rem rgba(27, 22, 66, 0.1), 0 2rem 3rem -1rem rgba(27, 22, 66, 0.05) !important;
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
}

.LandingTheme .top-left {
    top: 0 !important;
    left: 0 !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.LandingTheme .top-right {
    top: 0 !important;
    right: 0 !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.LandingTheme .top-center {
    top: 0 !important;
    left: 50% !important;
    -webkit-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
}

.LandingTheme .bottom-right {
    right: 0 !important;
    bottom: 0 !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.LandingTheme .bottom-left {
    bottom: 0 !important;
    left: 0 !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.LandingTheme .bottom-center {
    bottom: 0 !important;
    left: 50% !important;
    -webkit-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
}

@media (min-width: 576px) {
    .LandingTheme .top-sm-left {
        top: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-sm-right {
        top: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-sm-center {
        top: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
    .LandingTheme .bottom-sm-left {
        bottom: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-sm-right {
        bottom: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-sm-center {
        bottom: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
}

@media (min-width: 768px) {
    .LandingTheme .top-md-left {
        top: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-md-right {
        top: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-md-center {
        top: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
    .LandingTheme .bottom-md-left {
        bottom: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-md-right {
        bottom: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-md-center {
        bottom: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
}

@media (min-width: 992px) {
    .LandingTheme .top-lg-left {
        top: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-lg-right {
        top: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-lg-center {
        top: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
    .LandingTheme .bottom-lg-left {
        bottom: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-lg-right {
        bottom: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-lg-center {
        bottom: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .top-xl-left {
        top: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-xl-right {
        top: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .top-xl-center {
        top: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
    .LandingTheme .bottom-xl-left {
        bottom: 0 !important;
        left: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-xl-right {
        bottom: 0 !important;
        right: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }
    .LandingTheme .bottom-xl-center {
        bottom: 0 !important;
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
}

.LandingTheme .shadow-img {
    -webkit-box-shadow: 2rem 2rem #eaf2fe !important;
    box-shadow: 2rem 2rem #eaf2fe !important;
}

.LandingTheme .mvw-100 {
    max-width: 100vw !important;
}

.LandingTheme .mw-25 {
    max-width: 25% !important;
}

.LandingTheme .vh-25 {
    height: 25vh !important;
}

.LandingTheme .vw-25 {
    width: 25vw !important;
}

.LandingTheme .mw-50 {
    max-width: 50% !important;
}

.LandingTheme .vh-50 {
    height: 50vh !important;
}

.LandingTheme .vw-50 {
    width: 50vw !important;
}

.LandingTheme .mw-75 {
    max-width: 75% !important;
}

.LandingTheme .vh-75 {
    height: 75vh !important;
}

.LandingTheme .vw-75 {
    width: 75vw !important;
}

.LandingTheme .mw-100 {
    max-width: 100% !important;
}

.LandingTheme .vh-100 {
    height: 100vh !important;
}

.LandingTheme .vw-100 {
    width: 100vw !important;
}

.LandingTheme .mw-auto {
    max-width: auto !important;
}

.LandingTheme .mw-125 {
    max-width: 125% !important;
}

.LandingTheme .vh-125 {
    height: 125vh !important;
}

.LandingTheme .vw-125 {
    width: 125vw !important;
}

.LandingTheme .mw-150 {
    max-width: 150% !important;
}

.LandingTheme .vh-150 {
    height: 150vh !important;
}

.LandingTheme .vw-150 {
    width: 150vw !important;
}

@media (min-width: 576px) {
    .LandingTheme .h-sm-25 {
        height: 25% !important;
    }
    .LandingTheme .w-sm-25 {
        width: 25% !important;
    }
    .LandingTheme .mw-sm-25 {
        max-width: 25% !important;
    }
    .LandingTheme .vh-sm-25 {
        height: 25vh;
    }
    .LandingTheme .vw-sm-25 {
        width: 25vw;
    }
    .LandingTheme .h-sm-50 {
        height: 50% !important;
    }
    .LandingTheme .w-sm-50 {
        width: 50% !important;
    }
    .LandingTheme .mw-sm-50 {
        max-width: 50% !important;
    }
    .LandingTheme .vh-sm-50 {
        height: 50vh;
    }
    .LandingTheme .vw-sm-50 {
        width: 50vw;
    }
    .LandingTheme .h-sm-75 {
        height: 75% !important;
    }
    .LandingTheme .w-sm-75 {
        width: 75% !important;
    }
    .LandingTheme .mw-sm-75 {
        max-width: 75% !important;
    }
    .LandingTheme .vh-sm-75 {
        height: 75vh;
    }
    .LandingTheme .vw-sm-75 {
        width: 75vw;
    }
    .LandingTheme .h-sm-100 {
        height: 100% !important;
    }
    .LandingTheme .w-sm-100 {
        width: 100% !important;
    }
    .LandingTheme .mw-sm-100 {
        max-width: 100% !important;
    }
    .LandingTheme .vh-sm-100 {
        height: 100vh;
    }
    .LandingTheme .vw-sm-100 {
        width: 100vw;
    }
    .LandingTheme .h-sm-auto {
        height: auto !important;
    }
    .LandingTheme .w-sm-auto {
        width: auto !important;
    }
    .LandingTheme .mw-sm-auto {
        max-width: auto !important;
    }
    .LandingTheme .h-sm-125 {
        height: 125% !important;
    }
    .LandingTheme .w-sm-125 {
        width: 125% !important;
    }
    .LandingTheme .mw-sm-125 {
        max-width: 125% !important;
    }
    .LandingTheme .vh-sm-125 {
        height: 125vh;
    }
    .LandingTheme .vw-sm-125 {
        width: 125vw;
    }
    .LandingTheme .h-sm-150 {
        height: 150% !important;
    }
    .LandingTheme .w-sm-150 {
        width: 150% !important;
    }
    .LandingTheme .mw-sm-150 {
        max-width: 150% !important;
    }
    .LandingTheme .vh-sm-150 {
        height: 150vh;
    }
    .LandingTheme .vw-sm-150 {
        width: 150vw;
    }
}

@media (min-width: 768px) {
    .LandingTheme .h-md-25 {
        height: 25% !important;
    }
    .LandingTheme .w-md-25 {
        width: 25% !important;
    }
    .LandingTheme .mw-md-25 {
        max-width: 25% !important;
    }
    .LandingTheme .vh-md-25 {
        height: 25vh;
    }
    .LandingTheme .vw-md-25 {
        width: 25vw;
    }
    .LandingTheme .h-md-50 {
        height: 50% !important;
    }
    .LandingTheme .w-md-50 {
        width: 50% !important;
    }
    .LandingTheme .mw-md-50 {
        max-width: 50% !important;
    }
    .LandingTheme .vh-md-50 {
        height: 50vh;
    }
    .LandingTheme .vw-md-50 {
        width: 50vw;
    }
    .LandingTheme .h-md-75 {
        height: 75% !important;
    }
    .LandingTheme .w-md-75 {
        width: 75% !important;
    }
    .LandingTheme .mw-md-75 {
        max-width: 75% !important;
    }
    .LandingTheme .vh-md-75 {
        height: 75vh;
    }
    .LandingTheme .vw-md-75 {
        width: 75vw;
    }
    .LandingTheme .h-md-100 {
        height: 100% !important;
    }
    .LandingTheme .w-md-100 {
        width: 100% !important;
    }
    .LandingTheme .mw-md-100 {
        max-width: 100% !important;
    }
    .LandingTheme .vh-md-100 {
        height: 100vh;
    }
    .LandingTheme .vw-md-100 {
        width: 100vw;
    }
    .LandingTheme .h-md-auto {
        height: auto !important;
    }
    .LandingTheme .w-md-auto {
        width: auto !important;
    }
    .LandingTheme .mw-md-auto {
        max-width: auto !important;
    }
    .LandingTheme .h-md-125 {
        height: 125% !important;
    }
    .LandingTheme .w-md-125 {
        width: 125% !important;
    }
    .LandingTheme .mw-md-125 {
        max-width: 125% !important;
    }
    .LandingTheme .vh-md-125 {
        height: 125vh;
    }
    .LandingTheme .vw-md-125 {
        width: 125vw;
    }
    .LandingTheme .h-md-150 {
        height: 150% !important;
    }
    .LandingTheme .w-md-150 {
        width: 150% !important;
    }
    .LandingTheme .mw-md-150 {
        max-width: 150% !important;
    }
    .LandingTheme .vh-md-150 {
        height: 150vh;
    }
    .LandingTheme .vw-md-150 {
        width: 150vw;
    }
}

@media (min-width: 992px) {
    .LandingTheme .h-lg-25 {
        height: 25% !important;
    }
    .LandingTheme .w-lg-25 {
        width: 25% !important;
    }
    .LandingTheme .mw-lg-25 {
        max-width: 25% !important;
    }
    .LandingTheme .vh-lg-25 {
        height: 25vh;
    }
    .LandingTheme .vw-lg-25 {
        width: 25vw;
    }
    .LandingTheme .h-lg-50 {
        height: 50% !important;
    }
    .LandingTheme .w-lg-50 {
        width: 50% !important;
    }
    .LandingTheme .mw-lg-50 {
        max-width: 50% !important;
    }
    .LandingTheme .vh-lg-50 {
        height: 50vh;
    }
    .LandingTheme .vw-lg-50 {
        width: 50vw;
    }
    .LandingTheme .h-lg-75 {
        height: 75% !important;
    }
    .LandingTheme .w-lg-75 {
        width: 75% !important;
    }
    .LandingTheme .mw-lg-75 {
        max-width: 75% !important;
    }
    .LandingTheme .vh-lg-75 {
        height: 75vh;
    }
    .LandingTheme .vw-lg-75 {
        width: 75vw;
    }
    .LandingTheme .h-lg-100 {
        height: 100% !important;
    }
    .LandingTheme .w-lg-100 {
        width: 100% !important;
    }
    .LandingTheme .mw-lg-100 {
        max-width: 100% !important;
    }
    .LandingTheme .vh-lg-100 {
        height: 100vh;
    }
    .LandingTheme .vw-lg-100 {
        width: 100vw;
    }
    .LandingTheme .h-lg-auto {
        height: auto !important;
    }
    .LandingTheme .w-lg-auto {
        width: auto !important;
    }
    .LandingTheme .mw-lg-auto {
        max-width: auto !important;
    }
    .LandingTheme .h-lg-125 {
        height: 125% !important;
    }
    .LandingTheme .w-lg-125 {
        width: 125% !important;
    }
    .LandingTheme .mw-lg-125 {
        max-width: 125% !important;
    }
    .LandingTheme .vh-lg-125 {
        height: 125vh;
    }
    .LandingTheme .vw-lg-125 {
        width: 125vw;
    }
    .LandingTheme .h-lg-150 {
        height: 150% !important;
    }
    .LandingTheme .w-lg-150 {
        width: 150% !important;
    }
    .LandingTheme .mw-lg-150 {
        max-width: 150% !important;
    }
    .LandingTheme .vh-lg-150 {
        height: 150vh;
    }
    .LandingTheme .vw-lg-150 {
        width: 150vw;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .h-xl-25 {
        height: 25% !important;
    }
    .LandingTheme .w-xl-25 {
        width: 25% !important;
    }
    .LandingTheme .mw-xl-25 {
        max-width: 25% !important;
    }
    .LandingTheme .vh-xl-25 {
        height: 25vh;
    }
    .LandingTheme .vw-xl-25 {
        width: 25vw;
    }
    .LandingTheme .h-xl-50 {
        height: 50% !important;
    }
    .LandingTheme .w-xl-50 {
        width: 50% !important;
    }
    .LandingTheme .mw-xl-50 {
        max-width: 50% !important;
    }
    .LandingTheme .vh-xl-50 {
        height: 50vh;
    }
    .LandingTheme .vw-xl-50 {
        width: 50vw;
    }
    .LandingTheme .h-xl-75 {
        height: 75% !important;
    }
    .LandingTheme .w-xl-75 {
        width: 75% !important;
    }
    .LandingTheme .mw-xl-75 {
        max-width: 75% !important;
    }
    .LandingTheme .vh-xl-75 {
        height: 75vh;
    }
    .LandingTheme .vw-xl-75 {
        width: 75vw;
    }
    .LandingTheme .h-xl-100 {
        height: 100% !important;
    }
    .LandingTheme .w-xl-100 {
        width: 100% !important;
    }
    .LandingTheme .mw-xl-100 {
        max-width: 100% !important;
    }
    .LandingTheme .vh-xl-100 {
        height: 100vh;
    }
    .LandingTheme .vw-xl-100 {
        width: 100vw;
    }
    .LandingTheme .h-xl-auto {
        height: auto !important;
    }
    .LandingTheme .w-xl-auto {
        width: auto !important;
    }
    .LandingTheme .mw-xl-auto {
        max-width: auto !important;
    }
    .LandingTheme .h-xl-125 {
        height: 125% !important;
    }
    .LandingTheme .w-xl-125 {
        width: 125% !important;
    }
    .LandingTheme .mw-xl-125 {
        max-width: 125% !important;
    }
    .LandingTheme .vh-xl-125 {
        height: 125vh;
    }
    .LandingTheme .vw-xl-125 {
        width: 125vw;
    }
    .LandingTheme .h-xl-150 {
        height: 150% !important;
    }
    .LandingTheme .w-xl-150 {
        width: 150% !important;
    }
    .LandingTheme .mw-xl-150 {
        max-width: 150% !important;
    }
    .LandingTheme .vh-xl-150 {
        height: 150vh;
    }
    .LandingTheme .vw-xl-150 {
        width: 150vw;
    }
}

.LandingTheme .font-family-sans-serif {
    font-family: "HKGroteskPro",sans-serif !important;
}

.LandingTheme .font-family-serif {
    font-family: "DM Serif Display",serif !important;
}

.LandingTheme .font-size-h2 {
    font-size: 1.75rem !important;
}

.LandingTheme .font-size-h3 {
    font-size: 1.25rem !important;
}

.LandingTheme .font-size-lg {
    font-size: 1.25rem !important;
}

.LandingTheme .font-size-sm {
    font-size: 1rem !important;
}

.LandingTheme .font-size-xs {
    font-size: .875rem !important;
}

.LandingTheme .font-size-xxs {
    font-size: .75rem !important;
}

.LandingTheme .text-white-20 {
    color: rgba(255, 255, 255, 0.2) !important;
}

.LandingTheme .text-white-60 {
    color: rgba(255, 255, 255, 0.6) !important;
}

.LandingTheme a.text-white-60:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}

.LandingTheme .text-white-80 {
    color: rgba(255, 255, 255, 0.8) !important;
}

.LandingTheme .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.LandingTheme .text-gray-300 {
    color: #dce0f3 !important;
}

.LandingTheme .text-gray-500 {
    color: #8d9aae !important;
}

.LandingTheme .text-gray-600 {
    color: #575a7b !important;
}

.LandingTheme .text-gray-800 {
    color: #342e6c !important;
}

.LandingTheme [class*=text-underline-] {
    background: repeat-x left 1em/1em .15em;
}

.LandingTheme .text-underline-primary {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(102, 88, 234, 0.2)));
    background-image: -o-linear-gradient(left, rgba(102, 88, 234, 0.2));
    background-image: linear-gradient(to right, rgba(102, 88, 234, 0.2));
}

.LandingTheme .text-underline-secondary {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(87, 90, 123, 0.2)));
    background-image: -o-linear-gradient(left, rgba(87, 90, 123, 0.2));
    background-image: linear-gradient(to right, rgba(87, 90, 123, 0.2));
}

.LandingTheme .text-underline-success {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(44, 212, 162, 0.2)));
    background-image: -o-linear-gradient(left, rgba(44, 212, 162, 0.2));
    background-image: linear-gradient(to right, rgba(44, 212, 162, 0.2));
}

.LandingTheme .text-underline-info {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(23, 162, 184, 0.2)));
    background-image: -o-linear-gradient(left, rgba(23, 162, 184, 0.2));
    background-image: linear-gradient(to right, rgba(23, 162, 184, 0.2));
}

.LandingTheme .text-underline-warning {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(245, 192, 112, 0.4)));
    background-image: -o-linear-gradient(left, rgba(245, 192, 112, 0.4));
    background-image: linear-gradient(to right, rgba(245, 192, 112, 0.4));
}

.LandingTheme .text-underline-danger {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(249, 101, 91, 0.2)));
    background-image: -o-linear-gradient(left, rgba(249, 101, 91, 0.2));
    background-image: linear-gradient(to right, rgba(249, 101, 91, 0.2));
}

.LandingTheme .text-underline-light {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(243, 248, 255, 0.2)));
    background-image: -o-linear-gradient(left, rgba(243, 248, 255, 0.2));
    background-image: linear-gradient(to right, rgba(243, 248, 255, 0.2));
}

.LandingTheme .text-underline-dark {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(27, 22, 66, 0.2)));
    background-image: -o-linear-gradient(left, rgba(27, 22, 66, 0.2));
    background-image: linear-gradient(to right, rgba(27, 22, 66, 0.2));
}

.LandingTheme .text-underline-primary-dark {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(86, 76, 175, 0.2)));
    background-image: -o-linear-gradient(left, rgba(86, 76, 175, 0.2));
    background-image: linear-gradient(to right, rgba(86, 76, 175, 0.2));
}

.LandingTheme .text-underline-primary-light {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(104, 125, 237, 0.2)));
    background-image: -o-linear-gradient(left, rgba(104, 125, 237, 0.2));
    background-image: linear-gradient(to right, rgba(104, 125, 237, 0.2));
}

.LandingTheme .text-stripe {
    color: #6772e5 !important;
}

.LandingTheme .text-digital-ocean {
    color: #2485fd !important;
}

.LandingTheme .text-airbnb {
    color: #ff385c !important;
}

.LandingTheme .text-coinbase {
    color: #0081c9 !important;
}

.LandingTheme .text-netflix {
    color: #b81d24 !important;
}

.LandingTheme .text-dribbble {
    color: #da5988 !important;
}

.LandingTheme .text-pinterest {
    color: #cb2027 !important;
}

.LandingTheme .text-instagram {
    color: #3f5d87 !important;
}

.LandingTheme .line-height-reset {
    line-height: 1;
}

.LandingTheme #text-aa-sans-serif, .LandingTheme #text-aa-serif {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.LandingTheme #text-aa-serif {
    color: #fff;
}

.LandingTheme #text-aa-sans-serif {
    color: rgba(255, 255, 255, 0.2);
}

.LandingTheme .avatar {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem;
}

.LandingTheme .avatar:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg);
}

.LandingTheme .avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.LandingTheme .avatar-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #8d9aae;
    color: #fff;
}

.LandingTheme .avatar-offline::before, .LandingTheme .avatar-online::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;
}

.LandingTheme .avatar-offline .avatar-img, .LandingTheme .avatar-online .avatar-img {
    -webkit-mask-image: url(../img/masks/avatar-status.svg);
    mask-image: url(../img/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.LandingTheme .avatar-online::before {
    background-color: #2cd4a2;
}

.LandingTheme .avatar-offline::before {
    background-color: #8d9aae;
}

.LandingTheme .avatar-xs {
    width: 2.5rem;
    height: 2.5rem;
    font-size: .83333rem;
}

.LandingTheme .avatar-sm {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
}

.LandingTheme .avatar-lg {
    width: 5rem;
    height: 5rem;
    font-size: 1.66667rem;
}

.LandingTheme .avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: 2rem;
}

.LandingTheme .avatar-xxl {
    width: 6rem;
    height: 6rem;
    font-size: 2rem;
}

@media (min-width: 768px) {
    .LandingTheme .avatar-xxl {
        width: 7rem;
        height: 7rem;
        font-size: 2.33333rem;
    }
}

.LandingTheme .avatar.avatar-4by3 {
    width: 5.33333rem;
}

.LandingTheme .avatar-xs.avatar-4by3 {
    width: 3.33333rem;
}

.LandingTheme .avatar-sm.avatar-4by3 {
    width: 4rem;
}

.LandingTheme .avatar-lg.avatar-4by3 {
    width: 6.66667rem;
}

.LandingTheme .avatar-xl.avatar-4by3 {
    width: 8rem;
}

.LandingTheme .avatar-xxl.avatar-4by3 {
    width: 9.33333rem;
}

.LandingTheme .avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.LandingTheme .avatar-group .avatar + .avatar {
    margin-left: -1rem;
}

.LandingTheme .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -.625rem;
}

.LandingTheme .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -.75rem;
}

.LandingTheme .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1.25rem;
}

.LandingTheme .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1.5rem;
}

.LandingTheme .avatar-group .avatar-xxl + .avatar-xxl {
    margin-left: -1.75rem;
}

.LandingTheme .avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(../img/masks/avatar-group.svg);
    mask-image: url(../img/masks/avatar-group.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.LandingTheme .avatar-group .avatar:hover {
    -webkit-mask-image: none;
    mask-image: none;
    z-index: 1;
}

.LandingTheme .avatar-group .avatar:hover + .avatar {
    -webkit-mask-image: url(../img/masks/avatar-group-hover.svg);
    mask-image: url(../img/masks/avatar-group-hover.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.LandingTheme .avatar-group .avatar:hover + .avatar:last-child {
    -webkit-mask-image: url(../img/masks/avatar-group-hover-last.svg);
    mask-image: url(../img/masks/avatar-group-hover-last.svg);
}

.LandingTheme .docs-body {
    padding-top: 109px;
}

.LandingTheme .docs-breadcrumb {
    padding-top: .25rem;
    padding-bottom: .25rem;
    background-color: #1b1642;
}

@media (min-width: 992px) {
    .LandingTheme .docs-breadcrumb {
        display: none;
    }
}

.LandingTheme .docs-sidenav {
    overflow-y: auto;
}

@media (min-width: 992px) {
    .LandingTheme .docs-sidenav {
        position: fixed;
        top: 109px;
        max-height: calc(100vh - 109px);
    }
}

.LandingTheme .docs-sidenav-right {
    right: 0;
}

.LandingTheme .docs-card {
    border-radius: .5rem;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.LandingTheme .docs-card > :first-child {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.LandingTheme .docs-card > :last-child {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.LandingTheme .docs-card > .card-body {
    border: 1px solid #eaf2fe;
}

.LandingTheme .docs-card > .card-footer {
    font-size: .875rem;
    background-color: #1b1642;
}

.LandingTheme .icon > svg {
    width: 3rem;
    height: 3rem;
}

.LandingTheme .icon > svg [fill]:not([fill=none]) {
    fill: currentColor !important;
}

.LandingTheme .icon-sm > svg {
    width: 1.5rem;
    height: 1.5rem;
}

.LandingTheme .icon-auto > svg {
    width: 1em;
    height: 1em;
}

.LandingTheme .shape {
    position: absolute;
    pointer-events: none;
    background-color: currentColor;
}

.LandingTheme .shape-img {
    position: inherit;
    overflow: hidden;
}

.LandingTheme .shape-img > svg {
    position: inherit;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
}

.LandingTheme .shape-fluid-x {
    width: 100%;
}

.LandingTheme .shape-fluid-x .shape-img {
    width: inherit;
}

.LandingTheme .shape-top {
    bottom: 100%;
}

.LandingTheme .shape-top .shape-img {
    bottom: inherit;
}

.LandingTheme .shape-top .shape-img > svg {
    bottom: 0;
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
}

.LandingTheme .shape-bottom {
    top: 100%;
}

.LandingTheme .shape-bottom .shape-img {
    top: inherit;
}

.LandingTheme .shape-bottom .shape-img > svg {
    top: 0;
    -webkit-transform-origin: bottom center;
    -ms-transform-origin: bottom center;
    transform-origin: bottom center;
}

.LandingTheme .timeline {
    padding-left: 0;
    list-style-type: none;
}

.LandingTheme .timeline-item {
    position: relative;
    display: block;
    text-align: center;
    counter-increment: timeline-counter;
}

.LandingTheme .timeline-item + .timeline-item {
    margin-top: 2rem;
}

.LandingTheme .timeline-item::before {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin: 0 auto 1rem;
    font-size: 2.25rem;
    font-family: "DM Serif Display",serif;
    border-radius: 50%;
    content: counter(timeline-counter);
}

.LandingTheme .timeline-item::after {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    display: none;
    border-top: 2px solid;
    content: '';
}

.LandingTheme .timeline-item:first-child::after {
    left: 50%;
    width: 50%;
}

.LandingTheme .timeline-item:last-child::after {
    width: 50%;
}

.LandingTheme .timeline-primary .timeline-item::before {
    background-color: #bcb6f6;
    color: #fff;
}

.LandingTheme .timeline-primary .timeline-item.active::before {
    background-color: #6658ea;
}

.LandingTheme .timeline-primary .timeline-item::after {
    border-color: #bcb6f6;
}

.LandingTheme .timeline-primary .timeline-item.active::after {
    border-color: #6658ea;
}

.LandingTheme .timeline-secondary .timeline-item::before {
    background-color: #b5b6c5;
    color: #fff;
}

.LandingTheme .timeline-secondary .timeline-item.active::before {
    background-color: #575a7b;
}

.LandingTheme .timeline-secondary .timeline-item::after {
    border-color: #b5b6c5;
}

.LandingTheme .timeline-secondary .timeline-item.active::after {
    border-color: #575a7b;
}

.LandingTheme .timeline-success .timeline-item::before {
    background-color: #a2ecd6;
    color: #fff;
}

.LandingTheme .timeline-success .timeline-item.active::before {
    background-color: #2cd4a2;
}

.LandingTheme .timeline-success .timeline-item::after {
    border-color: #a2ecd6;
}

.LandingTheme .timeline-success .timeline-item.active::after {
    border-color: #2cd4a2;
}

.LandingTheme .timeline-info .timeline-item::before {
    background-color: #99d6e0;
    color: #fff;
}

.LandingTheme .timeline-info .timeline-item.active::before {
    background-color: #17a2b8;
}

.LandingTheme .timeline-info .timeline-item::after {
    border-color: #99d6e0;
}

.LandingTheme .timeline-info .timeline-item.active::after {
    border-color: #17a2b8;
}

.LandingTheme .timeline-warning .timeline-item::before {
    background-color: #fbe3c0;
    color: #fff;
}

.LandingTheme .timeline-warning .timeline-item.active::before {
    background-color: #f5c070;
}

.LandingTheme .timeline-warning .timeline-item::after {
    border-color: #fbe3c0;
}

.LandingTheme .timeline-warning .timeline-item.active::after {
    border-color: #f5c070;
}

.LandingTheme .timeline-danger .timeline-item::before {
    background-color: #fcbbb7;
    color: #fff;
}

.LandingTheme .timeline-danger .timeline-item.active::before {
    background-color: #f9655b;
}

.LandingTheme .timeline-danger .timeline-item::after {
    border-color: #fcbbb7;
}

.LandingTheme .timeline-danger .timeline-item.active::after {
    border-color: #f9655b;
}

.LandingTheme .timeline-light .timeline-item::before {
    background-color: #fafcff;
    color: #1b1642;
}

.LandingTheme .timeline-light .timeline-item.active::before {
    background-color: #f3f8ff;
}

.LandingTheme .timeline-light .timeline-item::after {
    border-color: #fafcff;
}

.LandingTheme .timeline-light .timeline-item.active::after {
    border-color: #f3f8ff;
}

.LandingTheme .timeline-dark .timeline-item::before {
    background-color: #9b98ac;
    color: #fff;
}

.LandingTheme .timeline-dark .timeline-item.active::before {
    background-color: #1b1642;
}

.LandingTheme .timeline-dark .timeline-item::after {
    border-color: #9b98ac;
}

.LandingTheme .timeline-dark .timeline-item.active::after {
    border-color: #1b1642;
}

.LandingTheme .timeline-primary-dark .timeline-item::before {
    background-color: #b5b0dc;
    color: #fff;
}

.LandingTheme .timeline-primary-dark .timeline-item.active::before {
    background-color: #564caf;
}

.LandingTheme .timeline-primary-dark .timeline-item::after {
    border-color: #b5b0dc;
}

.LandingTheme .timeline-primary-dark .timeline-item.active::after {
    border-color: #564caf;
}

.LandingTheme .timeline-primary-light .timeline-item::before {
    background-color: #bdc6f7;
    color: #fff;
}

.LandingTheme .timeline-primary-light .timeline-item.active::before {
    background-color: #687ded;
}

.LandingTheme .timeline-primary-light .timeline-item::after {
    border-color: #bdc6f7;
}

.LandingTheme .timeline-primary-light .timeline-item.active::after {
    border-color: #687ded;
}

.LandingTheme .timeline-expand-xs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.LandingTheme .timeline-expand-xs .timeline-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.LandingTheme .timeline-expand-xs .timeline-item::after {
    display: block;
}

.LandingTheme .timeline-expand-xs .timeline-item + .timeline-item {
    margin-top: 0;
}

@media (min-width: 576px) {
    .LandingTheme .timeline-expand-sm {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .LandingTheme .timeline-expand-sm .timeline-item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .LandingTheme .timeline-expand-sm .timeline-item::after {
        display: block;
    }
    .LandingTheme .timeline-expand-sm .timeline-item + .timeline-item {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .LandingTheme .timeline-expand-md {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .LandingTheme .timeline-expand-md .timeline-item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .LandingTheme .timeline-expand-md .timeline-item::after {
        display: block;
    }
    .LandingTheme .timeline-expand-md .timeline-item + .timeline-item {
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .LandingTheme .timeline-expand-lg {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .LandingTheme .timeline-expand-lg .timeline-item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .LandingTheme .timeline-expand-lg .timeline-item::after {
        display: block;
    }
    .LandingTheme .timeline-expand-lg .timeline-item + .timeline-item {
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .LandingTheme .timeline-expand-xl {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .LandingTheme .timeline-expand-xl .timeline-item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .LandingTheme .timeline-expand-xl .timeline-item::after {
        display: block;
    }
    .LandingTheme .timeline-expand-xl .timeline-item + .timeline-item {
        margin-top: 0;
    }
}

.LandingTheme [data-aos=wipe-left], .LandingTheme [data-aos=wipe-right] {
    -webkit-transition: .3s ease all;
    -o-transition: .3s ease all;
    transition: .3s ease all;
}

.LandingTheme [data-aos=wipe-left].aos-animate, .LandingTheme [data-aos=wipe-right].aos-animate {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.LandingTheme [data-aos=wipe-left] {
    -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
}

.LandingTheme [data-aos=wipe-right] {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}

.LandingTheme body.compensate-for-scrollbar {
    margin-right: 0 !important;
}

.LandingTheme .fancybox-container .fancybox-bg {
    background-color: #1b1642;
}

.LandingTheme [data-flickity]:not(.flickity-enabled) {
    display: none;
}

.LandingTheme .flickity-button {
    z-index: 1;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06);
}

.LandingTheme .flickity-button:hover {
    color: #fff;
    background-color: #4736e6;
    border-color: #3c2be4;
}

.LandingTheme .flickity-button.focus, .LandingTheme .flickity-button:focus {
    color: #fff;
    background-color: #4736e6;
    border-color: #3c2be4;
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
}

.LandingTheme .flickity-button.disabled, .LandingTheme .flickity-button:disabled {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
}

.LandingTheme .flickity-button:not(:disabled):not(.disabled).active, .LandingTheme .flickity-button:not(:disabled):not(.disabled):active, .LandingTheme .show > .flickity-button.dropdown-toggle {
    color: #fff;
    background-color: #3c2be4;
    border-color: #321fe3;
}

.LandingTheme .flickity-button:not(:disabled):not(.disabled).active:focus, .LandingTheme .flickity-button:not(:disabled):not(.disabled):active:focus, .LandingTheme .show > .flickity-button.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
    box-shadow: 0 0 6px rgba(27, 22, 66, 0.03), 0 14px 24px rgba(27, 22, 66, 0.06), 0 0 0 0 rgba(125, 113, 237, 0.5);
}

.LandingTheme .flickity-button:active, .LandingTheme .flickity-button:focus, .LandingTheme .flickity-button:hover {
    opacity: 1;
    background-color: #5546e8;
}

.LandingTheme .flickity-button::before {
    font-family: Feather;
}

.LandingTheme .flickity-button.previous {
    left: 0;
    border-radius: .375rem 0 0 .375rem;
}

.LandingTheme .flickity-button.previous::before {
    content: "\e910";
}

.LandingTheme .flickity-button.next {
    right: 0;
    border-radius: 0 .375rem .375rem 0;
}

.LandingTheme .flickity-button.next::before {
    content: "\e912";
}

.LandingTheme .flickity-button-icon {
    display: none;
}

@media (max-width: 767.98px) {
    .LandingTheme .flickity-buttons-overlap .flickity-button {
        top: auto;
        bottom: 0;
        -webkit-transform: translate(-0.5rem, 1.5rem);
        -ms-transform: translate(-0.5rem, 1.5rem);
        transform: translate(-0.5rem, 1.5rem);
    }
    .LandingTheme .flickity-buttons-overlap .flickity-button.next {
        left: 50%;
        -webkit-transform: translate(0, 1.5rem);
        -ms-transform: translate(0, 1.5rem);
        transform: translate(0, 1.5rem);
    }
    .LandingTheme .flickity-buttons-overlap .flickity-button.next::after {
        content: "";
        position: absolute;
        top: 30%;
        left: 0;
        height: 40%;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    .LandingTheme .flickity-buttons-overlap .flickity-button.previous {
        left: 50%;
        -webkit-transform: translate(-100%, 1.5rem);
        -ms-transform: translate(-100%, 1.5rem);
        transform: translate(-100%, 1.5rem);
    }
}

@media (min-width: 768px) {
    .LandingTheme .flickity-buttons-overlap .flickity-button.previous {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .LandingTheme .flickity-buttons-overlap .flickity-button.next {
        -webkit-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
        transform: translate(50%, -50%);
    }
}

.LandingTheme .flickity-buttons-bottom-left .flickity-button {
    top: auto;
    bottom: 0;
    -webkit-transform: translate(-1.5rem, 1.5rem);
    -ms-transform: translate(-1.5rem, 1.5rem);
    transform: translate(-1.5rem, 1.5rem);
}

.LandingTheme .flickity-buttons-bottom-left .flickity-button.next {
    left: 3.75rem;
}

.LandingTheme .flickity-buttons-bottom-left .flickity-button.previous {
    left: 0;
}

@media (max-width: 767.98px) {
    .LandingTheme .flickity-buttons-bottom-left .flickity-button.next {
        left: 50%;
        -webkit-transform: translate(0, 1.5rem);
        -ms-transform: translate(0, 1.5rem);
        transform: translate(0, 1.5rem);
    }
    .LandingTheme .flickity-buttons-bottom-left .flickity-button.previous {
        left: 50%;
        -webkit-transform: translate(-100%, 1.5rem);
        -ms-transform: translate(-100%, 1.5rem);
        transform: translate(-100%, 1.5rem);
    }
}

.LandingTheme .flickity-buttons-bottom-right .flickity-button {
    top: auto;
    bottom: 0;
    -webkit-transform: translate(1.5rem, 1.5rem);
    -ms-transform: translate(1.5rem, 1.5rem);
    transform: translate(1.5rem, 1.5rem);
}

.LandingTheme .flickity-buttons-bottom-right .flickity-button.next {
    right: 0;
}

.LandingTheme .flickity-buttons-bottom-right .flickity-button.previous {
    left: auto;
    right: 3.75rem;
}

@media (max-width: 767.98px) {
    .LandingTheme .flickity-buttons-bottom-right .flickity-button.next {
        left: 50%;
        -webkit-transform: translate(0, 1.5rem);
        -ms-transform: translate(0, 1.5rem);
        transform: translate(0, 1.5rem);
    }
    .LandingTheme .flickity-buttons-bottom-right .flickity-button.previous {
        left: 50%;
        -webkit-transform: translate(-100%, 1.5rem);
        -ms-transform: translate(-100%, 1.5rem);
        transform: translate(-100%, 1.5rem);
    }
}

.LandingTheme .flickity-buttons-bottom-center .flickity-button {
    top: auto;
    bottom: 0;
    -webkit-transform: translate(1.5rem, 1.5rem);
    -ms-transform: translate(1.5rem, 1.5rem);
    transform: translate(1.5rem, 1.5rem);
}

.LandingTheme .flickity-buttons-bottom-center .flickity-button.next {
    left: 50%;
    -webkit-transform: translate(0, 1.5rem);
    -ms-transform: translate(0, 1.5rem);
    transform: translate(0, 1.5rem);
}

.LandingTheme .flickity-buttons-bottom-center .flickity-button.previous {
    left: 50%;
    -webkit-transform: translate(-100%, 1.5rem);
    -ms-transform: translate(-100%, 1.5rem);
    transform: translate(-100%, 1.5rem);
}

.LandingTheme .flickity-buttons-adjacent .flickity-button.next::after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    height: 40%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.LandingTheme .flickity-buttons-rounded .flickity-button.next {
    border-bottom-right-radius: 1rem;
}

.LandingTheme .flickity-buttons-rounded .flickity-button.previous {
    border-top-left-radius: 1rem;
}

.LandingTheme .flickity-buttons-responsive .flickity-button {
    display: none;
}

@media (min-width: 768px) {
    .LandingTheme .flickity-buttons-responsive .flickity-button {
        display: block;
    }
    .LandingTheme .flickity-buttons-responsive .flickity-page-dots {
        display: none;
    }
}

.LandingTheme .flickity-viewport-visible .flickity-viewport {
    overflow: visible;
}

.LandingTheme .flickity-items-fade .flickity-slider > * {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    transition-property: opacity,transform,-webkit-transform;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
}

.LandingTheme .flickity-items-fade .flickity-hidden {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}

.LandingTheme .flickity-items-fade .flickity-slider > :not(.is-selected) {
    opacity: .1;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.LandingTheme .flickity-items-fade .flickity-slider > :not(.is-selected) .flickity-hidden {
    opacity: 0;
}

.LandingTheme .flickity-page-dots {
    position: relative;
}

.LandingTheme .flickity-page-dots .dot {
    width: .5rem;
    height: .5rem;
    margin: 0;
    background-color: #dce0f3;
    opacity: 1;
}

.LandingTheme .flickity-page-dots .dot + .dot {
    margin-left: .5rem;
}

.LandingTheme .flickity-page-dots .dot.is-selected {
    background-color: #6658ea;
}

.LandingTheme .flickity-soft-edges .flickity-viewport {
    border-radius: inherit;
}

.LandingTheme .flickity-soft-edges .flickity-viewport::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -o-linear-gradient(left, #fff, rgba(255, 255, 255, 0) 16px), -o-linear-gradient(right, #fff, rgba(255, 255, 255, 0) 16px);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0) 16px), linear-gradient(to left, #fff, rgba(255, 255, 255, 0) 16px);
}

.LandingTheme .flickity-soft-edges-primary .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #6658ea, rgba(102, 88, 234, 0) 16px), -o-linear-gradient(right, #6658ea, rgba(102, 88, 234, 0) 16px);
    background-image: linear-gradient(to right, #6658ea, rgba(102, 88, 234, 0) 16px), linear-gradient(to left, #6658ea, rgba(102, 88, 234, 0) 16px);
}

.LandingTheme .flickity-soft-edges-secondary .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #575a7b, rgba(87, 90, 123, 0) 16px), -o-linear-gradient(right, #575a7b, rgba(87, 90, 123, 0) 16px);
    background-image: linear-gradient(to right, #575a7b, rgba(87, 90, 123, 0) 16px), linear-gradient(to left, #575a7b, rgba(87, 90, 123, 0) 16px);
}

.LandingTheme .flickity-soft-edges-success .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #2cd4a2, rgba(44, 212, 162, 0) 16px), -o-linear-gradient(right, #2cd4a2, rgba(44, 212, 162, 0) 16px);
    background-image: linear-gradient(to right, #2cd4a2, rgba(44, 212, 162, 0) 16px), linear-gradient(to left, #2cd4a2, rgba(44, 212, 162, 0) 16px);
}

.LandingTheme .flickity-soft-edges-info .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #17a2b8, rgba(23, 162, 184, 0) 16px), -o-linear-gradient(right, #17a2b8, rgba(23, 162, 184, 0) 16px);
    background-image: linear-gradient(to right, #17a2b8, rgba(23, 162, 184, 0) 16px), linear-gradient(to left, #17a2b8, rgba(23, 162, 184, 0) 16px);
}

.LandingTheme .flickity-soft-edges-warning .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #f5c070, rgba(245, 192, 112, 0) 16px), -o-linear-gradient(right, #f5c070, rgba(245, 192, 112, 0) 16px);
    background-image: linear-gradient(to right, #f5c070, rgba(245, 192, 112, 0) 16px), linear-gradient(to left, #f5c070, rgba(245, 192, 112, 0) 16px);
}

.LandingTheme .flickity-soft-edges-danger .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #f9655b, rgba(249, 101, 91, 0) 16px), -o-linear-gradient(right, #f9655b, rgba(249, 101, 91, 0) 16px);
    background-image: linear-gradient(to right, #f9655b, rgba(249, 101, 91, 0) 16px), linear-gradient(to left, #f9655b, rgba(249, 101, 91, 0) 16px);
}

.LandingTheme .flickity-soft-edges-light .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #f3f8ff, rgba(243, 248, 255, 0) 16px), -o-linear-gradient(right, #f3f8ff, rgba(243, 248, 255, 0) 16px);
    background-image: linear-gradient(to right, #f3f8ff, rgba(243, 248, 255, 0) 16px), linear-gradient(to left, #f3f8ff, rgba(243, 248, 255, 0) 16px);
}

.LandingTheme .flickity-soft-edges-dark .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #1b1642, rgba(27, 22, 66, 0) 16px), -o-linear-gradient(right, #1b1642, rgba(27, 22, 66, 0) 16px);
    background-image: linear-gradient(to right, #1b1642, rgba(27, 22, 66, 0) 16px), linear-gradient(to left, #1b1642, rgba(27, 22, 66, 0) 16px);
}

.LandingTheme .flickity-soft-edges-primary-dark .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #564caf, rgba(86, 76, 175, 0) 16px), -o-linear-gradient(right, #564caf, rgba(86, 76, 175, 0) 16px);
    background-image: linear-gradient(to right, #564caf, rgba(86, 76, 175, 0) 16px), linear-gradient(to left, #564caf, rgba(86, 76, 175, 0) 16px);
}

.LandingTheme .flickity-soft-edges-primary-light .flickity-viewport::after {
    background-image: -o-linear-gradient(left, #687ded, rgba(104, 125, 237, 0) 16px), -o-linear-gradient(right, #687ded, rgba(104, 125, 237, 0) 16px);
    background-image: linear-gradient(to right, #687ded, rgba(104, 125, 237, 0) 16px), linear-gradient(to left, #687ded, rgba(104, 125, 237, 0) 16px);
}

.LandingTheme .hljs {
    padding: 0;
    background-color: transparent;
}
