/*  https://sass2css.herokuapp.com */
/*!
* Bootstrap v4.4.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
* Copyright 2011-2019 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(22, 28, 45, 0);
}
body {
    margin: 0;
    font-family: HKGroteskPro,serif;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    text-align: left;
    background-color: #fff;
}

.PrimaryTheme :root {
    --blue:#007bff;
    --indigo:#6610f2;
    --purple:#6f42c1;
    --pink:#e83e8c;
    --red:#dc3545;
    --orange:#fd7e14;
    --yellow:#ffc107;
    --green:#28a745;
    --teal:#20c997;
    --cyan:#17a2b8;
    --white:#fff;
    --gray:#869ab8;
    --gray-dark:#384c74;
    --primary:#335eea;
    --secondary:#506690;
    --success:#42ba96;
    --info:#7c69ef;
    --warning:#fad776;
    --danger:#df4759;
    --light:#f9fbfd;
    --dark:#1b2a4e;
    --primary-desat:#6c8aec;
    --black:#161c2d;
    --white:#fff;
    --breakpoint-xs:0;
    --breakpoint-sm:576px;
    --breakpoint-md:768px;
    --breakpoint-lg:992px;
    --breakpoint-xl:1200px;
    --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

.PrimaryTheme *, .PrimaryTheme ::after, .PrimaryTheme ::before {
    box-sizing: border-box;
}

.PrimaryTheme article, .PrimaryTheme aside, .PrimaryTheme figcaption, .PrimaryTheme figure, .PrimaryTheme footer, .PrimaryTheme header, .PrimaryTheme hgroup, .PrimaryTheme main, .PrimaryTheme nav, .PrimaryTheme section {
    display: block;
}

.PrimaryTheme [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

.PrimaryTheme hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

.PrimaryTheme h1, .PrimaryTheme h2, .PrimaryTheme h3, .PrimaryTheme h4, .PrimaryTheme h5, .PrimaryTheme h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.PrimaryTheme p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.PrimaryTheme abbr[data-original-title], .PrimaryTheme abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

.PrimaryTheme address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

.PrimaryTheme dl, .PrimaryTheme ol, .PrimaryTheme ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.PrimaryTheme ol ol, .PrimaryTheme ol ul, .PrimaryTheme ul ol, .PrimaryTheme ul ul {
    margin-bottom: 0;
}

.PrimaryTheme dt {
    font-weight: 600;
}

.PrimaryTheme dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

.PrimaryTheme blockquote {
    margin: 0 0 1rem;
}

.PrimaryTheme b, .PrimaryTheme strong {
    font-weight: bolder;
}

.PrimaryTheme small {
    font-size: 80%;
}

.PrimaryTheme sub, .PrimaryTheme sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.PrimaryTheme sub {
    bottom: -.25em;
}

.PrimaryTheme sup {
    top: -.5em;
}

.PrimaryTheme a {
    color: #335eea;
    text-decoration: none;
    background-color: transparent;
}

.PrimaryTheme a:hover {
    color: #133bbd;
    text-decoration: underline;
}

.PrimaryTheme a:not([href]) {
    color: inherit;
    text-decoration: none;
}

.PrimaryTheme a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

.PrimaryTheme code, .PrimaryTheme kbd, .PrimaryTheme pre, .PrimaryTheme samp {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-size: 1em;
}

.PrimaryTheme pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

.PrimaryTheme figure {
    margin: 0 0 1rem;
}

.PrimaryTheme img {
    vertical-align: middle;
    border-style: none;
}

.PrimaryTheme svg {
    overflow: hidden;
    vertical-align: middle;
}

.PrimaryTheme table {
    border-collapse: collapse;
}

.PrimaryTheme caption {
    padding-top: 1.5rem 2rem;
    padding-bottom: 1.5rem 2rem;
    color: #869ab8;
    text-align: left;
    caption-side: bottom;
}

.PrimaryTheme th {
    text-align: inherit;
}

.PrimaryTheme label {
    display: inline-block;
    margin-bottom: .5rem;
}

.PrimaryTheme button {
    border-radius: 0;
}

.PrimaryTheme button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

.PrimaryTheme button, .PrimaryTheme input, .PrimaryTheme optgroup, .PrimaryTheme select, .PrimaryTheme textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.PrimaryTheme button, .PrimaryTheme input {
    overflow: visible;
}

.PrimaryTheme button, .PrimaryTheme select {
    text-transform: none;
}

.PrimaryTheme select {
    word-wrap: normal;
}

.PrimaryTheme [type=button], .PrimaryTheme [type=reset], .PrimaryTheme [type=submit], .PrimaryTheme button {
    -webkit-appearance: button;
}

.PrimaryTheme [type=button]:not(:disabled), .PrimaryTheme [type=reset]:not(:disabled), .PrimaryTheme [type=submit]:not(:disabled), .PrimaryTheme button:not(:disabled) {
    cursor: pointer;
}

.PrimaryTheme [type=button]::-moz-focus-inner, .PrimaryTheme [type=reset]::-moz-focus-inner, .PrimaryTheme [type=submit]::-moz-focus-inner, .PrimaryTheme button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.PrimaryTheme input[type=checkbox], .PrimaryTheme input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.PrimaryTheme input[type=date], .PrimaryTheme input[type=datetime-local], .PrimaryTheme input[type=month], .PrimaryTheme input[type=time] {
    -webkit-appearance: listbox;
}

.PrimaryTheme textarea {
    overflow: auto;
    resize: vertical;
}

.PrimaryTheme fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

.PrimaryTheme legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.PrimaryTheme progress {
    vertical-align: baseline;
}

.PrimaryTheme [type=number]::-webkit-inner-spin-button, .PrimaryTheme [type=number]::-webkit-outer-spin-button {
    height: auto;
}

.PrimaryTheme [type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

.PrimaryTheme [type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.PrimaryTheme ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

.PrimaryTheme output {
    display: inline-block;
}

.PrimaryTheme summary {
    display: list-item;
    cursor: pointer;
}

.PrimaryTheme template {
    display: none;
}

.PrimaryTheme [hidden] {
    display: none !important;
}

.PrimaryTheme .h1, .PrimaryTheme .h2, .PrimaryTheme .h3, .PrimaryTheme .h4, .PrimaryTheme .h5, .PrimaryTheme .h6, .PrimaryTheme h1, .PrimaryTheme h2, .PrimaryTheme h3, .PrimaryTheme h4, .PrimaryTheme h5, .PrimaryTheme h6 {
    margin-bottom: .5rem;
    font-weight: 400;
    line-height: 1;
}

.PrimaryTheme .h1, .PrimaryTheme h1 {
    font-size: 2.3125rem;
}

.PrimaryTheme .h2, .PrimaryTheme h2 {
    font-size: 1.9375rem;
}

.PrimaryTheme .h3, .PrimaryTheme h3 {
    font-size: 1.3125rem;
}

.PrimaryTheme .h4, .PrimaryTheme h4 {
    font-size: 1.1875rem;
}

.PrimaryTheme .h5, .PrimaryTheme h5 {
    font-size: 1.0625rem;
}

.PrimaryTheme .h6, .PrimaryTheme h6 {
    font-size: .75rem;
}

.PrimaryTheme .lead {
    font-size: 1.3125rem;
    font-weight: 400;
}

.PrimaryTheme .display-1 {
    font-size: 4.375rem;
    font-weight: 400;
    line-height: 1;
}

.PrimaryTheme .display-2 {
    font-size: 3.8125rem;
    font-weight: 400;
    line-height: 1;
}

.PrimaryTheme .display-3 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1;
}

.PrimaryTheme .display-4 {
    font-size: 2.6875rem;
    font-weight: 400;
    line-height: 1;
}

.PrimaryTheme hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #f1f4f8;
}

.PrimaryTheme .small, .PrimaryTheme small {
    font-size: 88.2%;
    font-weight: 400;
}

.PrimaryTheme .mark, .PrimaryTheme mark {
    padding: .2em;
    background-color: #fcf8e3;
}

.PrimaryTheme .list-unstyled {
    padding-left: 0;
    list-style: none;
}

.PrimaryTheme .list-inline {
    padding-left: 0;
    list-style: none;
}

.PrimaryTheme .list-inline-item {
    display: inline-block;
}

.PrimaryTheme .list-inline-item:not(:last-child) {
    margin-right: .5rem;
}

.PrimaryTheme .initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.PrimaryTheme .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.PrimaryTheme .blockquote-footer {
    display: block;
    font-size: .75rem;
    color: #869ab8;
}

.PrimaryTheme .blockquote-footer::before {
    content: "\2014\00A0";
}

.PrimaryTheme .img-fluid {
    max-width: 100%;
    height: auto;
}

.PrimaryTheme .img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #d9e2ef;
    border-radius: .375rem;
    box-shadow: 0 1px 2px rgba(22, 28, 45, 0.075);
    max-width: 100%;
    height: auto;
}

.PrimaryTheme .figure {
    display: inline-block;
}

.PrimaryTheme .figure-img {
    margin-bottom: .5rem;
    line-height: 1;
}

.PrimaryTheme .figure-caption {
    font-size: 88.2%;
    color: #869ab8;
}

.PrimaryTheme code {
    font-size: .8125rem;
    color: #e83e8c;
    word-wrap: break-word;
}

.PrimaryTheme a > code {
    color: inherit;
}

.PrimaryTheme kbd {
    padding: .2rem .4rem;
    font-size: .8125rem;
    color: #fff;
    background-color: #1b2a4e;
    border-radius: .2rem;
    box-shadow: inset 0 -0.1rem 0 rgba(22, 28, 45, 0.25);
}

.PrimaryTheme kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600;
    box-shadow: none;
}

.PrimaryTheme pre {
    display: block;
    font-size: .8125rem;
    color: #1b2a4e;
}

.PrimaryTheme pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.PrimaryTheme .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.PrimaryTheme .container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .PrimaryTheme .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .container {
        max-width: 1040px;
    }
}

.PrimaryTheme .container-fluid, .PrimaryTheme .container-lg, .PrimaryTheme .container-md, .PrimaryTheme .container-sm, .PrimaryTheme .container-xl {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .PrimaryTheme .container, .PrimaryTheme .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .container, .PrimaryTheme .container-md, .PrimaryTheme .container-sm {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .container, .PrimaryTheme .container-lg, .PrimaryTheme .container-md, .PrimaryTheme .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .container, .PrimaryTheme .container-lg, .PrimaryTheme .container-md, .PrimaryTheme .container-sm, .PrimaryTheme .container-xl {
        max-width: 1040px;
    }
}

.PrimaryTheme .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
}

.PrimaryTheme .no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.PrimaryTheme .no-gutters > .col, .PrimaryTheme .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.PrimaryTheme .col, .PrimaryTheme .col-1, .PrimaryTheme .col-10, .PrimaryTheme .col-11, .PrimaryTheme .col-12, .PrimaryTheme .col-2, .PrimaryTheme .col-3, .PrimaryTheme .col-4, .PrimaryTheme .col-5, .PrimaryTheme .col-6, .PrimaryTheme .col-7, .PrimaryTheme .col-8, .PrimaryTheme .col-9, .PrimaryTheme .col-auto, .PrimaryTheme .col-lg, .PrimaryTheme .col-lg-1, .PrimaryTheme .col-lg-10, .PrimaryTheme .col-lg-11, .PrimaryTheme .col-lg-12, .PrimaryTheme .col-lg-2, .PrimaryTheme .col-lg-3, .PrimaryTheme .col-lg-4, .PrimaryTheme .col-lg-5, .PrimaryTheme .col-lg-6, .PrimaryTheme .col-lg-7, .PrimaryTheme .col-lg-8, .PrimaryTheme .col-lg-9, .PrimaryTheme .col-lg-auto, .PrimaryTheme .col-md, .PrimaryTheme .col-md-1, .PrimaryTheme .col-md-10, .PrimaryTheme .col-md-11, .PrimaryTheme .col-md-12, .PrimaryTheme .col-md-2, .PrimaryTheme .col-md-3, .PrimaryTheme .col-md-4, .PrimaryTheme .col-md-5, .PrimaryTheme .col-md-6, .PrimaryTheme .col-md-7, .PrimaryTheme .col-md-8, .PrimaryTheme .col-md-9, .PrimaryTheme .col-md-auto, .PrimaryTheme .col-sm, .PrimaryTheme .col-sm-1, .PrimaryTheme .col-sm-10, .PrimaryTheme .col-sm-11, .PrimaryTheme .col-sm-12, .PrimaryTheme .col-sm-2, .PrimaryTheme .col-sm-3, .PrimaryTheme .col-sm-4, .PrimaryTheme .col-sm-5, .PrimaryTheme .col-sm-6, .PrimaryTheme .col-sm-7, .PrimaryTheme .col-sm-8, .PrimaryTheme .col-sm-9, .PrimaryTheme .col-sm-auto, .PrimaryTheme .col-xl, .PrimaryTheme .col-xl-1, .PrimaryTheme .col-xl-10, .PrimaryTheme .col-xl-11, .PrimaryTheme .col-xl-12, .PrimaryTheme .col-xl-2, .PrimaryTheme .col-xl-3, .PrimaryTheme .col-xl-4, .PrimaryTheme .col-xl-5, .PrimaryTheme .col-xl-6, .PrimaryTheme .col-xl-7, .PrimaryTheme .col-xl-8, .PrimaryTheme .col-xl-9, .PrimaryTheme .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}

.PrimaryTheme .col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
}

.PrimaryTheme .row-cols-1 > * {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

.PrimaryTheme .row-cols-2 > * {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.PrimaryTheme .row-cols-3 > * {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.PrimaryTheme .row-cols-4 > * {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

.PrimaryTheme .row-cols-5 > * {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
}

.PrimaryTheme .row-cols-6 > * {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.PrimaryTheme .col-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.PrimaryTheme .col-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.PrimaryTheme .col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.PrimaryTheme .col-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

.PrimaryTheme .col-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.PrimaryTheme .col-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.PrimaryTheme .col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.PrimaryTheme .col-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.PrimaryTheme .col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.PrimaryTheme .col-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
}

.PrimaryTheme .col-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.PrimaryTheme .col-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.PrimaryTheme .col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

.PrimaryTheme .order-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
}

.PrimaryTheme .order-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
}

.PrimaryTheme .order-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
}

.PrimaryTheme .order-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
}

.PrimaryTheme .order-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
}

.PrimaryTheme .order-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
}

.PrimaryTheme .order-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
}

.PrimaryTheme .order-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
}

.PrimaryTheme .order-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
}

.PrimaryTheme .order-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
}

.PrimaryTheme .order-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
}

.PrimaryTheme .order-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
}

.PrimaryTheme .order-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
}

.PrimaryTheme .order-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
}

.PrimaryTheme .order-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
}

.PrimaryTheme .offset-1 {
    margin-left: 8.33333%;
}

.PrimaryTheme .offset-2 {
    margin-left: 16.66667%;
}

.PrimaryTheme .offset-3 {
    margin-left: 25%;
}

.PrimaryTheme .offset-4 {
    margin-left: 33.33333%;
}

.PrimaryTheme .offset-5 {
    margin-left: 41.66667%;
}

.PrimaryTheme .offset-6 {
    margin-left: 50%;
}

.PrimaryTheme .offset-7 {
    margin-left: 58.33333%;
}

.PrimaryTheme .offset-8 {
    margin-left: 66.66667%;
}

.PrimaryTheme .offset-9 {
    margin-left: 75%;
}

.PrimaryTheme .offset-10 {
    margin-left: 83.33333%;
}

.PrimaryTheme .offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .PrimaryTheme .col-sm {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .PrimaryTheme .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-sm-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .PrimaryTheme .col-sm-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .PrimaryTheme .col-sm-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-sm-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .col-sm-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .col-sm-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .PrimaryTheme .col-sm-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .col-sm-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .PrimaryTheme .col-sm-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .PrimaryTheme .col-sm-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .PrimaryTheme .col-sm-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .PrimaryTheme .col-sm-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .PrimaryTheme .col-sm-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .order-sm-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .PrimaryTheme .order-sm-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }
    .PrimaryTheme .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }
    .PrimaryTheme .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
    .PrimaryTheme .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }
    .PrimaryTheme .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }
    .PrimaryTheme .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }
    .PrimaryTheme .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }
    .PrimaryTheme .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }
    .PrimaryTheme .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }
    .PrimaryTheme .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }
    .PrimaryTheme .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }
    .PrimaryTheme .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }
    .PrimaryTheme .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }
    .PrimaryTheme .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }
    .PrimaryTheme .offset-sm-0 {
        margin-left: 0;
    }
    .PrimaryTheme .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .PrimaryTheme .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .PrimaryTheme .offset-sm-3 {
        margin-left: 25%;
    }
    .PrimaryTheme .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .PrimaryTheme .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .PrimaryTheme .offset-sm-6 {
        margin-left: 50%;
    }
    .PrimaryTheme .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .PrimaryTheme .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .PrimaryTheme .offset-sm-9 {
        margin-left: 75%;
    }
    .PrimaryTheme .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .PrimaryTheme .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .col-md {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .PrimaryTheme .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-md-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .PrimaryTheme .col-md-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .PrimaryTheme .col-md-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-md-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .col-md-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .col-md-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .PrimaryTheme .col-md-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .col-md-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .PrimaryTheme .col-md-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .PrimaryTheme .col-md-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .PrimaryTheme .col-md-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .PrimaryTheme .col-md-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .PrimaryTheme .col-md-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .order-md-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .PrimaryTheme .order-md-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }
    .PrimaryTheme .order-md-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }
    .PrimaryTheme .order-md-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
    .PrimaryTheme .order-md-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }
    .PrimaryTheme .order-md-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }
    .PrimaryTheme .order-md-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }
    .PrimaryTheme .order-md-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }
    .PrimaryTheme .order-md-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }
    .PrimaryTheme .order-md-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }
    .PrimaryTheme .order-md-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }
    .PrimaryTheme .order-md-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }
    .PrimaryTheme .order-md-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }
    .PrimaryTheme .order-md-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }
    .PrimaryTheme .order-md-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }
    .PrimaryTheme .offset-md-0 {
        margin-left: 0;
    }
    .PrimaryTheme .offset-md-1 {
        margin-left: 8.33333%;
    }
    .PrimaryTheme .offset-md-2 {
        margin-left: 16.66667%;
    }
    .PrimaryTheme .offset-md-3 {
        margin-left: 25%;
    }
    .PrimaryTheme .offset-md-4 {
        margin-left: 33.33333%;
    }
    .PrimaryTheme .offset-md-5 {
        margin-left: 41.66667%;
    }
    .PrimaryTheme .offset-md-6 {
        margin-left: 50%;
    }
    .PrimaryTheme .offset-md-7 {
        margin-left: 58.33333%;
    }
    .PrimaryTheme .offset-md-8 {
        margin-left: 66.66667%;
    }
    .PrimaryTheme .offset-md-9 {
        margin-left: 75%;
    }
    .PrimaryTheme .offset-md-10 {
        margin-left: 83.33333%;
    }
    .PrimaryTheme .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .col-lg {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .PrimaryTheme .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-lg-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .PrimaryTheme .col-lg-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .PrimaryTheme .col-lg-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-lg-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .col-lg-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .col-lg-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .PrimaryTheme .col-lg-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .col-lg-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .PrimaryTheme .col-lg-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .PrimaryTheme .col-lg-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .PrimaryTheme .col-lg-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .PrimaryTheme .col-lg-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .PrimaryTheme .col-lg-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .order-lg-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .PrimaryTheme .order-lg-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }
    .PrimaryTheme .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }
    .PrimaryTheme .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
    .PrimaryTheme .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }
    .PrimaryTheme .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }
    .PrimaryTheme .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }
    .PrimaryTheme .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }
    .PrimaryTheme .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }
    .PrimaryTheme .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }
    .PrimaryTheme .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }
    .PrimaryTheme .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }
    .PrimaryTheme .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }
    .PrimaryTheme .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }
    .PrimaryTheme .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }
    .PrimaryTheme .offset-lg-0 {
        margin-left: 0;
    }
    .PrimaryTheme .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .PrimaryTheme .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .PrimaryTheme .offset-lg-3 {
        margin-left: 25%;
    }
    .PrimaryTheme .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .PrimaryTheme .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .PrimaryTheme .offset-lg-6 {
        margin-left: 50%;
    }
    .PrimaryTheme .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .PrimaryTheme .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .PrimaryTheme .offset-lg-9 {
        margin-left: 75%;
    }
    .PrimaryTheme .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .PrimaryTheme .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .col-xl {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .PrimaryTheme .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-xl-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .PrimaryTheme .col-xl-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .PrimaryTheme .col-xl-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .PrimaryTheme .col-xl-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .PrimaryTheme .col-xl-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .PrimaryTheme .col-xl-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .PrimaryTheme .col-xl-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .PrimaryTheme .col-xl-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .PrimaryTheme .col-xl-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .PrimaryTheme .col-xl-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .PrimaryTheme .col-xl-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .PrimaryTheme .col-xl-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .PrimaryTheme .col-xl-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .PrimaryTheme .order-xl-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }
    .PrimaryTheme .order-xl-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }
    .PrimaryTheme .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }
    .PrimaryTheme .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
    .PrimaryTheme .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }
    .PrimaryTheme .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }
    .PrimaryTheme .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }
    .PrimaryTheme .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }
    .PrimaryTheme .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }
    .PrimaryTheme .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }
    .PrimaryTheme .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }
    .PrimaryTheme .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }
    .PrimaryTheme .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }
    .PrimaryTheme .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }
    .PrimaryTheme .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }
    .PrimaryTheme .offset-xl-0 {
        margin-left: 0;
    }
    .PrimaryTheme .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .PrimaryTheme .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .PrimaryTheme .offset-xl-3 {
        margin-left: 25%;
    }
    .PrimaryTheme .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .PrimaryTheme .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .PrimaryTheme .offset-xl-6 {
        margin-left: 50%;
    }
    .PrimaryTheme .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .PrimaryTheme .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .PrimaryTheme .offset-xl-9 {
        margin-left: 75%;
    }
    .PrimaryTheme .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .PrimaryTheme .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.PrimaryTheme .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #161c2d;
    background-color: #fff;
}

.PrimaryTheme .table td, .PrimaryTheme .table th {
    padding: 1.5rem 2rem;
    vertical-align: top;
    border-top: 1px solid #f1f4f8;
}

.PrimaryTheme .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f4f8;
}

.PrimaryTheme .table tbody + tbody {
    border-top: 2px solid #f1f4f8;
}

.PrimaryTheme .table-sm td, .PrimaryTheme .table-sm th {
    padding: .3rem;
}

.PrimaryTheme .table-bordered {
    border: 1px solid #f1f4f8;
}

.PrimaryTheme .table-bordered td, .PrimaryTheme .table-bordered th {
    border: 1px solid #f1f4f8;
}

.PrimaryTheme .table-bordered thead td, .PrimaryTheme .table-bordered thead th {
    border-bottom-width: 2px;
}

.PrimaryTheme .table-borderless tbody + tbody, .PrimaryTheme .table-borderless td, .PrimaryTheme .table-borderless th, .PrimaryTheme .table-borderless thead th {
    border: 0;
}

.PrimaryTheme .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9fbfd;
}

.PrimaryTheme .table-hover tbody tr:hover {
    color: #161c2d;
    background-color: rgba(22, 28, 45, 0.075);
}

.PrimaryTheme .table-primary, .PrimaryTheme .table-primary > td, .PrimaryTheme .table-primary > th {
    background-color: #c6d2f9;
}

.PrimaryTheme .table-primary tbody + tbody, .PrimaryTheme .table-primary td, .PrimaryTheme .table-primary th, .PrimaryTheme .table-primary thead th {
    border-color: #95abf4;
}

.PrimaryTheme .table-hover .table-primary:hover {
    background-color: #afc0f7;
}

.PrimaryTheme .table-hover .table-primary:hover > td, .PrimaryTheme .table-hover .table-primary:hover > th {
    background-color: #afc0f7;
}

.PrimaryTheme .table-secondary, .PrimaryTheme .table-secondary > td, .PrimaryTheme .table-secondary > th {
    background-color: #ced4e0;
}

.PrimaryTheme .table-secondary tbody + tbody, .PrimaryTheme .table-secondary td, .PrimaryTheme .table-secondary th, .PrimaryTheme .table-secondary thead th {
    border-color: #a4afc5;
}

.PrimaryTheme .table-hover .table-secondary:hover {
    background-color: #bec6d6;
}

.PrimaryTheme .table-hover .table-secondary:hover > td, .PrimaryTheme .table-hover .table-secondary:hover > th {
    background-color: #bec6d6;
}

.PrimaryTheme .table-success, .PrimaryTheme .table-success > td, .PrimaryTheme .table-success > th {
    background-color: #caece2;
}

.PrimaryTheme .table-success tbody + tbody, .PrimaryTheme .table-success td, .PrimaryTheme .table-success th, .PrimaryTheme .table-success thead th {
    border-color: #9ddbc8;
}

.PrimaryTheme .table-hover .table-success:hover {
    background-color: #b7e5d8;
}

.PrimaryTheme .table-hover .table-success:hover > td, .PrimaryTheme .table-hover .table-success:hover > th {
    background-color: #b7e5d8;
}

.PrimaryTheme .table-info, .PrimaryTheme .table-info > td, .PrimaryTheme .table-info > th {
    background-color: #dad5fb;
}

.PrimaryTheme .table-info tbody + tbody, .PrimaryTheme .table-info td, .PrimaryTheme .table-info th, .PrimaryTheme .table-info thead th {
    border-color: #bbb1f7;
}

.PrimaryTheme .table-hover .table-info:hover {
    background-color: #c5bef9;
}

.PrimaryTheme .table-hover .table-info:hover > td, .PrimaryTheme .table-hover .table-info:hover > th {
    background-color: #c5bef9;
}

.PrimaryTheme .table-warning, .PrimaryTheme .table-warning > td, .PrimaryTheme .table-warning > th {
    background-color: #fef4d9;
}

.PrimaryTheme .table-warning tbody + tbody, .PrimaryTheme .table-warning td, .PrimaryTheme .table-warning th, .PrimaryTheme .table-warning thead th {
    border-color: #fceab8;
}

.PrimaryTheme .table-hover .table-warning:hover {
    background-color: #fdedc0;
}

.PrimaryTheme .table-hover .table-warning:hover > td, .PrimaryTheme .table-hover .table-warning:hover > th {
    background-color: #fdedc0;
}

.PrimaryTheme .table-danger, .PrimaryTheme .table-danger > td, .PrimaryTheme .table-danger > th {
    background-color: #f6cbd1;
}

.PrimaryTheme .table-danger tbody + tbody, .PrimaryTheme .table-danger td, .PrimaryTheme .table-danger th, .PrimaryTheme .table-danger thead th {
    border-color: #ee9fa9;
}

.PrimaryTheme .table-hover .table-danger:hover {
    background-color: #f2b5be;
}

.PrimaryTheme .table-hover .table-danger:hover > td, .PrimaryTheme .table-hover .table-danger:hover > th {
    background-color: #f2b5be;
}

.PrimaryTheme .table-light, .PrimaryTheme .table-light > td, .PrimaryTheme .table-light > th {
    background-color: #fdfefe;
}

.PrimaryTheme .table-light tbody + tbody, .PrimaryTheme .table-light td, .PrimaryTheme .table-light th, .PrimaryTheme .table-light thead th {
    border-color: #fcfdfe;
}

.PrimaryTheme .table-hover .table-light:hover {
    background-color: #ecf6f6;
}

.PrimaryTheme .table-hover .table-light:hover > td, .PrimaryTheme .table-hover .table-light:hover > th {
    background-color: #ecf6f6;
}

.PrimaryTheme .table-dark, .PrimaryTheme .table-dark > td, .PrimaryTheme .table-dark > th {
    background-color: #bfc3cd;
}

.PrimaryTheme .table-dark tbody + tbody, .PrimaryTheme .table-dark td, .PrimaryTheme .table-dark th, .PrimaryTheme .table-dark thead th {
    border-color: #8890a3;
}

.PrimaryTheme .table-hover .table-dark:hover {
    background-color: #b1b6c2;
}

.PrimaryTheme .table-hover .table-dark:hover > td, .PrimaryTheme .table-hover .table-dark:hover > th {
    background-color: #b1b6c2;
}

.PrimaryTheme .table-primary-desat, .PrimaryTheme .table-primary-desat > td, .PrimaryTheme .table-primary-desat > th {
    background-color: #d6defa;
}

.PrimaryTheme .table-primary-desat tbody + tbody, .PrimaryTheme .table-primary-desat td, .PrimaryTheme .table-primary-desat th, .PrimaryTheme .table-primary-desat thead th {
    border-color: #b3c2f5;
}

.PrimaryTheme .table-hover .table-primary-desat:hover {
    background-color: #bfccf7;
}

.PrimaryTheme .table-hover .table-primary-desat:hover > td, .PrimaryTheme .table-hover .table-primary-desat:hover > th {
    background-color: #bfccf7;
}

.PrimaryTheme .table-black, .PrimaryTheme .table-black > td, .PrimaryTheme .table-black > th {
    background-color: #bebfc4;
}

.PrimaryTheme .table-black tbody + tbody, .PrimaryTheme .table-black td, .PrimaryTheme .table-black th, .PrimaryTheme .table-black thead th {
    border-color: #868992;
}

.PrimaryTheme .table-hover .table-black:hover {
    background-color: #b1b2b8;
}

.PrimaryTheme .table-hover .table-black:hover > td, .PrimaryTheme .table-hover .table-black:hover > th {
    background-color: #b1b2b8;
}

.PrimaryTheme .table-white, .PrimaryTheme .table-white > td, .PrimaryTheme .table-white > th {
    background-color: #fff;
}

.PrimaryTheme .table-white tbody + tbody, .PrimaryTheme .table-white td, .PrimaryTheme .table-white th, .PrimaryTheme .table-white thead th {
    border-color: #fff;
}

.PrimaryTheme .table-hover .table-white:hover {
    background-color: #f2f2f2;
}

.PrimaryTheme .table-hover .table-white:hover > td, .PrimaryTheme .table-hover .table-white:hover > th {
    background-color: #f2f2f2;
}

.PrimaryTheme .table-active, .PrimaryTheme .table-active > td, .PrimaryTheme .table-active > th {
    background-color: rgba(22, 28, 45, 0.075);
}

.PrimaryTheme .table-hover .table-active:hover {
    background-color: rgba(14, 17, 28, 0.075);
}

.PrimaryTheme .table-hover .table-active:hover > td, .PrimaryTheme .table-hover .table-active:hover > th {
    background-color: rgba(14, 17, 28, 0.075);
}

.PrimaryTheme .table .thead-dark th {
    color: #fff;
    background-color: #384c74;
    border-color: #445d8e;
}

.PrimaryTheme .table .thead-light th {
    color: #161c2d;
    background-color: #fff;
    border-color: #f1f4f8;
}

.PrimaryTheme .table-dark {
    color: #fff;
    background-color: #384c74;
}

.PrimaryTheme .table-dark td, .PrimaryTheme .table-dark th, .PrimaryTheme .table-dark thead th {
    border-color: #445d8e;
}

.PrimaryTheme .table-dark.table-bordered {
    border: 0;
}

.PrimaryTheme .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.PrimaryTheme .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .PrimaryTheme .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .PrimaryTheme .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .PrimaryTheme .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .PrimaryTheme .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .PrimaryTheme .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .PrimaryTheme .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .PrimaryTheme .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .PrimaryTheme .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.PrimaryTheme .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.PrimaryTheme .table-responsive > .table-bordered {
    border: 0;
}

.PrimaryTheme .form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    padding: .8125rem 1.25rem;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f1f4f8;
    border-radius: .375rem;
    box-shadow: none;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .form-control {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.PrimaryTheme .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #161c2d;
}

.PrimaryTheme .form-control:focus {
    color: #161c2d;
    background-color: #fff;
    border-color: #a7b9f6;
    outline: 0;
    box-shadow: none,none;
}

.PrimaryTheme .form-control::-webkit-input-placeholder {
    color: #869ab8;
    opacity: 1;
}

.PrimaryTheme .form-control::-moz-placeholder {
    color: #869ab8;
    opacity: 1;
}

.PrimaryTheme .form-control:-ms-input-placeholder {
    color: #869ab8;
    opacity: 1;
}

.PrimaryTheme .form-control::-ms-input-placeholder {
    color: #869ab8;
    opacity: 1;
}

.PrimaryTheme .form-control::placeholder {
    color: #869ab8;
    opacity: 1;
}

.PrimaryTheme .form-control:disabled, .PrimaryTheme .form-control[readonly] {
    background-color: #f1f4f8;
    opacity: 1;
}

.PrimaryTheme select.form-control:focus::-ms-value {
    color: #161c2d;
    background-color: #fff;
}

.PrimaryTheme .form-control-file, .PrimaryTheme .form-control-range {
    display: block;
    width: 100%;
}

.PrimaryTheme .col-form-label {
    padding-top: calc(.8125rem + 1px);
    padding-bottom: calc(.8125rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6;
}

.PrimaryTheme .col-form-label-lg {
    padding-top: calc(1.122rem + 1px);
    padding-bottom: calc(1.122rem + 1px);
    font-size: 1.0625rem;
    line-height: 1.3;
}

.PrimaryTheme .col-form-label-sm {
    padding-top: calc(.5625rem + 1px);
    padding-bottom: calc(.5625rem + 1px);
    font-size: 1.0625rem;
    line-height: 1.3;
}

.PrimaryTheme .form-control-plaintext {
    display: block;
    width: 100%;
    padding: .8125rem 0;
    margin-bottom: 0;
    font-size: 1.0625rem;
    line-height: 1.6;
    color: #161c2d;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.PrimaryTheme .form-control-plaintext.form-control-lg, .PrimaryTheme .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}

.PrimaryTheme .form-control-sm {
    height: calc(1.3em + 1.125rem + 2px);
    padding: .5625rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem;
}

.PrimaryTheme .form-control-lg {
    height: calc(1.3em + 2.244rem + 2px);
    padding: 1.122rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem;
}

.PrimaryTheme select.form-control[multiple], .PrimaryTheme select.form-control[size] {
    height: auto;
}

.PrimaryTheme textarea.form-control {
    height: auto;
}

.PrimaryTheme .form-group {
    margin-bottom: 1rem;
}

.PrimaryTheme .form-text {
    display: block;
    margin-top: .25rem;
}

.PrimaryTheme .form-row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.PrimaryTheme .form-row > .col, .PrimaryTheme .form-row > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
}

.PrimaryTheme .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.PrimaryTheme .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
}

.PrimaryTheme .form-check-input:disabled ~ .form-check-label, .PrimaryTheme .form-check-input[disabled] ~ .form-check-label {
    color: #869ab8;
}

.PrimaryTheme .form-check-label {
    margin-bottom: 0;
}

.PrimaryTheme .form-check-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
}

.PrimaryTheme .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.PrimaryTheme .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 88.2%;
    color: #42ba96;
}

.PrimaryTheme .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .9375rem;
    line-height: 1.6;
    color: #fff;
    background-color: rgba(66, 186, 150, 0.9);
    border-radius: .375rem;
}

.PrimaryTheme .is-valid ~ .valid-feedback, .PrimaryTheme .is-valid ~ .valid-tooltip, .PrimaryTheme .was-validated :valid ~ .valid-feedback, .PrimaryTheme .was-validated :valid ~ .valid-tooltip {
    display: block;
}

.PrimaryTheme .form-control.is-valid, .PrimaryTheme .was-validated .form-control:valid {
    border-color: #42ba96;
}

.PrimaryTheme .form-control.is-valid:focus, .PrimaryTheme .was-validated .form-control:valid:focus {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}

.PrimaryTheme .custom-select.is-valid, .PrimaryTheme .was-validated .custom-select:valid {
    border-color: #42ba96;
}

.PrimaryTheme .custom-select.is-valid:focus, .PrimaryTheme .was-validated .custom-select:valid:focus {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}

.PrimaryTheme .form-check-input.is-valid ~ .form-check-label, .PrimaryTheme .was-validated .form-check-input:valid ~ .form-check-label {
    color: #42ba96;
}

.PrimaryTheme .form-check-input.is-valid ~ .valid-feedback, .PrimaryTheme .form-check-input.is-valid ~ .valid-tooltip, .PrimaryTheme .was-validated .form-check-input:valid ~ .valid-feedback, .PrimaryTheme .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
}

.PrimaryTheme .custom-control-input.is-valid ~ .custom-control-label, .PrimaryTheme .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #42ba96;
}

.PrimaryTheme .custom-control-input.is-valid ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: #42ba96;
}

.PrimaryTheme .custom-control-input.is-valid:checked ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: #66c9ab;
    background-color: #66c9ab;
}

.PrimaryTheme .custom-control-input.is-valid:focus ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}

.PrimaryTheme .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #42ba96;
}

.PrimaryTheme .custom-file-input.is-valid ~ .custom-file-label, .PrimaryTheme .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #42ba96;
}

.PrimaryTheme .custom-file-input.is-valid:focus ~ .custom-file-label, .PrimaryTheme .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}

.PrimaryTheme .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 88.2%;
    color: #df4759;
}

.PrimaryTheme .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .9375rem;
    line-height: 1.6;
    color: #fff;
    background-color: rgba(223, 71, 89, 0.9);
    border-radius: .375rem;
}

.PrimaryTheme .is-invalid ~ .invalid-feedback, .PrimaryTheme .is-invalid ~ .invalid-tooltip, .PrimaryTheme .was-validated :invalid ~ .invalid-feedback, .PrimaryTheme .was-validated :invalid ~ .invalid-tooltip {
    display: block;
}

.PrimaryTheme .form-control.is-invalid, .PrimaryTheme .was-validated .form-control:invalid {
    border-color: #df4759;
}

.PrimaryTheme .form-control.is-invalid:focus, .PrimaryTheme .was-validated .form-control:invalid:focus {
    border-color: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}

.PrimaryTheme .custom-select.is-invalid, .PrimaryTheme .was-validated .custom-select:invalid {
    border-color: #df4759;
}

.PrimaryTheme .custom-select.is-invalid:focus, .PrimaryTheme .was-validated .custom-select:invalid:focus {
    border-color: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}

.PrimaryTheme .form-check-input.is-invalid ~ .form-check-label, .PrimaryTheme .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #df4759;
}

.PrimaryTheme .form-check-input.is-invalid ~ .invalid-feedback, .PrimaryTheme .form-check-input.is-invalid ~ .invalid-tooltip, .PrimaryTheme .was-validated .form-check-input:invalid ~ .invalid-feedback, .PrimaryTheme .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
}

.PrimaryTheme .custom-control-input.is-invalid ~ .custom-control-label, .PrimaryTheme .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #df4759;
}

.PrimaryTheme .custom-control-input.is-invalid ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: #df4759;
}

.PrimaryTheme .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
    border-color: #e77280;
    background-color: #e77280;
}

.PrimaryTheme .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}

.PrimaryTheme .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .PrimaryTheme .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #df4759;
}

.PrimaryTheme .custom-file-input.is-invalid ~ .custom-file-label, .PrimaryTheme .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #df4759;
}

.PrimaryTheme .custom-file-input.is-invalid:focus ~ .custom-file-label, .PrimaryTheme .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}

.PrimaryTheme .form-inline {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
}

.PrimaryTheme .form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .PrimaryTheme .form-inline label {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .PrimaryTheme .form-inline .form-group {
        display: -webkit-box;
        display: flex;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 0;
    }
    .PrimaryTheme .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .PrimaryTheme .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .PrimaryTheme .form-inline .custom-select, .PrimaryTheme .form-inline .input-group {
        width: auto;
    }
    .PrimaryTheme .form-inline .form-check {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .PrimaryTheme .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0;
    }
    .PrimaryTheme .form-inline .custom-control {
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
    .PrimaryTheme .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.PrimaryTheme .btn {
    display: inline-block;
    font-weight: 600;
    color: #161c2d;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .8125rem 1.25rem;
    font-size: 1.0625rem;
    line-height: 1.6;
    border-radius: .375rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .btn {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .btn:hover {
    color: #161c2d;
    text-decoration: none;
}

.PrimaryTheme .btn.focus, .PrimaryTheme .btn:focus {
    outline: 0;
    box-shadow: none;
}

.PrimaryTheme .btn.disabled, .PrimaryTheme .btn:disabled {
    opacity: .65;
    box-shadow: none;
}

.PrimaryTheme .btn:not(:disabled):not(.disabled).active, .PrimaryTheme .btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
}

.PrimaryTheme a.btn.disabled, .PrimaryTheme fieldset:disabled a.btn {
    pointer-events: none;
}

.PrimaryTheme .btn-primary {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
    box-shadow: none;
}

.PrimaryTheme .btn-primary:hover {
    color: #fff;
    background-color: #1746e0;
    border-color: #1643d4;
}

.PrimaryTheme .btn-primary.focus, .PrimaryTheme .btn-primary:focus {
    color: #fff;
    background-color: #1746e0;
    border-color: #1643d4;
    box-shadow: none, 0 0 0 0 rgba(82, 118, 237, 0.5);
}

.PrimaryTheme .btn-primary.disabled, .PrimaryTheme .btn-primary:disabled {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .btn-primary:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-primary:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1643d4;
    border-color: #153fc9;
}

.PrimaryTheme .btn-primary:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-primary:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5);
}

.PrimaryTheme .btn-secondary {
    color: #fff;
    background-color: #506690;
    border-color: #506690;
    box-shadow: none;
}

.PrimaryTheme .btn-secondary:hover {
    color: #fff;
    background-color: #425577;
    border-color: #3e4f6f;
}

.PrimaryTheme .btn-secondary.focus, .PrimaryTheme .btn-secondary:focus {
    color: #fff;
    background-color: #425577;
    border-color: #3e4f6f;
    box-shadow: none, 0 0 0 0 rgba(106, 125, 161, 0.5);
}

.PrimaryTheme .btn-secondary.disabled, .PrimaryTheme .btn-secondary:disabled {
    color: #fff;
    background-color: #506690;
    border-color: #506690;
}

.PrimaryTheme .btn-secondary:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-secondary:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3e4f6f;
    border-color: #394967;
}

.PrimaryTheme .btn-secondary:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-secondary:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(106, 125, 161, 0.5);
}

.PrimaryTheme .btn-success {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96;
    box-shadow: none;
}

.PrimaryTheme .btn-success:hover {
    color: #fff;
    background-color: #389e7f;
    border-color: #359478;
}

.PrimaryTheme .btn-success.focus, .PrimaryTheme .btn-success:focus {
    color: #fff;
    background-color: #389e7f;
    border-color: #359478;
    box-shadow: none, 0 0 0 0 rgba(94, 196, 166, 0.5);
}

.PrimaryTheme .btn-success.disabled, .PrimaryTheme .btn-success:disabled {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96;
}

.PrimaryTheme .btn-success:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-success:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #359478;
    border-color: #318b70;
}

.PrimaryTheme .btn-success:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-success:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5);
}

.PrimaryTheme .btn-info {
    color: #fff;
    background-color: #7c69ef;
    border-color: #7c69ef;
    box-shadow: none;
}

.PrimaryTheme .btn-info:hover {
    color: #fff;
    background-color: #5e46eb;
    border-color: #543bea;
}

.PrimaryTheme .btn-info.focus, .PrimaryTheme .btn-info:focus {
    color: #fff;
    background-color: #5e46eb;
    border-color: #543bea;
    box-shadow: none, 0 0 0 0 rgba(144, 128, 241, 0.5);
}

.PrimaryTheme .btn-info.disabled, .PrimaryTheme .btn-info:disabled {
    color: #fff;
    background-color: #7c69ef;
    border-color: #7c69ef;
}

.PrimaryTheme .btn-info:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-info:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #543bea;
    border-color: #4a2fe9;
}

.PrimaryTheme .btn-info:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-info:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(144, 128, 241, 0.5);
}

.PrimaryTheme .btn-warning {
    color: #1b2a4e;
    background-color: #fad776;
    border-color: #fad776;
    box-shadow: none;
}

.PrimaryTheme .btn-warning:hover {
    color: #1b2a4e;
    background-color: #f9cc51;
    border-color: #f8c945;
}

.PrimaryTheme .btn-warning.focus, .PrimaryTheme .btn-warning:focus {
    color: #1b2a4e;
    background-color: #f9cc51;
    border-color: #f8c945;
    box-shadow: none, 0 0 0 0 rgba(217, 189, 112, 0.5);
}

.PrimaryTheme .btn-warning.disabled, .PrimaryTheme .btn-warning:disabled {
    color: #1b2a4e;
    background-color: #fad776;
    border-color: #fad776;
}

.PrimaryTheme .btn-warning:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-warning:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-warning.dropdown-toggle {
    color: #1b2a4e;
    background-color: #f8c945;
    border-color: #f8c538;
}

.PrimaryTheme .btn-warning:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-warning:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(217, 189, 112, 0.5);
}

.PrimaryTheme .btn-danger {
    color: #fff;
    background-color: #df4759;
    border-color: #df4759;
    box-shadow: none;
}

.PrimaryTheme .btn-danger:hover {
    color: #fff;
    background-color: #d9263c;
    border-color: #cf2438;
}

.PrimaryTheme .btn-danger.focus, .PrimaryTheme .btn-danger:focus {
    color: #fff;
    background-color: #d9263c;
    border-color: #cf2438;
    box-shadow: none, 0 0 0 0 rgba(228, 99, 114, 0.5);
}

.PrimaryTheme .btn-danger.disabled, .PrimaryTheme .btn-danger:disabled {
    color: #fff;
    background-color: #df4759;
    border-color: #df4759;
}

.PrimaryTheme .btn-danger:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-danger:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cf2438;
    border-color: #c42235;
}

.PrimaryTheme .btn-danger:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-danger:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(228, 99, 114, 0.5);
}

.PrimaryTheme .btn-light {
    color: #1b2a4e;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
    box-shadow: none;
}

.PrimaryTheme .btn-light:hover {
    color: #1b2a4e;
    background-color: #dce8f3;
    border-color: #d3e2f0;
}

.PrimaryTheme .btn-light.focus, .PrimaryTheme .btn-light:focus {
    color: #1b2a4e;
    background-color: #dce8f3;
    border-color: #d3e2f0;
    box-shadow: none, 0 0 0 0 rgba(216, 220, 227, 0.5);
}

.PrimaryTheme .btn-light.disabled, .PrimaryTheme .btn-light:disabled {
    color: #1b2a4e;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
}

.PrimaryTheme .btn-light:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-light:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-light.dropdown-toggle {
    color: #1b2a4e;
    background-color: #d3e2f0;
    border-color: #c9dbed;
}

.PrimaryTheme .btn-light:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-light:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(216, 220, 227, 0.5);
}

.PrimaryTheme .btn-dark {
    color: #fff;
    background-color: #1b2a4e;
    border-color: #1b2a4e;
    box-shadow: none;
}

.PrimaryTheme .btn-dark:hover {
    color: #fff;
    background-color: #111b32;
    border-color: #0e1628;
}

.PrimaryTheme .btn-dark.focus, .PrimaryTheme .btn-dark:focus {
    color: #fff;
    background-color: #111b32;
    border-color: #0e1628;
    box-shadow: none, 0 0 0 0 rgba(61, 74, 105, 0.5);
}

.PrimaryTheme .btn-dark.disabled, .PrimaryTheme .btn-dark:disabled {
    color: #fff;
    background-color: #1b2a4e;
    border-color: #1b2a4e;
}

.PrimaryTheme .btn-dark:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-dark:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0e1628;
    border-color: #0b111f;
}

.PrimaryTheme .btn-dark:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-dark:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(61, 74, 105, 0.5);
}

.PrimaryTheme .btn-primary-desat {
    color: #fff;
    background-color: #6c8aec;
    border-color: #6c8aec;
    box-shadow: none;
}

.PrimaryTheme .btn-primary-desat:hover {
    color: #fff;
    background-color: #4a6fe8;
    border-color: #3f66e6;
}

.PrimaryTheme .btn-primary-desat.focus, .PrimaryTheme .btn-primary-desat:focus {
    color: #fff;
    background-color: #4a6fe8;
    border-color: #3f66e6;
    box-shadow: none, 0 0 0 0 rgba(130, 156, 239, 0.5);
}

.PrimaryTheme .btn-primary-desat.disabled, .PrimaryTheme .btn-primary-desat:disabled {
    color: #fff;
    background-color: #6c8aec;
    border-color: #6c8aec;
}

.PrimaryTheme .btn-primary-desat:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-primary-desat:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-primary-desat.dropdown-toggle {
    color: #fff;
    background-color: #3f66e6;
    border-color: #345de5;
}

.PrimaryTheme .btn-primary-desat:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-primary-desat:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-primary-desat.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(130, 156, 239, 0.5);
}

.PrimaryTheme .btn-black {
    color: #fff;
    background-color: #161c2d;
    border-color: #161c2d;
    box-shadow: none;
}

.PrimaryTheme .btn-black:hover {
    color: #fff;
    background-color: #090c13;
    border-color: #05070b;
}

.PrimaryTheme .btn-black.focus, .PrimaryTheme .btn-black:focus {
    color: #fff;
    background-color: #090c13;
    border-color: #05070b;
    box-shadow: none, 0 0 0 0 rgba(57, 62, 77, 0.5);
}

.PrimaryTheme .btn-black.disabled, .PrimaryTheme .btn-black:disabled {
    color: #fff;
    background-color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .btn-black:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-black:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: #05070b;
    border-color: #010102;
}

.PrimaryTheme .btn-black:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-black:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(57, 62, 77, 0.5);
}

.PrimaryTheme .btn-white {
    color: #1b2a4e;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
}

.PrimaryTheme .btn-white:hover {
    color: #1b2a4e;
    background-color: #ececec;
    border-color: #e6e6e6;
}

.PrimaryTheme .btn-white.focus, .PrimaryTheme .btn-white:focus {
    color: #1b2a4e;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: none, 0 0 0 0 rgba(221, 223, 228, 0.5);
}

.PrimaryTheme .btn-white.disabled, .PrimaryTheme .btn-white:disabled {
    color: #1b2a4e;
    background-color: #fff;
    border-color: #fff;
}

.PrimaryTheme .btn-white:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-white:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-white.dropdown-toggle {
    color: #1b2a4e;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}

.PrimaryTheme .btn-white:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-white:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5);
}

.PrimaryTheme .btn-outline-primary {
    color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .btn-outline-primary:hover {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .btn-outline-primary.focus, .PrimaryTheme .btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .btn-outline-primary.disabled, .PrimaryTheme .btn-outline-primary:disabled {
    color: #335eea;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-primary:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-primary:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .btn-outline-secondary {
    color: #506690;
    border-color: #506690;
}

.PrimaryTheme .btn-outline-secondary:hover {
    color: #fff;
    background-color: #506690;
    border-color: #506690;
}

.PrimaryTheme .btn-outline-secondary.focus, .PrimaryTheme .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}

.PrimaryTheme .btn-outline-secondary.disabled, .PrimaryTheme .btn-outline-secondary:disabled {
    color: #506690;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-secondary:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-secondary:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #506690;
    border-color: #506690;
}

.PrimaryTheme .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}

.PrimaryTheme .btn-outline-success {
    color: #42ba96;
    border-color: #42ba96;
}

.PrimaryTheme .btn-outline-success:hover {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96;
}

.PrimaryTheme .btn-outline-success.focus, .PrimaryTheme .btn-outline-success:focus {
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5);
}

.PrimaryTheme .btn-outline-success.disabled, .PrimaryTheme .btn-outline-success:disabled {
    color: #42ba96;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-success:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-success:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96;
}

.PrimaryTheme .btn-outline-success:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-success:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5);
}

.PrimaryTheme .btn-outline-info {
    color: #7c69ef;
    border-color: #7c69ef;
}

.PrimaryTheme .btn-outline-info:hover {
    color: #fff;
    background-color: #7c69ef;
    border-color: #7c69ef;
}

.PrimaryTheme .btn-outline-info.focus, .PrimaryTheme .btn-outline-info:focus {
    box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5);
}

.PrimaryTheme .btn-outline-info.disabled, .PrimaryTheme .btn-outline-info:disabled {
    color: #7c69ef;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-info:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-info:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #7c69ef;
    border-color: #7c69ef;
}

.PrimaryTheme .btn-outline-info:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-info:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5);
}

.PrimaryTheme .btn-outline-warning {
    color: #fad776;
    border-color: #fad776;
}

.PrimaryTheme .btn-outline-warning:hover {
    color: #1b2a4e;
    background-color: #fad776;
    border-color: #fad776;
}

.PrimaryTheme .btn-outline-warning.focus, .PrimaryTheme .btn-outline-warning:focus {
    box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5);
}

.PrimaryTheme .btn-outline-warning.disabled, .PrimaryTheme .btn-outline-warning:disabled {
    color: #fad776;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-warning:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-warning:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-warning.dropdown-toggle {
    color: #1b2a4e;
    background-color: #fad776;
    border-color: #fad776;
}

.PrimaryTheme .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5);
}

.PrimaryTheme .btn-outline-danger {
    color: #df4759;
    border-color: #df4759;
}

.PrimaryTheme .btn-outline-danger:hover {
    color: #fff;
    background-color: #df4759;
    border-color: #df4759;
}

.PrimaryTheme .btn-outline-danger.focus, .PrimaryTheme .btn-outline-danger:focus {
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5);
}

.PrimaryTheme .btn-outline-danger.disabled, .PrimaryTheme .btn-outline-danger:disabled {
    color: #df4759;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-danger:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-danger:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #df4759;
    border-color: #df4759;
}

.PrimaryTheme .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5);
}

.PrimaryTheme .btn-outline-light {
    color: #f9fbfd;
    border-color: #f9fbfd;
}

.PrimaryTheme .btn-outline-light:hover {
    color: #1b2a4e;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
}

.PrimaryTheme .btn-outline-light.focus, .PrimaryTheme .btn-outline-light:focus {
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
}

.PrimaryTheme .btn-outline-light.disabled, .PrimaryTheme .btn-outline-light:disabled {
    color: #f9fbfd;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-light:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-light:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-light.dropdown-toggle {
    color: #1b2a4e;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
}

.PrimaryTheme .btn-outline-light:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-light:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
}

.PrimaryTheme .btn-outline-dark {
    color: #1b2a4e;
    border-color: #1b2a4e;
}

.PrimaryTheme .btn-outline-dark:hover {
    color: #fff;
    background-color: #1b2a4e;
    border-color: #1b2a4e;
}

.PrimaryTheme .btn-outline-dark.focus, .PrimaryTheme .btn-outline-dark:focus {
    box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5);
}

.PrimaryTheme .btn-outline-dark.disabled, .PrimaryTheme .btn-outline-dark:disabled {
    color: #1b2a4e;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-dark:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-dark:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1b2a4e;
    border-color: #1b2a4e;
}

.PrimaryTheme .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5);
}

.PrimaryTheme .btn-outline-primary-desat {
    color: #6c8aec;
    border-color: #6c8aec;
}

.PrimaryTheme .btn-outline-primary-desat:hover {
    color: #fff;
    background-color: #6c8aec;
    border-color: #6c8aec;
}

.PrimaryTheme .btn-outline-primary-desat.focus, .PrimaryTheme .btn-outline-primary-desat:focus {
    box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5);
}

.PrimaryTheme .btn-outline-primary-desat.disabled, .PrimaryTheme .btn-outline-primary-desat:disabled {
    color: #6c8aec;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-primary-desat:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-primary-desat:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-primary-desat.dropdown-toggle {
    color: #fff;
    background-color: #6c8aec;
    border-color: #6c8aec;
}

.PrimaryTheme .btn-outline-primary-desat:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-primary-desat:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-primary-desat.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5);
}

.PrimaryTheme .btn-outline-black {
    color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .btn-outline-black:hover {
    color: #fff;
    background-color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .btn-outline-black.focus, .PrimaryTheme .btn-outline-black:focus {
    box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5);
}

.PrimaryTheme .btn-outline-black.disabled, .PrimaryTheme .btn-outline-black:disabled {
    color: #161c2d;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-black:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-black:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .btn-outline-black:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-black:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5);
}

.PrimaryTheme .btn-outline-white {
    color: #fff;
    border-color: #fff;
}

.PrimaryTheme .btn-outline-white:hover {
    color: #1b2a4e;
    background-color: #fff;
    border-color: #fff;
}

.PrimaryTheme .btn-outline-white.focus, .PrimaryTheme .btn-outline-white:focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.PrimaryTheme .btn-outline-white.disabled, .PrimaryTheme .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent;
}

.PrimaryTheme .btn-outline-white:not(:disabled):not(.disabled).active, .PrimaryTheme .btn-outline-white:not(:disabled):not(.disabled):active, .PrimaryTheme .show > .btn-outline-white.dropdown-toggle {
    color: #1b2a4e;
    background-color: #fff;
    border-color: #fff;
}

.PrimaryTheme .btn-outline-white:not(:disabled):not(.disabled).active:focus, .PrimaryTheme .btn-outline-white:not(:disabled):not(.disabled):active:focus, .PrimaryTheme .show > .btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.PrimaryTheme .btn-link {
    font-weight: 400;
    color: #335eea;
    text-decoration: none;
}

.PrimaryTheme .btn-link:hover {
    color: #133bbd;
    text-decoration: underline;
}

.PrimaryTheme .btn-link.focus, .PrimaryTheme .btn-link:focus {
    text-decoration: underline;
    box-shadow: none;
}

.PrimaryTheme .btn-link.disabled, .PrimaryTheme .btn-link:disabled {
    color: #869ab8;
    pointer-events: none;
}

.PrimaryTheme .btn-group-lg > .btn, .PrimaryTheme .btn-lg {
    padding: 1.122rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem;
}

.PrimaryTheme .btn-group-sm > .btn, .PrimaryTheme .btn-sm {
    padding: .5625rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem;
}

.PrimaryTheme .btn-block {
    display: block;
    width: 100%;
}

.PrimaryTheme .btn-block + .btn-block {
    margin-top: .5rem;
}

.PrimaryTheme input[type=button].btn-block, .PrimaryTheme input[type=reset].btn-block, .PrimaryTheme input[type=submit].btn-block {
    width: 100%;
}

.PrimaryTheme .fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .fade {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .fade:not(.show) {
    opacity: 0;
}

.PrimaryTheme .collapse:not(.show) {
    display: none;
}

.PrimaryTheme .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .collapsing {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .dropdown, .PrimaryTheme .dropleft, .PrimaryTheme .dropright, .PrimaryTheme .dropup {
    position: relative;
}

.PrimaryTheme .dropdown-toggle {
    white-space: nowrap;
}

.PrimaryTheme .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.PrimaryTheme .dropdown-toggle:empty::after {
    margin-left: 0;
}

.PrimaryTheme .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 14rem;
    padding: 1.625rem 0;
    margin: 0;
    font-size: .9375rem;
    color: #161c2d;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(22, 28, 45, 0.15);
    border-radius: .375rem;
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .dropdown-menu-left {
    right: auto;
    left: 0;
}

.PrimaryTheme .dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .PrimaryTheme .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .PrimaryTheme .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .PrimaryTheme .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .PrimaryTheme .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .PrimaryTheme .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.PrimaryTheme .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.PrimaryTheme .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
}

.PrimaryTheme .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.PrimaryTheme .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0;
}

.PrimaryTheme .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}

.PrimaryTheme .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.PrimaryTheme .dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.PrimaryTheme .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0;
}

.PrimaryTheme .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
}

.PrimaryTheme .dropleft .dropdown-toggle::after {
    display: none;
}

.PrimaryTheme .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
}

.PrimaryTheme .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.PrimaryTheme .dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.PrimaryTheme .dropdown-menu[x-placement^=bottom], .PrimaryTheme .dropdown-menu[x-placement^=left], .PrimaryTheme .dropdown-menu[x-placement^=right], .PrimaryTheme .dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto;
}

.PrimaryTheme .dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #f1f4f8;
}

.PrimaryTheme .dropdown-item {
    display: block;
    width: 100%;
    padding: 0 1.75rem;
    clear: both;
    font-weight: 400;
    color: #506690;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.PrimaryTheme .dropdown-item:focus, .PrimaryTheme .dropdown-item:hover {
    color: #335eea;
    text-decoration: none;
    background-color: none;
}

.PrimaryTheme .dropdown-item.active, .PrimaryTheme .dropdown-item:active {
    color: #335eea;
    text-decoration: none;
    background-color: none;
}

.PrimaryTheme .dropdown-item.disabled, .PrimaryTheme .dropdown-item:disabled {
    color: #869ab8;
    pointer-events: none;
    background-color: transparent;
}

.PrimaryTheme .dropdown-menu.show {
    display: block;
}

.PrimaryTheme .dropdown-header {
    display: block;
    padding: 1.625rem 1.75rem;
    margin-bottom: 0;
    font-size: .9375rem;
    color: #335eea;
    white-space: nowrap;
}

.PrimaryTheme .dropdown-item-text {
    display: block;
    padding: 0 1.75rem;
    color: #506690;
}

.PrimaryTheme .btn-group, .PrimaryTheme .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    vertical-align: middle;
}

.PrimaryTheme .btn-group-vertical > .btn, .PrimaryTheme .btn-group > .btn {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}

.PrimaryTheme .btn-group-vertical > .btn:hover, .PrimaryTheme .btn-group > .btn:hover {
    z-index: 1;
}

.PrimaryTheme .btn-group-vertical > .btn.active, .PrimaryTheme .btn-group-vertical > .btn:active, .PrimaryTheme .btn-group-vertical > .btn:focus, .PrimaryTheme .btn-group > .btn.active, .PrimaryTheme .btn-group > .btn:active, .PrimaryTheme .btn-group > .btn:focus {
    z-index: 1;
}

.PrimaryTheme .btn-toolbar {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.PrimaryTheme .btn-toolbar .input-group {
    width: auto;
}

.PrimaryTheme .btn-group > .btn-group:not(:first-child), .PrimaryTheme .btn-group > .btn:not(:first-child) {
    margin-left: -1px;
}

.PrimaryTheme .btn-group > .btn-group:not(:last-child) > .btn, .PrimaryTheme .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.PrimaryTheme .btn-group > .btn-group:not(:first-child) > .btn, .PrimaryTheme .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .dropdown-toggle-split {
    padding-right: .9375rem;
    padding-left: .9375rem;
}

.PrimaryTheme .dropdown-toggle-split::after, .PrimaryTheme .dropright .dropdown-toggle-split::after, .PrimaryTheme .dropup .dropdown-toggle-split::after {
    margin-left: 0;
}

.PrimaryTheme .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.PrimaryTheme .btn-group-sm > .btn + .dropdown-toggle-split, .PrimaryTheme .btn-sm + .dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem;
}

.PrimaryTheme .btn-group-lg > .btn + .dropdown-toggle-split, .PrimaryTheme .btn-lg + .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
}

.PrimaryTheme .btn-group.show .dropdown-toggle {
    box-shadow: none;
}

.PrimaryTheme .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none;
}

.PrimaryTheme .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
}

.PrimaryTheme .btn-group-vertical > .btn, .PrimaryTheme .btn-group-vertical > .btn-group {
    width: 100%;
}

.PrimaryTheme .btn-group-vertical > .btn-group:not(:first-child), .PrimaryTheme .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px;
}

.PrimaryTheme .btn-group-vertical > .btn-group:not(:last-child) > .btn, .PrimaryTheme .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .btn-group-vertical > .btn-group:not(:first-child) > .btn, .PrimaryTheme .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.PrimaryTheme .btn-group-toggle > .btn, .PrimaryTheme .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}

.PrimaryTheme .btn-group-toggle > .btn input[type=checkbox], .PrimaryTheme .btn-group-toggle > .btn input[type=radio], .PrimaryTheme .btn-group-toggle > .btn-group > .btn input[type=checkbox], .PrimaryTheme .btn-group-toggle > .btn-group > .btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.PrimaryTheme .input-group {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
}

.PrimaryTheme .input-group > .custom-file, .PrimaryTheme .input-group > .custom-select, .PrimaryTheme .input-group > .form-control, .PrimaryTheme .input-group > .form-control-plaintext {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}

.PrimaryTheme .input-group > .custom-file + .custom-file, .PrimaryTheme .input-group > .custom-file + .custom-select, .PrimaryTheme .input-group > .custom-file + .form-control, .PrimaryTheme .input-group > .custom-select + .custom-file, .PrimaryTheme .input-group > .custom-select + .custom-select, .PrimaryTheme .input-group > .custom-select + .form-control, .PrimaryTheme .input-group > .form-control + .custom-file, .PrimaryTheme .input-group > .form-control + .custom-select, .PrimaryTheme .input-group > .form-control + .form-control, .PrimaryTheme .input-group > .form-control-plaintext + .custom-file, .PrimaryTheme .input-group > .form-control-plaintext + .custom-select, .PrimaryTheme .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px;
}

.PrimaryTheme .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .PrimaryTheme .input-group > .custom-select:focus, .PrimaryTheme .input-group > .form-control:focus {
    z-index: 3;
}

.PrimaryTheme .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}

.PrimaryTheme .input-group > .custom-select:not(:last-child), .PrimaryTheme .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.PrimaryTheme .input-group > .custom-select:not(:first-child), .PrimaryTheme .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .input-group > .custom-file {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.PrimaryTheme .input-group > .custom-file:not(:last-child) .custom-file-label, .PrimaryTheme .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.PrimaryTheme .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .input-group-append, .PrimaryTheme .input-group-prepend {
    display: -webkit-box;
    display: flex;
}

.PrimaryTheme .input-group-append .btn, .PrimaryTheme .input-group-prepend .btn {
    position: relative;
    z-index: 2;
}

.PrimaryTheme .input-group-append .btn:focus, .PrimaryTheme .input-group-prepend .btn:focus {
    z-index: 3;
}

.PrimaryTheme .input-group-append .btn + .btn, .PrimaryTheme .input-group-append .btn + .input-group-text, .PrimaryTheme .input-group-append .input-group-text + .btn, .PrimaryTheme .input-group-append .input-group-text + .input-group-text, .PrimaryTheme .input-group-prepend .btn + .btn, .PrimaryTheme .input-group-prepend .btn + .input-group-text, .PrimaryTheme .input-group-prepend .input-group-text + .btn, .PrimaryTheme .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
}

.PrimaryTheme .input-group-prepend {
    margin-right: -1px;
}

.PrimaryTheme .input-group-append {
    margin-left: -1px;
}

.PrimaryTheme .input-group-text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: .8125rem 1.25rem;
    margin-bottom: 0;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #869ab8;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #f1f4f8;
    border-radius: .375rem;
}

.PrimaryTheme .input-group-text input[type=checkbox], .PrimaryTheme .input-group-text input[type=radio] {
    margin-top: 0;
}

.PrimaryTheme .input-group-lg > .custom-select, .PrimaryTheme .input-group-lg > .form-control:not(textarea) {
    height: calc(1.3em + 2.244rem + 2px);
}

.PrimaryTheme .input-group-lg > .custom-select, .PrimaryTheme .input-group-lg > .form-control, .PrimaryTheme .input-group-lg > .input-group-append > .btn, .PrimaryTheme .input-group-lg > .input-group-append > .input-group-text, .PrimaryTheme .input-group-lg > .input-group-prepend > .btn, .PrimaryTheme .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 1.122rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem;
}

.PrimaryTheme .input-group-sm > .custom-select, .PrimaryTheme .input-group-sm > .form-control:not(textarea) {
    height: calc(1.3em + 1.125rem + 2px);
}

.PrimaryTheme .input-group-sm > .custom-select, .PrimaryTheme .input-group-sm > .form-control, .PrimaryTheme .input-group-sm > .input-group-append > .btn, .PrimaryTheme .input-group-sm > .input-group-append > .input-group-text, .PrimaryTheme .input-group-sm > .input-group-prepend > .btn, .PrimaryTheme .input-group-sm > .input-group-prepend > .input-group-text {
    padding: .5625rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem;
}

.PrimaryTheme .input-group-lg > .custom-select, .PrimaryTheme .input-group-sm > .custom-select {
    padding-right: 2.25rem;
}

.PrimaryTheme .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .PrimaryTheme .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .PrimaryTheme .input-group > .input-group-append:not(:last-child) > .btn, .PrimaryTheme .input-group > .input-group-append:not(:last-child) > .input-group-text, .PrimaryTheme .input-group > .input-group-prepend > .btn, .PrimaryTheme .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.PrimaryTheme .input-group > .input-group-append > .btn, .PrimaryTheme .input-group > .input-group-append > .input-group-text, .PrimaryTheme .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .PrimaryTheme .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .PrimaryTheme .input-group > .input-group-prepend:not(:first-child) > .btn, .PrimaryTheme .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .custom-control {
    position: relative;
    display: block;
    min-height: 1.7rem;
    padding-left: 1.5rem;
}

.PrimaryTheme .custom-control-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    margin-right: 1rem;
}

.PrimaryTheme .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.35rem;
    opacity: 0;
}

.PrimaryTheme .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #335eea;
    background-color: #335eea;
    box-shadow: none;
}

.PrimaryTheme .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none,none;
}

.PrimaryTheme .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a7b9f6;
}

.PrimaryTheme .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #d5defb;
    border-color: #d5defb;
    box-shadow: none;
}

.PrimaryTheme .custom-control-input:disabled ~ .custom-control-label, .PrimaryTheme .custom-control-input[disabled] ~ .custom-control-label {
    color: #869ab8;
}

.PrimaryTheme .custom-control-input:disabled ~ .custom-control-label::before, .PrimaryTheme .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #f1f4f8;
}

.PrimaryTheme .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.PrimaryTheme .custom-control-label::before {
    position: absolute;
    top: .35rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #d9e2ef;
    border: #abbcd5 solid 0;
    box-shadow: none;
}

.PrimaryTheme .custom-control-label::after {
    position: absolute;
    top: .35rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.PrimaryTheme .custom-checkbox .custom-control-label::before {
    border-radius: .375rem;
}

.PrimaryTheme .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.PrimaryTheme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #335eea;
    background-color: #335eea;
    box-shadow: none;
}

.PrimaryTheme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e");
}

.PrimaryTheme .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.PrimaryTheme .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.PrimaryTheme .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .custom-switch {
    padding-left: 3rem;
}

.PrimaryTheme .custom-switch .custom-control-label::before {
    left: -3rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: .5rem;
}

.PrimaryTheme .custom-switch .custom-control-label::after {
    top: calc(.35rem + 0px);
    left: calc(-3rem + 0px);
    width: 1rem;
    height: 1rem;
    background-color: #abbcd5;
    border-radius: .5rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #d9e2ef;
    -webkit-transform: translateX(1.5rem);
    transform: translateX(1.5rem);
}

.PrimaryTheme .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    padding: .8125rem 2.25rem .8125rem 1.25rem;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.25rem center/24px 24px;
    border: 1px solid #f1f4f8;
    border-radius: .375rem;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.PrimaryTheme .custom-select:focus {
    border-color: #a7b9f6;
    outline: 0;
    box-shadow: none,none;
}

.PrimaryTheme .custom-select:focus::-ms-value {
    color: #161c2d;
    background-color: #fff;
}

.PrimaryTheme .custom-select[multiple], .PrimaryTheme .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none;
}

.PrimaryTheme .custom-select:disabled {
    color: #869ab8;
    background-color: #f1f4f8;
}

.PrimaryTheme .custom-select::-ms-expand {
    display: none;
}

.PrimaryTheme .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #161c2d;
}

.PrimaryTheme .custom-select-sm {
    height: calc(1.3em + 1.125rem + 2px);
    padding-top: .5625rem;
    padding-bottom: .5625rem;
    padding-left: 1rem;
    font-size: 1.0625rem;
}

.PrimaryTheme .custom-select-lg {
    height: calc(1.3em + 2.244rem + 2px);
    padding-top: 1.122rem;
    padding-bottom: 1.122rem;
    padding-left: 1.5rem;
    font-size: 1.0625rem;
}

.PrimaryTheme .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    margin-bottom: 0;
}

.PrimaryTheme .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    margin: 0;
    opacity: 0;
}

.PrimaryTheme .custom-file-input:focus ~ .custom-file-label {
    border-color: #a7b9f6;
    box-shadow: none;
}

.PrimaryTheme .custom-file-input:disabled ~ .custom-file-label, .PrimaryTheme .custom-file-input[disabled] ~ .custom-file-label {
    background-color: #f1f4f8;
}

.PrimaryTheme .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}

.PrimaryTheme .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.PrimaryTheme .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.6em + 1.625rem + 2px);
    padding: .8125rem 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    background-color: #fff;
    border: 1px solid #f1f4f8;
    border-radius: .375rem;
    box-shadow: none;
}

.PrimaryTheme .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.625rem);
    padding: .8125rem 1.25rem;
    line-height: 1.6;
    color: #506690;
    content: "Browse";
    background-color: #f1f4f8;
    border-left: inherit;
    border-radius: 0 .375rem .375rem 0;
}

.PrimaryTheme .custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.PrimaryTheme .custom-range:focus {
    outline: 0;
}

.PrimaryTheme .custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff,none;
}

.PrimaryTheme .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff,none;
}

.PrimaryTheme .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff,none;
}

.PrimaryTheme .custom-range::-moz-focus-outer {
    border: 0;
}

.PrimaryTheme .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #335eea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .custom-range::-webkit-slider-thumb:active {
    background-color: #d5defb;
}

.PrimaryTheme .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d9e2ef;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #335eea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
    -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.PrimaryTheme .custom-range::-moz-range-thumb:active {
    background-color: #d5defb;
}

.PrimaryTheme .custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d9e2ef;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #335eea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
    -ms-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.PrimaryTheme .custom-range::-ms-thumb:active {
    background-color: #d5defb;
}

.PrimaryTheme .custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .custom-range::-ms-fill-lower {
    background-color: #d9e2ef;
    border-radius: 1rem;
}

.PrimaryTheme .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #d9e2ef;
    border-radius: 1rem;
}

.PrimaryTheme .custom-range:disabled::-webkit-slider-thumb {
    background-color: #abbcd5;
}

.PrimaryTheme .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.PrimaryTheme .custom-range:disabled::-moz-range-thumb {
    background-color: #abbcd5;
}

.PrimaryTheme .custom-range:disabled::-moz-range-track {
    cursor: default;
}

.PrimaryTheme .custom-range:disabled::-ms-thumb {
    background-color: #abbcd5;
}

.PrimaryTheme .custom-control-label::before, .PrimaryTheme .custom-file-label, .PrimaryTheme .custom-select {
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .custom-control-label::before, .PrimaryTheme .custom-file-label, .PrimaryTheme .custom-select {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .nav {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.PrimaryTheme .nav-link {
    display: block;
    padding: .5rem 1rem;
}

.PrimaryTheme .nav-link:focus, .PrimaryTheme .nav-link:hover {
    text-decoration: none;
}

.PrimaryTheme .nav-link.disabled {
    color: #869ab8;
    pointer-events: none;
    cursor: default;
}

.PrimaryTheme .nav-tabs {
    border-bottom: 1px solid #d9e2ef;
}

.PrimaryTheme .nav-tabs .nav-item {
    margin-bottom: -1px;
}

.PrimaryTheme .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}

.PrimaryTheme .nav-tabs .nav-link:focus, .PrimaryTheme .nav-tabs .nav-link:hover {
    border-color: #f1f4f8 #f1f4f8 #d9e2ef;
}

.PrimaryTheme .nav-tabs .nav-link.disabled {
    color: #869ab8;
    background-color: transparent;
    border-color: transparent;
}

.PrimaryTheme .nav-tabs .nav-item.show .nav-link, .PrimaryTheme .nav-tabs .nav-link.active {
    color: #506690;
    background-color: #fff;
    border-color: #d9e2ef #d9e2ef #fff;
}

.PrimaryTheme .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.PrimaryTheme .nav-pills .nav-link {
    border-radius: .375rem;
}

.PrimaryTheme .nav-pills .nav-link.active, .PrimaryTheme .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #335eea;
}

.PrimaryTheme .nav-fill .nav-item {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    text-align: center;
}

.PrimaryTheme .nav-justified .nav-item {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    text-align: center;
}

.PrimaryTheme .tab-content > .tab-pane {
    display: none;
}

.PrimaryTheme .tab-content > .active {
    display: block;
}

.PrimaryTheme .navbar {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1.25rem 0;
}

.PrimaryTheme .navbar .container, .PrimaryTheme .navbar .container-fluid, .PrimaryTheme .navbar .container-lg, .PrimaryTheme .navbar .container-md, .PrimaryTheme .navbar .container-sm, .PrimaryTheme .navbar .container-xl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.PrimaryTheme .navbar-brand {
    display: inline-block;
    padding-top: .15rem;
    padding-bottom: .15rem;
    margin-right: 0;
    font-size: 1.5rem;
    line-height: inherit;
    white-space: nowrap;
}

.PrimaryTheme .navbar-brand:focus, .PrimaryTheme .navbar-brand:hover {
    text-decoration: none;
}

.PrimaryTheme .navbar-nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.PrimaryTheme .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.PrimaryTheme .navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.PrimaryTheme .navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.PrimaryTheme .navbar-collapse {
    flex-basis: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    align-items: center;
}

.PrimaryTheme .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.1875rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .375rem;
}

.PrimaryTheme .navbar-toggler:focus, .PrimaryTheme .navbar-toggler:hover {
    text-decoration: none;
}

.PrimaryTheme .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .PrimaryTheme .navbar-expand-sm > .container, .PrimaryTheme .navbar-expand-sm > .container-fluid, .PrimaryTheme .navbar-expand-sm > .container-lg, .PrimaryTheme .navbar-expand-sm > .container-md, .PrimaryTheme .navbar-expand-sm > .container-sm, .PrimaryTheme .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .PrimaryTheme .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
    .PrimaryTheme .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .PrimaryTheme .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .PrimaryTheme .navbar-expand-sm > .container, .PrimaryTheme .navbar-expand-sm > .container-fluid, .PrimaryTheme .navbar-expand-sm > .container-lg, .PrimaryTheme .navbar-expand-sm > .container-md, .PrimaryTheme .navbar-expand-sm > .container-sm, .PrimaryTheme .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap;
    }
    .PrimaryTheme .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: flex !important;
        flex-basis: auto;
    }
    .PrimaryTheme .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .PrimaryTheme .navbar-expand-md > .container, .PrimaryTheme .navbar-expand-md > .container-fluid, .PrimaryTheme .navbar-expand-md > .container-lg, .PrimaryTheme .navbar-expand-md > .container-md, .PrimaryTheme .navbar-expand-md > .container-sm, .PrimaryTheme .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
    .PrimaryTheme .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .PrimaryTheme .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .PrimaryTheme .navbar-expand-md > .container, .PrimaryTheme .navbar-expand-md > .container-fluid, .PrimaryTheme .navbar-expand-md > .container-lg, .PrimaryTheme .navbar-expand-md > .container-md, .PrimaryTheme .navbar-expand-md > .container-sm, .PrimaryTheme .navbar-expand-md > .container-xl {
        flex-wrap: nowrap;
    }
    .PrimaryTheme .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: flex !important;
        flex-basis: auto;
    }
    .PrimaryTheme .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-expand-lg > .container, .PrimaryTheme .navbar-expand-lg > .container-fluid, .PrimaryTheme .navbar-expand-lg > .container-lg, .PrimaryTheme .navbar-expand-lg > .container-md, .PrimaryTheme .navbar-expand-lg > .container-sm, .PrimaryTheme .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
    .PrimaryTheme .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .PrimaryTheme .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .PrimaryTheme .navbar-expand-lg > .container, .PrimaryTheme .navbar-expand-lg > .container-fluid, .PrimaryTheme .navbar-expand-lg > .container-lg, .PrimaryTheme .navbar-expand-lg > .container-md, .PrimaryTheme .navbar-expand-lg > .container-sm, .PrimaryTheme .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap;
    }
    .PrimaryTheme .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: flex !important;
        flex-basis: auto;
    }
    .PrimaryTheme .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .PrimaryTheme .navbar-expand-xl > .container, .PrimaryTheme .navbar-expand-xl > .container-fluid, .PrimaryTheme .navbar-expand-xl > .container-lg, .PrimaryTheme .navbar-expand-xl > .container-md, .PrimaryTheme .navbar-expand-xl > .container-sm, .PrimaryTheme .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
    .PrimaryTheme .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .PrimaryTheme .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .PrimaryTheme .navbar-expand-xl > .container, .PrimaryTheme .navbar-expand-xl > .container-fluid, .PrimaryTheme .navbar-expand-xl > .container-lg, .PrimaryTheme .navbar-expand-xl > .container-md, .PrimaryTheme .navbar-expand-xl > .container-sm, .PrimaryTheme .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap;
    }
    .PrimaryTheme .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: flex !important;
        flex-basis: auto;
    }
    .PrimaryTheme .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.PrimaryTheme .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.PrimaryTheme .navbar-expand > .container, .PrimaryTheme .navbar-expand > .container-fluid, .PrimaryTheme .navbar-expand > .container-lg, .PrimaryTheme .navbar-expand > .container-md, .PrimaryTheme .navbar-expand > .container-sm, .PrimaryTheme .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
}

.PrimaryTheme .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.PrimaryTheme .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.PrimaryTheme .navbar-expand .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.PrimaryTheme .navbar-expand > .container, .PrimaryTheme .navbar-expand > .container-fluid, .PrimaryTheme .navbar-expand > .container-lg, .PrimaryTheme .navbar-expand > .container-md, .PrimaryTheme .navbar-expand > .container-sm, .PrimaryTheme .navbar-expand > .container-xl {
    flex-wrap: nowrap;
}

.PrimaryTheme .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
}

.PrimaryTheme .navbar-expand .navbar-toggler {
    display: none;
}

.PrimaryTheme .navbar-light .navbar-brand {
    color: #335eea;
}

.PrimaryTheme .navbar-light .navbar-brand:focus, .PrimaryTheme .navbar-light .navbar-brand:hover {
    color: #335eea;
}

.PrimaryTheme .navbar-light .navbar-nav .nav-link {
    color: #506690;
}

.PrimaryTheme .navbar-light .navbar-nav .nav-link:focus, .PrimaryTheme .navbar-light .navbar-nav .nav-link:hover {
    color: #335eea;
}

.PrimaryTheme .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(22, 28, 45, 0.3);
}

.PrimaryTheme .navbar-light .navbar-nav .active > .nav-link, .PrimaryTheme .navbar-light .navbar-nav .nav-link.active, .PrimaryTheme .navbar-light .navbar-nav .nav-link.show, .PrimaryTheme .navbar-light .navbar-nav .show > .nav-link {
    color: #335eea;
}

.PrimaryTheme .navbar-light .navbar-toggler {
    color: #506690;
    border-color: transparent;
}

.PrimaryTheme .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23506690' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.PrimaryTheme .navbar-light .navbar-text {
    color: #506690;
}

.PrimaryTheme .navbar-light .navbar-text a {
    color: #335eea;
}

.PrimaryTheme .navbar-light .navbar-text a:focus, .PrimaryTheme .navbar-light .navbar-text a:hover {
    color: #335eea;
}

.PrimaryTheme .navbar-dark .navbar-brand {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-brand:focus, .PrimaryTheme .navbar-dark .navbar-brand:hover {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-nav .nav-link:focus, .PrimaryTheme .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.PrimaryTheme .navbar-dark .navbar-nav .active > .nav-link, .PrimaryTheme .navbar-dark .navbar-nav .nav-link.active, .PrimaryTheme .navbar-dark .navbar-nav .nav-link.show, .PrimaryTheme .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-toggler {
    color: #fff;
    border-color: transparent;
}

.PrimaryTheme .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.PrimaryTheme .navbar-dark .navbar-text {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-text a {
    color: #fff;
}

.PrimaryTheme .navbar-dark .navbar-text a:focus, .PrimaryTheme .navbar-dark .navbar-text a:hover {
    color: #fff;
}

.PrimaryTheme .card {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f1f4f8;
    border-radius: .375rem;
}

.PrimaryTheme .card > hr {
    margin-right: 0;
    margin-left: 0;
}

.PrimaryTheme .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}

.PrimaryTheme .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.PrimaryTheme .card-body {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 2rem;
}

.PrimaryTheme .card-title {
    margin-bottom: 1rem;
}

.PrimaryTheme .card-subtitle {
    margin-top: -.5rem;
    margin-bottom: 0;
}

.PrimaryTheme .card-text:last-child {
    margin-bottom: 0;
}
@media (max-device-width: 750px) {
    .PrimaryTheme .card-text-sm {
        line-height: 1.4;
        font-size: 0.9rem;
    }
}

.PrimaryTheme .card-link:hover {
    text-decoration: none;
}

.PrimaryTheme .card-link + .card-link {
    margin-left: 2rem;
}

.PrimaryTheme .card-header {
    padding: 1rem 2rem;
    margin-bottom: 0;
    background-color: rgba(22, 28, 45, 0.03);
    border-bottom: 0 solid #f1f4f8;
}

.PrimaryTheme .card-header:first-child {
    border-radius: calc(.375rem - 0px) calc(.375rem - 0px) 0 0;
}

.PrimaryTheme .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

.PrimaryTheme .card-footer {
    padding: 1rem 2rem;
    background-color: rgba(22, 28, 45, 0.03);
    border-top: 0 solid #f1f4f8;
}

.PrimaryTheme .card-footer:last-child {
    border-radius: 0 0 calc(.375rem - 0px) calc(.375rem - 0px);
}

.PrimaryTheme .card-header-tabs {
    margin-right: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
    border-bottom: 0;
}

.PrimaryTheme .card-header-pills {
    margin-right: -1rem;
    margin-left: -1rem;
}

.PrimaryTheme .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.PrimaryTheme .card-img, .PrimaryTheme .card-img-bottom, .PrimaryTheme .card-img-top {
    flex-shrink: 0;
    width: 100%;
}

.PrimaryTheme .card-img, .PrimaryTheme .card-img-top {
    border-top-left-radius: calc(.375rem - 0px);
    border-top-right-radius: calc(.375rem - 0px);
}

.PrimaryTheme .card-img, .PrimaryTheme .card-img-bottom {
    border-bottom-right-radius: calc(.375rem - 0px);
    border-bottom-left-radius: calc(.375rem - 0px);
}

.PrimaryTheme .card-deck .card {
    margin-bottom: 20px;
}

@media (min-width: 576px) {
    .PrimaryTheme .card-deck {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        margin-right: -20px;
        margin-left: -20px;
    }
    .PrimaryTheme .card-deck .card {
        -webkit-box-flex: 1;
        flex: 1 0 0%;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px;
    }
}

.PrimaryTheme .card-group > .card {
    margin-bottom: 20px;
}

@media (min-width: 576px) {
    .PrimaryTheme .card-group {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
    }
    .PrimaryTheme .card-group > .card {
        -webkit-box-flex: 1;
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .PrimaryTheme .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .PrimaryTheme .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .PrimaryTheme .card-group > .card:not(:last-child) .card-header, .PrimaryTheme .card-group > .card:not(:last-child) .card-img-top {
        border-top-right-radius: 0;
    }
    .PrimaryTheme .card-group > .card:not(:last-child) .card-footer, .PrimaryTheme .card-group > .card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }
    .PrimaryTheme .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .PrimaryTheme .card-group > .card:not(:first-child) .card-header, .PrimaryTheme .card-group > .card:not(:first-child) .card-img-top {
        border-top-left-radius: 0;
    }
    .PrimaryTheme .card-group > .card:not(:first-child) .card-footer, .PrimaryTheme .card-group > .card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }
}

.PrimaryTheme .card-columns .card {
    margin-bottom: 1rem;
}

@media (min-width: 576px) {
    .PrimaryTheme .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .PrimaryTheme .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.PrimaryTheme .accordion > .card {
    overflow: hidden;
}

.PrimaryTheme .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.PrimaryTheme .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0;
}

.PrimaryTheme .breadcrumb {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 0;
    margin-bottom: 0;
    font-size: .9375rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}

.PrimaryTheme .breadcrumb-item + .breadcrumb-item {
    padding-left: .5rem;
}

.PrimaryTheme .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #506690;
    content: "/";
}

.PrimaryTheme .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

.PrimaryTheme .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

.PrimaryTheme .breadcrumb-item.active {
    color: #506690;
}

.PrimaryTheme .pagination {
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .375rem;
}

.PrimaryTheme .page-link {
    position: relative;
    display: block;
    padding: .8125rem 1.25rem;
    margin-left: -1px;
    line-height: 1.6;
    color: #335eea;
    background-color: #fff;
    border: 1px solid #d9e2ef;
}

.PrimaryTheme .page-link:hover {
    z-index: 2;
    color: #133bbd;
    text-decoration: none;
    background-color: #f1f4f8;
    border-color: #d9e2ef;
}

.PrimaryTheme .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.PrimaryTheme .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.PrimaryTheme .page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
}

.PrimaryTheme .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .page-item.disabled .page-link {
    color: #869ab8;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #d9e2ef;
}

.PrimaryTheme .pagination-lg .page-link {
    padding: 1.122rem 1.5rem;
    font-size: 1.1875rem;
    line-height: 1.3;
}

.PrimaryTheme .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.PrimaryTheme .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.PrimaryTheme .pagination-sm .page-link {
    padding: .5625rem 1rem;
    font-size: .9375rem;
    line-height: 1.3;
}

.PrimaryTheme .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.PrimaryTheme .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
}

.PrimaryTheme .badge {
    display: inline-block;
    padding: .25rem .75rem;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .375rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .badge {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme a.badge:focus, .PrimaryTheme a.badge:hover {
    text-decoration: none;
}

.PrimaryTheme .badge:empty {
    display: none;
}

.PrimaryTheme .btn .badge {
    position: relative;
    top: -1px;
}

.PrimaryTheme .badge-pill {
    padding-right: .75rem;
    padding-left: .75rem;
    border-radius: 10rem;
}

.PrimaryTheme .badge-primary {
    color: #fff;
    background-color: #335eea;
}

.PrimaryTheme a.badge-primary:focus, .PrimaryTheme a.badge-primary:hover {
    color: #fff;
    background-color: #1643d4;
}

.PrimaryTheme a.badge-primary.focus, .PrimaryTheme a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5);
}

.PrimaryTheme .badge-secondary {
    color: #fff;
    background-color: #506690;
}

.PrimaryTheme a.badge-secondary:focus, .PrimaryTheme a.badge-secondary:hover {
    color: #fff;
    background-color: #3e4f6f;
}

.PrimaryTheme a.badge-secondary.focus, .PrimaryTheme a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}

.PrimaryTheme .badge-success {
    color: #fff;
    background-color: #42ba96;
}

.PrimaryTheme a.badge-success:focus, .PrimaryTheme a.badge-success:hover {
    color: #fff;
    background-color: #359478;
}

.PrimaryTheme a.badge-success.focus, .PrimaryTheme a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5);
}

.PrimaryTheme .badge-info {
    color: #fff;
    background-color: #7c69ef;
}

.PrimaryTheme a.badge-info:focus, .PrimaryTheme a.badge-info:hover {
    color: #fff;
    background-color: #543bea;
}

.PrimaryTheme a.badge-info.focus, .PrimaryTheme a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5);
}

.PrimaryTheme .badge-warning {
    color: #1b2a4e;
    background-color: #fad776;
}

.PrimaryTheme a.badge-warning:focus, .PrimaryTheme a.badge-warning:hover {
    color: #1b2a4e;
    background-color: #f8c945;
}

.PrimaryTheme a.badge-warning.focus, .PrimaryTheme a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5);
}

.PrimaryTheme .badge-danger {
    color: #fff;
    background-color: #df4759;
}

.PrimaryTheme a.badge-danger:focus, .PrimaryTheme a.badge-danger:hover {
    color: #fff;
    background-color: #cf2438;
}

.PrimaryTheme a.badge-danger.focus, .PrimaryTheme a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5);
}

.PrimaryTheme .badge-light {
    color: #1b2a4e;
    background-color: #f9fbfd;
}

.PrimaryTheme a.badge-light:focus, .PrimaryTheme a.badge-light:hover {
    color: #1b2a4e;
    background-color: #d3e2f0;
}

.PrimaryTheme a.badge-light.focus, .PrimaryTheme a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
}

.PrimaryTheme .badge-dark {
    color: #fff;
    background-color: #1b2a4e;
}

.PrimaryTheme a.badge-dark:focus, .PrimaryTheme a.badge-dark:hover {
    color: #fff;
    background-color: #0e1628;
}

.PrimaryTheme a.badge-dark.focus, .PrimaryTheme a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5);
}

.PrimaryTheme .badge-primary-desat {
    color: #fff;
    background-color: #6c8aec;
}

.PrimaryTheme a.badge-primary-desat:focus, .PrimaryTheme a.badge-primary-desat:hover {
    color: #fff;
    background-color: #3f66e6;
}

.PrimaryTheme a.badge-primary-desat.focus, .PrimaryTheme a.badge-primary-desat:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5);
}

.PrimaryTheme .badge-black {
    color: #fff;
    background-color: #161c2d;
}

.PrimaryTheme a.badge-black:focus, .PrimaryTheme a.badge-black:hover {
    color: #fff;
    background-color: #05070b;
}

.PrimaryTheme a.badge-black.focus, .PrimaryTheme a.badge-black:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5);
}

.PrimaryTheme .badge-white {
    color: #1b2a4e;
    background-color: #fff;
}

.PrimaryTheme a.badge-white:focus, .PrimaryTheme a.badge-white:hover {
    color: #1b2a4e;
    background-color: #e6e6e6;
}

.PrimaryTheme a.badge-white.focus, .PrimaryTheme a.badge-white:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.PrimaryTheme .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #f1f4f8;
    border-radius: .5rem;
}

@media (min-width: 576px) {
    .PrimaryTheme .jumbotron {
        padding: 4rem 2rem;
    }
}

.PrimaryTheme .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.PrimaryTheme .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .375rem;
}

.PrimaryTheme .alert-heading {
    color: inherit;
}

.PrimaryTheme .alert-link {
    font-weight: 600;
}

.PrimaryTheme .alert-dismissible {
    padding-right: 4.09375rem;
}

.PrimaryTheme .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
}

.PrimaryTheme .alert-primary {
    color: #253e8f;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .alert-primary hr {
    border-top-color: #1c4ce8;
}

.PrimaryTheme .alert-primary .alert-link {
    color: #1b2c66;
}

.PrimaryTheme .alert-secondary {
    color: #344260;
    background-color: #506690;
    border-color: #506690;
}

.PrimaryTheme .alert-secondary hr {
    border-top-color: #475a80;
}

.PrimaryTheme .alert-secondary .alert-link {
    color: #222b3f;
}

.PrimaryTheme .alert-success {
    color: #2d6e64;
    background-color: #42ba96;
    border-color: #42ba96;
}

.PrimaryTheme .alert-success hr {
    border-top-color: #3ba787;
}

.PrimaryTheme .alert-success .alert-link {
    color: #1e4a43;
}

.PrimaryTheme .alert-info {
    color: #4b4492;
    background-color: #7c69ef;
    border-color: #7c69ef;
}

.PrimaryTheme .alert-info hr {
    border-top-color: #6852ed;
}

.PrimaryTheme .alert-info .alert-link {
    color: #39346f;
}

.PrimaryTheme .alert-warning {
    color: #8d7d53;
    background-color: #fad776;
    border-color: #fad776;
}

.PrimaryTheme .alert-warning hr {
    border-top-color: #f9d05d;
}

.PrimaryTheme .alert-warning .alert-link {
    color: #6d6140;
}

.PrimaryTheme .alert-danger {
    color: #7f3244;
    background-color: #df4759;
    border-color: #df4759;
}

.PrimaryTheme .alert-danger hr {
    border-top-color: #db3145;
}

.PrimaryTheme .alert-danger .alert-link {
    color: #5a2430;
}

.PrimaryTheme .alert-light {
    color: #8c9099;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
}

.PrimaryTheme .alert-light hr {
    border-top-color: #e6eef7;
}

.PrimaryTheme .alert-light .alert-link {
    color: #727680;
}

.PrimaryTheme .alert-dark {
    color: #19233e;
    background-color: #1b2a4e;
    border-color: #1b2a4e;
}

.PrimaryTheme .alert-dark hr {
    border-top-color: #14203b;
}

.PrimaryTheme .alert-dark .alert-link {
    color: #0a0e1a;
}

.PrimaryTheme .alert-primary-desat {
    color: #435590;
    background-color: #6c8aec;
    border-color: #6c8aec;
}

.PrimaryTheme .alert-primary-desat hr {
    border-top-color: #5578e9;
}

.PrimaryTheme .alert-primary-desat .alert-link {
    color: #33406d;
}

.PrimaryTheme .alert-black {
    color: #161c2d;
    background-color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .alert-black hr {
    border-top-color: #0e111c;
}

.PrimaryTheme .alert-black .alert-link {
    color: #05070b;
}

.PrimaryTheme .alert-white {
    color: #8f929a;
    background-color: #fff;
    border-color: #fff;
}

.PrimaryTheme .alert-white hr {
    border-top-color: #f2f2f2;
}

.PrimaryTheme .alert-white .alert-link {
    color: #757881;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.PrimaryTheme .progress {
    display: -webkit-box;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .79688rem;
    background-color: #f1f4f8;
    border-radius: .375rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .progress-bar {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #335eea;
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .progress-bar {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.PrimaryTheme .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.PrimaryTheme .media {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
}

.PrimaryTheme .media-body {
    -webkit-box-flex: 1;
    flex: 1;
}

.PrimaryTheme .list-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.PrimaryTheme .list-group-item-action {
    width: 100%;
    color: #506690;
    text-align: inherit;
}

.PrimaryTheme .list-group-item-action:focus, .PrimaryTheme .list-group-item-action:hover {
    z-index: 1;
    color: #506690;
    text-decoration: none;
    background-color: #f9fbfd;
}

.PrimaryTheme .list-group-item-action:active {
    color: #161c2d;
    background-color: #f1f4f8;
}

.PrimaryTheme .list-group-item {
    position: relative;
    display: block;
    padding: 1.5rem 2rem;
    background-color: #fff;
    border: 1px solid #f1f4f8;
}

.PrimaryTheme .list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}

.PrimaryTheme .list-group-item:last-child {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.PrimaryTheme .list-group-item.disabled, .PrimaryTheme .list-group-item:disabled {
    color: #869ab8;
    pointer-events: none;
    background-color: #fff;
}

.PrimaryTheme .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .list-group-item + .list-group-item {
    border-top-width: 0;
}

.PrimaryTheme .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.PrimaryTheme .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.PrimaryTheme .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0;
}

.PrimaryTheme .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: .375rem;
    border-bottom-left-radius: 0;
}

.PrimaryTheme .list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.PrimaryTheme .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.PrimaryTheme .list-group-horizontal .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .PrimaryTheme .list-group-horizontal-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }
    .PrimaryTheme .list-group-horizontal-sm .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .PrimaryTheme .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .list-group-horizontal-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }
    .PrimaryTheme .list-group-horizontal-md .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .PrimaryTheme .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .list-group-horizontal-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }
    .PrimaryTheme .list-group-horizontal-lg .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .PrimaryTheme .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .list-group-horizontal-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
    .PrimaryTheme .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
    .PrimaryTheme .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }
    .PrimaryTheme .list-group-horizontal-xl .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .PrimaryTheme .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.PrimaryTheme .list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.PrimaryTheme .list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.PrimaryTheme .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.PrimaryTheme .list-group-item-primary {
    color: #253e8f;
    background-color: #c6d2f9;
}

.PrimaryTheme .list-group-item-primary.list-group-item-action:focus, .PrimaryTheme .list-group-item-primary.list-group-item-action:hover {
    color: #253e8f;
    background-color: #afc0f7;
}

.PrimaryTheme .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #253e8f;
    border-color: #253e8f;
}

.PrimaryTheme .list-group-item-secondary {
    color: #344260;
    background-color: #ced4e0;
}

.PrimaryTheme .list-group-item-secondary.list-group-item-action:focus, .PrimaryTheme .list-group-item-secondary.list-group-item-action:hover {
    color: #344260;
    background-color: #bec6d6;
}

.PrimaryTheme .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #344260;
    border-color: #344260;
}

.PrimaryTheme .list-group-item-success {
    color: #2d6e64;
    background-color: #caece2;
}

.PrimaryTheme .list-group-item-success.list-group-item-action:focus, .PrimaryTheme .list-group-item-success.list-group-item-action:hover {
    color: #2d6e64;
    background-color: #b7e5d8;
}

.PrimaryTheme .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #2d6e64;
    border-color: #2d6e64;
}

.PrimaryTheme .list-group-item-info {
    color: #4b4492;
    background-color: #dad5fb;
}

.PrimaryTheme .list-group-item-info.list-group-item-action:focus, .PrimaryTheme .list-group-item-info.list-group-item-action:hover {
    color: #4b4492;
    background-color: #c5bef9;
}

.PrimaryTheme .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #4b4492;
    border-color: #4b4492;
}

.PrimaryTheme .list-group-item-warning {
    color: #8d7d53;
    background-color: #fef4d9;
}

.PrimaryTheme .list-group-item-warning.list-group-item-action:focus, .PrimaryTheme .list-group-item-warning.list-group-item-action:hover {
    color: #8d7d53;
    background-color: #fdedc0;
}

.PrimaryTheme .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8d7d53;
    border-color: #8d7d53;
}

.PrimaryTheme .list-group-item-danger {
    color: #7f3244;
    background-color: #f6cbd1;
}

.PrimaryTheme .list-group-item-danger.list-group-item-action:focus, .PrimaryTheme .list-group-item-danger.list-group-item-action:hover {
    color: #7f3244;
    background-color: #f2b5be;
}

.PrimaryTheme .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f3244;
    border-color: #7f3244;
}

.PrimaryTheme .list-group-item-light {
    color: #8c9099;
    background-color: #fdfefe;
}

.PrimaryTheme .list-group-item-light.list-group-item-action:focus, .PrimaryTheme .list-group-item-light.list-group-item-action:hover {
    color: #8c9099;
    background-color: #ecf6f6;
}

.PrimaryTheme .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8c9099;
    border-color: #8c9099;
}

.PrimaryTheme .list-group-item-dark {
    color: #19233e;
    background-color: #bfc3cd;
}

.PrimaryTheme .list-group-item-dark.list-group-item-action:focus, .PrimaryTheme .list-group-item-dark.list-group-item-action:hover {
    color: #19233e;
    background-color: #b1b6c2;
}

.PrimaryTheme .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #19233e;
    border-color: #19233e;
}

.PrimaryTheme .list-group-item-primary-desat {
    color: #435590;
    background-color: #d6defa;
}

.PrimaryTheme .list-group-item-primary-desat.list-group-item-action:focus, .PrimaryTheme .list-group-item-primary-desat.list-group-item-action:hover {
    color: #435590;
    background-color: #bfccf7;
}

.PrimaryTheme .list-group-item-primary-desat.list-group-item-action.active {
    color: #fff;
    background-color: #435590;
    border-color: #435590;
}

.PrimaryTheme .list-group-item-black {
    color: #161c2d;
    background-color: #bebfc4;
}

.PrimaryTheme .list-group-item-black.list-group-item-action:focus, .PrimaryTheme .list-group-item-black.list-group-item-action:hover {
    color: #161c2d;
    background-color: #b1b2b8;
}

.PrimaryTheme .list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .list-group-item-white {
    color: #8f929a;
    background-color: #fff;
}

.PrimaryTheme .list-group-item-white.list-group-item-action:focus, .PrimaryTheme .list-group-item-white.list-group-item-action:hover {
    color: #8f929a;
    background-color: #f2f2f2;
}

.PrimaryTheme .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #8f929a;
    border-color: #8f929a;
}

.PrimaryTheme .close {
    float: right;
    font-size: 1.59375rem;
    font-weight: 600;
    line-height: 1;
    color: #869ab8;
    text-shadow: none;
    opacity: .5;
}

.PrimaryTheme .close:hover {
    color: #869ab8;
    text-decoration: none;
}

.PrimaryTheme .close:not(:disabled):not(.disabled):focus, .PrimaryTheme .close:not(:disabled):not(.disabled):hover {
    opacity: .75;
}

.PrimaryTheme button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.PrimaryTheme a.close.disabled {
    pointer-events: none;
}

.PrimaryTheme .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(22, 28, 45, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem;
}

.PrimaryTheme .toast:not(:last-child) {
    margin-bottom: .75rem;
}

.PrimaryTheme .toast.showing {
    opacity: 1;
}

.PrimaryTheme .toast.show {
    display: block;
    opacity: 1;
}

.PrimaryTheme .toast.hide {
    display: none;
}

.PrimaryTheme .toast-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: .5rem .75rem;
    color: #869ab8;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.PrimaryTheme .toast-body {
    padding: .75rem;
}

.PrimaryTheme .modal-open {
    overflow: hidden;
}

.PrimaryTheme .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.PrimaryTheme .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.PrimaryTheme .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

.PrimaryTheme .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.PrimaryTheme .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.PrimaryTheme .modal-dialog-scrollable {
    display: -webkit-box;
    display: flex;
    max-height: calc(100% - 1rem);
}

.PrimaryTheme .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.PrimaryTheme .modal-dialog-scrollable .modal-footer, .PrimaryTheme .modal-dialog-scrollable .modal-header {
    flex-shrink: 0;
}

.PrimaryTheme .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.PrimaryTheme .modal-dialog-centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.PrimaryTheme .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.PrimaryTheme .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
}

.PrimaryTheme .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.PrimaryTheme .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.PrimaryTheme .modal-content {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(22, 28, 45, 0.2);
    border-radius: .5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(22, 28, 45, 0.5);
    outline: 0;
}

.PrimaryTheme .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #161c2d;
}

.PrimaryTheme .modal-backdrop.fade {
    opacity: 0;
}

.PrimaryTheme .modal-backdrop.show {
    opacity: .8;
}

.PrimaryTheme .modal-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #f1f4f8;
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px);
}

.PrimaryTheme .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.PrimaryTheme .modal-title {
    margin-bottom: 0;
    line-height: 1.6;
}

.PrimaryTheme .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 2.5rem;
}

.PrimaryTheme .modal-footer {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: 2.25rem;
    border-top: 1px solid #f1f4f8;
    border-bottom-right-radius: calc(.5rem - 1px);
    border-bottom-left-radius: calc(.5rem - 1px);
}

.PrimaryTheme .modal-footer > * {
    margin: .25rem;
}

.PrimaryTheme .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .PrimaryTheme .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .PrimaryTheme .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .PrimaryTheme .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .PrimaryTheme .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .PrimaryTheme .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .PrimaryTheme .modal-content {
        box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.5);
    }
    .PrimaryTheme .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .modal-lg, .PrimaryTheme .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .modal-xl {
        max-width: 1140px;
    }
}

.PrimaryTheme .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: HKGroteskPro,serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9375rem;
    word-wrap: break-word;
    opacity: 0;
}

.PrimaryTheme .tooltip.show {
    opacity: .9;
}

.PrimaryTheme .tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem;
}

.PrimaryTheme .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=top], .PrimaryTheme .bs-tooltip-top {
    padding: .4rem 0;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=top] .arrow, .PrimaryTheme .bs-tooltip-top .arrow {
    bottom: 0;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=top] .arrow::before, .PrimaryTheme .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #161c2d;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=right], .PrimaryTheme .bs-tooltip-right {
    padding: 0 .4rem;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=right] .arrow, .PrimaryTheme .bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=right] .arrow::before, .PrimaryTheme .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #161c2d;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=bottom], .PrimaryTheme .bs-tooltip-bottom {
    padding: .4rem 0;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=bottom] .arrow, .PrimaryTheme .bs-tooltip-bottom .arrow {
    top: 0;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .PrimaryTheme .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #161c2d;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=left], .PrimaryTheme .bs-tooltip-left {
    padding: 0 .4rem;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=left] .arrow, .PrimaryTheme .bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem;
}

.PrimaryTheme .bs-tooltip-auto[x-placement^=left] .arrow::before, .PrimaryTheme .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #161c2d;
}

.PrimaryTheme .tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #161c2d;
    border-radius: .375rem;
}

.PrimaryTheme .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 380px;
    font-family: HKGroteskPro,serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9375rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .375rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .popover .arrow {
    position: absolute;
    display: block;
    width: 1.25rem;
    height: .625rem;
    margin: 0 .375rem;
}

.PrimaryTheme .popover .arrow::after, .PrimaryTheme .popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.PrimaryTheme .bs-popover-auto[x-placement^=top], .PrimaryTheme .bs-popover-top {
    margin-bottom: .625rem;
}

.PrimaryTheme .bs-popover-auto[x-placement^=top] > .arrow, .PrimaryTheme .bs-popover-top > .arrow {
    bottom: calc(-.625rem - 1px);
}

.PrimaryTheme .bs-popover-auto[x-placement^=top] > .arrow::before, .PrimaryTheme .bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: .625rem .625rem 0;
    border-top-color: rgba(0, 0, 0, 0.05);
}

.PrimaryTheme .bs-popover-auto[x-placement^=top] > .arrow::after, .PrimaryTheme .bs-popover-top > .arrow::after {
    bottom: 1px;
    border-width: .625rem .625rem 0;
    border-top-color: #fff;
}

.PrimaryTheme .bs-popover-auto[x-placement^=right], .PrimaryTheme .bs-popover-right {
    margin-left: .625rem;
}

.PrimaryTheme .bs-popover-auto[x-placement^=right] > .arrow, .PrimaryTheme .bs-popover-right > .arrow {
    left: calc(-.625rem - 1px);
    width: .625rem;
    height: 1.25rem;
    margin: .375rem 0;
}

.PrimaryTheme .bs-popover-auto[x-placement^=right] > .arrow::before, .PrimaryTheme .bs-popover-right > .arrow::before {
    left: 0;
    border-width: .625rem .625rem .625rem 0;
    border-right-color: rgba(0, 0, 0, 0.05);
}

.PrimaryTheme .bs-popover-auto[x-placement^=right] > .arrow::after, .PrimaryTheme .bs-popover-right > .arrow::after {
    left: 1px;
    border-width: .625rem .625rem .625rem 0;
    border-right-color: #fff;
}

.PrimaryTheme .bs-popover-auto[x-placement^=bottom], .PrimaryTheme .bs-popover-bottom {
    margin-top: .625rem;
}

.PrimaryTheme .bs-popover-auto[x-placement^=bottom] > .arrow, .PrimaryTheme .bs-popover-bottom > .arrow {
    top: calc(-.625rem - 1px);
}

.PrimaryTheme .bs-popover-auto[x-placement^=bottom] > .arrow::before, .PrimaryTheme .bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 .625rem .625rem .625rem;
    border-bottom-color: rgba(0, 0, 0, 0.05);
}

.PrimaryTheme .bs-popover-auto[x-placement^=bottom] > .arrow::after, .PrimaryTheme .bs-popover-bottom > .arrow::after {
    top: 1px;
    border-width: 0 .625rem .625rem .625rem;
    border-bottom-color: #fff;
}

.PrimaryTheme .bs-popover-auto[x-placement^=bottom] .popover-header::before, .PrimaryTheme .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1.25rem;
    margin-left: -.625rem;
    content: "";
    border-bottom: 1px solid transparent;
}

.PrimaryTheme .bs-popover-auto[x-placement^=left], .PrimaryTheme .bs-popover-left {
    margin-right: .625rem;
}

.PrimaryTheme .bs-popover-auto[x-placement^=left] > .arrow, .PrimaryTheme .bs-popover-left > .arrow {
    right: calc(-.625rem - 1px);
    width: .625rem;
    height: 1.25rem;
    margin: .375rem 0;
}

.PrimaryTheme .bs-popover-auto[x-placement^=left] > .arrow::before, .PrimaryTheme .bs-popover-left > .arrow::before {
    right: 0;
    border-width: .625rem 0 .625rem .625rem;
    border-left-color: rgba(0, 0, 0, 0.05);
}

.PrimaryTheme .bs-popover-auto[x-placement^=left] > .arrow::after, .PrimaryTheme .bs-popover-left > .arrow::after {
    right: 1px;
    border-width: .625rem 0 .625rem .625rem;
    border-left-color: #fff;
}

.PrimaryTheme .popover-header {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    font-size: 1.0625rem;
    color: #335eea;
    background-color: transparent;
    border-bottom: 1px solid transparent;
    border-top-left-radius: calc(.375rem - 1px);
    border-top-right-radius: calc(.375rem - 1px);
}

.PrimaryTheme .popover-header:empty {
    display: none;
}

.PrimaryTheme .popover-body {
    padding: 1.5rem 2rem;
    color: #869ab8;
}

.PrimaryTheme .carousel {
    position: relative;
}

.PrimaryTheme .carousel.pointer-event {
    touch-action: pan-y;
}

.PrimaryTheme .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.PrimaryTheme .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.PrimaryTheme .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .carousel-item {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .carousel-item-next, .PrimaryTheme .carousel-item-prev, .PrimaryTheme .carousel-item.active {
    display: block;
}

.PrimaryTheme .active.carousel-item-right, .PrimaryTheme .carousel-item-next:not(.carousel-item-left) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.PrimaryTheme .active.carousel-item-left, .PrimaryTheme .carousel-item-prev:not(.carousel-item-right) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.PrimaryTheme .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
}

.PrimaryTheme .carousel-fade .carousel-item-next.carousel-item-left, .PrimaryTheme .carousel-fade .carousel-item-prev.carousel-item-right, .PrimaryTheme .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
}

.PrimaryTheme .carousel-fade .active.carousel-item-left, .PrimaryTheme .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s .6s;
    transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .carousel-fade .active.carousel-item-left, .PrimaryTheme .carousel-fade .active.carousel-item-right {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .carousel-control-next, .PrimaryTheme .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .carousel-control-next, .PrimaryTheme .carousel-control-prev {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .carousel-control-next:focus, .PrimaryTheme .carousel-control-next:hover, .PrimaryTheme .carousel-control-prev:focus, .PrimaryTheme .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

.PrimaryTheme .carousel-control-prev {
    left: 0;
}

.PrimaryTheme .carousel-control-next {
    right: 0;
}

.PrimaryTheme .carousel-control-next-icon, .PrimaryTheme .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}

.PrimaryTheme .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.PrimaryTheme .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.PrimaryTheme .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.PrimaryTheme .carousel-indicators li {
    box-sizing: content-box;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity .6s ease;
    transition: opacity .6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .PrimaryTheme .carousel-indicators li {
        -webkit-transition: none;
        transition: none;
    }
}

.PrimaryTheme .carousel-indicators .active {
    opacity: 1;
}

.PrimaryTheme .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.PrimaryTheme .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.PrimaryTheme .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.PrimaryTheme .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 1.5s linear infinite;
    animation: spinner-grow 1.5s linear infinite;
    /* -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite; */
}

.PrimaryTheme .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.PrimaryTheme .align-baseline {
    vertical-align: baseline !important;
}

.PrimaryTheme .align-top {
    vertical-align: top !important;
}

.PrimaryTheme .align-middle {
    vertical-align: middle !important;
}

.PrimaryTheme .align-bottom {
    vertical-align: bottom !important;
}

.PrimaryTheme .align-text-bottom {
    vertical-align: text-bottom !important;
}

.PrimaryTheme .align-text-top {
    vertical-align: text-top !important;
}

.PrimaryTheme .bg-primary {
    background-color: #335eea !important;
}

.PrimaryTheme a.bg-primary:focus, .PrimaryTheme a.bg-primary:hover, .PrimaryTheme button.bg-primary:focus, .PrimaryTheme button.bg-primary:hover {
    background-color: #1643d4 !important;
}

.PrimaryTheme .bg-secondary {
    background-color: #506690 !important;
}

.PrimaryTheme a.bg-secondary:focus, .PrimaryTheme a.bg-secondary:hover, .PrimaryTheme button.bg-secondary:focus, .PrimaryTheme button.bg-secondary:hover {
    background-color: #3e4f6f !important;
}

.PrimaryTheme .bg-success {
    background-color: #42ba96 !important;
}

.PrimaryTheme a.bg-success:focus, .PrimaryTheme a.bg-success:hover, .PrimaryTheme button.bg-success:focus, .PrimaryTheme button.bg-success:hover {
    background-color: #359478 !important;
}

.PrimaryTheme .bg-info {
    background-color: #7c69ef !important;
}

.PrimaryTheme a.bg-info:focus, .PrimaryTheme a.bg-info:hover, .PrimaryTheme button.bg-info:focus, .PrimaryTheme button.bg-info:hover {
    background-color: #543bea !important;
}

.PrimaryTheme .bg-warning {
    background-color: #fad776 !important;
}

.PrimaryTheme a.bg-warning:focus, .PrimaryTheme a.bg-warning:hover, .PrimaryTheme button.bg-warning:focus, .PrimaryTheme button.bg-warning:hover {
    background-color: #f8c945 !important;
}

.PrimaryTheme .bg-danger {
    background-color: #df4759 !important;
}

.PrimaryTheme a.bg-danger:focus, .PrimaryTheme a.bg-danger:hover, .PrimaryTheme button.bg-danger:focus, .PrimaryTheme button.bg-danger:hover {
    background-color: #cf2438 !important;
}

.PrimaryTheme .bg-light {
    background-color: #f9fbfd !important;
}

.PrimaryTheme a.bg-light:focus, .PrimaryTheme a.bg-light:hover, .PrimaryTheme button.bg-light:focus, .PrimaryTheme button.bg-light:hover {
    background-color: #d3e2f0 !important;
}

.PrimaryTheme .bg-dark {
    background-color: #1b2a4e !important;
}

.PrimaryTheme a.bg-dark:focus, .PrimaryTheme a.bg-dark:hover, .PrimaryTheme button.bg-dark:focus, .PrimaryTheme button.bg-dark:hover {
    background-color: #0e1628 !important;
}

.PrimaryTheme .bg-primary-desat {
    background-color: #6c8aec !important;
}

.PrimaryTheme a.bg-primary-desat:focus, .PrimaryTheme a.bg-primary-desat:hover, .PrimaryTheme button.bg-primary-desat:focus, .PrimaryTheme button.bg-primary-desat:hover {
    background-color: #3f66e6 !important;
}

.PrimaryTheme .bg-black {
    background-color: #161c2d !important;
}

.PrimaryTheme a.bg-black:focus, .PrimaryTheme a.bg-black:hover, .PrimaryTheme button.bg-black:focus, .PrimaryTheme button.bg-black:hover {
    background-color: #05070b !important;
}

.PrimaryTheme .bg-white {
    background-color: #fff !important;
}

.PrimaryTheme a.bg-white:focus, .PrimaryTheme a.bg-white:hover, .PrimaryTheme button.bg-white:focus, .PrimaryTheme button.bg-white:hover {
    background-color: #e6e6e6 !important;
}

.PrimaryTheme .bg-white {
    background-color: #fff !important;
}

.PrimaryTheme .bg-transparent {
    background-color: transparent !important;
}

.PrimaryTheme .border {
    border: 1px solid #f1f4f8 !important;
}

.PrimaryTheme .border-top {
    border-top: 1px solid #f1f4f8 !important;
}

.PrimaryTheme .border-right {
    border-right: 1px solid #f1f4f8 !important;
}

.PrimaryTheme .border-bottom {
    border-bottom: 1px solid #f1f4f8 !important;
}

.PrimaryTheme .border-left {
    border-left: 1px solid #f1f4f8 !important;
}

.PrimaryTheme .border-0 {
    border: 0 !important;
}

.PrimaryTheme .border-top-0 {
    border-top: 0 !important;
}

.PrimaryTheme .border-right-0 {
    border-right: 0 !important;
}

.PrimaryTheme .border-bottom-0 {
    border-bottom: 0 !important;
}

.PrimaryTheme .border-left-0 {
    border-left: 0 !important;
}

.PrimaryTheme .border-primary {
    border-color: #335eea !important;
}

.PrimaryTheme .border-secondary {
    border-color: #506690 !important;
}

.PrimaryTheme .border-success {
    border-color: #42ba96 !important;
}

.PrimaryTheme .border-info {
    border-color: #7c69ef !important;
}

.PrimaryTheme .border-warning {
    border-color: #fad776 !important;
}

.PrimaryTheme .border-danger {
    border-color: #df4759 !important;
}

.PrimaryTheme .border-light {
    border-color: #f9fbfd !important;
}

.PrimaryTheme .border-dark {
    border-color: #1b2a4e !important;
}

.PrimaryTheme .border-primary-desat {
    border-color: #6c8aec !important;
}

.PrimaryTheme .border-black {
    border-color: #161c2d !important;
}

.PrimaryTheme .border-white {
    border-color: #fff !important;
}

.PrimaryTheme .border-white {
    border-color: #fff !important;
}

.PrimaryTheme .rounded-sm {
    border-radius: .2rem !important;
}

.PrimaryTheme .rounded {
    border-radius: .375rem !important;
}

.PrimaryTheme .rounded-top {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
}

.PrimaryTheme .rounded-right {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
}

.PrimaryTheme .rounded-bottom {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
}

.PrimaryTheme .rounded-left {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
}

.PrimaryTheme .rounded-lg {
    border-radius: .5rem !important;
}

.PrimaryTheme .rounded-circle {
    border-radius: 50% !important;
}

.PrimaryTheme .rounded-pill {
    border-radius: 50rem !important;
}

.PrimaryTheme .rounded-0 {
    border-radius: 0 !important;
}

.PrimaryTheme .clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.PrimaryTheme .d-none {
    display: none !important;
}

.PrimaryTheme .d-inline {
    display: inline !important;
}

.PrimaryTheme .d-inline-block {
    display: inline-block !important;
}

.PrimaryTheme .d-block {
    display: block !important;
}

.PrimaryTheme .d-table {
    display: table !important;
}

.PrimaryTheme .d-table-row {
    display: table-row !important;
}

.PrimaryTheme .d-table-cell {
    display: table-cell !important;
}

.PrimaryTheme .d-flex {
    display: -webkit-box !important;
    display: flex !important;
}

.PrimaryTheme .d-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .d-sm-none {
        display: none !important;
    }
    .PrimaryTheme .d-sm-inline {
        display: inline !important;
    }
    .PrimaryTheme .d-sm-inline-block {
        display: inline-block !important;
    }
    .PrimaryTheme .d-sm-block {
        display: block !important;
    }
    .PrimaryTheme .d-sm-table {
        display: table !important;
    }
    .PrimaryTheme .d-sm-table-row {
        display: table-row !important;
    }
    .PrimaryTheme .d-sm-table-cell {
        display: table-cell !important;
    }
    .PrimaryTheme .d-sm-flex {
        display: -webkit-box !important;
        display: flex !important;
    }
    .PrimaryTheme .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .d-md-none {
        display: none !important;
    }
    .PrimaryTheme .d-md-inline {
        display: inline !important;
    }
    .PrimaryTheme .d-md-inline-block {
        display: inline-block !important;
    }
    .PrimaryTheme .d-md-block {
        display: block !important;
    }
    .PrimaryTheme .d-md-table {
        display: table !important;
    }
    .PrimaryTheme .d-md-table-row {
        display: table-row !important;
    }
    .PrimaryTheme .d-md-table-cell {
        display: table-cell !important;
    }
    .PrimaryTheme .d-md-flex {
        display: -webkit-box !important;
        display: flex !important;
    }
    .PrimaryTheme .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .d-lg-none {
        display: none !important;
    }
    .PrimaryTheme .d-lg-inline {
        display: inline !important;
    }
    .PrimaryTheme .d-lg-inline-block {
        display: inline-block !important;
    }
    .PrimaryTheme .d-lg-block {
        display: block !important;
    }
    .PrimaryTheme .d-lg-table {
        display: table !important;
    }
    .PrimaryTheme .d-lg-table-row {
        display: table-row !important;
    }
    .PrimaryTheme .d-lg-table-cell {
        display: table-cell !important;
    }
    .PrimaryTheme .d-lg-flex {
        display: -webkit-box !important;
        display: flex !important;
    }
    .PrimaryTheme .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .d-xl-none {
        display: none !important;
    }
    .PrimaryTheme .d-xl-inline {
        display: inline !important;
    }
    .PrimaryTheme .d-xl-inline-block {
        display: inline-block !important;
    }
    .PrimaryTheme .d-xl-block {
        display: block !important;
    }
    .PrimaryTheme .d-xl-table {
        display: table !important;
    }
    .PrimaryTheme .d-xl-table-row {
        display: table-row !important;
    }
    .PrimaryTheme .d-xl-table-cell {
        display: table-cell !important;
    }
    .PrimaryTheme .d-xl-flex {
        display: -webkit-box !important;
        display: flex !important;
    }
    .PrimaryTheme .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: inline-flex !important;
    }
}

@media print {
    .PrimaryTheme .d-print-none {
        display: none !important;
    }
    .PrimaryTheme .d-print-inline {
        display: inline !important;
    }
    .PrimaryTheme .d-print-inline-block {
        display: inline-block !important;
    }
    .PrimaryTheme .d-print-block {
        display: block !important;
    }
    .PrimaryTheme .d-print-table {
        display: table !important;
    }
    .PrimaryTheme .d-print-table-row {
        display: table-row !important;
    }
    .PrimaryTheme .d-print-table-cell {
        display: table-cell !important;
    }
    .PrimaryTheme .d-print-flex {
        display: -webkit-box !important;
        display: flex !important;
    }
    .PrimaryTheme .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: inline-flex !important;
    }
}

.PrimaryTheme .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.PrimaryTheme .embed-responsive::before {
    display: block;
    content: "";
}

.PrimaryTheme .embed-responsive .embed-responsive-item, .PrimaryTheme .embed-responsive embed, .PrimaryTheme .embed-responsive iframe, .PrimaryTheme .embed-responsive object, .PrimaryTheme .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.PrimaryTheme .embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.PrimaryTheme .embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.PrimaryTheme .embed-responsive-4by3::before {
    padding-top: 75%;
}

.PrimaryTheme .embed-responsive-1by1::before {
    padding-top: 100%;
}

.PrimaryTheme .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
}

.PrimaryTheme .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
}

.PrimaryTheme .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
}

.PrimaryTheme .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
}

.PrimaryTheme .flex-wrap {
    flex-wrap: wrap !important;
}

.PrimaryTheme .flex-nowrap {
    flex-wrap: nowrap !important;
}

.PrimaryTheme .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.PrimaryTheme .flex-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
}

.PrimaryTheme .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
}

.PrimaryTheme .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
}

.PrimaryTheme .flex-shrink-0 {
    flex-shrink: 0 !important;
}

.PrimaryTheme .flex-shrink-1 {
    flex-shrink: 1 !important;
}

.PrimaryTheme .justify-content-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
}

.PrimaryTheme .justify-content-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
}

.PrimaryTheme .justify-content-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
}

.PrimaryTheme .justify-content-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
}

.PrimaryTheme .justify-content-around {
    justify-content: space-around !important;
}

.PrimaryTheme .align-items-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
}

.PrimaryTheme .align-items-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
}

.PrimaryTheme .align-items-center {
    -webkit-box-align: center !important;
    align-items: center !important;
}

.PrimaryTheme .align-items-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
}

.PrimaryTheme .align-items-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
}

.PrimaryTheme .align-content-start {
    align-content: flex-start !important;
}

.PrimaryTheme .align-content-end {
    align-content: flex-end !important;
}

.PrimaryTheme .align-content-center {
    align-content: center !important;
}

.PrimaryTheme .align-content-between {
    align-content: space-between !important;
}

.PrimaryTheme .align-content-around {
    align-content: space-around !important;
}

.PrimaryTheme .align-content-stretch {
    align-content: stretch !important;
}

.PrimaryTheme .align-self-auto {
    align-self: auto !important;
}

.PrimaryTheme .align-self-start {
    align-self: flex-start !important;
}

.PrimaryTheme .align-self-end {
    align-self: flex-end !important;
}

.PrimaryTheme .align-self-center {
    align-self: center !important;
}

.PrimaryTheme .align-self-baseline {
    align-self: baseline !important;
}

.PrimaryTheme .align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        flex-direction: row !important;
    }
    .PrimaryTheme .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        flex-direction: column !important;
    }
    .PrimaryTheme .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        flex-direction: row-reverse !important;
    }
    .PrimaryTheme .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        flex-direction: column-reverse !important;
    }
    .PrimaryTheme .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .PrimaryTheme .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .PrimaryTheme .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .PrimaryTheme .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        flex: 1 1 auto !important;
    }
    .PrimaryTheme .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        flex-grow: 0 !important;
    }
    .PrimaryTheme .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }
    .PrimaryTheme .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .PrimaryTheme .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .PrimaryTheme .justify-content-sm-start {
        -webkit-box-pack: start !important;
        justify-content: flex-start !important;
    }
    .PrimaryTheme .justify-content-sm-end {
        -webkit-box-pack: end !important;
        justify-content: flex-end !important;
    }
    .PrimaryTheme .justify-content-sm-center {
        -webkit-box-pack: center !important;
        justify-content: center !important;
    }
    .PrimaryTheme .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        justify-content: space-between !important;
    }
    .PrimaryTheme .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .PrimaryTheme .align-items-sm-start {
        -webkit-box-align: start !important;
        align-items: flex-start !important;
    }
    .PrimaryTheme .align-items-sm-end {
        -webkit-box-align: end !important;
        align-items: flex-end !important;
    }
    .PrimaryTheme .align-items-sm-center {
        -webkit-box-align: center !important;
        align-items: center !important;
    }
    .PrimaryTheme .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        align-items: baseline !important;
    }
    .PrimaryTheme .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        align-items: stretch !important;
    }
    .PrimaryTheme .align-content-sm-start {
        align-content: flex-start !important;
    }
    .PrimaryTheme .align-content-sm-end {
        align-content: flex-end !important;
    }
    .PrimaryTheme .align-content-sm-center {
        align-content: center !important;
    }
    .PrimaryTheme .align-content-sm-between {
        align-content: space-between !important;
    }
    .PrimaryTheme .align-content-sm-around {
        align-content: space-around !important;
    }
    .PrimaryTheme .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .PrimaryTheme .align-self-sm-auto {
        align-self: auto !important;
    }
    .PrimaryTheme .align-self-sm-start {
        align-self: flex-start !important;
    }
    .PrimaryTheme .align-self-sm-end {
        align-self: flex-end !important;
    }
    .PrimaryTheme .align-self-sm-center {
        align-self: center !important;
    }
    .PrimaryTheme .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .PrimaryTheme .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        flex-direction: row !important;
    }
    .PrimaryTheme .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        flex-direction: column !important;
    }
    .PrimaryTheme .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        flex-direction: row-reverse !important;
    }
    .PrimaryTheme .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        flex-direction: column-reverse !important;
    }
    .PrimaryTheme .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .PrimaryTheme .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .PrimaryTheme .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .PrimaryTheme .flex-md-fill {
        -webkit-box-flex: 1 !important;
        flex: 1 1 auto !important;
    }
    .PrimaryTheme .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        flex-grow: 0 !important;
    }
    .PrimaryTheme .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }
    .PrimaryTheme .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .PrimaryTheme .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .PrimaryTheme .justify-content-md-start {
        -webkit-box-pack: start !important;
        justify-content: flex-start !important;
    }
    .PrimaryTheme .justify-content-md-end {
        -webkit-box-pack: end !important;
        justify-content: flex-end !important;
    }
    .PrimaryTheme .justify-content-md-center {
        -webkit-box-pack: center !important;
        justify-content: center !important;
    }
    .PrimaryTheme .justify-content-md-between {
        -webkit-box-pack: justify !important;
        justify-content: space-between !important;
    }
    .PrimaryTheme .justify-content-md-around {
        justify-content: space-around !important;
    }
    .PrimaryTheme .align-items-md-start {
        -webkit-box-align: start !important;
        align-items: flex-start !important;
    }
    .PrimaryTheme .align-items-md-end {
        -webkit-box-align: end !important;
        align-items: flex-end !important;
    }
    .PrimaryTheme .align-items-md-center {
        -webkit-box-align: center !important;
        align-items: center !important;
    }
    .PrimaryTheme .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        align-items: baseline !important;
    }
    .PrimaryTheme .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        align-items: stretch !important;
    }
    .PrimaryTheme .align-content-md-start {
        align-content: flex-start !important;
    }
    .PrimaryTheme .align-content-md-end {
        align-content: flex-end !important;
    }
    .PrimaryTheme .align-content-md-center {
        align-content: center !important;
    }
    .PrimaryTheme .align-content-md-between {
        align-content: space-between !important;
    }
    .PrimaryTheme .align-content-md-around {
        align-content: space-around !important;
    }
    .PrimaryTheme .align-content-md-stretch {
        align-content: stretch !important;
    }
    .PrimaryTheme .align-self-md-auto {
        align-self: auto !important;
    }
    .PrimaryTheme .align-self-md-start {
        align-self: flex-start !important;
    }
    .PrimaryTheme .align-self-md-end {
        align-self: flex-end !important;
    }
    .PrimaryTheme .align-self-md-center {
        align-self: center !important;
    }
    .PrimaryTheme .align-self-md-baseline {
        align-self: baseline !important;
    }
    .PrimaryTheme .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        flex-direction: row !important;
    }
    .PrimaryTheme .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        flex-direction: column !important;
    }
    .PrimaryTheme .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        flex-direction: row-reverse !important;
    }
    .PrimaryTheme .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        flex-direction: column-reverse !important;
    }
    .PrimaryTheme .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .PrimaryTheme .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .PrimaryTheme .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .PrimaryTheme .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        flex: 1 1 auto !important;
    }
    .PrimaryTheme .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        flex-grow: 0 !important;
    }
    .PrimaryTheme .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }
    .PrimaryTheme .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .PrimaryTheme .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .PrimaryTheme .justify-content-lg-start {
        -webkit-box-pack: start !important;
        justify-content: flex-start !important;
    }
    .PrimaryTheme .justify-content-lg-end {
        -webkit-box-pack: end !important;
        justify-content: flex-end !important;
    }
    .PrimaryTheme .justify-content-lg-center {
        -webkit-box-pack: center !important;
        justify-content: center !important;
    }
    .PrimaryTheme .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        justify-content: space-between !important;
    }
    .PrimaryTheme .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .PrimaryTheme .align-items-lg-start {
        -webkit-box-align: start !important;
        align-items: flex-start !important;
    }
    .PrimaryTheme .align-items-lg-end {
        -webkit-box-align: end !important;
        align-items: flex-end !important;
    }
    .PrimaryTheme .align-items-lg-center {
        -webkit-box-align: center !important;
        align-items: center !important;
    }
    .PrimaryTheme .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        align-items: baseline !important;
    }
    .PrimaryTheme .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        align-items: stretch !important;
    }
    .PrimaryTheme .align-content-lg-start {
        align-content: flex-start !important;
    }
    .PrimaryTheme .align-content-lg-end {
        align-content: flex-end !important;
    }
    .PrimaryTheme .align-content-lg-center {
        align-content: center !important;
    }
    .PrimaryTheme .align-content-lg-between {
        align-content: space-between !important;
    }
    .PrimaryTheme .align-content-lg-around {
        align-content: space-around !important;
    }
    .PrimaryTheme .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .PrimaryTheme .align-self-lg-auto {
        align-self: auto !important;
    }
    .PrimaryTheme .align-self-lg-start {
        align-self: flex-start !important;
    }
    .PrimaryTheme .align-self-lg-end {
        align-self: flex-end !important;
    }
    .PrimaryTheme .align-self-lg-center {
        align-self: center !important;
    }
    .PrimaryTheme .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .PrimaryTheme .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        flex-direction: row !important;
    }
    .PrimaryTheme .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        flex-direction: column !important;
    }
    .PrimaryTheme .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        flex-direction: row-reverse !important;
    }
    .PrimaryTheme .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        flex-direction: column-reverse !important;
    }
    .PrimaryTheme .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .PrimaryTheme .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .PrimaryTheme .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .PrimaryTheme .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        flex: 1 1 auto !important;
    }
    .PrimaryTheme .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        flex-grow: 0 !important;
    }
    .PrimaryTheme .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }
    .PrimaryTheme .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .PrimaryTheme .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .PrimaryTheme .justify-content-xl-start {
        -webkit-box-pack: start !important;
        justify-content: flex-start !important;
    }
    .PrimaryTheme .justify-content-xl-end {
        -webkit-box-pack: end !important;
        justify-content: flex-end !important;
    }
    .PrimaryTheme .justify-content-xl-center {
        -webkit-box-pack: center !important;
        justify-content: center !important;
    }
    .PrimaryTheme .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        justify-content: space-between !important;
    }
    .PrimaryTheme .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .PrimaryTheme .align-items-xl-start {
        -webkit-box-align: start !important;
        align-items: flex-start !important;
    }
    .PrimaryTheme .align-items-xl-end {
        -webkit-box-align: end !important;
        align-items: flex-end !important;
    }
    .PrimaryTheme .align-items-xl-center {
        -webkit-box-align: center !important;
        align-items: center !important;
    }
    .PrimaryTheme .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        align-items: baseline !important;
    }
    .PrimaryTheme .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        align-items: stretch !important;
    }
    .PrimaryTheme .align-content-xl-start {
        align-content: flex-start !important;
    }
    .PrimaryTheme .align-content-xl-end {
        align-content: flex-end !important;
    }
    .PrimaryTheme .align-content-xl-center {
        align-content: center !important;
    }
    .PrimaryTheme .align-content-xl-between {
        align-content: space-between !important;
    }
    .PrimaryTheme .align-content-xl-around {
        align-content: space-around !important;
    }
    .PrimaryTheme .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .PrimaryTheme .align-self-xl-auto {
        align-self: auto !important;
    }
    .PrimaryTheme .align-self-xl-start {
        align-self: flex-start !important;
    }
    .PrimaryTheme .align-self-xl-end {
        align-self: flex-end !important;
    }
    .PrimaryTheme .align-self-xl-center {
        align-self: center !important;
    }
    .PrimaryTheme .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .PrimaryTheme .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.PrimaryTheme .float-left {
    float: left !important;
}

.PrimaryTheme .float-right {
    float: right !important;
}

.PrimaryTheme .float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .float-sm-left {
        float: left !important;
    }
    .PrimaryTheme .float-sm-right {
        float: right !important;
    }
    .PrimaryTheme .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .float-md-left {
        float: left !important;
    }
    .PrimaryTheme .float-md-right {
        float: right !important;
    }
    .PrimaryTheme .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .float-lg-left {
        float: left !important;
    }
    .PrimaryTheme .float-lg-right {
        float: right !important;
    }
    .PrimaryTheme .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .float-xl-left {
        float: left !important;
    }
    .PrimaryTheme .float-xl-right {
        float: right !important;
    }
    .PrimaryTheme .float-xl-none {
        float: none !important;
    }
}

.PrimaryTheme .overflow-auto {
    overflow: auto !important;
}

.PrimaryTheme .overflow-hidden {
    overflow: hidden !important;
}

.PrimaryTheme .position-static {
    position: static !important;
}

.PrimaryTheme .position-relative {
    position: relative !important;
}

.PrimaryTheme .position-absolute {
    position: absolute !important;
}

.PrimaryTheme .position-fixed {
    position: fixed !important;
}

.PrimaryTheme .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.PrimaryTheme .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.PrimaryTheme .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
    .PrimaryTheme .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.PrimaryTheme .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.PrimaryTheme .sr-only-focusable:active, .PrimaryTheme .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.PrimaryTheme .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075) !important;
}

.PrimaryTheme .shadow {
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
}

.PrimaryTheme .shadow-lg {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1) !important;
}

.PrimaryTheme .shadow-none {
    box-shadow: none !important;
}

.PrimaryTheme .w-25 {
    width: 25% !important;
}

.PrimaryTheme .w-50 {
    width: 50% !important;
}

.PrimaryTheme .w-75 {
    width: 75% !important;
}

.PrimaryTheme .w-100 {
    width: 100% !important;
}

.PrimaryTheme .w-auto {
    width: auto !important;
}

.PrimaryTheme .w-110 {
    width: 110% !important;
}

.PrimaryTheme .w-120 {
    width: 120% !important;
}

.PrimaryTheme .w-130 {
    width: 130% !important;
}

.PrimaryTheme .w-140 {
    width: 140% !important;
}

.PrimaryTheme .w-150 {
    width: 150% !important;
}

.PrimaryTheme .h-25 {
    height: 25% !important;
}

.PrimaryTheme .h-50 {
    height: 50% !important;
}

.PrimaryTheme .h-75 {
    height: 75% !important;
}

.PrimaryTheme .h-100 {
    height: 100% !important;
}

.PrimaryTheme .h-auto {
    height: auto !important;
}

.PrimaryTheme .h-110 {
    height: 110% !important;
}

.PrimaryTheme .h-120 {
    height: 120% !important;
}

.PrimaryTheme .h-130 {
    height: 130% !important;
}

.PrimaryTheme .h-140 {
    height: 140% !important;
}

.PrimaryTheme .h-150 {
    height: 150% !important;
}

.PrimaryTheme .mw-100 {
    max-width: 100% !important;
}

.PrimaryTheme .mh-100 {
    max-height: 100% !important;
}

.PrimaryTheme .min-vw-100 {
    min-width: 100vw !important;
}

.PrimaryTheme .min-vh-100 {
    min-height: 100vh !important;
}

.PrimaryTheme .vw-100 {
    width: 100vw !important;
}

.PrimaryTheme .vh-100 {
    height: 100vh !important;
}

.PrimaryTheme .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.PrimaryTheme .m-0 {
    margin: 0 !important;
}

.PrimaryTheme .mt-0, .PrimaryTheme .my-0 {
    margin-top: 0 !important;
}

.PrimaryTheme .mr-0, .PrimaryTheme .mx-0 {
    margin-right: 0 !important;
}

.PrimaryTheme .mb-0, .PrimaryTheme .my-0 {
    margin-bottom: 0 !important;
}

.PrimaryTheme .ml-0, .PrimaryTheme .mx-0 {
    margin-left: 0 !important;
}

.PrimaryTheme .m-1 {
    margin: .25rem !important;
}

.PrimaryTheme .mt-1, .PrimaryTheme .my-1 {
    margin-top: .25rem !important;
}

.PrimaryTheme .mr-1, .PrimaryTheme .mx-1 {
    margin-right: .25rem !important;
}

.PrimaryTheme .mb-1, .PrimaryTheme .my-1 {
    margin-bottom: .25rem !important;
}

.PrimaryTheme .ml-1, .PrimaryTheme .mx-1 {
    margin-left: .25rem !important;
}

.PrimaryTheme .m-2 {
    margin: .5rem !important;
}

.PrimaryTheme .mt-2, .PrimaryTheme .my-2 {
    margin-top: .5rem !important;
}

.PrimaryTheme .mr-2, .PrimaryTheme .mx-2 {
    margin-right: .5rem !important;
}

.PrimaryTheme .mb-2, .PrimaryTheme .my-2 {
    margin-bottom: .5rem !important;
}

.PrimaryTheme .ml-2, .PrimaryTheme .mx-2 {
    margin-left: .5rem !important;
}

.PrimaryTheme .m-3 {
    margin: .75rem !important;
}

.PrimaryTheme .mt-3, .PrimaryTheme .my-3 {
    margin-top: .75rem !important;
}

.PrimaryTheme .mr-3, .PrimaryTheme .mx-3 {
    margin-right: .75rem !important;
}

.PrimaryTheme .mb-3, .PrimaryTheme .my-3 {
    margin-bottom: .75rem !important;
}

.PrimaryTheme .ml-3, .PrimaryTheme .mx-3 {
    margin-left: .75rem !important;
}

.PrimaryTheme .m-4 {
    margin: 1rem !important;
}

.PrimaryTheme .mt-4, .PrimaryTheme .my-4 {
    margin-top: 1rem !important;
}

.PrimaryTheme .mr-4, .PrimaryTheme .mx-4 {
    margin-right: 1rem !important;
}

.PrimaryTheme .mb-4, .PrimaryTheme .my-4 {
    margin-bottom: 1rem !important;
}

.PrimaryTheme .ml-4, .PrimaryTheme .mx-4 {
    margin-left: 1rem !important;
}

.PrimaryTheme .m-5 {
    margin: 1.5rem !important;
}

.PrimaryTheme .mt-5, .PrimaryTheme .my-5 {
    margin-top: 1.5rem !important;
}

.PrimaryTheme .mr-5, .PrimaryTheme .mx-5 {
    margin-right: 1.5rem !important;
}

.PrimaryTheme .mb-5, .PrimaryTheme .my-5 {
    margin-bottom: 1.5rem !important;
}

.PrimaryTheme .ml-5, .PrimaryTheme .mx-5 {
    margin-left: 1.5rem !important;
}

.PrimaryTheme .m-6 {
    margin: 2rem !important;
}

.PrimaryTheme .mt-6, .PrimaryTheme .my-6 {
    margin-top: 2rem !important;
}

.PrimaryTheme .mr-6, .PrimaryTheme .mx-6 {
    margin-right: 2rem !important;
}

.PrimaryTheme .mb-6, .PrimaryTheme .my-6 {
    margin-bottom: 2rem !important;
}

.PrimaryTheme .ml-6, .PrimaryTheme .mx-6 {
    margin-left: 2rem !important;
}

.PrimaryTheme .m-7 {
    margin: 2.5rem !important;
}

.PrimaryTheme .mt-7, .PrimaryTheme .my-7 {
    margin-top: 2.5rem !important;
}

.PrimaryTheme .mr-7, .PrimaryTheme .mx-7 {
    margin-right: 2.5rem !important;
}

.PrimaryTheme .mb-7, .PrimaryTheme .my-7 {
    margin-bottom: 2.5rem !important;
}

.PrimaryTheme .ml-7, .PrimaryTheme .mx-7 {
    margin-left: 2.5rem !important;
}

.PrimaryTheme .m-8 {
    margin: 3rem !important;
}

.PrimaryTheme .mt-8, .PrimaryTheme .my-8 {
    margin-top: 3rem !important;
}

.PrimaryTheme .mr-8, .PrimaryTheme .mx-8 {
    margin-right: 3rem !important;
}

.PrimaryTheme .mb-8, .PrimaryTheme .my-8 {
    margin-bottom: 3rem !important;
}

.PrimaryTheme .ml-8, .PrimaryTheme .mx-8 {
    margin-left: 3rem !important;
}

.PrimaryTheme .m-9 {
    margin: 4rem !important;
}

.PrimaryTheme .mt-9, .PrimaryTheme .my-9 {
    margin-top: 4rem !important;
}

.PrimaryTheme .mr-9, .PrimaryTheme .mx-9 {
    margin-right: 4rem !important;
}

.PrimaryTheme .mb-9, .PrimaryTheme .my-9 {
    margin-bottom: 4rem !important;
}

.PrimaryTheme .ml-9, .PrimaryTheme .mx-9 {
    margin-left: 4rem !important;
}

.PrimaryTheme .m-10 {
    margin: 5rem !important;
}

.PrimaryTheme .mt-10, .PrimaryTheme .my-10 {
    margin-top: 5rem !important;
}

.PrimaryTheme .mr-10, .PrimaryTheme .mx-10 {
    margin-right: 5rem !important;
}

.PrimaryTheme .mb-10, .PrimaryTheme .my-10 {
    margin-bottom: 5rem !important;
}

.PrimaryTheme .ml-10, .PrimaryTheme .mx-10 {
    margin-left: 5rem !important;
}

.PrimaryTheme .m-11 {
    margin: 6rem !important;
}

.PrimaryTheme .mt-11, .PrimaryTheme .my-11 {
    margin-top: 6rem !important;
}

.PrimaryTheme .mr-11, .PrimaryTheme .mx-11 {
    margin-right: 6rem !important;
}

.PrimaryTheme .mb-11, .PrimaryTheme .my-11 {
    margin-bottom: 6rem !important;
}

.PrimaryTheme .ml-11, .PrimaryTheme .mx-11 {
    margin-left: 6rem !important;
}

.PrimaryTheme .m-12 {
    margin: 8rem !important;
}

.PrimaryTheme .mt-12, .PrimaryTheme .my-12 {
    margin-top: 8rem !important;
}

.PrimaryTheme .mr-12, .PrimaryTheme .mx-12 {
    margin-right: 8rem !important;
}

.PrimaryTheme .mb-12, .PrimaryTheme .my-12 {
    margin-bottom: 8rem !important;
}

.PrimaryTheme .ml-12, .PrimaryTheme .mx-12 {
    margin-left: 8rem !important;
}

.PrimaryTheme .m-13 {
    margin: 10rem !important;
}

.PrimaryTheme .mt-13, .PrimaryTheme .my-13 {
    margin-top: 10rem !important;
}

.PrimaryTheme .mr-13, .PrimaryTheme .mx-13 {
    margin-right: 10rem !important;
}

.PrimaryTheme .mb-13, .PrimaryTheme .my-13 {
    margin-bottom: 10rem !important;
}

.PrimaryTheme .ml-13, .PrimaryTheme .mx-13 {
    margin-left: 10rem !important;
}

.PrimaryTheme .m-14 {
    margin: 12rem !important;
}

.PrimaryTheme .mt-14, .PrimaryTheme .my-14 {
    margin-top: 12rem !important;
}

.PrimaryTheme .mr-14, .PrimaryTheme .mx-14 {
    margin-right: 12rem !important;
}

.PrimaryTheme .mb-14, .PrimaryTheme .my-14 {
    margin-bottom: 12rem !important;
}

.PrimaryTheme .ml-14, .PrimaryTheme .mx-14 {
    margin-left: 12rem !important;
}

.PrimaryTheme .m-15 {
    margin: 16rem !important;
}

.PrimaryTheme .mt-15, .PrimaryTheme .my-15 {
    margin-top: 16rem !important;
}

.PrimaryTheme .mr-15, .PrimaryTheme .mx-15 {
    margin-right: 16rem !important;
}

.PrimaryTheme .mb-15, .PrimaryTheme .my-15 {
    margin-bottom: 16rem !important;
}

.PrimaryTheme .ml-15, .PrimaryTheme .mx-15 {
    margin-left: 16rem !important;
}

.PrimaryTheme .m-16 {
    margin: 25rem !important;
}

.PrimaryTheme .mt-16, .PrimaryTheme .my-16 {
    margin-top: 25rem !important;
}

.PrimaryTheme .mr-16, .PrimaryTheme .mx-16 {
    margin-right: 25rem !important;
}

.PrimaryTheme .mb-16, .PrimaryTheme .my-16 {
    margin-bottom: 25rem !important;
}

.PrimaryTheme .ml-16, .PrimaryTheme .mx-16 {
    margin-left: 25rem !important;
}

.PrimaryTheme .p-0 {
    padding: 0 !important;
}

.PrimaryTheme .pt-0, .PrimaryTheme .py-0 {
    padding-top: 0 !important;
}

.PrimaryTheme .pr-0, .PrimaryTheme .px-0 {
    padding-right: 0 !important;
}

.PrimaryTheme .pb-0, .PrimaryTheme .py-0 {
    padding-bottom: 0 !important;
}

.PrimaryTheme .pl-0, .PrimaryTheme .px-0 {
    padding-left: 0 !important;
}

.PrimaryTheme .p-1 {
    padding: .25rem !important;
}

.PrimaryTheme .pt-1, .PrimaryTheme .py-1 {
    padding-top: .25rem !important;
}

.PrimaryTheme .pr-1, .PrimaryTheme .px-1 {
    padding-right: .25rem !important;
}

.PrimaryTheme .pb-1, .PrimaryTheme .py-1 {
    padding-bottom: .25rem !important;
}

.PrimaryTheme .pl-1, .PrimaryTheme .px-1 {
    padding-left: .25rem !important;
}

.PrimaryTheme .p-2 {
    padding: .5rem !important;
}

.PrimaryTheme .pt-2, .PrimaryTheme .py-2 {
    padding-top: .5rem !important;
}

.PrimaryTheme .pr-2, .PrimaryTheme .px-2 {
    padding-right: .5rem !important;
}

.PrimaryTheme .pb-2, .PrimaryTheme .py-2 {
    padding-bottom: .5rem !important;
}

.PrimaryTheme .pl-2, .PrimaryTheme .px-2 {
    padding-left: .5rem !important;
}

.PrimaryTheme .p-3 {
    padding: .75rem !important;
}

.PrimaryTheme .pt-3, .PrimaryTheme .py-3 {
    padding-top: .75rem !important;
}

.PrimaryTheme .pr-3, .PrimaryTheme .px-3 {
    padding-right: .75rem !important;
}

.PrimaryTheme .pb-3, .PrimaryTheme .py-3 {
    padding-bottom: .75rem !important;
}

.PrimaryTheme .pl-3, .PrimaryTheme .px-3 {
    padding-left: .75rem !important;
}

.PrimaryTheme .p-4 {
    padding: 1rem !important;
}

.PrimaryTheme .pt-4, .PrimaryTheme .py-4 {
    padding-top: 1rem !important;
}

.PrimaryTheme .pr-4, .PrimaryTheme .px-4 {
    padding-right: 1rem !important;
}

.PrimaryTheme .pb-4, .PrimaryTheme .py-4 {
    padding-bottom: 1rem !important;
}

.PrimaryTheme .pl-4, .PrimaryTheme .px-4 {
    padding-left: 1rem !important;
}

.PrimaryTheme .p-5 {
    padding: 1.5rem !important;
}

.PrimaryTheme .pt-5, .PrimaryTheme .py-5 {
    padding-top: 1.5rem !important;
}

.PrimaryTheme .pr-5, .PrimaryTheme .px-5 {
    padding-right: 1.5rem !important;
}

.PrimaryTheme .pb-5, .PrimaryTheme .py-5 {
    padding-bottom: 1.5rem !important;
}

.PrimaryTheme .pl-5, .PrimaryTheme .px-5 {
    padding-left: 1.5rem !important;
}

.PrimaryTheme .p-6 {
    padding: 2rem !important;
}

.PrimaryTheme .pt-6, .PrimaryTheme .py-6 {
    padding-top: 2rem !important;
}

.PrimaryTheme .pr-6, .PrimaryTheme .px-6 {
    padding-right: 2rem !important;
}

.PrimaryTheme .pb-6, .PrimaryTheme .py-6 {
    padding-bottom: 2rem !important;
}

.PrimaryTheme .pl-6, .PrimaryTheme .px-6 {
    padding-left: 2rem !important;
}

.PrimaryTheme .p-7 {
    padding: 2.5rem !important;
}

.PrimaryTheme .pt-7, .PrimaryTheme .py-7 {
    padding-top: 2.5rem !important;
}

.PrimaryTheme .pr-7, .PrimaryTheme .px-7 {
    padding-right: 2.5rem !important;
}

.PrimaryTheme .pb-7, .PrimaryTheme .py-7 {
    padding-bottom: 2.5rem !important;
}

.PrimaryTheme .pl-7, .PrimaryTheme .px-7 {
    padding-left: 2.5rem !important;
}

.PrimaryTheme .p-8 {
    padding: 3rem !important;
}

.PrimaryTheme .pt-8, .PrimaryTheme .py-8 {
    padding-top: 3rem !important;
}

.PrimaryTheme .pr-8, .PrimaryTheme .px-8 {
    padding-right: 3rem !important;
}

.PrimaryTheme .pb-8, .PrimaryTheme .py-8 {
    padding-bottom: 3rem !important;
}

.PrimaryTheme .pl-8, .PrimaryTheme .px-8 {
    padding-left: 3rem !important;
}

.PrimaryTheme .p-9 {
    padding: 4rem !important;
}

.PrimaryTheme .pt-9, .PrimaryTheme .py-9 {
    padding-top: 4rem !important;
}

.PrimaryTheme .pr-9, .PrimaryTheme .px-9 {
    padding-right: 4rem !important;
}

.PrimaryTheme .pb-9, .PrimaryTheme .py-9 {
    padding-bottom: 4rem !important;
}

.PrimaryTheme .pl-9, .PrimaryTheme .px-9 {
    padding-left: 4rem !important;
}

.PrimaryTheme .p-10 {
    padding: 5rem !important;
}

.PrimaryTheme .pt-10, .PrimaryTheme .py-10 {
    padding-top: 5rem !important;
}

.PrimaryTheme .pr-10, .PrimaryTheme .px-10 {
    padding-right: 5rem !important;
}

.PrimaryTheme .pb-10, .PrimaryTheme .py-10 {
    padding-bottom: 5rem !important;
}

.PrimaryTheme .pl-10, .PrimaryTheme .px-10 {
    padding-left: 5rem !important;
}

.PrimaryTheme .p-11 {
    padding: 6rem !important;
}

.PrimaryTheme .pt-11, .PrimaryTheme .py-11 {
    padding-top: 6rem !important;
}

.PrimaryTheme .pr-11, .PrimaryTheme .px-11 {
    padding-right: 6rem !important;
}

.PrimaryTheme .pb-11, .PrimaryTheme .py-11 {
    padding-bottom: 6rem !important;
}

.PrimaryTheme .pl-11, .PrimaryTheme .px-11 {
    padding-left: 6rem !important;
}

.PrimaryTheme .p-12 {
    padding: 8rem !important;
}

.PrimaryTheme .pt-12, .PrimaryTheme .py-12 {
    padding-top: 8rem !important;
}

.PrimaryTheme .pr-12, .PrimaryTheme .px-12 {
    padding-right: 8rem !important;
}

.PrimaryTheme .pb-12, .PrimaryTheme .py-12 {
    padding-bottom: 8rem !important;
}

.PrimaryTheme .pl-12, .PrimaryTheme .px-12 {
    padding-left: 8rem !important;
}

.PrimaryTheme .p-13 {
    padding: 10rem !important;
}

.PrimaryTheme .pt-13, .PrimaryTheme .py-13 {
    padding-top: 10rem !important;
}

.PrimaryTheme .pr-13, .PrimaryTheme .px-13 {
    padding-right: 10rem !important;
}

.PrimaryTheme .pb-13, .PrimaryTheme .py-13 {
    padding-bottom: 10rem !important;
}

.PrimaryTheme .pl-13, .PrimaryTheme .px-13 {
    padding-left: 10rem !important;
}

.PrimaryTheme .p-14 {
    padding: 12rem !important;
}

.PrimaryTheme .pt-14, .PrimaryTheme .py-14 {
    padding-top: 12rem !important;
}

.PrimaryTheme .pr-14, .PrimaryTheme .px-14 {
    padding-right: 12rem !important;
}

.PrimaryTheme .pb-14, .PrimaryTheme .py-14 {
    padding-bottom: 12rem !important;
}

.PrimaryTheme .pl-14, .PrimaryTheme .px-14 {
    padding-left: 12rem !important;
}

.PrimaryTheme .p-15 {
    padding: 16rem !important;
}

.PrimaryTheme .pt-15, .PrimaryTheme .py-15 {
    padding-top: 16rem !important;
}

.PrimaryTheme .pr-15, .PrimaryTheme .px-15 {
    padding-right: 16rem !important;
}

.PrimaryTheme .pb-15, .PrimaryTheme .py-15 {
    padding-bottom: 16rem !important;
}

.PrimaryTheme .pl-15, .PrimaryTheme .px-15 {
    padding-left: 16rem !important;
}

.PrimaryTheme .p-16 {
    padding: 25rem !important;
}

.PrimaryTheme .pt-16, .PrimaryTheme .py-16 {
    padding-top: 25rem !important;
}

.PrimaryTheme .pr-16, .PrimaryTheme .px-16 {
    padding-right: 25rem !important;
}

.PrimaryTheme .pb-16, .PrimaryTheme .py-16 {
    padding-bottom: 25rem !important;
}

.PrimaryTheme .pl-16, .PrimaryTheme .px-16 {
    padding-left: 25rem !important;
}

.PrimaryTheme .m-n1 {
    margin: -.25rem !important;
}

.PrimaryTheme .mt-n1, .PrimaryTheme .my-n1 {
    margin-top: -.25rem !important;
}

.PrimaryTheme .mr-n1, .PrimaryTheme .mx-n1 {
    margin-right: -.25rem !important;
}

.PrimaryTheme .mb-n1, .PrimaryTheme .my-n1 {
    margin-bottom: -.25rem !important;
}

.PrimaryTheme .ml-n1, .PrimaryTheme .mx-n1 {
    margin-left: -.25rem !important;
}

.PrimaryTheme .m-n2 {
    margin: -.5rem !important;
}

.PrimaryTheme .mt-n2, .PrimaryTheme .my-n2 {
    margin-top: -.5rem !important;
}

.PrimaryTheme .mr-n2, .PrimaryTheme .mx-n2 {
    margin-right: -.5rem !important;
}

.PrimaryTheme .mb-n2, .PrimaryTheme .my-n2 {
    margin-bottom: -.5rem !important;
}

.PrimaryTheme .ml-n2, .PrimaryTheme .mx-n2 {
    margin-left: -.5rem !important;
}

.PrimaryTheme .m-n3 {
    margin: -.75rem !important;
}

.PrimaryTheme .mt-n3, .PrimaryTheme .my-n3 {
    margin-top: -.75rem !important;
}

.PrimaryTheme .mr-n3, .PrimaryTheme .mx-n3 {
    margin-right: -.75rem !important;
}

.PrimaryTheme .mb-n3, .PrimaryTheme .my-n3 {
    margin-bottom: -.75rem !important;
}

.PrimaryTheme .ml-n3, .PrimaryTheme .mx-n3 {
    margin-left: -.75rem !important;
}

.PrimaryTheme .m-n4 {
    margin: -1rem !important;
}

.PrimaryTheme .mt-n4, .PrimaryTheme .my-n4 {
    margin-top: -1rem !important;
}

.PrimaryTheme .mr-n4, .PrimaryTheme .mx-n4 {
    margin-right: -1rem !important;
}

.PrimaryTheme .mb-n4, .PrimaryTheme .my-n4 {
    margin-bottom: -1rem !important;
}

.PrimaryTheme .ml-n4, .PrimaryTheme .mx-n4 {
    margin-left: -1rem !important;
}

.PrimaryTheme .m-n5 {
    margin: -1.5rem !important;
}

.PrimaryTheme .mt-n5, .PrimaryTheme .my-n5 {
    margin-top: -1.5rem !important;
}

.PrimaryTheme .mr-n5, .PrimaryTheme .mx-n5 {
    margin-right: -1.5rem !important;
}

.PrimaryTheme .mb-n5, .PrimaryTheme .my-n5 {
    margin-bottom: -1.5rem !important;
}

.PrimaryTheme .ml-n5, .PrimaryTheme .mx-n5 {
    margin-left: -1.5rem !important;
}

.PrimaryTheme .m-n6 {
    margin: -2rem !important;
}

.PrimaryTheme .mt-n6, .PrimaryTheme .my-n6 {
    margin-top: -2rem !important;
}

.PrimaryTheme .mr-n6, .PrimaryTheme .mx-n6 {
    margin-right: -2rem !important;
}

.PrimaryTheme .mb-n6, .PrimaryTheme .my-n6 {
    margin-bottom: -2rem !important;
}

.PrimaryTheme .ml-n6, .PrimaryTheme .mx-n6 {
    margin-left: -2rem !important;
}

.PrimaryTheme .m-n7 {
    margin: -2.5rem !important;
}

.PrimaryTheme .mt-n7, .PrimaryTheme .my-n7 {
    margin-top: -2.5rem !important;
}

.PrimaryTheme .mr-n7, .PrimaryTheme .mx-n7 {
    margin-right: -2.5rem !important;
}

.PrimaryTheme .mb-n7, .PrimaryTheme .my-n7 {
    margin-bottom: -2.5rem !important;
}

.PrimaryTheme .ml-n7, .PrimaryTheme .mx-n7 {
    margin-left: -2.5rem !important;
}

.PrimaryTheme .m-n8 {
    margin: -3rem !important;
}

.PrimaryTheme .mt-n8, .PrimaryTheme .my-n8 {
    margin-top: -3rem !important;
}

.PrimaryTheme .mr-n8, .PrimaryTheme .mx-n8 {
    margin-right: -3rem !important;
}

.PrimaryTheme .mb-n8, .PrimaryTheme .my-n8 {
    margin-bottom: -3rem !important;
}

.PrimaryTheme .ml-n8, .PrimaryTheme .mx-n8 {
    margin-left: -3rem !important;
}

.PrimaryTheme .m-n9 {
    margin: -4rem !important;
}

.PrimaryTheme .mt-n9, .PrimaryTheme .my-n9 {
    margin-top: -4rem !important;
}

.PrimaryTheme .mr-n9, .PrimaryTheme .mx-n9 {
    margin-right: -4rem !important;
}

.PrimaryTheme .mb-n9, .PrimaryTheme .my-n9 {
    margin-bottom: -4rem !important;
}

.PrimaryTheme .ml-n9, .PrimaryTheme .mx-n9 {
    margin-left: -4rem !important;
}

.PrimaryTheme .m-n10 {
    margin: -5rem !important;
}

.PrimaryTheme .mt-n10, .PrimaryTheme .my-n10 {
    margin-top: -5rem !important;
}

.PrimaryTheme .mr-n10, .PrimaryTheme .mx-n10 {
    margin-right: -5rem !important;
}

.PrimaryTheme .mb-n10, .PrimaryTheme .my-n10 {
    margin-bottom: -5rem !important;
}

.PrimaryTheme .ml-n10, .PrimaryTheme .mx-n10 {
    margin-left: -5rem !important;
}

.PrimaryTheme .m-n11 {
    margin: -6rem !important;
}

.PrimaryTheme .mt-n11, .PrimaryTheme .my-n11 {
    margin-top: -6rem !important;
}

.PrimaryTheme .mr-n11, .PrimaryTheme .mx-n11 {
    margin-right: -6rem !important;
}

.PrimaryTheme .mb-n11, .PrimaryTheme .my-n11 {
    margin-bottom: -6rem !important;
}

.PrimaryTheme .ml-n11, .PrimaryTheme .mx-n11 {
    margin-left: -6rem !important;
}

.PrimaryTheme .m-n12 {
    margin: -8rem !important;
}

.PrimaryTheme .mt-n12, .PrimaryTheme .my-n12 {
    margin-top: -8rem !important;
}

.PrimaryTheme .mr-n12, .PrimaryTheme .mx-n12 {
    margin-right: -8rem !important;
}

.PrimaryTheme .mb-n12, .PrimaryTheme .my-n12 {
    margin-bottom: -8rem !important;
}

.PrimaryTheme .ml-n12, .PrimaryTheme .mx-n12 {
    margin-left: -8rem !important;
}

.PrimaryTheme .m-n13 {
    margin: -10rem !important;
}

.PrimaryTheme .mt-n13, .PrimaryTheme .my-n13 {
    margin-top: -10rem !important;
}

.PrimaryTheme .mr-n13, .PrimaryTheme .mx-n13 {
    margin-right: -10rem !important;
}

.PrimaryTheme .mb-n13, .PrimaryTheme .my-n13 {
    margin-bottom: -10rem !important;
}

.PrimaryTheme .ml-n13, .PrimaryTheme .mx-n13 {
    margin-left: -10rem !important;
}

.PrimaryTheme .m-n14 {
    margin: -12rem !important;
}

.PrimaryTheme .mt-n14, .PrimaryTheme .my-n14 {
    margin-top: -12rem !important;
}

.PrimaryTheme .mr-n14, .PrimaryTheme .mx-n14 {
    margin-right: -12rem !important;
}

.PrimaryTheme .mb-n14, .PrimaryTheme .my-n14 {
    margin-bottom: -12rem !important;
}

.PrimaryTheme .ml-n14, .PrimaryTheme .mx-n14 {
    margin-left: -12rem !important;
}

.PrimaryTheme .m-n15 {
    margin: -16rem !important;
}

.PrimaryTheme .mt-n15, .PrimaryTheme .my-n15 {
    margin-top: -16rem !important;
}

.PrimaryTheme .mr-n15, .PrimaryTheme .mx-n15 {
    margin-right: -16rem !important;
}

.PrimaryTheme .mb-n15, .PrimaryTheme .my-n15 {
    margin-bottom: -16rem !important;
}

.PrimaryTheme .ml-n15, .PrimaryTheme .mx-n15 {
    margin-left: -16rem !important;
}

.PrimaryTheme .m-n16 {
    margin: -25rem !important;
}

.PrimaryTheme .mt-n16, .PrimaryTheme .my-n16 {
    margin-top: -25rem !important;
}

.PrimaryTheme .mr-n16, .PrimaryTheme .mx-n16 {
    margin-right: -25rem !important;
}

.PrimaryTheme .mb-n16, .PrimaryTheme .my-n16 {
    margin-bottom: -25rem !important;
}

.PrimaryTheme .ml-n16, .PrimaryTheme .mx-n16 {
    margin-left: -25rem !important;
}

.PrimaryTheme .m-auto {
    margin: auto !important;
}

.PrimaryTheme .mt-auto, .PrimaryTheme .my-auto {
    margin-top: auto !important;
}

.PrimaryTheme .mr-auto, .PrimaryTheme .mx-auto {
    margin-right: auto !important;
}

.PrimaryTheme .mb-auto, .PrimaryTheme .my-auto {
    margin-bottom: auto !important;
}

.PrimaryTheme .ml-auto, .PrimaryTheme .mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .m-sm-0 {
        margin: 0 !important;
    }
    .PrimaryTheme .mt-sm-0, .PrimaryTheme .my-sm-0 {
        margin-top: 0 !important;
    }
    .PrimaryTheme .mr-sm-0, .PrimaryTheme .mx-sm-0 {
        margin-right: 0 !important;
    }
    .PrimaryTheme .mb-sm-0, .PrimaryTheme .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .PrimaryTheme .ml-sm-0, .PrimaryTheme .mx-sm-0 {
        margin-left: 0 !important;
    }
    .PrimaryTheme .m-sm-1 {
        margin: .25rem !important;
    }
    .PrimaryTheme .mt-sm-1, .PrimaryTheme .my-sm-1 {
        margin-top: .25rem !important;
    }
    .PrimaryTheme .mr-sm-1, .PrimaryTheme .mx-sm-1 {
        margin-right: .25rem !important;
    }
    .PrimaryTheme .mb-sm-1, .PrimaryTheme .my-sm-1 {
        margin-bottom: .25rem !important;
    }
    .PrimaryTheme .ml-sm-1, .PrimaryTheme .mx-sm-1 {
        margin-left: .25rem !important;
    }
    .PrimaryTheme .m-sm-2 {
        margin: .5rem !important;
    }
    .PrimaryTheme .mt-sm-2, .PrimaryTheme .my-sm-2 {
        margin-top: .5rem !important;
    }
    .PrimaryTheme .mr-sm-2, .PrimaryTheme .mx-sm-2 {
        margin-right: .5rem !important;
    }
    .PrimaryTheme .mb-sm-2, .PrimaryTheme .my-sm-2 {
        margin-bottom: .5rem !important;
    }
    .PrimaryTheme .ml-sm-2, .PrimaryTheme .mx-sm-2 {
        margin-left: .5rem !important;
    }
    .PrimaryTheme .m-sm-3 {
        margin: .75rem !important;
    }
    .PrimaryTheme .mt-sm-3, .PrimaryTheme .my-sm-3 {
        margin-top: .75rem !important;
    }
    .PrimaryTheme .mr-sm-3, .PrimaryTheme .mx-sm-3 {
        margin-right: .75rem !important;
    }
    .PrimaryTheme .mb-sm-3, .PrimaryTheme .my-sm-3 {
        margin-bottom: .75rem !important;
    }
    .PrimaryTheme .ml-sm-3, .PrimaryTheme .mx-sm-3 {
        margin-left: .75rem !important;
    }
    .PrimaryTheme .m-sm-4 {
        margin: 1rem !important;
    }
    .PrimaryTheme .mt-sm-4, .PrimaryTheme .my-sm-4 {
        margin-top: 1rem !important;
    }
    .PrimaryTheme .mr-sm-4, .PrimaryTheme .mx-sm-4 {
        margin-right: 1rem !important;
    }
    .PrimaryTheme .mb-sm-4, .PrimaryTheme .my-sm-4 {
        margin-bottom: 1rem !important;
    }
    .PrimaryTheme .ml-sm-4, .PrimaryTheme .mx-sm-4 {
        margin-left: 1rem !important;
    }
    .PrimaryTheme .m-sm-5 {
        margin: 1.5rem !important;
    }
    .PrimaryTheme .mt-sm-5, .PrimaryTheme .my-sm-5 {
        margin-top: 1.5rem !important;
    }
    .PrimaryTheme .mr-sm-5, .PrimaryTheme .mx-sm-5 {
        margin-right: 1.5rem !important;
    }
    .PrimaryTheme .mb-sm-5, .PrimaryTheme .my-sm-5 {
        margin-bottom: 1.5rem !important;
    }
    .PrimaryTheme .ml-sm-5, .PrimaryTheme .mx-sm-5 {
        margin-left: 1.5rem !important;
    }
    .PrimaryTheme .m-sm-6 {
        margin: 2rem !important;
    }
    .PrimaryTheme .mt-sm-6, .PrimaryTheme .my-sm-6 {
        margin-top: 2rem !important;
    }
    .PrimaryTheme .mr-sm-6, .PrimaryTheme .mx-sm-6 {
        margin-right: 2rem !important;
    }
    .PrimaryTheme .mb-sm-6, .PrimaryTheme .my-sm-6 {
        margin-bottom: 2rem !important;
    }
    .PrimaryTheme .ml-sm-6, .PrimaryTheme .mx-sm-6 {
        margin-left: 2rem !important;
    }
    .PrimaryTheme .m-sm-7 {
        margin: 2.5rem !important;
    }
    .PrimaryTheme .mt-sm-7, .PrimaryTheme .my-sm-7 {
        margin-top: 2.5rem !important;
    }
    .PrimaryTheme .mr-sm-7, .PrimaryTheme .mx-sm-7 {
        margin-right: 2.5rem !important;
    }
    .PrimaryTheme .mb-sm-7, .PrimaryTheme .my-sm-7 {
        margin-bottom: 2.5rem !important;
    }
    .PrimaryTheme .ml-sm-7, .PrimaryTheme .mx-sm-7 {
        margin-left: 2.5rem !important;
    }
    .PrimaryTheme .m-sm-8 {
        margin: 3rem !important;
    }
    .PrimaryTheme .mt-sm-8, .PrimaryTheme .my-sm-8 {
        margin-top: 3rem !important;
    }
    .PrimaryTheme .mr-sm-8, .PrimaryTheme .mx-sm-8 {
        margin-right: 3rem !important;
    }
    .PrimaryTheme .mb-sm-8, .PrimaryTheme .my-sm-8 {
        margin-bottom: 3rem !important;
    }
    .PrimaryTheme .ml-sm-8, .PrimaryTheme .mx-sm-8 {
        margin-left: 3rem !important;
    }
    .PrimaryTheme .m-sm-9 {
        margin: 4rem !important;
    }
    .PrimaryTheme .mt-sm-9, .PrimaryTheme .my-sm-9 {
        margin-top: 4rem !important;
    }
    .PrimaryTheme .mr-sm-9, .PrimaryTheme .mx-sm-9 {
        margin-right: 4rem !important;
    }
    .PrimaryTheme .mb-sm-9, .PrimaryTheme .my-sm-9 {
        margin-bottom: 4rem !important;
    }
    .PrimaryTheme .ml-sm-9, .PrimaryTheme .mx-sm-9 {
        margin-left: 4rem !important;
    }
    .PrimaryTheme .m-sm-10 {
        margin: 5rem !important;
    }
    .PrimaryTheme .mt-sm-10, .PrimaryTheme .my-sm-10 {
        margin-top: 5rem !important;
    }
    .PrimaryTheme .mr-sm-10, .PrimaryTheme .mx-sm-10 {
        margin-right: 5rem !important;
    }
    .PrimaryTheme .mb-sm-10, .PrimaryTheme .my-sm-10 {
        margin-bottom: 5rem !important;
    }
    .PrimaryTheme .ml-sm-10, .PrimaryTheme .mx-sm-10 {
        margin-left: 5rem !important;
    }
    .PrimaryTheme .m-sm-11 {
        margin: 6rem !important;
    }
    .PrimaryTheme .mt-sm-11, .PrimaryTheme .my-sm-11 {
        margin-top: 6rem !important;
    }
    .PrimaryTheme .mr-sm-11, .PrimaryTheme .mx-sm-11 {
        margin-right: 6rem !important;
    }
    .PrimaryTheme .mb-sm-11, .PrimaryTheme .my-sm-11 {
        margin-bottom: 6rem !important;
    }
    .PrimaryTheme .ml-sm-11, .PrimaryTheme .mx-sm-11 {
        margin-left: 6rem !important;
    }
    .PrimaryTheme .m-sm-12 {
        margin: 8rem !important;
    }
    .PrimaryTheme .mt-sm-12, .PrimaryTheme .my-sm-12 {
        margin-top: 8rem !important;
    }
    .PrimaryTheme .mr-sm-12, .PrimaryTheme .mx-sm-12 {
        margin-right: 8rem !important;
    }
    .PrimaryTheme .mb-sm-12, .PrimaryTheme .my-sm-12 {
        margin-bottom: 8rem !important;
    }
    .PrimaryTheme .ml-sm-12, .PrimaryTheme .mx-sm-12 {
        margin-left: 8rem !important;
    }
    .PrimaryTheme .m-sm-13 {
        margin: 10rem !important;
    }
    .PrimaryTheme .mt-sm-13, .PrimaryTheme .my-sm-13 {
        margin-top: 10rem !important;
    }
    .PrimaryTheme .mr-sm-13, .PrimaryTheme .mx-sm-13 {
        margin-right: 10rem !important;
    }
    .PrimaryTheme .mb-sm-13, .PrimaryTheme .my-sm-13 {
        margin-bottom: 10rem !important;
    }
    .PrimaryTheme .ml-sm-13, .PrimaryTheme .mx-sm-13 {
        margin-left: 10rem !important;
    }
    .PrimaryTheme .m-sm-14 {
        margin: 12rem !important;
    }
    .PrimaryTheme .mt-sm-14, .PrimaryTheme .my-sm-14 {
        margin-top: 12rem !important;
    }
    .PrimaryTheme .mr-sm-14, .PrimaryTheme .mx-sm-14 {
        margin-right: 12rem !important;
    }
    .PrimaryTheme .mb-sm-14, .PrimaryTheme .my-sm-14 {
        margin-bottom: 12rem !important;
    }
    .PrimaryTheme .ml-sm-14, .PrimaryTheme .mx-sm-14 {
        margin-left: 12rem !important;
    }
    .PrimaryTheme .m-sm-15 {
        margin: 16rem !important;
    }
    .PrimaryTheme .mt-sm-15, .PrimaryTheme .my-sm-15 {
        margin-top: 16rem !important;
    }
    .PrimaryTheme .mr-sm-15, .PrimaryTheme .mx-sm-15 {
        margin-right: 16rem !important;
    }
    .PrimaryTheme .mb-sm-15, .PrimaryTheme .my-sm-15 {
        margin-bottom: 16rem !important;
    }
    .PrimaryTheme .ml-sm-15, .PrimaryTheme .mx-sm-15 {
        margin-left: 16rem !important;
    }
    .PrimaryTheme .m-sm-16 {
        margin: 25rem !important;
    }
    .PrimaryTheme .mt-sm-16, .PrimaryTheme .my-sm-16 {
        margin-top: 25rem !important;
    }
    .PrimaryTheme .mr-sm-16, .PrimaryTheme .mx-sm-16 {
        margin-right: 25rem !important;
    }
    .PrimaryTheme .mb-sm-16, .PrimaryTheme .my-sm-16 {
        margin-bottom: 25rem !important;
    }
    .PrimaryTheme .ml-sm-16, .PrimaryTheme .mx-sm-16 {
        margin-left: 25rem !important;
    }
    .PrimaryTheme .p-sm-0 {
        padding: 0 !important;
    }
    .PrimaryTheme .pt-sm-0, .PrimaryTheme .py-sm-0 {
        padding-top: 0 !important;
    }
    .PrimaryTheme .pr-sm-0, .PrimaryTheme .px-sm-0 {
        padding-right: 0 !important;
    }
    .PrimaryTheme .pb-sm-0, .PrimaryTheme .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .PrimaryTheme .pl-sm-0, .PrimaryTheme .px-sm-0 {
        padding-left: 0 !important;
    }
    .PrimaryTheme .p-sm-1 {
        padding: .25rem !important;
    }
    .PrimaryTheme .pt-sm-1, .PrimaryTheme .py-sm-1 {
        padding-top: .25rem !important;
    }
    .PrimaryTheme .pr-sm-1, .PrimaryTheme .px-sm-1 {
        padding-right: .25rem !important;
    }
    .PrimaryTheme .pb-sm-1, .PrimaryTheme .py-sm-1 {
        padding-bottom: .25rem !important;
    }
    .PrimaryTheme .pl-sm-1, .PrimaryTheme .px-sm-1 {
        padding-left: .25rem !important;
    }
    .PrimaryTheme .p-sm-2 {
        padding: .5rem !important;
    }
    .PrimaryTheme .pt-sm-2, .PrimaryTheme .py-sm-2 {
        padding-top: .5rem !important;
    }
    .PrimaryTheme .pr-sm-2, .PrimaryTheme .px-sm-2 {
        padding-right: .5rem !important;
    }
    .PrimaryTheme .pb-sm-2, .PrimaryTheme .py-sm-2 {
        padding-bottom: .5rem !important;
    }
    .PrimaryTheme .pl-sm-2, .PrimaryTheme .px-sm-2 {
        padding-left: .5rem !important;
    }
    .PrimaryTheme .p-sm-3 {
        padding: .75rem !important;
    }
    .PrimaryTheme .pt-sm-3, .PrimaryTheme .py-sm-3 {
        padding-top: .75rem !important;
    }
    .PrimaryTheme .pr-sm-3, .PrimaryTheme .px-sm-3 {
        padding-right: .75rem !important;
    }
    .PrimaryTheme .pb-sm-3, .PrimaryTheme .py-sm-3 {
        padding-bottom: .75rem !important;
    }
    .PrimaryTheme .pl-sm-3, .PrimaryTheme .px-sm-3 {
        padding-left: .75rem !important;
    }
    .PrimaryTheme .p-sm-4 {
        padding: 1rem !important;
    }
    .PrimaryTheme .pt-sm-4, .PrimaryTheme .py-sm-4 {
        padding-top: 1rem !important;
    }
    .PrimaryTheme .pr-sm-4, .PrimaryTheme .px-sm-4 {
        padding-right: 1rem !important;
    }
    .PrimaryTheme .pb-sm-4, .PrimaryTheme .py-sm-4 {
        padding-bottom: 1rem !important;
    }
    .PrimaryTheme .pl-sm-4, .PrimaryTheme .px-sm-4 {
        padding-left: 1rem !important;
    }
    .PrimaryTheme .p-sm-5 {
        padding: 1.5rem !important;
    }
    .PrimaryTheme .pt-sm-5, .PrimaryTheme .py-sm-5 {
        padding-top: 1.5rem !important;
    }
    .PrimaryTheme .pr-sm-5, .PrimaryTheme .px-sm-5 {
        padding-right: 1.5rem !important;
    }
    .PrimaryTheme .pb-sm-5, .PrimaryTheme .py-sm-5 {
        padding-bottom: 1.5rem !important;
    }
    .PrimaryTheme .pl-sm-5, .PrimaryTheme .px-sm-5 {
        padding-left: 1.5rem !important;
    }
    .PrimaryTheme .p-sm-6 {
        padding: 2rem !important;
    }
    .PrimaryTheme .pt-sm-6, .PrimaryTheme .py-sm-6 {
        padding-top: 2rem !important;
    }
    .PrimaryTheme .pr-sm-6, .PrimaryTheme .px-sm-6 {
        padding-right: 2rem !important;
    }
    .PrimaryTheme .pb-sm-6, .PrimaryTheme .py-sm-6 {
        padding-bottom: 2rem !important;
    }
    .PrimaryTheme .pl-sm-6, .PrimaryTheme .px-sm-6 {
        padding-left: 2rem !important;
    }
    .PrimaryTheme .p-sm-7 {
        padding: 2.5rem !important;
    }
    .PrimaryTheme .pt-sm-7, .PrimaryTheme .py-sm-7 {
        padding-top: 2.5rem !important;
    }
    .PrimaryTheme .pr-sm-7, .PrimaryTheme .px-sm-7 {
        padding-right: 2.5rem !important;
    }
    .PrimaryTheme .pb-sm-7, .PrimaryTheme .py-sm-7 {
        padding-bottom: 2.5rem !important;
    }
    .PrimaryTheme .pl-sm-7, .PrimaryTheme .px-sm-7 {
        padding-left: 2.5rem !important;
    }
    .PrimaryTheme .p-sm-8 {
        padding: 3rem !important;
    }
    .PrimaryTheme .pt-sm-8, .PrimaryTheme .py-sm-8 {
        padding-top: 3rem !important;
    }
    .PrimaryTheme .pr-sm-8, .PrimaryTheme .px-sm-8 {
        padding-right: 3rem !important;
    }
    .PrimaryTheme .pb-sm-8, .PrimaryTheme .py-sm-8 {
        padding-bottom: 3rem !important;
    }
    .PrimaryTheme .pl-sm-8, .PrimaryTheme .px-sm-8 {
        padding-left: 3rem !important;
    }
    .PrimaryTheme .p-sm-9 {
        padding: 4rem !important;
    }
    .PrimaryTheme .pt-sm-9, .PrimaryTheme .py-sm-9 {
        padding-top: 4rem !important;
    }
    .PrimaryTheme .pr-sm-9, .PrimaryTheme .px-sm-9 {
        padding-right: 4rem !important;
    }
    .PrimaryTheme .pb-sm-9, .PrimaryTheme .py-sm-9 {
        padding-bottom: 4rem !important;
    }
    .PrimaryTheme .pl-sm-9, .PrimaryTheme .px-sm-9 {
        padding-left: 4rem !important;
    }
    .PrimaryTheme .p-sm-10 {
        padding: 5rem !important;
    }
    .PrimaryTheme .pt-sm-10, .PrimaryTheme .py-sm-10 {
        padding-top: 5rem !important;
    }
    .PrimaryTheme .pr-sm-10, .PrimaryTheme .px-sm-10 {
        padding-right: 5rem !important;
    }
    .PrimaryTheme .pb-sm-10, .PrimaryTheme .py-sm-10 {
        padding-bottom: 5rem !important;
    }
    .PrimaryTheme .pl-sm-10, .PrimaryTheme .px-sm-10 {
        padding-left: 5rem !important;
    }
    .PrimaryTheme .p-sm-11 {
        padding: 6rem !important;
    }
    .PrimaryTheme .pt-sm-11, .PrimaryTheme .py-sm-11 {
        padding-top: 6rem !important;
    }
    .PrimaryTheme .pr-sm-11, .PrimaryTheme .px-sm-11 {
        padding-right: 6rem !important;
    }
    .PrimaryTheme .pb-sm-11, .PrimaryTheme .py-sm-11 {
        padding-bottom: 6rem !important;
    }
    .PrimaryTheme .pl-sm-11, .PrimaryTheme .px-sm-11 {
        padding-left: 6rem !important;
    }
    .PrimaryTheme .p-sm-12 {
        padding: 8rem !important;
    }
    .PrimaryTheme .pt-sm-12, .PrimaryTheme .py-sm-12 {
        padding-top: 8rem !important;
    }
    .PrimaryTheme .pr-sm-12, .PrimaryTheme .px-sm-12 {
        padding-right: 8rem !important;
    }
    .PrimaryTheme .pb-sm-12, .PrimaryTheme .py-sm-12 {
        padding-bottom: 8rem !important;
    }
    .PrimaryTheme .pl-sm-12, .PrimaryTheme .px-sm-12 {
        padding-left: 8rem !important;
    }
    .PrimaryTheme .p-sm-13 {
        padding: 10rem !important;
    }
    .PrimaryTheme .pt-sm-13, .PrimaryTheme .py-sm-13 {
        padding-top: 10rem !important;
    }
    .PrimaryTheme .pr-sm-13, .PrimaryTheme .px-sm-13 {
        padding-right: 10rem !important;
    }
    .PrimaryTheme .pb-sm-13, .PrimaryTheme .py-sm-13 {
        padding-bottom: 10rem !important;
    }
    .PrimaryTheme .pl-sm-13, .PrimaryTheme .px-sm-13 {
        padding-left: 10rem !important;
    }
    .PrimaryTheme .p-sm-14 {
        padding: 12rem !important;
    }
    .PrimaryTheme .pt-sm-14, .PrimaryTheme .py-sm-14 {
        padding-top: 12rem !important;
    }
    .PrimaryTheme .pr-sm-14, .PrimaryTheme .px-sm-14 {
        padding-right: 12rem !important;
    }
    .PrimaryTheme .pb-sm-14, .PrimaryTheme .py-sm-14 {
        padding-bottom: 12rem !important;
    }
    .PrimaryTheme .pl-sm-14, .PrimaryTheme .px-sm-14 {
        padding-left: 12rem !important;
    }
    .PrimaryTheme .p-sm-15 {
        padding: 16rem !important;
    }
    .PrimaryTheme .pt-sm-15, .PrimaryTheme .py-sm-15 {
        padding-top: 16rem !important;
    }
    .PrimaryTheme .pr-sm-15, .PrimaryTheme .px-sm-15 {
        padding-right: 16rem !important;
    }
    .PrimaryTheme .pb-sm-15, .PrimaryTheme .py-sm-15 {
        padding-bottom: 16rem !important;
    }
    .PrimaryTheme .pl-sm-15, .PrimaryTheme .px-sm-15 {
        padding-left: 16rem !important;
    }
    .PrimaryTheme .p-sm-16 {
        padding: 25rem !important;
    }
    .PrimaryTheme .pt-sm-16, .PrimaryTheme .py-sm-16 {
        padding-top: 25rem !important;
    }
    .PrimaryTheme .pr-sm-16, .PrimaryTheme .px-sm-16 {
        padding-right: 25rem !important;
    }
    .PrimaryTheme .pb-sm-16, .PrimaryTheme .py-sm-16 {
        padding-bottom: 25rem !important;
    }
    .PrimaryTheme .pl-sm-16, .PrimaryTheme .px-sm-16 {
        padding-left: 25rem !important;
    }
    .PrimaryTheme .m-sm-n1 {
        margin: -.25rem !important;
    }
    .PrimaryTheme .mt-sm-n1, .PrimaryTheme .my-sm-n1 {
        margin-top: -.25rem !important;
    }
    .PrimaryTheme .mr-sm-n1, .PrimaryTheme .mx-sm-n1 {
        margin-right: -.25rem !important;
    }
    .PrimaryTheme .mb-sm-n1, .PrimaryTheme .my-sm-n1 {
        margin-bottom: -.25rem !important;
    }
    .PrimaryTheme .ml-sm-n1, .PrimaryTheme .mx-sm-n1 {
        margin-left: -.25rem !important;
    }
    .PrimaryTheme .m-sm-n2 {
        margin: -.5rem !important;
    }
    .PrimaryTheme .mt-sm-n2, .PrimaryTheme .my-sm-n2 {
        margin-top: -.5rem !important;
    }
    .PrimaryTheme .mr-sm-n2, .PrimaryTheme .mx-sm-n2 {
        margin-right: -.5rem !important;
    }
    .PrimaryTheme .mb-sm-n2, .PrimaryTheme .my-sm-n2 {
        margin-bottom: -.5rem !important;
    }
    .PrimaryTheme .ml-sm-n2, .PrimaryTheme .mx-sm-n2 {
        margin-left: -.5rem !important;
    }
    .PrimaryTheme .m-sm-n3 {
        margin: -.75rem !important;
    }
    .PrimaryTheme .mt-sm-n3, .PrimaryTheme .my-sm-n3 {
        margin-top: -.75rem !important;
    }
    .PrimaryTheme .mr-sm-n3, .PrimaryTheme .mx-sm-n3 {
        margin-right: -.75rem !important;
    }
    .PrimaryTheme .mb-sm-n3, .PrimaryTheme .my-sm-n3 {
        margin-bottom: -.75rem !important;
    }
    .PrimaryTheme .ml-sm-n3, .PrimaryTheme .mx-sm-n3 {
        margin-left: -.75rem !important;
    }
    .PrimaryTheme .m-sm-n4 {
        margin: -1rem !important;
    }
    .PrimaryTheme .mt-sm-n4, .PrimaryTheme .my-sm-n4 {
        margin-top: -1rem !important;
    }
    .PrimaryTheme .mr-sm-n4, .PrimaryTheme .mx-sm-n4 {
        margin-right: -1rem !important;
    }
    .PrimaryTheme .mb-sm-n4, .PrimaryTheme .my-sm-n4 {
        margin-bottom: -1rem !important;
    }
    .PrimaryTheme .ml-sm-n4, .PrimaryTheme .mx-sm-n4 {
        margin-left: -1rem !important;
    }
    .PrimaryTheme .m-sm-n5 {
        margin: -1.5rem !important;
    }
    .PrimaryTheme .mt-sm-n5, .PrimaryTheme .my-sm-n5 {
        margin-top: -1.5rem !important;
    }
    .PrimaryTheme .mr-sm-n5, .PrimaryTheme .mx-sm-n5 {
        margin-right: -1.5rem !important;
    }
    .PrimaryTheme .mb-sm-n5, .PrimaryTheme .my-sm-n5 {
        margin-bottom: -1.5rem !important;
    }
    .PrimaryTheme .ml-sm-n5, .PrimaryTheme .mx-sm-n5 {
        margin-left: -1.5rem !important;
    }
    .PrimaryTheme .m-sm-n6 {
        margin: -2rem !important;
    }
    .PrimaryTheme .mt-sm-n6, .PrimaryTheme .my-sm-n6 {
        margin-top: -2rem !important;
    }
    .PrimaryTheme .mr-sm-n6, .PrimaryTheme .mx-sm-n6 {
        margin-right: -2rem !important;
    }
    .PrimaryTheme .mb-sm-n6, .PrimaryTheme .my-sm-n6 {
        margin-bottom: -2rem !important;
    }
    .PrimaryTheme .ml-sm-n6, .PrimaryTheme .mx-sm-n6 {
        margin-left: -2rem !important;
    }
    .PrimaryTheme .m-sm-n7 {
        margin: -2.5rem !important;
    }
    .PrimaryTheme .mt-sm-n7, .PrimaryTheme .my-sm-n7 {
        margin-top: -2.5rem !important;
    }
    .PrimaryTheme .mr-sm-n7, .PrimaryTheme .mx-sm-n7 {
        margin-right: -2.5rem !important;
    }
    .PrimaryTheme .mb-sm-n7, .PrimaryTheme .my-sm-n7 {
        margin-bottom: -2.5rem !important;
    }
    .PrimaryTheme .ml-sm-n7, .PrimaryTheme .mx-sm-n7 {
        margin-left: -2.5rem !important;
    }
    .PrimaryTheme .m-sm-n8 {
        margin: -3rem !important;
    }
    .PrimaryTheme .mt-sm-n8, .PrimaryTheme .my-sm-n8 {
        margin-top: -3rem !important;
    }
    .PrimaryTheme .mr-sm-n8, .PrimaryTheme .mx-sm-n8 {
        margin-right: -3rem !important;
    }
    .PrimaryTheme .mb-sm-n8, .PrimaryTheme .my-sm-n8 {
        margin-bottom: -3rem !important;
    }
    .PrimaryTheme .ml-sm-n8, .PrimaryTheme .mx-sm-n8 {
        margin-left: -3rem !important;
    }
    .PrimaryTheme .m-sm-n9 {
        margin: -4rem !important;
    }
    .PrimaryTheme .mt-sm-n9, .PrimaryTheme .my-sm-n9 {
        margin-top: -4rem !important;
    }
    .PrimaryTheme .mr-sm-n9, .PrimaryTheme .mx-sm-n9 {
        margin-right: -4rem !important;
    }
    .PrimaryTheme .mb-sm-n9, .PrimaryTheme .my-sm-n9 {
        margin-bottom: -4rem !important;
    }
    .PrimaryTheme .ml-sm-n9, .PrimaryTheme .mx-sm-n9 {
        margin-left: -4rem !important;
    }
    .PrimaryTheme .m-sm-n10 {
        margin: -5rem !important;
    }
    .PrimaryTheme .mt-sm-n10, .PrimaryTheme .my-sm-n10 {
        margin-top: -5rem !important;
    }
    .PrimaryTheme .mr-sm-n10, .PrimaryTheme .mx-sm-n10 {
        margin-right: -5rem !important;
    }
    .PrimaryTheme .mb-sm-n10, .PrimaryTheme .my-sm-n10 {
        margin-bottom: -5rem !important;
    }
    .PrimaryTheme .ml-sm-n10, .PrimaryTheme .mx-sm-n10 {
        margin-left: -5rem !important;
    }
    .PrimaryTheme .m-sm-n11 {
        margin: -6rem !important;
    }
    .PrimaryTheme .mt-sm-n11, .PrimaryTheme .my-sm-n11 {
        margin-top: -6rem !important;
    }
    .PrimaryTheme .mr-sm-n11, .PrimaryTheme .mx-sm-n11 {
        margin-right: -6rem !important;
    }
    .PrimaryTheme .mb-sm-n11, .PrimaryTheme .my-sm-n11 {
        margin-bottom: -6rem !important;
    }
    .PrimaryTheme .ml-sm-n11, .PrimaryTheme .mx-sm-n11 {
        margin-left: -6rem !important;
    }
    .PrimaryTheme .m-sm-n12 {
        margin: -8rem !important;
    }
    .PrimaryTheme .mt-sm-n12, .PrimaryTheme .my-sm-n12 {
        margin-top: -8rem !important;
    }
    .PrimaryTheme .mr-sm-n12, .PrimaryTheme .mx-sm-n12 {
        margin-right: -8rem !important;
    }
    .PrimaryTheme .mb-sm-n12, .PrimaryTheme .my-sm-n12 {
        margin-bottom: -8rem !important;
    }
    .PrimaryTheme .ml-sm-n12, .PrimaryTheme .mx-sm-n12 {
        margin-left: -8rem !important;
    }
    .PrimaryTheme .m-sm-n13 {
        margin: -10rem !important;
    }
    .PrimaryTheme .mt-sm-n13, .PrimaryTheme .my-sm-n13 {
        margin-top: -10rem !important;
    }
    .PrimaryTheme .mr-sm-n13, .PrimaryTheme .mx-sm-n13 {
        margin-right: -10rem !important;
    }
    .PrimaryTheme .mb-sm-n13, .PrimaryTheme .my-sm-n13 {
        margin-bottom: -10rem !important;
    }
    .PrimaryTheme .ml-sm-n13, .PrimaryTheme .mx-sm-n13 {
        margin-left: -10rem !important;
    }
    .PrimaryTheme .m-sm-n14 {
        margin: -12rem !important;
    }
    .PrimaryTheme .mt-sm-n14, .PrimaryTheme .my-sm-n14 {
        margin-top: -12rem !important;
    }
    .PrimaryTheme .mr-sm-n14, .PrimaryTheme .mx-sm-n14 {
        margin-right: -12rem !important;
    }
    .PrimaryTheme .mb-sm-n14, .PrimaryTheme .my-sm-n14 {
        margin-bottom: -12rem !important;
    }
    .PrimaryTheme .ml-sm-n14, .PrimaryTheme .mx-sm-n14 {
        margin-left: -12rem !important;
    }
    .PrimaryTheme .m-sm-n15 {
        margin: -16rem !important;
    }
    .PrimaryTheme .mt-sm-n15, .PrimaryTheme .my-sm-n15 {
        margin-top: -16rem !important;
    }
    .PrimaryTheme .mr-sm-n15, .PrimaryTheme .mx-sm-n15 {
        margin-right: -16rem !important;
    }
    .PrimaryTheme .mb-sm-n15, .PrimaryTheme .my-sm-n15 {
        margin-bottom: -16rem !important;
    }
    .PrimaryTheme .ml-sm-n15, .PrimaryTheme .mx-sm-n15 {
        margin-left: -16rem !important;
    }
    .PrimaryTheme .m-sm-n16 {
        margin: -25rem !important;
    }
    .PrimaryTheme .mt-sm-n16, .PrimaryTheme .my-sm-n16 {
        margin-top: -25rem !important;
    }
    .PrimaryTheme .mr-sm-n16, .PrimaryTheme .mx-sm-n16 {
        margin-right: -25rem !important;
    }
    .PrimaryTheme .mb-sm-n16, .PrimaryTheme .my-sm-n16 {
        margin-bottom: -25rem !important;
    }
    .PrimaryTheme .ml-sm-n16, .PrimaryTheme .mx-sm-n16 {
        margin-left: -25rem !important;
    }
    .PrimaryTheme .m-sm-auto {
        margin: auto !important;
    }
    .PrimaryTheme .mt-sm-auto, .PrimaryTheme .my-sm-auto {
        margin-top: auto !important;
    }
    .PrimaryTheme .mr-sm-auto, .PrimaryTheme .mx-sm-auto {
        margin-right: auto !important;
    }
    .PrimaryTheme .mb-sm-auto, .PrimaryTheme .my-sm-auto {
        margin-bottom: auto !important;
    }
    .PrimaryTheme .ml-sm-auto, .PrimaryTheme .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .m-md-0 {
        margin: 0 !important;
    }
    .PrimaryTheme .mt-md-0, .PrimaryTheme .my-md-0 {
        margin-top: 0 !important;
    }
    .PrimaryTheme .mr-md-0, .PrimaryTheme .mx-md-0 {
        margin-right: 0 !important;
    }
    .PrimaryTheme .mb-md-0, .PrimaryTheme .my-md-0 {
        margin-bottom: 0 !important;
    }
    .PrimaryTheme .ml-md-0, .PrimaryTheme .mx-md-0 {
        margin-left: 0 !important;
    }
    .PrimaryTheme .m-md-1 {
        margin: .25rem !important;
    }
    .PrimaryTheme .mt-md-1, .PrimaryTheme .my-md-1 {
        margin-top: .25rem !important;
    }
    .PrimaryTheme .mr-md-1, .PrimaryTheme .mx-md-1 {
        margin-right: .25rem !important;
    }
    .PrimaryTheme .mb-md-1, .PrimaryTheme .my-md-1 {
        margin-bottom: .25rem !important;
    }
    .PrimaryTheme .ml-md-1, .PrimaryTheme .mx-md-1 {
        margin-left: .25rem !important;
    }
    .PrimaryTheme .m-md-2 {
        margin: .5rem !important;
    }
    .PrimaryTheme .mt-md-2, .PrimaryTheme .my-md-2 {
        margin-top: .5rem !important;
    }
    .PrimaryTheme .mr-md-2, .PrimaryTheme .mx-md-2 {
        margin-right: .5rem !important;
    }
    .PrimaryTheme .mb-md-2, .PrimaryTheme .my-md-2 {
        margin-bottom: .5rem !important;
    }
    .PrimaryTheme .ml-md-2, .PrimaryTheme .mx-md-2 {
        margin-left: .5rem !important;
    }
    .PrimaryTheme .m-md-3 {
        margin: .75rem !important;
    }
    .PrimaryTheme .mt-md-3, .PrimaryTheme .my-md-3 {
        margin-top: .75rem !important;
    }
    .PrimaryTheme .mr-md-3, .PrimaryTheme .mx-md-3 {
        margin-right: .75rem !important;
    }
    .PrimaryTheme .mb-md-3, .PrimaryTheme .my-md-3 {
        margin-bottom: .75rem !important;
    }
    .PrimaryTheme .ml-md-3, .PrimaryTheme .mx-md-3 {
        margin-left: .75rem !important;
    }
    .PrimaryTheme .m-md-4 {
        margin: 1rem !important;
    }
    .PrimaryTheme .mt-md-4, .PrimaryTheme .my-md-4 {
        margin-top: 1rem !important;
    }
    .PrimaryTheme .mr-md-4, .PrimaryTheme .mx-md-4 {
        margin-right: 1rem !important;
    }
    .PrimaryTheme .mb-md-4, .PrimaryTheme .my-md-4 {
        margin-bottom: 1rem !important;
    }
    .PrimaryTheme .ml-md-4, .PrimaryTheme .mx-md-4 {
        margin-left: 1rem !important;
    }
    .PrimaryTheme .m-md-5 {
        margin: 1.5rem !important;
    }
    .PrimaryTheme .mt-md-5, .PrimaryTheme .my-md-5 {
        margin-top: 1.5rem !important;
    }
    .PrimaryTheme .mr-md-5, .PrimaryTheme .mx-md-5 {
        margin-right: 1.5rem !important;
    }
    .PrimaryTheme .mb-md-5, .PrimaryTheme .my-md-5 {
        margin-bottom: 1.5rem !important;
    }
    .PrimaryTheme .ml-md-5, .PrimaryTheme .mx-md-5 {
        margin-left: 1.5rem !important;
    }
    .PrimaryTheme .m-md-6 {
        margin: 2rem !important;
    }
    .PrimaryTheme .mt-md-6, .PrimaryTheme .my-md-6 {
        margin-top: 2rem !important;
    }
    .PrimaryTheme .mr-md-6, .PrimaryTheme .mx-md-6 {
        margin-right: 2rem !important;
    }
    .PrimaryTheme .mb-md-6, .PrimaryTheme .my-md-6 {
        margin-bottom: 2rem !important;
    }
    .PrimaryTheme .ml-md-6, .PrimaryTheme .mx-md-6 {
        margin-left: 2rem !important;
    }
    .PrimaryTheme .m-md-7 {
        margin: 2.5rem !important;
    }
    .PrimaryTheme .mt-md-7, .PrimaryTheme .my-md-7 {
        margin-top: 2.5rem !important;
    }
    .PrimaryTheme .mr-md-7, .PrimaryTheme .mx-md-7 {
        margin-right: 2.5rem !important;
    }
    .PrimaryTheme .mb-md-7, .PrimaryTheme .my-md-7 {
        margin-bottom: 2.5rem !important;
    }
    .PrimaryTheme .ml-md-7, .PrimaryTheme .mx-md-7 {
        margin-left: 2.5rem !important;
    }
    .PrimaryTheme .m-md-8 {
        margin: 3rem !important;
    }
    .PrimaryTheme .mt-md-8, .PrimaryTheme .my-md-8 {
        margin-top: 3rem !important;
    }
    .PrimaryTheme .mr-md-8, .PrimaryTheme .mx-md-8 {
        margin-right: 3rem !important;
    }
    .PrimaryTheme .mb-md-8, .PrimaryTheme .my-md-8 {
        margin-bottom: 3rem !important;
    }
    .PrimaryTheme .ml-md-8, .PrimaryTheme .mx-md-8 {
        margin-left: 3rem !important;
    }
    .PrimaryTheme .m-md-9 {
        margin: 4rem !important;
    }
    .PrimaryTheme .mt-md-9, .PrimaryTheme .my-md-9 {
        margin-top: 4rem !important;
    }
    .PrimaryTheme .mr-md-9, .PrimaryTheme .mx-md-9 {
        margin-right: 4rem !important;
    }
    .PrimaryTheme .mb-md-9, .PrimaryTheme .my-md-9 {
        margin-bottom: 4rem !important;
    }
    .PrimaryTheme .ml-md-9, .PrimaryTheme .mx-md-9 {
        margin-left: 4rem !important;
    }
    .PrimaryTheme .m-md-10 {
        margin: 5rem !important;
    }
    .PrimaryTheme .mt-md-10, .PrimaryTheme .my-md-10 {
        margin-top: 5rem !important;
    }
    .PrimaryTheme .mr-md-10, .PrimaryTheme .mx-md-10 {
        margin-right: 5rem !important;
    }
    .PrimaryTheme .mb-md-10, .PrimaryTheme .my-md-10 {
        margin-bottom: 5rem !important;
    }
    .PrimaryTheme .ml-md-10, .PrimaryTheme .mx-md-10 {
        margin-left: 5rem !important;
    }
    .PrimaryTheme .m-md-11 {
        margin: 6rem !important;
    }
    .PrimaryTheme .mt-md-11, .PrimaryTheme .my-md-11 {
        margin-top: 6rem !important;
    }
    .PrimaryTheme .mr-md-11, .PrimaryTheme .mx-md-11 {
        margin-right: 6rem !important;
    }
    .PrimaryTheme .mb-md-11, .PrimaryTheme .my-md-11 {
        margin-bottom: 6rem !important;
    }
    .PrimaryTheme .ml-md-11, .PrimaryTheme .mx-md-11 {
        margin-left: 6rem !important;
    }
    .PrimaryTheme .m-md-12 {
        margin: 8rem !important;
    }
    .PrimaryTheme .mt-md-12, .PrimaryTheme .my-md-12 {
        margin-top: 8rem !important;
    }
    .PrimaryTheme .mr-md-12, .PrimaryTheme .mx-md-12 {
        margin-right: 8rem !important;
    }
    .PrimaryTheme .mb-md-12, .PrimaryTheme .my-md-12 {
        margin-bottom: 8rem !important;
    }
    .PrimaryTheme .ml-md-12, .PrimaryTheme .mx-md-12 {
        margin-left: 8rem !important;
    }
    .PrimaryTheme .m-md-13 {
        margin: 10rem !important;
    }
    .PrimaryTheme .mt-md-13, .PrimaryTheme .my-md-13 {
        margin-top: 10rem !important;
    }
    .PrimaryTheme .mr-md-13, .PrimaryTheme .mx-md-13 {
        margin-right: 10rem !important;
    }
    .PrimaryTheme .mb-md-13, .PrimaryTheme .my-md-13 {
        margin-bottom: 10rem !important;
    }
    .PrimaryTheme .ml-md-13, .PrimaryTheme .mx-md-13 {
        margin-left: 10rem !important;
    }
    .PrimaryTheme .m-md-14 {
        margin: 12rem !important;
    }
    .PrimaryTheme .mt-md-14, .PrimaryTheme .my-md-14 {
        margin-top: 12rem !important;
    }
    .PrimaryTheme .mr-md-14, .PrimaryTheme .mx-md-14 {
        margin-right: 12rem !important;
    }
    .PrimaryTheme .mb-md-14, .PrimaryTheme .my-md-14 {
        margin-bottom: 12rem !important;
    }
    .PrimaryTheme .ml-md-14, .PrimaryTheme .mx-md-14 {
        margin-left: 12rem !important;
    }
    .PrimaryTheme .m-md-15 {
        margin: 16rem !important;
    }
    .PrimaryTheme .mt-md-15, .PrimaryTheme .my-md-15 {
        margin-top: 16rem !important;
    }
    .PrimaryTheme .mr-md-15, .PrimaryTheme .mx-md-15 {
        margin-right: 16rem !important;
    }
    .PrimaryTheme .mb-md-15, .PrimaryTheme .my-md-15 {
        margin-bottom: 16rem !important;
    }
    .PrimaryTheme .ml-md-15, .PrimaryTheme .mx-md-15 {
        margin-left: 16rem !important;
    }
    .PrimaryTheme .m-md-16 {
        margin: 25rem !important;
    }
    .PrimaryTheme .mt-md-16, .PrimaryTheme .my-md-16 {
        margin-top: 25rem !important;
    }
    .PrimaryTheme .mr-md-16, .PrimaryTheme .mx-md-16 {
        margin-right: 25rem !important;
    }
    .PrimaryTheme .mb-md-16, .PrimaryTheme .my-md-16 {
        margin-bottom: 25rem !important;
    }
    .PrimaryTheme .ml-md-16, .PrimaryTheme .mx-md-16 {
        margin-left: 25rem !important;
    }
    .PrimaryTheme .p-md-0 {
        padding: 0 !important;
    }
    .PrimaryTheme .pt-md-0, .PrimaryTheme .py-md-0 {
        padding-top: 0 !important;
    }
    .PrimaryTheme .pr-md-0, .PrimaryTheme .px-md-0 {
        padding-right: 0 !important;
    }
    .PrimaryTheme .pb-md-0, .PrimaryTheme .py-md-0 {
        padding-bottom: 0 !important;
    }
    .PrimaryTheme .pl-md-0, .PrimaryTheme .px-md-0 {
        padding-left: 0 !important;
    }
    .PrimaryTheme .p-md-1 {
        padding: .25rem !important;
    }
    .PrimaryTheme .pt-md-1, .PrimaryTheme .py-md-1 {
        padding-top: .25rem !important;
    }
    .PrimaryTheme .pr-md-1, .PrimaryTheme .px-md-1 {
        padding-right: .25rem !important;
    }
    .PrimaryTheme .pb-md-1, .PrimaryTheme .py-md-1 {
        padding-bottom: .25rem !important;
    }
    .PrimaryTheme .pl-md-1, .PrimaryTheme .px-md-1 {
        padding-left: .25rem !important;
    }
    .PrimaryTheme .p-md-2 {
        padding: .5rem !important;
    }
    .PrimaryTheme .pt-md-2, .PrimaryTheme .py-md-2 {
        padding-top: .5rem !important;
    }
    .PrimaryTheme .pr-md-2, .PrimaryTheme .px-md-2 {
        padding-right: .5rem !important;
    }
    .PrimaryTheme .pb-md-2, .PrimaryTheme .py-md-2 {
        padding-bottom: .5rem !important;
    }
    .PrimaryTheme .pl-md-2, .PrimaryTheme .px-md-2 {
        padding-left: .5rem !important;
    }
    .PrimaryTheme .p-md-3 {
        padding: .75rem !important;
    }
    .PrimaryTheme .pt-md-3, .PrimaryTheme .py-md-3 {
        padding-top: .75rem !important;
    }
    .PrimaryTheme .pr-md-3, .PrimaryTheme .px-md-3 {
        padding-right: .75rem !important;
    }
    .PrimaryTheme .pb-md-3, .PrimaryTheme .py-md-3 {
        padding-bottom: .75rem !important;
    }
    .PrimaryTheme .pl-md-3, .PrimaryTheme .px-md-3 {
        padding-left: .75rem !important;
    }
    .PrimaryTheme .p-md-4 {
        padding: 1rem !important;
    }
    .PrimaryTheme .pt-md-4, .PrimaryTheme .py-md-4 {
        padding-top: 1rem !important;
    }
    .PrimaryTheme .pr-md-4, .PrimaryTheme .px-md-4 {
        padding-right: 1rem !important;
    }
    .PrimaryTheme .pb-md-4, .PrimaryTheme .py-md-4 {
        padding-bottom: 1rem !important;
    }
    .PrimaryTheme .pl-md-4, .PrimaryTheme .px-md-4 {
        padding-left: 1rem !important;
    }
    .PrimaryTheme .p-md-5 {
        padding: 1.5rem !important;
    }
    .PrimaryTheme .pt-md-5, .PrimaryTheme .py-md-5 {
        padding-top: 1.5rem !important;
    }
    .PrimaryTheme .pr-md-5, .PrimaryTheme .px-md-5 {
        padding-right: 1.5rem !important;
    }
    .PrimaryTheme .pb-md-5, .PrimaryTheme .py-md-5 {
        padding-bottom: 1.5rem !important;
    }
    .PrimaryTheme .pl-md-5, .PrimaryTheme .px-md-5 {
        padding-left: 1.5rem !important;
    }
    .PrimaryTheme .p-md-6 {
        padding: 2rem !important;
    }
    .PrimaryTheme .pt-md-6, .PrimaryTheme .py-md-6 {
        padding-top: 2rem !important;
    }
    .PrimaryTheme .pr-md-6, .PrimaryTheme .px-md-6 {
        padding-right: 2rem !important;
    }
    .PrimaryTheme .pb-md-6, .PrimaryTheme .py-md-6 {
        padding-bottom: 2rem !important;
    }
    .PrimaryTheme .pl-md-6, .PrimaryTheme .px-md-6 {
        padding-left: 2rem !important;
    }
    .PrimaryTheme .p-md-7 {
        padding: 2.5rem !important;
    }
    .PrimaryTheme .pt-md-7, .PrimaryTheme .py-md-7 {
        padding-top: 2.5rem !important;
    }
    .PrimaryTheme .pr-md-7, .PrimaryTheme .px-md-7 {
        padding-right: 2.5rem !important;
    }
    .PrimaryTheme .pb-md-7, .PrimaryTheme .py-md-7 {
        padding-bottom: 2.5rem !important;
    }
    .PrimaryTheme .pl-md-7, .PrimaryTheme .px-md-7 {
        padding-left: 2.5rem !important;
    }
    .PrimaryTheme .p-md-8 {
        padding: 3rem !important;
    }
    .PrimaryTheme .pt-md-8, .PrimaryTheme .py-md-8 {
        padding-top: 3rem !important;
    }
    .PrimaryTheme .pr-md-8, .PrimaryTheme .px-md-8 {
        padding-right: 3rem !important;
    }
    .PrimaryTheme .pb-md-8, .PrimaryTheme .py-md-8 {
        padding-bottom: 3rem !important;
    }
    .PrimaryTheme .pl-md-8, .PrimaryTheme .px-md-8 {
        padding-left: 3rem !important;
    }
    .PrimaryTheme .p-md-9 {
        padding: 4rem !important;
    }
    .PrimaryTheme .pt-md-9, .PrimaryTheme .py-md-9 {
        padding-top: 4rem !important;
    }
    .PrimaryTheme .pr-md-9, .PrimaryTheme .px-md-9 {
        padding-right: 4rem !important;
    }
    .PrimaryTheme .pb-md-9, .PrimaryTheme .py-md-9 {
        padding-bottom: 4rem !important;
    }
    .PrimaryTheme .pl-md-9, .PrimaryTheme .px-md-9 {
        padding-left: 4rem !important;
    }
    .PrimaryTheme .p-md-10 {
        padding: 5rem !important;
    }
    .PrimaryTheme .pt-md-10, .PrimaryTheme .py-md-10 {
        padding-top: 5rem !important;
    }
    .PrimaryTheme .pr-md-10, .PrimaryTheme .px-md-10 {
        padding-right: 5rem !important;
    }
    .PrimaryTheme .pb-md-10, .PrimaryTheme .py-md-10 {
        padding-bottom: 5rem !important;
    }
    .PrimaryTheme .pl-md-10, .PrimaryTheme .px-md-10 {
        padding-left: 5rem !important;
    }
    .PrimaryTheme .p-md-11 {
        padding: 6rem !important;
    }
    .PrimaryTheme .pt-md-11, .PrimaryTheme .py-md-11 {
        padding-top: 6rem !important;
    }
    .PrimaryTheme .pr-md-11, .PrimaryTheme .px-md-11 {
        padding-right: 6rem !important;
    }
    .PrimaryTheme .pb-md-11, .PrimaryTheme .py-md-11 {
        padding-bottom: 6rem !important;
    }
    .PrimaryTheme .pl-md-11, .PrimaryTheme .px-md-11 {
        padding-left: 6rem !important;
    }
    .PrimaryTheme .p-md-12 {
        padding: 8rem !important;
    }
    .PrimaryTheme .pt-md-12, .PrimaryTheme .py-md-12 {
        padding-top: 8rem !important;
    }
    .PrimaryTheme .pr-md-12, .PrimaryTheme .px-md-12 {
        padding-right: 8rem !important;
    }
    .PrimaryTheme .pb-md-12, .PrimaryTheme .py-md-12 {
        padding-bottom: 8rem !important;
    }
    .PrimaryTheme .pl-md-12, .PrimaryTheme .px-md-12 {
        padding-left: 8rem !important;
    }
    .PrimaryTheme .p-md-13 {
        padding: 10rem !important;
    }
    .PrimaryTheme .pt-md-13, .PrimaryTheme .py-md-13 {
        padding-top: 10rem !important;
    }
    .PrimaryTheme .pr-md-13, .PrimaryTheme .px-md-13 {
        padding-right: 10rem !important;
    }
    .PrimaryTheme .pb-md-13, .PrimaryTheme .py-md-13 {
        padding-bottom: 10rem !important;
    }
    .PrimaryTheme .pl-md-13, .PrimaryTheme .px-md-13 {
        padding-left: 10rem !important;
    }
    .PrimaryTheme .p-md-14 {
        padding: 12rem !important;
    }
    .PrimaryTheme .pt-md-14, .PrimaryTheme .py-md-14 {
        padding-top: 12rem !important;
    }
    .PrimaryTheme .pr-md-14, .PrimaryTheme .px-md-14 {
        padding-right: 12rem !important;
    }
    .PrimaryTheme .pb-md-14, .PrimaryTheme .py-md-14 {
        padding-bottom: 12rem !important;
    }
    .PrimaryTheme .pl-md-14, .PrimaryTheme .px-md-14 {
        padding-left: 12rem !important;
    }
    .PrimaryTheme .p-md-15 {
        padding: 16rem !important;
    }
    .PrimaryTheme .pt-md-15, .PrimaryTheme .py-md-15 {
        padding-top: 16rem !important;
    }
    .PrimaryTheme .pr-md-15, .PrimaryTheme .px-md-15 {
        padding-right: 16rem !important;
    }
    .PrimaryTheme .pb-md-15, .PrimaryTheme .py-md-15 {
        padding-bottom: 16rem !important;
    }
    .PrimaryTheme .pl-md-15, .PrimaryTheme .px-md-15 {
        padding-left: 16rem !important;
    }
    .PrimaryTheme .p-md-16 {
        padding: 25rem !important;
    }
    .PrimaryTheme .pt-md-16, .PrimaryTheme .py-md-16 {
        padding-top: 25rem !important;
    }
    .PrimaryTheme .pr-md-16, .PrimaryTheme .px-md-16 {
        padding-right: 25rem !important;
    }
    .PrimaryTheme .pb-md-16, .PrimaryTheme .py-md-16 {
        padding-bottom: 25rem !important;
    }
    .PrimaryTheme .pl-md-16, .PrimaryTheme .px-md-16 {
        padding-left: 25rem !important;
    }
    .PrimaryTheme .m-md-n1 {
        margin: -.25rem !important;
    }
    .PrimaryTheme .mt-md-n1, .PrimaryTheme .my-md-n1 {
        margin-top: -.25rem !important;
    }
    .PrimaryTheme .mr-md-n1, .PrimaryTheme .mx-md-n1 {
        margin-right: -.25rem !important;
    }
    .PrimaryTheme .mb-md-n1, .PrimaryTheme .my-md-n1 {
        margin-bottom: -.25rem !important;
    }
    .PrimaryTheme .ml-md-n1, .PrimaryTheme .mx-md-n1 {
        margin-left: -.25rem !important;
    }
    .PrimaryTheme .m-md-n2 {
        margin: -.5rem !important;
    }
    .PrimaryTheme .mt-md-n2, .PrimaryTheme .my-md-n2 {
        margin-top: -.5rem !important;
    }
    .PrimaryTheme .mr-md-n2, .PrimaryTheme .mx-md-n2 {
        margin-right: -.5rem !important;
    }
    .PrimaryTheme .mb-md-n2, .PrimaryTheme .my-md-n2 {
        margin-bottom: -.5rem !important;
    }
    .PrimaryTheme .ml-md-n2, .PrimaryTheme .mx-md-n2 {
        margin-left: -.5rem !important;
    }
    .PrimaryTheme .m-md-n3 {
        margin: -.75rem !important;
    }
    .PrimaryTheme .mt-md-n3, .PrimaryTheme .my-md-n3 {
        margin-top: -.75rem !important;
    }
    .PrimaryTheme .mr-md-n3, .PrimaryTheme .mx-md-n3 {
        margin-right: -.75rem !important;
    }
    .PrimaryTheme .mb-md-n3, .PrimaryTheme .my-md-n3 {
        margin-bottom: -.75rem !important;
    }
    .PrimaryTheme .ml-md-n3, .PrimaryTheme .mx-md-n3 {
        margin-left: -.75rem !important;
    }
    .PrimaryTheme .m-md-n4 {
        margin: -1rem !important;
    }
    .PrimaryTheme .mt-md-n4, .PrimaryTheme .my-md-n4 {
        margin-top: -1rem !important;
    }
    .PrimaryTheme .mr-md-n4, .PrimaryTheme .mx-md-n4 {
        margin-right: -1rem !important;
    }
    .PrimaryTheme .mb-md-n4, .PrimaryTheme .my-md-n4 {
        margin-bottom: -1rem !important;
    }
    .PrimaryTheme .ml-md-n4, .PrimaryTheme .mx-md-n4 {
        margin-left: -1rem !important;
    }
    .PrimaryTheme .m-md-n5 {
        margin: -1.5rem !important;
    }
    .PrimaryTheme .mt-md-n5, .PrimaryTheme .my-md-n5 {
        margin-top: -1.5rem !important;
    }
    .PrimaryTheme .mr-md-n5, .PrimaryTheme .mx-md-n5 {
        margin-right: -1.5rem !important;
    }
    .PrimaryTheme .mb-md-n5, .PrimaryTheme .my-md-n5 {
        margin-bottom: -1.5rem !important;
    }
    .PrimaryTheme .ml-md-n5, .PrimaryTheme .mx-md-n5 {
        margin-left: -1.5rem !important;
    }
    .PrimaryTheme .m-md-n6 {
        margin: -2rem !important;
    }
    .PrimaryTheme .mt-md-n6, .PrimaryTheme .my-md-n6 {
        margin-top: -2rem !important;
    }
    .PrimaryTheme .mr-md-n6, .PrimaryTheme .mx-md-n6 {
        margin-right: -2rem !important;
    }
    .PrimaryTheme .mb-md-n6, .PrimaryTheme .my-md-n6 {
        margin-bottom: -2rem !important;
    }
    .PrimaryTheme .ml-md-n6, .PrimaryTheme .mx-md-n6 {
        margin-left: -2rem !important;
    }
    .PrimaryTheme .m-md-n7 {
        margin: -2.5rem !important;
    }
    .PrimaryTheme .mt-md-n7, .PrimaryTheme .my-md-n7 {
        margin-top: -2.5rem !important;
    }
    .PrimaryTheme .mr-md-n7, .PrimaryTheme .mx-md-n7 {
        margin-right: -2.5rem !important;
    }
    .PrimaryTheme .mb-md-n7, .PrimaryTheme .my-md-n7 {
        margin-bottom: -2.5rem !important;
    }
    .PrimaryTheme .ml-md-n7, .PrimaryTheme .mx-md-n7 {
        margin-left: -2.5rem !important;
    }
    .PrimaryTheme .m-md-n8 {
        margin: -3rem !important;
    }
    .PrimaryTheme .mt-md-n8, .PrimaryTheme .my-md-n8 {
        margin-top: -3rem !important;
    }
    .PrimaryTheme .mr-md-n8, .PrimaryTheme .mx-md-n8 {
        margin-right: -3rem !important;
    }
    .PrimaryTheme .mb-md-n8, .PrimaryTheme .my-md-n8 {
        margin-bottom: -3rem !important;
    }
    .PrimaryTheme .ml-md-n8, .PrimaryTheme .mx-md-n8 {
        margin-left: -3rem !important;
    }
    .PrimaryTheme .m-md-n9 {
        margin: -4rem !important;
    }
    .PrimaryTheme .mt-md-n9, .PrimaryTheme .my-md-n9 {
        margin-top: -4rem !important;
    }
    .PrimaryTheme .mr-md-n9, .PrimaryTheme .mx-md-n9 {
        margin-right: -4rem !important;
    }
    .PrimaryTheme .mb-md-n9, .PrimaryTheme .my-md-n9 {
        margin-bottom: -4rem !important;
    }
    .PrimaryTheme .ml-md-n9, .PrimaryTheme .mx-md-n9 {
        margin-left: -4rem !important;
    }
    .PrimaryTheme .m-md-n10 {
        margin: -5rem !important;
    }
    .PrimaryTheme .mt-md-n10, .PrimaryTheme .my-md-n10 {
        margin-top: -5rem !important;
    }
    .PrimaryTheme .mr-md-n10, .PrimaryTheme .mx-md-n10 {
        margin-right: -5rem !important;
    }
    .PrimaryTheme .mb-md-n10, .PrimaryTheme .my-md-n10 {
        margin-bottom: -5rem !important;
    }
    .PrimaryTheme .ml-md-n10, .PrimaryTheme .mx-md-n10 {
        margin-left: -5rem !important;
    }
    .PrimaryTheme .m-md-n11 {
        margin: -6rem !important;
    }
    .PrimaryTheme .mt-md-n11, .PrimaryTheme .my-md-n11 {
        margin-top: -6rem !important;
    }
    .PrimaryTheme .mr-md-n11, .PrimaryTheme .mx-md-n11 {
        margin-right: -6rem !important;
    }
    .PrimaryTheme .mb-md-n11, .PrimaryTheme .my-md-n11 {
        margin-bottom: -6rem !important;
    }
    .PrimaryTheme .ml-md-n11, .PrimaryTheme .mx-md-n11 {
        margin-left: -6rem !important;
    }
    .PrimaryTheme .m-md-n12 {
        margin: -8rem !important;
    }
    .PrimaryTheme .mt-md-n12, .PrimaryTheme .my-md-n12 {
        margin-top: -8rem !important;
    }
    .PrimaryTheme .mr-md-n12, .PrimaryTheme .mx-md-n12 {
        margin-right: -8rem !important;
    }
    .PrimaryTheme .mb-md-n12, .PrimaryTheme .my-md-n12 {
        margin-bottom: -8rem !important;
    }
    .PrimaryTheme .ml-md-n12, .PrimaryTheme .mx-md-n12 {
        margin-left: -8rem !important;
    }
    .PrimaryTheme .m-md-n13 {
        margin: -10rem !important;
    }
    .PrimaryTheme .mt-md-n13, .PrimaryTheme .my-md-n13 {
        margin-top: -10rem !important;
    }
    .PrimaryTheme .mr-md-n13, .PrimaryTheme .mx-md-n13 {
        margin-right: -10rem !important;
    }
    .PrimaryTheme .mb-md-n13, .PrimaryTheme .my-md-n13 {
        margin-bottom: -10rem !important;
    }
    .PrimaryTheme .ml-md-n13, .PrimaryTheme .mx-md-n13 {
        margin-left: -10rem !important;
    }
    .PrimaryTheme .m-md-n14 {
        margin: -12rem !important;
    }
    .PrimaryTheme .mt-md-n14, .PrimaryTheme .my-md-n14 {
        margin-top: -12rem !important;
    }
    .PrimaryTheme .mr-md-n14, .PrimaryTheme .mx-md-n14 {
        margin-right: -12rem !important;
    }
    .PrimaryTheme .mb-md-n14, .PrimaryTheme .my-md-n14 {
        margin-bottom: -12rem !important;
    }
    .PrimaryTheme .ml-md-n14, .PrimaryTheme .mx-md-n14 {
        margin-left: -12rem !important;
    }
    .PrimaryTheme .m-md-n15 {
        margin: -16rem !important;
    }
    .PrimaryTheme .mt-md-n15, .PrimaryTheme .my-md-n15 {
        margin-top: -16rem !important;
    }
    .PrimaryTheme .mr-md-n15, .PrimaryTheme .mx-md-n15 {
        margin-right: -16rem !important;
    }
    .PrimaryTheme .mb-md-n15, .PrimaryTheme .my-md-n15 {
        margin-bottom: -16rem !important;
    }
    .PrimaryTheme .ml-md-n15, .PrimaryTheme .mx-md-n15 {
        margin-left: -16rem !important;
    }
    .PrimaryTheme .m-md-n16 {
        margin: -25rem !important;
    }
    .PrimaryTheme .mt-md-n16, .PrimaryTheme .my-md-n16 {
        margin-top: -25rem !important;
    }
    .PrimaryTheme .mr-md-n16, .PrimaryTheme .mx-md-n16 {
        margin-right: -25rem !important;
    }
    .PrimaryTheme .mb-md-n16, .PrimaryTheme .my-md-n16 {
        margin-bottom: -25rem !important;
    }
    .PrimaryTheme .ml-md-n16, .PrimaryTheme .mx-md-n16 {
        margin-left: -25rem !important;
    }
    .PrimaryTheme .m-md-auto {
        margin: auto !important;
    }
    .PrimaryTheme .mt-md-auto, .PrimaryTheme .my-md-auto {
        margin-top: auto !important;
    }
    .PrimaryTheme .mr-md-auto, .PrimaryTheme .mx-md-auto {
        margin-right: auto !important;
    }
    .PrimaryTheme .mb-md-auto, .PrimaryTheme .my-md-auto {
        margin-bottom: auto !important;
    }
    .PrimaryTheme .ml-md-auto, .PrimaryTheme .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .m-lg-0 {
        margin: 0 !important;
    }
    .PrimaryTheme .mt-lg-0, .PrimaryTheme .my-lg-0 {
        margin-top: 0 !important;
    }
    .PrimaryTheme .mr-lg-0, .PrimaryTheme .mx-lg-0 {
        margin-right: 0 !important;
    }
    .PrimaryTheme .mb-lg-0, .PrimaryTheme .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .PrimaryTheme .ml-lg-0, .PrimaryTheme .mx-lg-0 {
        margin-left: 0 !important;
    }
    .PrimaryTheme .m-lg-1 {
        margin: .25rem !important;
    }
    .PrimaryTheme .mt-lg-1, .PrimaryTheme .my-lg-1 {
        margin-top: .25rem !important;
    }
    .PrimaryTheme .mr-lg-1, .PrimaryTheme .mx-lg-1 {
        margin-right: .25rem !important;
    }
    .PrimaryTheme .mb-lg-1, .PrimaryTheme .my-lg-1 {
        margin-bottom: .25rem !important;
    }
    .PrimaryTheme .ml-lg-1, .PrimaryTheme .mx-lg-1 {
        margin-left: .25rem !important;
    }
    .PrimaryTheme .m-lg-2 {
        margin: .5rem !important;
    }
    .PrimaryTheme .mt-lg-2, .PrimaryTheme .my-lg-2 {
        margin-top: .5rem !important;
    }
    .PrimaryTheme .mr-lg-2, .PrimaryTheme .mx-lg-2 {
        margin-right: .5rem !important;
    }
    .PrimaryTheme .mb-lg-2, .PrimaryTheme .my-lg-2 {
        margin-bottom: .5rem !important;
    }
    .PrimaryTheme .ml-lg-2, .PrimaryTheme .mx-lg-2 {
        margin-left: .5rem !important;
    }
    .PrimaryTheme .m-lg-3 {
        margin: .75rem !important;
    }
    .PrimaryTheme .mt-lg-3, .PrimaryTheme .my-lg-3 {
        margin-top: .75rem !important;
    }
    .PrimaryTheme .mr-lg-3, .PrimaryTheme .mx-lg-3 {
        margin-right: .75rem !important;
    }
    .PrimaryTheme .mb-lg-3, .PrimaryTheme .my-lg-3 {
        margin-bottom: .75rem !important;
    }
    .PrimaryTheme .ml-lg-3, .PrimaryTheme .mx-lg-3 {
        margin-left: .75rem !important;
    }
    .PrimaryTheme .m-lg-4 {
        margin: 1rem !important;
    }
    .PrimaryTheme .mt-lg-4, .PrimaryTheme .my-lg-4 {
        margin-top: 1rem !important;
    }
    .PrimaryTheme .mr-lg-4, .PrimaryTheme .mx-lg-4 {
        margin-right: 1rem !important;
    }
    .PrimaryTheme .mb-lg-4, .PrimaryTheme .my-lg-4 {
        margin-bottom: 1rem !important;
    }
    .PrimaryTheme .ml-lg-4, .PrimaryTheme .mx-lg-4 {
        margin-left: 1rem !important;
    }
    .PrimaryTheme .m-lg-5 {
        margin: 1.5rem !important;
    }
    .PrimaryTheme .mt-lg-5, .PrimaryTheme .my-lg-5 {
        margin-top: 1.5rem !important;
    }
    .PrimaryTheme .mr-lg-5, .PrimaryTheme .mx-lg-5 {
        margin-right: 1.5rem !important;
    }
    .PrimaryTheme .mb-lg-5, .PrimaryTheme .my-lg-5 {
        margin-bottom: 1.5rem !important;
    }
    .PrimaryTheme .ml-lg-5, .PrimaryTheme .mx-lg-5 {
        margin-left: 1.5rem !important;
    }
    .PrimaryTheme .m-lg-6 {
        margin: 2rem !important;
    }
    .PrimaryTheme .mt-lg-6, .PrimaryTheme .my-lg-6 {
        margin-top: 2rem !important;
    }
    .PrimaryTheme .mr-lg-6, .PrimaryTheme .mx-lg-6 {
        margin-right: 2rem !important;
    }
    .PrimaryTheme .mb-lg-6, .PrimaryTheme .my-lg-6 {
        margin-bottom: 2rem !important;
    }
    .PrimaryTheme .ml-lg-6, .PrimaryTheme .mx-lg-6 {
        margin-left: 2rem !important;
    }
    .PrimaryTheme .m-lg-7 {
        margin: 2.5rem !important;
    }
    .PrimaryTheme .mt-lg-7, .PrimaryTheme .my-lg-7 {
        margin-top: 2.5rem !important;
    }
    .PrimaryTheme .mr-lg-7, .PrimaryTheme .mx-lg-7 {
        margin-right: 2.5rem !important;
    }
    .PrimaryTheme .mb-lg-7, .PrimaryTheme .my-lg-7 {
        margin-bottom: 2.5rem !important;
    }
    .PrimaryTheme .ml-lg-7, .PrimaryTheme .mx-lg-7 {
        margin-left: 2.5rem !important;
    }
    .PrimaryTheme .m-lg-8 {
        margin: 3rem !important;
    }
    .PrimaryTheme .mt-lg-8, .PrimaryTheme .my-lg-8 {
        margin-top: 3rem !important;
    }
    .PrimaryTheme .mr-lg-8, .PrimaryTheme .mx-lg-8 {
        margin-right: 3rem !important;
    }
    .PrimaryTheme .mb-lg-8, .PrimaryTheme .my-lg-8 {
        margin-bottom: 3rem !important;
    }
    .PrimaryTheme .ml-lg-8, .PrimaryTheme .mx-lg-8 {
        margin-left: 3rem !important;
    }
    .PrimaryTheme .m-lg-9 {
        margin: 4rem !important;
    }
    .PrimaryTheme .mt-lg-9, .PrimaryTheme .my-lg-9 {
        margin-top: 4rem !important;
    }
    .PrimaryTheme .mr-lg-9, .PrimaryTheme .mx-lg-9 {
        margin-right: 4rem !important;
    }
    .PrimaryTheme .mb-lg-9, .PrimaryTheme .my-lg-9 {
        margin-bottom: 4rem !important;
    }
    .PrimaryTheme .ml-lg-9, .PrimaryTheme .mx-lg-9 {
        margin-left: 4rem !important;
    }
    .PrimaryTheme .m-lg-10 {
        margin: 5rem !important;
    }
    .PrimaryTheme .mt-lg-10, .PrimaryTheme .my-lg-10 {
        margin-top: 5rem !important;
    }
    .PrimaryTheme .mr-lg-10, .PrimaryTheme .mx-lg-10 {
        margin-right: 5rem !important;
    }
    .PrimaryTheme .mb-lg-10, .PrimaryTheme .my-lg-10 {
        margin-bottom: 5rem !important;
    }
    .PrimaryTheme .ml-lg-10, .PrimaryTheme .mx-lg-10 {
        margin-left: 5rem !important;
    }
    .PrimaryTheme .m-lg-11 {
        margin: 6rem !important;
    }
    .PrimaryTheme .mt-lg-11, .PrimaryTheme .my-lg-11 {
        margin-top: 6rem !important;
    }
    .PrimaryTheme .mr-lg-11, .PrimaryTheme .mx-lg-11 {
        margin-right: 6rem !important;
    }
    .PrimaryTheme .mb-lg-11, .PrimaryTheme .my-lg-11 {
        margin-bottom: 6rem !important;
    }
    .PrimaryTheme .ml-lg-11, .PrimaryTheme .mx-lg-11 {
        margin-left: 6rem !important;
    }
    .PrimaryTheme .m-lg-12 {
        margin: 8rem !important;
    }
    .PrimaryTheme .mt-lg-12, .PrimaryTheme .my-lg-12 {
        margin-top: 8rem !important;
    }
    .PrimaryTheme .mr-lg-12, .PrimaryTheme .mx-lg-12 {
        margin-right: 8rem !important;
    }
    .PrimaryTheme .mb-lg-12, .PrimaryTheme .my-lg-12 {
        margin-bottom: 8rem !important;
    }
    .PrimaryTheme .ml-lg-12, .PrimaryTheme .mx-lg-12 {
        margin-left: 8rem !important;
    }
    .PrimaryTheme .m-lg-13 {
        margin: 10rem !important;
    }
    .PrimaryTheme .mt-lg-13, .PrimaryTheme .my-lg-13 {
        margin-top: 10rem !important;
    }
    .PrimaryTheme .mr-lg-13, .PrimaryTheme .mx-lg-13 {
        margin-right: 10rem !important;
    }
    .PrimaryTheme .mb-lg-13, .PrimaryTheme .my-lg-13 {
        margin-bottom: 10rem !important;
    }
    .PrimaryTheme .ml-lg-13, .PrimaryTheme .mx-lg-13 {
        margin-left: 10rem !important;
    }
    .PrimaryTheme .m-lg-14 {
        margin: 12rem !important;
    }
    .PrimaryTheme .mt-lg-14, .PrimaryTheme .my-lg-14 {
        margin-top: 12rem !important;
    }
    .PrimaryTheme .mr-lg-14, .PrimaryTheme .mx-lg-14 {
        margin-right: 12rem !important;
    }
    .PrimaryTheme .mb-lg-14, .PrimaryTheme .my-lg-14 {
        margin-bottom: 12rem !important;
    }
    .PrimaryTheme .ml-lg-14, .PrimaryTheme .mx-lg-14 {
        margin-left: 12rem !important;
    }
    .PrimaryTheme .m-lg-15 {
        margin: 16rem !important;
    }
    .PrimaryTheme .mt-lg-15, .PrimaryTheme .my-lg-15 {
        margin-top: 16rem !important;
    }
    .PrimaryTheme .mr-lg-15, .PrimaryTheme .mx-lg-15 {
        margin-right: 16rem !important;
    }
    .PrimaryTheme .mb-lg-15, .PrimaryTheme .my-lg-15 {
        margin-bottom: 16rem !important;
    }
    .PrimaryTheme .ml-lg-15, .PrimaryTheme .mx-lg-15 {
        margin-left: 16rem !important;
    }
    .PrimaryTheme .m-lg-16 {
        margin: 25rem !important;
    }
    .PrimaryTheme .mt-lg-16, .PrimaryTheme .my-lg-16 {
        margin-top: 25rem !important;
    }
    .PrimaryTheme .mr-lg-16, .PrimaryTheme .mx-lg-16 {
        margin-right: 25rem !important;
    }
    .PrimaryTheme .mb-lg-16, .PrimaryTheme .my-lg-16 {
        margin-bottom: 25rem !important;
    }
    .PrimaryTheme .ml-lg-16, .PrimaryTheme .mx-lg-16 {
        margin-left: 25rem !important;
    }
    .PrimaryTheme .p-lg-0 {
        padding: 0 !important;
    }
    .PrimaryTheme .pt-lg-0, .PrimaryTheme .py-lg-0 {
        padding-top: 0 !important;
    }
    .PrimaryTheme .pr-lg-0, .PrimaryTheme .px-lg-0 {
        padding-right: 0 !important;
    }
    .PrimaryTheme .pb-lg-0, .PrimaryTheme .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .PrimaryTheme .pl-lg-0, .PrimaryTheme .px-lg-0 {
        padding-left: 0 !important;
    }
    .PrimaryTheme .p-lg-1 {
        padding: .25rem !important;
    }
    .PrimaryTheme .pt-lg-1, .PrimaryTheme .py-lg-1 {
        padding-top: .25rem !important;
    }
    .PrimaryTheme .pr-lg-1, .PrimaryTheme .px-lg-1 {
        padding-right: .25rem !important;
    }
    .PrimaryTheme .pb-lg-1, .PrimaryTheme .py-lg-1 {
        padding-bottom: .25rem !important;
    }
    .PrimaryTheme .pl-lg-1, .PrimaryTheme .px-lg-1 {
        padding-left: .25rem !important;
    }
    .PrimaryTheme .p-lg-2 {
        padding: .5rem !important;
    }
    .PrimaryTheme .pt-lg-2, .PrimaryTheme .py-lg-2 {
        padding-top: .5rem !important;
    }
    .PrimaryTheme .pr-lg-2, .PrimaryTheme .px-lg-2 {
        padding-right: .5rem !important;
    }
    .PrimaryTheme .pb-lg-2, .PrimaryTheme .py-lg-2 {
        padding-bottom: .5rem !important;
    }
    .PrimaryTheme .pl-lg-2, .PrimaryTheme .px-lg-2 {
        padding-left: .5rem !important;
    }
    .PrimaryTheme .p-lg-3 {
        padding: .75rem !important;
    }
    .PrimaryTheme .pt-lg-3, .PrimaryTheme .py-lg-3 {
        padding-top: .75rem !important;
    }
    .PrimaryTheme .pr-lg-3, .PrimaryTheme .px-lg-3 {
        padding-right: .75rem !important;
    }
    .PrimaryTheme .pb-lg-3, .PrimaryTheme .py-lg-3 {
        padding-bottom: .75rem !important;
    }
    .PrimaryTheme .pl-lg-3, .PrimaryTheme .px-lg-3 {
        padding-left: .75rem !important;
    }
    .PrimaryTheme .p-lg-4 {
        padding: 1rem !important;
    }
    .PrimaryTheme .pt-lg-4, .PrimaryTheme .py-lg-4 {
        padding-top: 1rem !important;
    }
    .PrimaryTheme .pr-lg-4, .PrimaryTheme .px-lg-4 {
        padding-right: 1rem !important;
    }
    .PrimaryTheme .pb-lg-4, .PrimaryTheme .py-lg-4 {
        padding-bottom: 1rem !important;
    }
    .PrimaryTheme .pl-lg-4, .PrimaryTheme .px-lg-4 {
        padding-left: 1rem !important;
    }
    .PrimaryTheme .p-lg-5 {
        padding: 1.5rem !important;
    }
    .PrimaryTheme .pt-lg-5, .PrimaryTheme .py-lg-5 {
        padding-top: 1.5rem !important;
    }
    .PrimaryTheme .pr-lg-5, .PrimaryTheme .px-lg-5 {
        padding-right: 1.5rem !important;
    }
    .PrimaryTheme .pb-lg-5, .PrimaryTheme .py-lg-5 {
        padding-bottom: 1.5rem !important;
    }
    .PrimaryTheme .pl-lg-5, .PrimaryTheme .px-lg-5 {
        padding-left: 1.5rem !important;
    }
    .PrimaryTheme .p-lg-6 {
        padding: 2rem !important;
    }
    .PrimaryTheme .pt-lg-6, .PrimaryTheme .py-lg-6 {
        padding-top: 2rem !important;
    }
    .PrimaryTheme .pr-lg-6, .PrimaryTheme .px-lg-6 {
        padding-right: 2rem !important;
    }
    .PrimaryTheme .pb-lg-6, .PrimaryTheme .py-lg-6 {
        padding-bottom: 2rem !important;
    }
    .PrimaryTheme .pl-lg-6, .PrimaryTheme .px-lg-6 {
        padding-left: 2rem !important;
    }
    .PrimaryTheme .p-lg-7 {
        padding: 2.5rem !important;
    }
    .PrimaryTheme .pt-lg-7, .PrimaryTheme .py-lg-7 {
        padding-top: 2.5rem !important;
    }
    .PrimaryTheme .pr-lg-7, .PrimaryTheme .px-lg-7 {
        padding-right: 2.5rem !important;
    }
    .PrimaryTheme .pb-lg-7, .PrimaryTheme .py-lg-7 {
        padding-bottom: 2.5rem !important;
    }
    .PrimaryTheme .pl-lg-7, .PrimaryTheme .px-lg-7 {
        padding-left: 2.5rem !important;
    }
    .PrimaryTheme .p-lg-8 {
        padding: 3rem !important;
    }
    .PrimaryTheme .pt-lg-8, .PrimaryTheme .py-lg-8 {
        padding-top: 3rem !important;
    }
    .PrimaryTheme .pr-lg-8, .PrimaryTheme .px-lg-8 {
        padding-right: 3rem !important;
    }
    .PrimaryTheme .pb-lg-8, .PrimaryTheme .py-lg-8 {
        padding-bottom: 3rem !important;
    }
    .PrimaryTheme .pl-lg-8, .PrimaryTheme .px-lg-8 {
        padding-left: 3rem !important;
    }
    .PrimaryTheme .p-lg-9 {
        padding: 4rem !important;
    }
    .PrimaryTheme .pt-lg-9, .PrimaryTheme .py-lg-9 {
        padding-top: 4rem !important;
    }
    .PrimaryTheme .pr-lg-9, .PrimaryTheme .px-lg-9 {
        padding-right: 4rem !important;
    }
    .PrimaryTheme .pb-lg-9, .PrimaryTheme .py-lg-9 {
        padding-bottom: 4rem !important;
    }
    .PrimaryTheme .pl-lg-9, .PrimaryTheme .px-lg-9 {
        padding-left: 4rem !important;
    }
    .PrimaryTheme .p-lg-10 {
        padding: 5rem !important;
    }
    .PrimaryTheme .pt-lg-10, .PrimaryTheme .py-lg-10 {
        padding-top: 5rem !important;
    }
    .PrimaryTheme .pr-lg-10, .PrimaryTheme .px-lg-10 {
        padding-right: 5rem !important;
    }
    .PrimaryTheme .pb-lg-10, .PrimaryTheme .py-lg-10 {
        padding-bottom: 5rem !important;
    }
    .PrimaryTheme .pl-lg-10, .PrimaryTheme .px-lg-10 {
        padding-left: 5rem !important;
    }
    .PrimaryTheme .p-lg-11 {
        padding: 6rem !important;
    }
    .PrimaryTheme .pt-lg-11, .PrimaryTheme .py-lg-11 {
        padding-top: 6rem !important;
    }
    .PrimaryTheme .pr-lg-11, .PrimaryTheme .px-lg-11 {
        padding-right: 6rem !important;
    }
    .PrimaryTheme .pb-lg-11, .PrimaryTheme .py-lg-11 {
        padding-bottom: 6rem !important;
    }
    .PrimaryTheme .pl-lg-11, .PrimaryTheme .px-lg-11 {
        padding-left: 6rem !important;
    }
    .PrimaryTheme .p-lg-12 {
        padding: 8rem !important;
    }
    .PrimaryTheme .pt-lg-12, .PrimaryTheme .py-lg-12 {
        padding-top: 8rem !important;
    }
    .PrimaryTheme .pr-lg-12, .PrimaryTheme .px-lg-12 {
        padding-right: 8rem !important;
    }
    .PrimaryTheme .pb-lg-12, .PrimaryTheme .py-lg-12 {
        padding-bottom: 8rem !important;
    }
    .PrimaryTheme .pl-lg-12, .PrimaryTheme .px-lg-12 {
        padding-left: 8rem !important;
    }
    .PrimaryTheme .p-lg-13 {
        padding: 10rem !important;
    }
    .PrimaryTheme .pt-lg-13, .PrimaryTheme .py-lg-13 {
        padding-top: 10rem !important;
    }
    .PrimaryTheme .pr-lg-13, .PrimaryTheme .px-lg-13 {
        padding-right: 10rem !important;
    }
    .PrimaryTheme .pb-lg-13, .PrimaryTheme .py-lg-13 {
        padding-bottom: 10rem !important;
    }
    .PrimaryTheme .pl-lg-13, .PrimaryTheme .px-lg-13 {
        padding-left: 10rem !important;
    }
    .PrimaryTheme .p-lg-14 {
        padding: 12rem !important;
    }
    .PrimaryTheme .pt-lg-14, .PrimaryTheme .py-lg-14 {
        padding-top: 12rem !important;
    }
    .PrimaryTheme .pr-lg-14, .PrimaryTheme .px-lg-14 {
        padding-right: 12rem !important;
    }
    .PrimaryTheme .pb-lg-14, .PrimaryTheme .py-lg-14 {
        padding-bottom: 12rem !important;
    }
    .PrimaryTheme .pl-lg-14, .PrimaryTheme .px-lg-14 {
        padding-left: 12rem !important;
    }
    .PrimaryTheme .p-lg-15 {
        padding: 16rem !important;
    }
    .PrimaryTheme .pt-lg-15, .PrimaryTheme .py-lg-15 {
        padding-top: 16rem !important;
    }
    .PrimaryTheme .pr-lg-15, .PrimaryTheme .px-lg-15 {
        padding-right: 16rem !important;
    }
    .PrimaryTheme .pb-lg-15, .PrimaryTheme .py-lg-15 {
        padding-bottom: 16rem !important;
    }
    .PrimaryTheme .pl-lg-15, .PrimaryTheme .px-lg-15 {
        padding-left: 16rem !important;
    }
    .PrimaryTheme .p-lg-16 {
        padding: 25rem !important;
    }
    .PrimaryTheme .pt-lg-16, .PrimaryTheme .py-lg-16 {
        padding-top: 25rem !important;
    }
    .PrimaryTheme .pr-lg-16, .PrimaryTheme .px-lg-16 {
        padding-right: 25rem !important;
    }
    .PrimaryTheme .pb-lg-16, .PrimaryTheme .py-lg-16 {
        padding-bottom: 25rem !important;
    }
    .PrimaryTheme .pl-lg-16, .PrimaryTheme .px-lg-16 {
        padding-left: 25rem !important;
    }
    .PrimaryTheme .m-lg-n1 {
        margin: -.25rem !important;
    }
    .PrimaryTheme .mt-lg-n1, .PrimaryTheme .my-lg-n1 {
        margin-top: -.25rem !important;
    }
    .PrimaryTheme .mr-lg-n1, .PrimaryTheme .mx-lg-n1 {
        margin-right: -.25rem !important;
    }
    .PrimaryTheme .mb-lg-n1, .PrimaryTheme .my-lg-n1 {
        margin-bottom: -.25rem !important;
    }
    .PrimaryTheme .ml-lg-n1, .PrimaryTheme .mx-lg-n1 {
        margin-left: -.25rem !important;
    }
    .PrimaryTheme .m-lg-n2 {
        margin: -.5rem !important;
    }
    .PrimaryTheme .mt-lg-n2, .PrimaryTheme .my-lg-n2 {
        margin-top: -.5rem !important;
    }
    .PrimaryTheme .mr-lg-n2, .PrimaryTheme .mx-lg-n2 {
        margin-right: -.5rem !important;
    }
    .PrimaryTheme .mb-lg-n2, .PrimaryTheme .my-lg-n2 {
        margin-bottom: -.5rem !important;
    }
    .PrimaryTheme .ml-lg-n2, .PrimaryTheme .mx-lg-n2 {
        margin-left: -.5rem !important;
    }
    .PrimaryTheme .m-lg-n3 {
        margin: -.75rem !important;
    }
    .PrimaryTheme .mt-lg-n3, .PrimaryTheme .my-lg-n3 {
        margin-top: -.75rem !important;
    }
    .PrimaryTheme .mr-lg-n3, .PrimaryTheme .mx-lg-n3 {
        margin-right: -.75rem !important;
    }
    .PrimaryTheme .mb-lg-n3, .PrimaryTheme .my-lg-n3 {
        margin-bottom: -.75rem !important;
    }
    .PrimaryTheme .ml-lg-n3, .PrimaryTheme .mx-lg-n3 {
        margin-left: -.75rem !important;
    }
    .PrimaryTheme .m-lg-n4 {
        margin: -1rem !important;
    }
    .PrimaryTheme .mt-lg-n4, .PrimaryTheme .my-lg-n4 {
        margin-top: -1rem !important;
    }
    .PrimaryTheme .mr-lg-n4, .PrimaryTheme .mx-lg-n4 {
        margin-right: -1rem !important;
    }
    .PrimaryTheme .mb-lg-n4, .PrimaryTheme .my-lg-n4 {
        margin-bottom: -1rem !important;
    }
    .PrimaryTheme .ml-lg-n4, .PrimaryTheme .mx-lg-n4 {
        margin-left: -1rem !important;
    }
    .PrimaryTheme .m-lg-n5 {
        margin: -1.5rem !important;
    }
    .PrimaryTheme .mt-lg-n5, .PrimaryTheme .my-lg-n5 {
        margin-top: -1.5rem !important;
    }
    .PrimaryTheme .mr-lg-n5, .PrimaryTheme .mx-lg-n5 {
        margin-right: -1.5rem !important;
    }
    .PrimaryTheme .mb-lg-n5, .PrimaryTheme .my-lg-n5 {
        margin-bottom: -1.5rem !important;
    }
    .PrimaryTheme .ml-lg-n5, .PrimaryTheme .mx-lg-n5 {
        margin-left: -1.5rem !important;
    }
    .PrimaryTheme .m-lg-n6 {
        margin: -2rem !important;
    }
    .PrimaryTheme .mt-lg-n6, .PrimaryTheme .my-lg-n6 {
        margin-top: -2rem !important;
    }
    .PrimaryTheme .mr-lg-n6, .PrimaryTheme .mx-lg-n6 {
        margin-right: -2rem !important;
    }
    .PrimaryTheme .mb-lg-n6, .PrimaryTheme .my-lg-n6 {
        margin-bottom: -2rem !important;
    }
    .PrimaryTheme .ml-lg-n6, .PrimaryTheme .mx-lg-n6 {
        margin-left: -2rem !important;
    }
    .PrimaryTheme .m-lg-n7 {
        margin: -2.5rem !important;
    }
    .PrimaryTheme .mt-lg-n7, .PrimaryTheme .my-lg-n7 {
        margin-top: -2.5rem !important;
    }
    .PrimaryTheme .mr-lg-n7, .PrimaryTheme .mx-lg-n7 {
        margin-right: -2.5rem !important;
    }
    .PrimaryTheme .mb-lg-n7, .PrimaryTheme .my-lg-n7 {
        margin-bottom: -2.5rem !important;
    }
    .PrimaryTheme .ml-lg-n7, .PrimaryTheme .mx-lg-n7 {
        margin-left: -2.5rem !important;
    }
    .PrimaryTheme .m-lg-n8 {
        margin: -3rem !important;
    }
    .PrimaryTheme .mt-lg-n8, .PrimaryTheme .my-lg-n8 {
        margin-top: -3rem !important;
    }
    .PrimaryTheme .mr-lg-n8, .PrimaryTheme .mx-lg-n8 {
        margin-right: -3rem !important;
    }
    .PrimaryTheme .mb-lg-n8, .PrimaryTheme .my-lg-n8 {
        margin-bottom: -3rem !important;
    }
    .PrimaryTheme .ml-lg-n8, .PrimaryTheme .mx-lg-n8 {
        margin-left: -3rem !important;
    }
    .PrimaryTheme .m-lg-n9 {
        margin: -4rem !important;
    }
    .PrimaryTheme .mt-lg-n9, .PrimaryTheme .my-lg-n9 {
        margin-top: -4rem !important;
    }
    .PrimaryTheme .mr-lg-n9, .PrimaryTheme .mx-lg-n9 {
        margin-right: -4rem !important;
    }
    .PrimaryTheme .mb-lg-n9, .PrimaryTheme .my-lg-n9 {
        margin-bottom: -4rem !important;
    }
    .PrimaryTheme .ml-lg-n9, .PrimaryTheme .mx-lg-n9 {
        margin-left: -4rem !important;
    }
    .PrimaryTheme .m-lg-n10 {
        margin: -5rem !important;
    }
    .PrimaryTheme .mt-lg-n10, .PrimaryTheme .my-lg-n10 {
        margin-top: -5rem !important;
    }
    .PrimaryTheme .mr-lg-n10, .PrimaryTheme .mx-lg-n10 {
        margin-right: -5rem !important;
    }
    .PrimaryTheme .mb-lg-n10, .PrimaryTheme .my-lg-n10 {
        margin-bottom: -5rem !important;
    }
    .PrimaryTheme .ml-lg-n10, .PrimaryTheme .mx-lg-n10 {
        margin-left: -5rem !important;
    }
    .PrimaryTheme .m-lg-n11 {
        margin: -6rem !important;
    }
    .PrimaryTheme .mt-lg-n11, .PrimaryTheme .my-lg-n11 {
        margin-top: -6rem !important;
    }
    .PrimaryTheme .mr-lg-n11, .PrimaryTheme .mx-lg-n11 {
        margin-right: -6rem !important;
    }
    .PrimaryTheme .mb-lg-n11, .PrimaryTheme .my-lg-n11 {
        margin-bottom: -6rem !important;
    }
    .PrimaryTheme .ml-lg-n11, .PrimaryTheme .mx-lg-n11 {
        margin-left: -6rem !important;
    }
    .PrimaryTheme .m-lg-n12 {
        margin: -8rem !important;
    }
    .PrimaryTheme .mt-lg-n12, .PrimaryTheme .my-lg-n12 {
        margin-top: -8rem !important;
    }
    .PrimaryTheme .mr-lg-n12, .PrimaryTheme .mx-lg-n12 {
        margin-right: -8rem !important;
    }
    .PrimaryTheme .mb-lg-n12, .PrimaryTheme .my-lg-n12 {
        margin-bottom: -8rem !important;
    }
    .PrimaryTheme .ml-lg-n12, .PrimaryTheme .mx-lg-n12 {
        margin-left: -8rem !important;
    }
    .PrimaryTheme .m-lg-n13 {
        margin: -10rem !important;
    }
    .PrimaryTheme .mt-lg-n13, .PrimaryTheme .my-lg-n13 {
        margin-top: -10rem !important;
    }
    .PrimaryTheme .mr-lg-n13, .PrimaryTheme .mx-lg-n13 {
        margin-right: -10rem !important;
    }
    .PrimaryTheme .mb-lg-n13, .PrimaryTheme .my-lg-n13 {
        margin-bottom: -10rem !important;
    }
    .PrimaryTheme .ml-lg-n13, .PrimaryTheme .mx-lg-n13 {
        margin-left: -10rem !important;
    }
    .PrimaryTheme .m-lg-n14 {
        margin: -12rem !important;
    }
    .PrimaryTheme .mt-lg-n14, .PrimaryTheme .my-lg-n14 {
        margin-top: -12rem !important;
    }
    .PrimaryTheme .mr-lg-n14, .PrimaryTheme .mx-lg-n14 {
        margin-right: -12rem !important;
    }
    .PrimaryTheme .mb-lg-n14, .PrimaryTheme .my-lg-n14 {
        margin-bottom: -12rem !important;
    }
    .PrimaryTheme .ml-lg-n14, .PrimaryTheme .mx-lg-n14 {
        margin-left: -12rem !important;
    }
    .PrimaryTheme .m-lg-n15 {
        margin: -16rem !important;
    }
    .PrimaryTheme .mt-lg-n15, .PrimaryTheme .my-lg-n15 {
        margin-top: -16rem !important;
    }
    .PrimaryTheme .mr-lg-n15, .PrimaryTheme .mx-lg-n15 {
        margin-right: -16rem !important;
    }
    .PrimaryTheme .mb-lg-n15, .PrimaryTheme .my-lg-n15 {
        margin-bottom: -16rem !important;
    }
    .PrimaryTheme .ml-lg-n15, .PrimaryTheme .mx-lg-n15 {
        margin-left: -16rem !important;
    }
    .PrimaryTheme .m-lg-n16 {
        margin: -25rem !important;
    }
    .PrimaryTheme .mt-lg-n16, .PrimaryTheme .my-lg-n16 {
        margin-top: -25rem !important;
    }
    .PrimaryTheme .mr-lg-n16, .PrimaryTheme .mx-lg-n16 {
        margin-right: -25rem !important;
    }
    .PrimaryTheme .mb-lg-n16, .PrimaryTheme .my-lg-n16 {
        margin-bottom: -25rem !important;
    }
    .PrimaryTheme .ml-lg-n16, .PrimaryTheme .mx-lg-n16 {
        margin-left: -25rem !important;
    }
    .PrimaryTheme .m-lg-auto {
        margin: auto !important;
    }
    .PrimaryTheme .mt-lg-auto, .PrimaryTheme .my-lg-auto {
        margin-top: auto !important;
    }
    .PrimaryTheme .mr-lg-auto, .PrimaryTheme .mx-lg-auto {
        margin-right: auto !important;
    }
    .PrimaryTheme .mb-lg-auto, .PrimaryTheme .my-lg-auto {
        margin-bottom: auto !important;
    }
    .PrimaryTheme .ml-lg-auto, .PrimaryTheme .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .m-xl-0 {
        margin: 0 !important;
    }
    .PrimaryTheme .mt-xl-0, .PrimaryTheme .my-xl-0 {
        margin-top: 0 !important;
    }
    .PrimaryTheme .mr-xl-0, .PrimaryTheme .mx-xl-0 {
        margin-right: 0 !important;
    }
    .PrimaryTheme .mb-xl-0, .PrimaryTheme .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .PrimaryTheme .ml-xl-0, .PrimaryTheme .mx-xl-0 {
        margin-left: 0 !important;
    }
    .PrimaryTheme .m-xl-1 {
        margin: .25rem !important;
    }
    .PrimaryTheme .mt-xl-1, .PrimaryTheme .my-xl-1 {
        margin-top: .25rem !important;
    }
    .PrimaryTheme .mr-xl-1, .PrimaryTheme .mx-xl-1 {
        margin-right: .25rem !important;
    }
    .PrimaryTheme .mb-xl-1, .PrimaryTheme .my-xl-1 {
        margin-bottom: .25rem !important;
    }
    .PrimaryTheme .ml-xl-1, .PrimaryTheme .mx-xl-1 {
        margin-left: .25rem !important;
    }
    .PrimaryTheme .m-xl-2 {
        margin: .5rem !important;
    }
    .PrimaryTheme .mt-xl-2, .PrimaryTheme .my-xl-2 {
        margin-top: .5rem !important;
    }
    .PrimaryTheme .mr-xl-2, .PrimaryTheme .mx-xl-2 {
        margin-right: .5rem !important;
    }
    .PrimaryTheme .mb-xl-2, .PrimaryTheme .my-xl-2 {
        margin-bottom: .5rem !important;
    }
    .PrimaryTheme .ml-xl-2, .PrimaryTheme .mx-xl-2 {
        margin-left: .5rem !important;
    }
    .PrimaryTheme .m-xl-3 {
        margin: .75rem !important;
    }
    .PrimaryTheme .mt-xl-3, .PrimaryTheme .my-xl-3 {
        margin-top: .75rem !important;
    }
    .PrimaryTheme .mr-xl-3, .PrimaryTheme .mx-xl-3 {
        margin-right: .75rem !important;
    }
    .PrimaryTheme .mb-xl-3, .PrimaryTheme .my-xl-3 {
        margin-bottom: .75rem !important;
    }
    .PrimaryTheme .ml-xl-3, .PrimaryTheme .mx-xl-3 {
        margin-left: .75rem !important;
    }
    .PrimaryTheme .m-xl-4 {
        margin: 1rem !important;
    }
    .PrimaryTheme .mt-xl-4, .PrimaryTheme .my-xl-4 {
        margin-top: 1rem !important;
    }
    .PrimaryTheme .mr-xl-4, .PrimaryTheme .mx-xl-4 {
        margin-right: 1rem !important;
    }
    .PrimaryTheme .mb-xl-4, .PrimaryTheme .my-xl-4 {
        margin-bottom: 1rem !important;
    }
    .PrimaryTheme .ml-xl-4, .PrimaryTheme .mx-xl-4 {
        margin-left: 1rem !important;
    }
    .PrimaryTheme .m-xl-5 {
        margin: 1.5rem !important;
    }
    .PrimaryTheme .mt-xl-5, .PrimaryTheme .my-xl-5 {
        margin-top: 1.5rem !important;
    }
    .PrimaryTheme .mr-xl-5, .PrimaryTheme .mx-xl-5 {
        margin-right: 1.5rem !important;
    }
    .PrimaryTheme .mb-xl-5, .PrimaryTheme .my-xl-5 {
        margin-bottom: 1.5rem !important;
    }
    .PrimaryTheme .ml-xl-5, .PrimaryTheme .mx-xl-5 {
        margin-left: 1.5rem !important;
    }
    .PrimaryTheme .m-xl-6 {
        margin: 2rem !important;
    }
    .PrimaryTheme .mt-xl-6, .PrimaryTheme .my-xl-6 {
        margin-top: 2rem !important;
    }
    .PrimaryTheme .mr-xl-6, .PrimaryTheme .mx-xl-6 {
        margin-right: 2rem !important;
    }
    .PrimaryTheme .mb-xl-6, .PrimaryTheme .my-xl-6 {
        margin-bottom: 2rem !important;
    }
    .PrimaryTheme .ml-xl-6, .PrimaryTheme .mx-xl-6 {
        margin-left: 2rem !important;
    }
    .PrimaryTheme .m-xl-7 {
        margin: 2.5rem !important;
    }
    .PrimaryTheme .mt-xl-7, .PrimaryTheme .my-xl-7 {
        margin-top: 2.5rem !important;
    }
    .PrimaryTheme .mr-xl-7, .PrimaryTheme .mx-xl-7 {
        margin-right: 2.5rem !important;
    }
    .PrimaryTheme .mb-xl-7, .PrimaryTheme .my-xl-7 {
        margin-bottom: 2.5rem !important;
    }
    .PrimaryTheme .ml-xl-7, .PrimaryTheme .mx-xl-7 {
        margin-left: 2.5rem !important;
    }
    .PrimaryTheme .m-xl-8 {
        margin: 3rem !important;
    }
    .PrimaryTheme .mt-xl-8, .PrimaryTheme .my-xl-8 {
        margin-top: 3rem !important;
    }
    .PrimaryTheme .mr-xl-8, .PrimaryTheme .mx-xl-8 {
        margin-right: 3rem !important;
    }
    .PrimaryTheme .mb-xl-8, .PrimaryTheme .my-xl-8 {
        margin-bottom: 3rem !important;
    }
    .PrimaryTheme .ml-xl-8, .PrimaryTheme .mx-xl-8 {
        margin-left: 3rem !important;
    }
    .PrimaryTheme .m-xl-9 {
        margin: 4rem !important;
    }
    .PrimaryTheme .mt-xl-9, .PrimaryTheme .my-xl-9 {
        margin-top: 4rem !important;
    }
    .PrimaryTheme .mr-xl-9, .PrimaryTheme .mx-xl-9 {
        margin-right: 4rem !important;
    }
    .PrimaryTheme .mb-xl-9, .PrimaryTheme .my-xl-9 {
        margin-bottom: 4rem !important;
    }
    .PrimaryTheme .ml-xl-9, .PrimaryTheme .mx-xl-9 {
        margin-left: 4rem !important;
    }
    .PrimaryTheme .m-xl-10 {
        margin: 5rem !important;
    }
    .PrimaryTheme .mt-xl-10, .PrimaryTheme .my-xl-10 {
        margin-top: 5rem !important;
    }
    .PrimaryTheme .mr-xl-10, .PrimaryTheme .mx-xl-10 {
        margin-right: 5rem !important;
    }
    .PrimaryTheme .mb-xl-10, .PrimaryTheme .my-xl-10 {
        margin-bottom: 5rem !important;
    }
    .PrimaryTheme .ml-xl-10, .PrimaryTheme .mx-xl-10 {
        margin-left: 5rem !important;
    }
    .PrimaryTheme .m-xl-11 {
        margin: 6rem !important;
    }
    .PrimaryTheme .mt-xl-11, .PrimaryTheme .my-xl-11 {
        margin-top: 6rem !important;
    }
    .PrimaryTheme .mr-xl-11, .PrimaryTheme .mx-xl-11 {
        margin-right: 6rem !important;
    }
    .PrimaryTheme .mb-xl-11, .PrimaryTheme .my-xl-11 {
        margin-bottom: 6rem !important;
    }
    .PrimaryTheme .ml-xl-11, .PrimaryTheme .mx-xl-11 {
        margin-left: 6rem !important;
    }
    .PrimaryTheme .m-xl-12 {
        margin: 8rem !important;
    }
    .PrimaryTheme .mt-xl-12, .PrimaryTheme .my-xl-12 {
        margin-top: 8rem !important;
    }
    .PrimaryTheme .mr-xl-12, .PrimaryTheme .mx-xl-12 {
        margin-right: 8rem !important;
    }
    .PrimaryTheme .mb-xl-12, .PrimaryTheme .my-xl-12 {
        margin-bottom: 8rem !important;
    }
    .PrimaryTheme .ml-xl-12, .PrimaryTheme .mx-xl-12 {
        margin-left: 8rem !important;
    }
    .PrimaryTheme .m-xl-13 {
        margin: 10rem !important;
    }
    .PrimaryTheme .mt-xl-13, .PrimaryTheme .my-xl-13 {
        margin-top: 10rem !important;
    }
    .PrimaryTheme .mr-xl-13, .PrimaryTheme .mx-xl-13 {
        margin-right: 10rem !important;
    }
    .PrimaryTheme .mb-xl-13, .PrimaryTheme .my-xl-13 {
        margin-bottom: 10rem !important;
    }
    .PrimaryTheme .ml-xl-13, .PrimaryTheme .mx-xl-13 {
        margin-left: 10rem !important;
    }
    .PrimaryTheme .m-xl-14 {
        margin: 12rem !important;
    }
    .PrimaryTheme .mt-xl-14, .PrimaryTheme .my-xl-14 {
        margin-top: 12rem !important;
    }
    .PrimaryTheme .mr-xl-14, .PrimaryTheme .mx-xl-14 {
        margin-right: 12rem !important;
    }
    .PrimaryTheme .mb-xl-14, .PrimaryTheme .my-xl-14 {
        margin-bottom: 12rem !important;
    }
    .PrimaryTheme .ml-xl-14, .PrimaryTheme .mx-xl-14 {
        margin-left: 12rem !important;
    }
    .PrimaryTheme .m-xl-15 {
        margin: 16rem !important;
    }
    .PrimaryTheme .mt-xl-15, .PrimaryTheme .my-xl-15 {
        margin-top: 16rem !important;
    }
    .PrimaryTheme .mr-xl-15, .PrimaryTheme .mx-xl-15 {
        margin-right: 16rem !important;
    }
    .PrimaryTheme .mb-xl-15, .PrimaryTheme .my-xl-15 {
        margin-bottom: 16rem !important;
    }
    .PrimaryTheme .ml-xl-15, .PrimaryTheme .mx-xl-15 {
        margin-left: 16rem !important;
    }
    .PrimaryTheme .m-xl-16 {
        margin: 25rem !important;
    }
    .PrimaryTheme .mt-xl-16, .PrimaryTheme .my-xl-16 {
        margin-top: 25rem !important;
    }
    .PrimaryTheme .mr-xl-16, .PrimaryTheme .mx-xl-16 {
        margin-right: 25rem !important;
    }
    .PrimaryTheme .mb-xl-16, .PrimaryTheme .my-xl-16 {
        margin-bottom: 25rem !important;
    }
    .PrimaryTheme .ml-xl-16, .PrimaryTheme .mx-xl-16 {
        margin-left: 25rem !important;
    }
    .PrimaryTheme .p-xl-0 {
        padding: 0 !important;
    }
    .PrimaryTheme .pt-xl-0, .PrimaryTheme .py-xl-0 {
        padding-top: 0 !important;
    }
    .PrimaryTheme .pr-xl-0, .PrimaryTheme .px-xl-0 {
        padding-right: 0 !important;
    }
    .PrimaryTheme .pb-xl-0, .PrimaryTheme .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .PrimaryTheme .pl-xl-0, .PrimaryTheme .px-xl-0 {
        padding-left: 0 !important;
    }
    .PrimaryTheme .p-xl-1 {
        padding: .25rem !important;
    }
    .PrimaryTheme .pt-xl-1, .PrimaryTheme .py-xl-1 {
        padding-top: .25rem !important;
    }
    .PrimaryTheme .pr-xl-1, .PrimaryTheme .px-xl-1 {
        padding-right: .25rem !important;
    }
    .PrimaryTheme .pb-xl-1, .PrimaryTheme .py-xl-1 {
        padding-bottom: .25rem !important;
    }
    .PrimaryTheme .pl-xl-1, .PrimaryTheme .px-xl-1 {
        padding-left: .25rem !important;
    }
    .PrimaryTheme .p-xl-2 {
        padding: .5rem !important;
    }
    .PrimaryTheme .pt-xl-2, .PrimaryTheme .py-xl-2 {
        padding-top: .5rem !important;
    }
    .PrimaryTheme .pr-xl-2, .PrimaryTheme .px-xl-2 {
        padding-right: .5rem !important;
    }
    .PrimaryTheme .pb-xl-2, .PrimaryTheme .py-xl-2 {
        padding-bottom: .5rem !important;
    }
    .PrimaryTheme .pl-xl-2, .PrimaryTheme .px-xl-2 {
        padding-left: .5rem !important;
    }
    .PrimaryTheme .p-xl-3 {
        padding: .75rem !important;
    }
    .PrimaryTheme .pt-xl-3, .PrimaryTheme .py-xl-3 {
        padding-top: .75rem !important;
    }
    .PrimaryTheme .pr-xl-3, .PrimaryTheme .px-xl-3 {
        padding-right: .75rem !important;
    }
    .PrimaryTheme .pb-xl-3, .PrimaryTheme .py-xl-3 {
        padding-bottom: .75rem !important;
    }
    .PrimaryTheme .pl-xl-3, .PrimaryTheme .px-xl-3 {
        padding-left: .75rem !important;
    }
    .PrimaryTheme .p-xl-4 {
        padding: 1rem !important;
    }
    .PrimaryTheme .pt-xl-4, .PrimaryTheme .py-xl-4 {
        padding-top: 1rem !important;
    }
    .PrimaryTheme .pr-xl-4, .PrimaryTheme .px-xl-4 {
        padding-right: 1rem !important;
    }
    .PrimaryTheme .pb-xl-4, .PrimaryTheme .py-xl-4 {
        padding-bottom: 1rem !important;
    }
    .PrimaryTheme .pl-xl-4, .PrimaryTheme .px-xl-4 {
        padding-left: 1rem !important;
    }
    .PrimaryTheme .p-xl-5 {
        padding: 1.5rem !important;
    }
    .PrimaryTheme .pt-xl-5, .PrimaryTheme .py-xl-5 {
        padding-top: 1.5rem !important;
    }
    .PrimaryTheme .pr-xl-5, .PrimaryTheme .px-xl-5 {
        padding-right: 1.5rem !important;
    }
    .PrimaryTheme .pb-xl-5, .PrimaryTheme .py-xl-5 {
        padding-bottom: 1.5rem !important;
    }
    .PrimaryTheme .pl-xl-5, .PrimaryTheme .px-xl-5 {
        padding-left: 1.5rem !important;
    }
    .PrimaryTheme .p-xl-6 {
        padding: 2rem !important;
    }
    .PrimaryTheme .pt-xl-6, .PrimaryTheme .py-xl-6 {
        padding-top: 2rem !important;
    }
    .PrimaryTheme .pr-xl-6, .PrimaryTheme .px-xl-6 {
        padding-right: 2rem !important;
    }
    .PrimaryTheme .pb-xl-6, .PrimaryTheme .py-xl-6 {
        padding-bottom: 2rem !important;
    }
    .PrimaryTheme .pl-xl-6, .PrimaryTheme .px-xl-6 {
        padding-left: 2rem !important;
    }
    .PrimaryTheme .p-xl-7 {
        padding: 2.5rem !important;
    }
    .PrimaryTheme .pt-xl-7, .PrimaryTheme .py-xl-7 {
        padding-top: 2.5rem !important;
    }
    .PrimaryTheme .pr-xl-7, .PrimaryTheme .px-xl-7 {
        padding-right: 2.5rem !important;
    }
    .PrimaryTheme .pb-xl-7, .PrimaryTheme .py-xl-7 {
        padding-bottom: 2.5rem !important;
    }
    .PrimaryTheme .pl-xl-7, .PrimaryTheme .px-xl-7 {
        padding-left: 2.5rem !important;
    }
    .PrimaryTheme .p-xl-8 {
        padding: 3rem !important;
    }
    .PrimaryTheme .pt-xl-8, .PrimaryTheme .py-xl-8 {
        padding-top: 3rem !important;
    }
    .PrimaryTheme .pr-xl-8, .PrimaryTheme .px-xl-8 {
        padding-right: 3rem !important;
    }
    .PrimaryTheme .pb-xl-8, .PrimaryTheme .py-xl-8 {
        padding-bottom: 3rem !important;
    }
    .PrimaryTheme .pl-xl-8, .PrimaryTheme .px-xl-8 {
        padding-left: 3rem !important;
    }
    .PrimaryTheme .p-xl-9 {
        padding: 4rem !important;
    }
    .PrimaryTheme .pt-xl-9, .PrimaryTheme .py-xl-9 {
        padding-top: 4rem !important;
    }
    .PrimaryTheme .pr-xl-9, .PrimaryTheme .px-xl-9 {
        padding-right: 4rem !important;
    }
    .PrimaryTheme .pb-xl-9, .PrimaryTheme .py-xl-9 {
        padding-bottom: 4rem !important;
    }
    .PrimaryTheme .pl-xl-9, .PrimaryTheme .px-xl-9 {
        padding-left: 4rem !important;
    }
    .PrimaryTheme .p-xl-10 {
        padding: 5rem !important;
    }
    .PrimaryTheme .pt-xl-10, .PrimaryTheme .py-xl-10 {
        padding-top: 5rem !important;
    }
    .PrimaryTheme .pr-xl-10, .PrimaryTheme .px-xl-10 {
        padding-right: 5rem !important;
    }
    .PrimaryTheme .pb-xl-10, .PrimaryTheme .py-xl-10 {
        padding-bottom: 5rem !important;
    }
    .PrimaryTheme .pl-xl-10, .PrimaryTheme .px-xl-10 {
        padding-left: 5rem !important;
    }
    .PrimaryTheme .p-xl-11 {
        padding: 6rem !important;
    }
    .PrimaryTheme .pt-xl-11, .PrimaryTheme .py-xl-11 {
        padding-top: 6rem !important;
    }
    .PrimaryTheme .pr-xl-11, .PrimaryTheme .px-xl-11 {
        padding-right: 6rem !important;
    }
    .PrimaryTheme .pb-xl-11, .PrimaryTheme .py-xl-11 {
        padding-bottom: 6rem !important;
    }
    .PrimaryTheme .pl-xl-11, .PrimaryTheme .px-xl-11 {
        padding-left: 6rem !important;
    }
    .PrimaryTheme .p-xl-12 {
        padding: 8rem !important;
    }
    .PrimaryTheme .pt-xl-12, .PrimaryTheme .py-xl-12 {
        padding-top: 8rem !important;
    }
    .PrimaryTheme .pr-xl-12, .PrimaryTheme .px-xl-12 {
        padding-right: 8rem !important;
    }
    .PrimaryTheme .pb-xl-12, .PrimaryTheme .py-xl-12 {
        padding-bottom: 8rem !important;
    }
    .PrimaryTheme .pl-xl-12, .PrimaryTheme .px-xl-12 {
        padding-left: 8rem !important;
    }
    .PrimaryTheme .p-xl-13 {
        padding: 10rem !important;
    }
    .PrimaryTheme .pt-xl-13, .PrimaryTheme .py-xl-13 {
        padding-top: 10rem !important;
    }
    .PrimaryTheme .pr-xl-13, .PrimaryTheme .px-xl-13 {
        padding-right: 10rem !important;
    }
    .PrimaryTheme .pb-xl-13, .PrimaryTheme .py-xl-13 {
        padding-bottom: 10rem !important;
    }
    .PrimaryTheme .pl-xl-13, .PrimaryTheme .px-xl-13 {
        padding-left: 10rem !important;
    }
    .PrimaryTheme .p-xl-14 {
        padding: 12rem !important;
    }
    .PrimaryTheme .pt-xl-14, .PrimaryTheme .py-xl-14 {
        padding-top: 12rem !important;
    }
    .PrimaryTheme .pr-xl-14, .PrimaryTheme .px-xl-14 {
        padding-right: 12rem !important;
    }
    .PrimaryTheme .pb-xl-14, .PrimaryTheme .py-xl-14 {
        padding-bottom: 12rem !important;
    }
    .PrimaryTheme .pl-xl-14, .PrimaryTheme .px-xl-14 {
        padding-left: 12rem !important;
    }
    .PrimaryTheme .p-xl-15 {
        padding: 16rem !important;
    }
    .PrimaryTheme .pt-xl-15, .PrimaryTheme .py-xl-15 {
        padding-top: 16rem !important;
    }
    .PrimaryTheme .pr-xl-15, .PrimaryTheme .px-xl-15 {
        padding-right: 16rem !important;
    }
    .PrimaryTheme .pb-xl-15, .PrimaryTheme .py-xl-15 {
        padding-bottom: 16rem !important;
    }
    .PrimaryTheme .pl-xl-15, .PrimaryTheme .px-xl-15 {
        padding-left: 16rem !important;
    }
    .PrimaryTheme .p-xl-16 {
        padding: 25rem !important;
    }
    .PrimaryTheme .pt-xl-16, .PrimaryTheme .py-xl-16 {
        padding-top: 25rem !important;
    }
    .PrimaryTheme .pr-xl-16, .PrimaryTheme .px-xl-16 {
        padding-right: 25rem !important;
    }
    .PrimaryTheme .pb-xl-16, .PrimaryTheme .py-xl-16 {
        padding-bottom: 25rem !important;
    }
    .PrimaryTheme .pl-xl-16, .PrimaryTheme .px-xl-16 {
        padding-left: 25rem !important;
    }
    .PrimaryTheme .m-xl-n1 {
        margin: -.25rem !important;
    }
    .PrimaryTheme .mt-xl-n1, .PrimaryTheme .my-xl-n1 {
        margin-top: -.25rem !important;
    }
    .PrimaryTheme .mr-xl-n1, .PrimaryTheme .mx-xl-n1 {
        margin-right: -.25rem !important;
    }
    .PrimaryTheme .mb-xl-n1, .PrimaryTheme .my-xl-n1 {
        margin-bottom: -.25rem !important;
    }
    .PrimaryTheme .ml-xl-n1, .PrimaryTheme .mx-xl-n1 {
        margin-left: -.25rem !important;
    }
    .PrimaryTheme .m-xl-n2 {
        margin: -.5rem !important;
    }
    .PrimaryTheme .mt-xl-n2, .PrimaryTheme .my-xl-n2 {
        margin-top: -.5rem !important;
    }
    .PrimaryTheme .mr-xl-n2, .PrimaryTheme .mx-xl-n2 {
        margin-right: -.5rem !important;
    }
    .PrimaryTheme .mb-xl-n2, .PrimaryTheme .my-xl-n2 {
        margin-bottom: -.5rem !important;
    }
    .PrimaryTheme .ml-xl-n2, .PrimaryTheme .mx-xl-n2 {
        margin-left: -.5rem !important;
    }
    .PrimaryTheme .m-xl-n3 {
        margin: -.75rem !important;
    }
    .PrimaryTheme .mt-xl-n3, .PrimaryTheme .my-xl-n3 {
        margin-top: -.75rem !important;
    }
    .PrimaryTheme .mr-xl-n3, .PrimaryTheme .mx-xl-n3 {
        margin-right: -.75rem !important;
    }
    .PrimaryTheme .mb-xl-n3, .PrimaryTheme .my-xl-n3 {
        margin-bottom: -.75rem !important;
    }
    .PrimaryTheme .ml-xl-n3, .PrimaryTheme .mx-xl-n3 {
        margin-left: -.75rem !important;
    }
    .PrimaryTheme .m-xl-n4 {
        margin: -1rem !important;
    }
    .PrimaryTheme .mt-xl-n4, .PrimaryTheme .my-xl-n4 {
        margin-top: -1rem !important;
    }
    .PrimaryTheme .mr-xl-n4, .PrimaryTheme .mx-xl-n4 {
        margin-right: -1rem !important;
    }
    .PrimaryTheme .mb-xl-n4, .PrimaryTheme .my-xl-n4 {
        margin-bottom: -1rem !important;
    }
    .PrimaryTheme .ml-xl-n4, .PrimaryTheme .mx-xl-n4 {
        margin-left: -1rem !important;
    }
    .PrimaryTheme .m-xl-n5 {
        margin: -1.5rem !important;
    }
    .PrimaryTheme .mt-xl-n5, .PrimaryTheme .my-xl-n5 {
        margin-top: -1.5rem !important;
    }
    .PrimaryTheme .mr-xl-n5, .PrimaryTheme .mx-xl-n5 {
        margin-right: -1.5rem !important;
    }
    .PrimaryTheme .mb-xl-n5, .PrimaryTheme .my-xl-n5 {
        margin-bottom: -1.5rem !important;
    }
    .PrimaryTheme .ml-xl-n5, .PrimaryTheme .mx-xl-n5 {
        margin-left: -1.5rem !important;
    }
    .PrimaryTheme .m-xl-n6 {
        margin: -2rem !important;
    }
    .PrimaryTheme .mt-xl-n6, .PrimaryTheme .my-xl-n6 {
        margin-top: -2rem !important;
    }
    .PrimaryTheme .mr-xl-n6, .PrimaryTheme .mx-xl-n6 {
        margin-right: -2rem !important;
    }
    .PrimaryTheme .mb-xl-n6, .PrimaryTheme .my-xl-n6 {
        margin-bottom: -2rem !important;
    }
    .PrimaryTheme .ml-xl-n6, .PrimaryTheme .mx-xl-n6 {
        margin-left: -2rem !important;
    }
    .PrimaryTheme .m-xl-n7 {
        margin: -2.5rem !important;
    }
    .PrimaryTheme .mt-xl-n7, .PrimaryTheme .my-xl-n7 {
        margin-top: -2.5rem !important;
    }
    .PrimaryTheme .mr-xl-n7, .PrimaryTheme .mx-xl-n7 {
        margin-right: -2.5rem !important;
    }
    .PrimaryTheme .mb-xl-n7, .PrimaryTheme .my-xl-n7 {
        margin-bottom: -2.5rem !important;
    }
    .PrimaryTheme .ml-xl-n7, .PrimaryTheme .mx-xl-n7 {
        margin-left: -2.5rem !important;
    }
    .PrimaryTheme .m-xl-n8 {
        margin: -3rem !important;
    }
    .PrimaryTheme .mt-xl-n8, .PrimaryTheme .my-xl-n8 {
        margin-top: -3rem !important;
    }
    .PrimaryTheme .mr-xl-n8, .PrimaryTheme .mx-xl-n8 {
        margin-right: -3rem !important;
    }
    .PrimaryTheme .mb-xl-n8, .PrimaryTheme .my-xl-n8 {
        margin-bottom: -3rem !important;
    }
    .PrimaryTheme .ml-xl-n8, .PrimaryTheme .mx-xl-n8 {
        margin-left: -3rem !important;
    }
    .PrimaryTheme .m-xl-n9 {
        margin: -4rem !important;
    }
    .PrimaryTheme .mt-xl-n9, .PrimaryTheme .my-xl-n9 {
        margin-top: -4rem !important;
    }
    .PrimaryTheme .mr-xl-n9, .PrimaryTheme .mx-xl-n9 {
        margin-right: -4rem !important;
    }
    .PrimaryTheme .mb-xl-n9, .PrimaryTheme .my-xl-n9 {
        margin-bottom: -4rem !important;
    }
    .PrimaryTheme .ml-xl-n9, .PrimaryTheme .mx-xl-n9 {
        margin-left: -4rem !important;
    }
    .PrimaryTheme .m-xl-n10 {
        margin: -5rem !important;
    }
    .PrimaryTheme .mt-xl-n10, .PrimaryTheme .my-xl-n10 {
        margin-top: -5rem !important;
    }
    .PrimaryTheme .mr-xl-n10, .PrimaryTheme .mx-xl-n10 {
        margin-right: -5rem !important;
    }
    .PrimaryTheme .mb-xl-n10, .PrimaryTheme .my-xl-n10 {
        margin-bottom: -5rem !important;
    }
    .PrimaryTheme .ml-xl-n10, .PrimaryTheme .mx-xl-n10 {
        margin-left: -5rem !important;
    }
    .PrimaryTheme .m-xl-n11 {
        margin: -6rem !important;
    }
    .PrimaryTheme .mt-xl-n11, .PrimaryTheme .my-xl-n11 {
        margin-top: -6rem !important;
    }
    .PrimaryTheme .mr-xl-n11, .PrimaryTheme .mx-xl-n11 {
        margin-right: -6rem !important;
    }
    .PrimaryTheme .mb-xl-n11, .PrimaryTheme .my-xl-n11 {
        margin-bottom: -6rem !important;
    }
    .PrimaryTheme .ml-xl-n11, .PrimaryTheme .mx-xl-n11 {
        margin-left: -6rem !important;
    }
    .PrimaryTheme .m-xl-n12 {
        margin: -8rem !important;
    }
    .PrimaryTheme .mt-xl-n12, .PrimaryTheme .my-xl-n12 {
        margin-top: -8rem !important;
    }
    .PrimaryTheme .mr-xl-n12, .PrimaryTheme .mx-xl-n12 {
        margin-right: -8rem !important;
    }
    .PrimaryTheme .mb-xl-n12, .PrimaryTheme .my-xl-n12 {
        margin-bottom: -8rem !important;
    }
    .PrimaryTheme .ml-xl-n12, .PrimaryTheme .mx-xl-n12 {
        margin-left: -8rem !important;
    }
    .PrimaryTheme .m-xl-n13 {
        margin: -10rem !important;
    }
    .PrimaryTheme .mt-xl-n13, .PrimaryTheme .my-xl-n13 {
        margin-top: -10rem !important;
    }
    .PrimaryTheme .mr-xl-n13, .PrimaryTheme .mx-xl-n13 {
        margin-right: -10rem !important;
    }
    .PrimaryTheme .mb-xl-n13, .PrimaryTheme .my-xl-n13 {
        margin-bottom: -10rem !important;
    }
    .PrimaryTheme .ml-xl-n13, .PrimaryTheme .mx-xl-n13 {
        margin-left: -10rem !important;
    }
    .PrimaryTheme .m-xl-n14 {
        margin: -12rem !important;
    }
    .PrimaryTheme .mt-xl-n14, .PrimaryTheme .my-xl-n14 {
        margin-top: -12rem !important;
    }
    .PrimaryTheme .mr-xl-n14, .PrimaryTheme .mx-xl-n14 {
        margin-right: -12rem !important;
    }
    .PrimaryTheme .mb-xl-n14, .PrimaryTheme .my-xl-n14 {
        margin-bottom: -12rem !important;
    }
    .PrimaryTheme .ml-xl-n14, .PrimaryTheme .mx-xl-n14 {
        margin-left: -12rem !important;
    }
    .PrimaryTheme .m-xl-n15 {
        margin: -16rem !important;
    }
    .PrimaryTheme .mt-xl-n15, .PrimaryTheme .my-xl-n15 {
        margin-top: -16rem !important;
    }
    .PrimaryTheme .mr-xl-n15, .PrimaryTheme .mx-xl-n15 {
        margin-right: -16rem !important;
    }
    .PrimaryTheme .mb-xl-n15, .PrimaryTheme .my-xl-n15 {
        margin-bottom: -16rem !important;
    }
    .PrimaryTheme .ml-xl-n15, .PrimaryTheme .mx-xl-n15 {
        margin-left: -16rem !important;
    }
    .PrimaryTheme .m-xl-n16 {
        margin: -25rem !important;
    }
    .PrimaryTheme .mt-xl-n16, .PrimaryTheme .my-xl-n16 {
        margin-top: -25rem !important;
    }
    .PrimaryTheme .mr-xl-n16, .PrimaryTheme .mx-xl-n16 {
        margin-right: -25rem !important;
    }
    .PrimaryTheme .mb-xl-n16, .PrimaryTheme .my-xl-n16 {
        margin-bottom: -25rem !important;
    }
    .PrimaryTheme .ml-xl-n16, .PrimaryTheme .mx-xl-n16 {
        margin-left: -25rem !important;
    }
    .PrimaryTheme .m-xl-auto {
        margin: auto !important;
    }
    .PrimaryTheme .mt-xl-auto, .PrimaryTheme .my-xl-auto {
        margin-top: auto !important;
    }
    .PrimaryTheme .mr-xl-auto, .PrimaryTheme .mx-xl-auto {
        margin-right: auto !important;
    }
    .PrimaryTheme .mb-xl-auto, .PrimaryTheme .my-xl-auto {
        margin-bottom: auto !important;
    }
    .PrimaryTheme .ml-xl-auto, .PrimaryTheme .mx-xl-auto {
        margin-left: auto !important;
    }
}

.PrimaryTheme .text-monospace {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
}

.PrimaryTheme .text-justify {
    text-align: justify !important;
}

.PrimaryTheme .text-wrap {
    white-space: normal !important;
}

.PrimaryTheme .text-nowrap {
    white-space: nowrap !important;
}

.PrimaryTheme .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.PrimaryTheme .text-left {
    text-align: left !important;
}

.PrimaryTheme .text-right {
    text-align: right !important;
}

.PrimaryTheme .text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .text-sm-left {
        text-align: left !important;
    }
    .PrimaryTheme .text-sm-right {
        text-align: right !important;
    }
    .PrimaryTheme .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .text-md-left {
        text-align: left !important;
    }
    .PrimaryTheme .text-md-right {
        text-align: right !important;
    }
    .PrimaryTheme .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .text-lg-left {
        text-align: left !important;
    }
    .PrimaryTheme .text-lg-right {
        text-align: right !important;
    }
    .PrimaryTheme .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .text-xl-left {
        text-align: left !important;
    }
    .PrimaryTheme .text-xl-right {
        text-align: right !important;
    }
    .PrimaryTheme .text-xl-center {
        text-align: center !important;
    }
}

.PrimaryTheme .text-lowercase {
    text-transform: lowercase !important;
}

.PrimaryTheme .text-uppercase {
    text-transform: uppercase !important;
}

.PrimaryTheme .text-capitalize {
    text-transform: capitalize !important;
}

.PrimaryTheme .font-weight-light {
    font-weight: 300 !important;
}

.PrimaryTheme .font-weight-lighter {
    font-weight: lighter !important;
}

.PrimaryTheme .font-weight-normal {
    font-weight: 400 !important;
}

.PrimaryTheme .font-weight-bold {
    font-weight: 600 !important;
}

.PrimaryTheme .font-weight-bolder {
    font-weight: bolder !important;
}

.PrimaryTheme .font-italic {
    font-style: italic !important;
}

.PrimaryTheme .text-white {
    color: #fff !important;
}

.PrimaryTheme .text-primary {
    color: #335eea !important;
}

.PrimaryTheme a.text-primary:focus, .PrimaryTheme a.text-primary:hover {
    color: #133bbd !important;
}

.PrimaryTheme .text-secondary {
    color: #506690 !important;
}

.PrimaryTheme a.text-secondary:focus, .PrimaryTheme a.text-secondary:hover {
    color: #35435f !important;
}

.PrimaryTheme .text-success {
    color: #42ba96 !important;
}

.PrimaryTheme a.text-success:focus, .PrimaryTheme a.text-success:hover {
    color: #2e8268 !important;
}

.PrimaryTheme .text-info {
    color: #7c69ef !important;
}

.PrimaryTheme a.text-info:focus, .PrimaryTheme a.text-info:hover {
    color: #4024e8 !important;
}

.PrimaryTheme .text-warning {
    color: #fad776 !important;
}

.PrimaryTheme a.text-warning:focus, .PrimaryTheme a.text-warning:hover {
    color: #f7c12c !important;
}

.PrimaryTheme .text-danger {
    color: #df4759 !important;
}

.PrimaryTheme a.text-danger:focus, .PrimaryTheme a.text-danger:hover {
    color: #b92032 !important;
}

.PrimaryTheme .text-light {
    color: #f9fbfd !important;
}

.PrimaryTheme a.text-light:focus, .PrimaryTheme a.text-light:hover {
    color: #c0d5ea !important;
}

.PrimaryTheme .text-dark {
    color: #1b2a4e !important;
}

.PrimaryTheme a.text-dark:focus, .PrimaryTheme a.text-dark:hover {
    color: #070b15 !important;
}

.PrimaryTheme .text-primary-desat {
    color: #6c8aec !important;
}

.PrimaryTheme a.text-primary-desat:focus, .PrimaryTheme a.text-primary-desat:hover {
    color: #2854e3 !important;
}

.PrimaryTheme .text-black {
    color: #161c2d !important;
}

.PrimaryTheme a.text-black:focus, .PrimaryTheme a.text-black:hover {
    color: #000 !important;
}

.PrimaryTheme .text-white {
    color: #fff !important;
}

.PrimaryTheme a.text-white:focus, .PrimaryTheme a.text-white:hover {
    color: #d9d9d9 !important;
}

.PrimaryTheme .text-body {
    color: #161c2d !important;
}

.PrimaryTheme .text-muted {
    color: #869ab8 !important;
}

.PrimaryTheme .text-black-50 {
    color: rgba(22, 28, 45, 0.5) !important;
}

.PrimaryTheme .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.PrimaryTheme .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.PrimaryTheme .text-decoration-none {
    text-decoration: none !important;
}

.PrimaryTheme .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.PrimaryTheme .text-reset {
    color: inherit !important;
}

.PrimaryTheme .visible {
    visibility: visible !important;
}

.PrimaryTheme .invisible {
    visibility: hidden !important;
}

@media print {
    .PrimaryTheme *, .PrimaryTheme ::after, .PrimaryTheme ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    .PrimaryTheme a:not(.btn) {
        text-decoration: underline;
    }
    .PrimaryTheme abbr[title]::after {
        content: " (" attr(title) ")";
    }
    .PrimaryTheme pre {
        white-space: pre-wrap !important;
    }
    .PrimaryTheme blockquote, .PrimaryTheme pre {
        border: 1px solid #abbcd5;
        page-break-inside: avoid;
    }
    .PrimaryTheme thead {
        display: table-header-group;
    }
    .PrimaryTheme img, .PrimaryTheme tr {
        page-break-inside: avoid;
    }
    .PrimaryTheme h2, .PrimaryTheme h3, .PrimaryTheme p {
        orphans: 3;
        widows: 3;
    }
    .PrimaryTheme h2, .PrimaryTheme h3 {
        page-break-after: avoid;
    }
    @page {
        .PrimaryTheme {
            size: a3;
        }
    }
    .PrimaryTheme body {
        min-width: 992px !important;
    }
    .PrimaryTheme .container {
        min-width: 992px !important;
    }
    .PrimaryTheme .navbar {
        display: none;
    }
    .PrimaryTheme .badge {
        border: 1px solid #161c2d;
    }
    .PrimaryTheme .table {
        border-collapse: collapse !important;
    }
    .PrimaryTheme .table td, .PrimaryTheme .table th {
        background-color: #fff !important;
    }
    .PrimaryTheme .table-bordered td, .PrimaryTheme .table-bordered th {
        border: 1px solid #d9e2ef !important;
    }
    .PrimaryTheme .table-dark {
        color: inherit;
    }
    .PrimaryTheme .table-dark tbody + tbody, .PrimaryTheme .table-dark td, .PrimaryTheme .table-dark th, .PrimaryTheme .table-dark thead th {
        border-color: #f1f4f8;
    }
    .PrimaryTheme .table .thead-dark th {
        color: inherit;
        border-color: #f1f4f8;
    }
}

.PrimaryTheme .alert-primary {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea;
}

.PrimaryTheme .alert-primary hr {
    border-top-color: #1c4ce8;
}

.PrimaryTheme .alert-primary .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-primary .alert-link, .PrimaryTheme .alert-primary .close {
    color: #fff;
}

.PrimaryTheme .alert-secondary {
    color: #fff;
    background-color: #506690;
    border-color: #506690;
}

.PrimaryTheme .alert-secondary hr {
    border-top-color: #475a80;
}

.PrimaryTheme .alert-secondary .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-secondary .alert-link, .PrimaryTheme .alert-secondary .close {
    color: #fff;
}

.PrimaryTheme .alert-success {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96;
}

.PrimaryTheme .alert-success hr {
    border-top-color: #3ba787;
}

.PrimaryTheme .alert-success .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-success .alert-link, .PrimaryTheme .alert-success .close {
    color: #fff;
}

.PrimaryTheme .alert-info {
    color: #fff;
    background-color: #7c69ef;
    border-color: #7c69ef;
}

.PrimaryTheme .alert-info hr {
    border-top-color: #6852ed;
}

.PrimaryTheme .alert-info .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-info .alert-link, .PrimaryTheme .alert-info .close {
    color: #fff;
}

.PrimaryTheme .alert-warning {
    color: #1b2a4e;
    background-color: #fad776;
    border-color: #fad776;
}

.PrimaryTheme .alert-warning hr {
    border-top-color: #f9d05d;
}

.PrimaryTheme .alert-warning .alert-link {
    color: #0e1628;
}

.PrimaryTheme .alert-warning .alert-link, .PrimaryTheme .alert-warning .close {
    color: #1b2a4e;
}

.PrimaryTheme .alert-danger {
    color: #fff;
    background-color: #df4759;
    border-color: #df4759;
}

.PrimaryTheme .alert-danger hr {
    border-top-color: #db3145;
}

.PrimaryTheme .alert-danger .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-danger .alert-link, .PrimaryTheme .alert-danger .close {
    color: #fff;
}

.PrimaryTheme .alert-light {
    color: #1b2a4e;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
}

.PrimaryTheme .alert-light hr {
    border-top-color: #e6eef7;
}

.PrimaryTheme .alert-light .alert-link {
    color: #0e1628;
}

.PrimaryTheme .alert-light .alert-link, .PrimaryTheme .alert-light .close {
    color: #1b2a4e;
}

.PrimaryTheme .alert-dark {
    color: #fff;
    background-color: #1b2a4e;
    border-color: #1b2a4e;
}

.PrimaryTheme .alert-dark hr {
    border-top-color: #14203b;
}

.PrimaryTheme .alert-dark .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-dark .alert-link, .PrimaryTheme .alert-dark .close {
    color: #fff;
}

.PrimaryTheme .alert-primary-desat {
    color: #fff;
    background-color: #6c8aec;
    border-color: #6c8aec;
}

.PrimaryTheme .alert-primary-desat hr {
    border-top-color: #5578e9;
}

.PrimaryTheme .alert-primary-desat .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-primary-desat .alert-link, .PrimaryTheme .alert-primary-desat .close {
    color: #fff;
}

.PrimaryTheme .alert-black {
    color: #fff;
    background-color: #161c2d;
    border-color: #161c2d;
}

.PrimaryTheme .alert-black hr {
    border-top-color: #0e111c;
}

.PrimaryTheme .alert-black .alert-link {
    color: #e6e6e6;
}

.PrimaryTheme .alert-black .alert-link, .PrimaryTheme .alert-black .close {
    color: #fff;
}

.PrimaryTheme .alert-white {
    color: #1b2a4e;
    background-color: #fff;
    border-color: #fff;
}

.PrimaryTheme .alert-white hr {
    border-top-color: #f2f2f2;
}

.PrimaryTheme .alert-white .alert-link {
    color: #0e1628;
}

.PrimaryTheme .alert-white .alert-link, .PrimaryTheme .alert-white .close {
    color: #1b2a4e;
}

.PrimaryTheme .badge-primary-soft {
    background-color: rgba(51, 94, 234, 0.1);
    color: #335eea;
}

.PrimaryTheme a.badge-primary-soft:focus, .PrimaryTheme a.badge-primary-soft:hover {
    background-color: rgba(51, 94, 234, 0.2);
    color: #335eea;
}

.PrimaryTheme .badge-secondary-soft {
    background-color: rgba(80, 102, 144, 0.1);
    color: #506690;
}

.PrimaryTheme a.badge-secondary-soft:focus, .PrimaryTheme a.badge-secondary-soft:hover {
    background-color: rgba(80, 102, 144, 0.2);
    color: #506690;
}

.PrimaryTheme .badge-success-soft {
    background-color: rgba(66, 186, 150, 0.1);
    color: #42ba96;
}

.PrimaryTheme a.badge-success-soft:focus, .PrimaryTheme a.badge-success-soft:hover {
    background-color: rgba(66, 186, 150, 0.2);
    color: #42ba96;
}

.PrimaryTheme .badge-info-soft {
    background-color: rgba(124, 105, 239, 0.1);
    color: #7c69ef;
}

.PrimaryTheme a.badge-info-soft:focus, .PrimaryTheme a.badge-info-soft:hover {
    background-color: rgba(124, 105, 239, 0.2);
    color: #7c69ef;
}

.PrimaryTheme .badge-warning-soft {
    background-color: rgba(250, 215, 118, 0.1);
    color: #fad776;
}

.PrimaryTheme a.badge-warning-soft:focus, .PrimaryTheme a.badge-warning-soft:hover {
    background-color: rgba(250, 215, 118, 0.2);
    color: #fad776;
}

.PrimaryTheme .badge-danger-soft {
    background-color: rgba(223, 71, 89, 0.1);
    color: #df4759;
}

.PrimaryTheme a.badge-danger-soft:focus, .PrimaryTheme a.badge-danger-soft:hover {
    background-color: rgba(223, 71, 89, 0.2);
    color: #df4759;
}

.PrimaryTheme .badge-light-soft {
    background-color: rgba(249, 251, 253, 0.1);
    color: #f9fbfd;
}

.PrimaryTheme a.badge-light-soft:focus, .PrimaryTheme a.badge-light-soft:hover {
    background-color: rgba(249, 251, 253, 0.2);
    color: #f9fbfd;
}

.PrimaryTheme .badge-dark-soft {
    background-color: rgba(27, 42, 78, 0.1);
    color: #1b2a4e;
}

.PrimaryTheme a.badge-dark-soft:focus, .PrimaryTheme a.badge-dark-soft:hover {
    background-color: rgba(27, 42, 78, 0.2);
    color: #1b2a4e;
}

.PrimaryTheme .badge-primary-desat-soft {
    background-color: rgba(108, 138, 236, 0.1);
    color: #6c8aec;
}

.PrimaryTheme a.badge-primary-desat-soft:focus, .PrimaryTheme a.badge-primary-desat-soft:hover {
    background-color: rgba(108, 138, 236, 0.2);
    color: #6c8aec;
}

.PrimaryTheme .badge-black-soft {
    background-color: rgba(22, 28, 45, 0.1);
    color: #161c2d;
}

.PrimaryTheme a.badge-black-soft:focus, .PrimaryTheme a.badge-black-soft:hover {
    background-color: rgba(22, 28, 45, 0.2);
    color: #161c2d;
}

.PrimaryTheme .badge-white-soft {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.PrimaryTheme a.badge-white-soft:focus, .PrimaryTheme a.badge-white-soft:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.PrimaryTheme .badge-gray-700-soft {
    background-color: rgba(80, 102, 144, 0.1);
    color: #506690;
}

.PrimaryTheme a.badge-gray-700-soft:focus, .PrimaryTheme a.badge-gray-700-soft:hover {
    background-color: rgba(80, 102, 144, 0.2);
    color: #506690;
}

.PrimaryTheme .badge-gray-600 {
    background-color: #869ab8;
    color: #fff;
}

.PrimaryTheme .badge-lg {
    padding: .7em 1.15em;
}

.PrimaryTheme .badge-rounded-circle {
    height: calc(1em + .5rem);
    padding-left: .25rem;
    padding-right: .25rem;
    border-radius: 10rem;
}

.PrimaryTheme .badge-rounded-circle > * {
    display: block;
    width: 1em;
}

.PrimaryTheme .badge-rounded-circle.badge-lg {
    height: calc(1em + 1.4em);
    padding-left: .7em;
    padding-right: .7em;
}

.PrimaryTheme .badge-float {
    position: absolute;
    z-index: 1000;
}

.PrimaryTheme .badge-float-outside {
    top: -.5rem;
    right: -.5rem;
}

.PrimaryTheme .badge-float-inside {
    top: 1rem;
    right: 1rem;
}

.PrimaryTheme .badge-white-soft.active {
    background-color: #fff;
    color: #335eea;
}

.PrimaryTheme .badge-white-soft.active:focus, .PrimaryTheme .badge-white-soft.active:hover {
    background-color: #f2f2f2;
    color: #335eea;
}

.PrimaryTheme .badge-secondary-soft.active {
    background-color: #335eea;
    color: #fff;
}

.PrimaryTheme .badge-secondary-soft.active:focus, .PrimaryTheme .badge-secondary-soft.active:hover {
    background-color: #1c4ce8;
    color: #fff;
}

.PrimaryTheme .breadcrumb-item {
    font-size: .9375rem;
}

.PrimaryTheme .breadcrumb-item + .breadcrumb-item::before {
    content: "\e930";
    font-family: Feather;
}

.PrimaryTheme .breadcrumb-scroll {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
}

.PrimaryTheme .breadcrumb-scroll .breadcrumb-item {
    white-space: nowrap;
}

.PrimaryTheme .breadcrumb-scroll::-webkit-scrollbar {
    display: none;
}

.PrimaryTheme .btn > img {
    max-height: 1em;
    width: auto;
}

.PrimaryTheme .btn:not([class*=btn-outline]) {
    border-color: transparent !important;
}

.PrimaryTheme .btn-white {
    background-color: #fff;
    color: #335eea;
}

.PrimaryTheme .btn-white:focus, .PrimaryTheme .btn-white:hover {
    background-color: #fff;
    color: #1c4ce8;
}

.PrimaryTheme .btn-white.active {
    background-color: #f2f2f2;
    color: #1c4ce8;
}

.PrimaryTheme .btn-group .btn-white {
    border-color: #d9e2ef !important;
    color: #869ab8;
}

.PrimaryTheme .btn-group .btn-white.active {
    background-color: #f2f2f2;
    color: #768daf;
    box-shadow: none;
}

.PrimaryTheme .btn-gray-400 {
    background-color: #c6d3e6;
    color: #fff;
}

.PrimaryTheme .btn-gray-400:focus, .PrimaryTheme .btn-gray-400:hover {
    background-color: #b4c5de;
    color: #f2f2f2;
}

.PrimaryTheme .btn-gray-400.active {
    background-color: #b4c5de;
    color: #f2f2f2;
}

.PrimaryTheme .btn-outline-gray-300 {
    border-color: #d9e2ef;
    color: #335eea;
}

.PrimaryTheme .btn-outline-gray-300:focus, .PrimaryTheme .btn-outline-gray-300:hover {
    background-color: #335eea;
    border-color: #335eea;
    color: #fff;
}

.PrimaryTheme .btn-outline-gray-300.active {
    background-color: #335eea;
    border-color: #335eea;
    color: #fff;
}

.PrimaryTheme .btn-primary-soft {
    background-color: rgba(51, 94, 234, 0.1);
    color: #335eea;
}

.PrimaryTheme .btn-primary-soft:focus, .PrimaryTheme .btn-primary-soft:hover {
    background-color: rgba(51, 94, 234, 0.15);
    color: #335eea;
}

.PrimaryTheme .btn-primary-soft.active {
    background-color: rgba(51, 94, 234, 0.15);
    color: #335eea;
}

.PrimaryTheme .btn-secondary-soft {
    background-color: rgba(80, 102, 144, 0.1);
    color: #506690;
}

.PrimaryTheme .btn-secondary-soft:focus, .PrimaryTheme .btn-secondary-soft:hover {
    background-color: rgba(80, 102, 144, 0.15);
    color: #506690;
}

.PrimaryTheme .btn-secondary-soft.active {
    background-color: rgba(80, 102, 144, 0.15);
    color: #506690;
}

.PrimaryTheme .btn-success-soft {
    background-color: rgba(66, 186, 150, 0.1);
    color: #42ba96;
}

.PrimaryTheme .btn-success-soft:focus, .PrimaryTheme .btn-success-soft:hover {
    background-color: rgba(66, 186, 150, 0.15);
    color: #42ba96;
}

.PrimaryTheme .btn-success-soft.active {
    background-color: rgba(66, 186, 150, 0.15);
    color: #42ba96;
}

.PrimaryTheme .btn-info-soft {
    background-color: rgba(124, 105, 239, 0.1);
    color: #7c69ef;
}

.PrimaryTheme .btn-info-soft:focus, .PrimaryTheme .btn-info-soft:hover {
    background-color: rgba(124, 105, 239, 0.15);
    color: #7c69ef;
}

.PrimaryTheme .btn-info-soft.active {
    background-color: rgba(124, 105, 239, 0.15);
    color: #7c69ef;
}

.PrimaryTheme .btn-warning-soft {
    background-color: rgba(250, 215, 118, 0.1);
    color: #fad776;
}

.PrimaryTheme .btn-warning-soft:focus, .PrimaryTheme .btn-warning-soft:hover {
    background-color: rgba(250, 215, 118, 0.15);
    color: #fad776;
}

.PrimaryTheme .btn-warning-soft.active {
    background-color: rgba(250, 215, 118, 0.15);
    color: #fad776;
}

.PrimaryTheme .btn-danger-soft {
    background-color: rgba(223, 71, 89, 0.1);
    color: #df4759;
}

.PrimaryTheme .btn-danger-soft:focus, .PrimaryTheme .btn-danger-soft:hover {
    background-color: rgba(223, 71, 89, 0.15);
    color: #df4759;
}

.PrimaryTheme .btn-danger-soft.active {
    background-color: rgba(223, 71, 89, 0.15);
    color: #df4759;
}

.PrimaryTheme .btn-light-soft {
    background-color: rgba(249, 251, 253, 0.1);
    color: #f9fbfd;
}

.PrimaryTheme .btn-light-soft:focus, .PrimaryTheme .btn-light-soft:hover {
    background-color: rgba(249, 251, 253, 0.15);
    color: #f9fbfd;
}

.PrimaryTheme .btn-light-soft.active {
    background-color: rgba(249, 251, 253, 0.15);
    color: #f9fbfd;
}

.PrimaryTheme .btn-dark-soft {
    background-color: rgba(27, 42, 78, 0.1);
    color: #1b2a4e;
}

.PrimaryTheme .btn-dark-soft:focus, .PrimaryTheme .btn-dark-soft:hover {
    background-color: rgba(27, 42, 78, 0.15);
    color: #1b2a4e;
}

.PrimaryTheme .btn-dark-soft.active {
    background-color: rgba(27, 42, 78, 0.15);
    color: #1b2a4e;
}

.PrimaryTheme .btn-primary-desat-soft {
    background-color: rgba(108, 138, 236, 0.1);
    color: #6c8aec;
}

.PrimaryTheme .btn-primary-desat-soft:focus, .PrimaryTheme .btn-primary-desat-soft:hover {
    background-color: rgba(108, 138, 236, 0.15);
    color: #6c8aec;
}

.PrimaryTheme .btn-primary-desat-soft.active {
    background-color: rgba(108, 138, 236, 0.15);
    color: #6c8aec;
}

.PrimaryTheme .btn-black-soft {
    background-color: rgba(22, 28, 45, 0.1);
    color: #161c2d;
}

.PrimaryTheme .btn-black-soft:focus, .PrimaryTheme .btn-black-soft:hover {
    background-color: rgba(22, 28, 45, 0.15);
    color: #161c2d;
}

.PrimaryTheme .btn-black-soft.active {
    background-color: rgba(22, 28, 45, 0.15);
    color: #161c2d;
}

.PrimaryTheme .btn-white-soft {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.PrimaryTheme .btn-white-soft:focus, .PrimaryTheme .btn-white-soft:hover {
    background-color: rgba(255, 255, 255, 0.15);
    color: #fff;
}

.PrimaryTheme .btn-white-soft.active {
    background-color: rgba(255, 255, 255, 0.15);
    color: #fff;
}

.PrimaryTheme .btn-pill {
    border-radius: 50rem;
}

.PrimaryTheme .btn-rounded-circle {
    padding-left: .8125rem;
    padding-right: .8125rem;
    border-radius: 50rem;
}

.PrimaryTheme .btn-rounded-circle > * {
    display: block;
    line-height: 1;
    width: 1em;
}

.PrimaryTheme .btn-group-lg > .btn-rounded-circle.btn, .PrimaryTheme .btn-rounded-circle.btn-lg {
    padding-left: 1.122rem;
    padding-right: 1.122rem;
}

.PrimaryTheme .btn-group-sm > .btn-rounded-circle.btn, .PrimaryTheme .btn-rounded-circle.btn-sm {
    padding-left: .5625rem;
    padding-right: .5625rem;
}

.PrimaryTheme .card {
    position: relative;
    width: 100%;
}

.PrimaryTheme .card-body {
    display: block;
    -webkit-box-flex: 0;
    flex-grow: 0;
}

.PrimaryTheme .card-footer {
    background-color: unset;
}

.PrimaryTheme .card-body, .PrimaryTheme .card-footer, .PrimaryTheme .card-img, .PrimaryTheme .card-img-left, .PrimaryTheme .card-img-right, .PrimaryTheme .card-img-top, .PrimaryTheme .card-meta {
    position: relative;
    min-height: 1px;
}

.PrimaryTheme a.card, .PrimaryTheme a.card-body, .PrimaryTheme a.card-footer, .PrimaryTheme a.card-meta {
    color: inherit;
}

.PrimaryTheme a.card-body:hover, .PrimaryTheme a.card-footer:hover, .PrimaryTheme a.card-meta:hover, .PrimaryTheme a.card:hover {
    text-decoration: none;
}

.PrimaryTheme .card-flush {
    background-color: unset;
}

.PrimaryTheme .card-flush > :not(.card-btn) {
    padding-left: 0;
    padding-right: 0;
}

.PrimaryTheme .card-border {
    position: relative;
}

.PrimaryTheme .card-border::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    border-top-width: 2px;
    border-bottom-width: calc(.375rem - 2px);
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: inherit;
    border-bottom-color: transparent;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}

.PrimaryTheme .card-border-lg::after {
    border-top-width: 3px;
    border-bottom-width: calc(.375rem - 3px);
}

.PrimaryTheme .card-border-xl::after {
    border-top-width: 4px;
    border-bottom-width: calc(.375rem - 3px);
}

@media (min-width: 768px) {
    .PrimaryTheme .card-row .card-body {
        padding: 4rem 2.5rem;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .card-row .card-meta {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}

.PrimaryTheme .card-img-right {
    border-radius: .375rem .375rem 0 0;
}

@media (min-width: 768px) {
    .PrimaryTheme .card-img-right {
        border-radius: 0 .375rem .375rem 0;
    }
}

.PrimaryTheme .card-img-left {
    border-radius: .375rem .375rem 0 0;
}

@media (min-width: 768px) {
    .PrimaryTheme .card-img-left {
        border-radius: .375rem 0 0 .375rem;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .card-img-slider {
        height: 100%;
        width: 100%;
    }
    .PrimaryTheme .card-img-slider * {
        height: inherit !important;
        width: inherit !important;
    }
}

.PrimaryTheme .card-img-overlay {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 2rem;
}

.PrimaryTheme .card-img-overlay .card-body {
    margin: -2rem;
    margin-top: auto;
    border-bottom-right-radius: calc(.375rem - 0px);
    border-bottom-left-radius: calc(.375rem - 0px);
}

.PrimaryTheme .card-img-overlay-hover {
    overflow: hidden;
}

.PrimaryTheme .card-img-overlay-hover .card-body {
    -webkit-transform: translateY(110%);
    transform: translateY(110%);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.PrimaryTheme .card-img-overlay-hover:hover .card-body {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .PrimaryTheme .card-group {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        flex-direction: column !important;
    }
    .PrimaryTheme .card-group > .card {
        -webkit-box-flex: 1;
        flex: auto;
    }
}

.PrimaryTheme .card-meta {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 2rem 1.5rem;
}

.PrimaryTheme .card-meta-divider {
    width: 100%;
    margin: 0 0 1.5rem;
}

.PrimaryTheme .card-btn:last-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.PrimaryTheme .card-zoom {
    overflow: hidden !important;
    border-radius: inherit;
}

.PrimaryTheme .card-zoom > [class*=card-img] {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform-origin: center center;
    transform-origin: center center;
}

.PrimaryTheme .card:hover > .card-zoom > [class*=card-img] {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.PrimaryTheme .close:focus, .PrimaryTheme .close:hover {
    outline: 0;
}

.PrimaryTheme .custom-switch {
    min-height: 1.375rem;
}

.PrimaryTheme .custom-switch .custom-control-label::before {
    top: .1625rem;
    height: 1.375rem;
    border-radius: .6875rem;
}

.PrimaryTheme .custom-switch .custom-control-label::after {
    top: .35rem;
    left: -2.8125rem;
    background-color: #fff;
}

.PrimaryTheme .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(1.125rem);
    transform: translateX(1.125rem);
}

.PrimaryTheme .custom-switch-dark .custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.2);
}

.PrimaryTheme .custom-switch-dark .custom-control-label::after {
    background-color: #fff;
}

.PrimaryTheme .custom-switch-dark .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #fff;
}

.PrimaryTheme .custom-switch-dark .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #335eea;
}

.PrimaryTheme .dropdown-menu {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.PrimaryTheme .dropdown-item {
    width: auto;
    min-width: 100%;
    margin-left: -1.75rem;
    margin-right: -1.75rem;
}

.PrimaryTheme .dropdown-item:focus, .PrimaryTheme .dropdown-item:hover {
    outline: 0;
}

.PrimaryTheme .dropdown-item + .dropdown-item {
    margin-top: .45rem;
}

.PrimaryTheme .dropdown-link {
    color: inherit;
}

.PrimaryTheme .dropdown-link:hover {
    text-decoration: inherit;
}

.PrimaryTheme .dropdown-link:focus, .PrimaryTheme .dropdown-link:hover {
    outline: 0;
}

.PrimaryTheme .dropdown-header {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
    padding-top: 0;
    padding-bottom: .5rem;
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.55;
    text-transform: uppercase;
    letter-spacing: .08em;
}

.PrimaryTheme .dropdown-toggle::after {
    font-family: Feather;
    vertical-align: middle;
    border: none !important;
    content: "\e92e";
}

.PrimaryTheme .dropright > .dropdown-toggle::after {
    content: "\e930";
}

.PrimaryTheme .dropdown-menu-md {
    min-width: 22rem;
}

.PrimaryTheme .dropdown-menu-lg {
    min-width: 35rem;
}

.PrimaryTheme .dropdown-menu-xl {
    min-width: 42rem;
}

.PrimaryTheme .dropdown-menu-lg, .PrimaryTheme .dropdown-menu-xl {
    padding: 2.375rem 2.5rem;
}

.PrimaryTheme .dropdown-menu-lg .dropdown-header, .PrimaryTheme .dropdown-menu-lg .dropdown-item, .PrimaryTheme .dropdown-menu-xl .dropdown-header, .PrimaryTheme .dropdown-menu-xl .dropdown-item {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.PrimaryTheme .dropright > .dropdown-menu {
    top: -1.625rem;
}

.PrimaryTheme .form-control-flush {
    padding-left: 0;
    padding-right: 0;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.PrimaryTheme .custom-file-label::after {
    font-weight: 600;
}

.PrimaryTheme .form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.PrimaryTheme .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    padding: .8125rem 1.25rem;
    margin-bottom: 0;
    line-height: 1.6;
    font-size: 1.0625rem;
    color: #869ab8;
    pointer-events: none;
    cursor: text;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.PrimaryTheme .form-label-group > .form-control-flush ~ label {
    padding-left: 0;
    padding-right: 0;
}

.PrimaryTheme .form-label-group > .form-control::-webkit-input-placeholder {
    color: transparent;
}

.PrimaryTheme .form-label-group > .form-control::-moz-placeholder {
    color: transparent;
}

.PrimaryTheme .form-label-group > .form-control:-ms-input-placeholder {
    color: transparent;
}

.PrimaryTheme .form-label-group > .form-control::-ms-input-placeholder {
    color: transparent;
}

.PrimaryTheme .form-label-group > .form-control::placeholder {
    color: transparent;
}

.PrimaryTheme .form-label-group > .form-control:not(:placeholder-shown) {
    padding-top: 1.21875rem;
    padding-bottom: .40625rem;
}

.PrimaryTheme .form-label-group > .form-control:not(:placeholder-shown) ~ label {
    padding-top: .00937rem;
    padding-bottom: .00937rem;
    font-size: .75rem;
}

@supports (-ms-ime-align: auto) {
    .PrimaryTheme .form-label-group > label {
        display: none;
    }
    .PrimaryTheme .form-label-group .form-control::-webkit-input-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control::-moz-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control:-ms-input-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control::-ms-input-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control::placeholder {
        color: #869ab8;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .PrimaryTheme .form-label-group > label {
        display: none;
    }
    .PrimaryTheme .form-label-group .form-control::-webkit-input-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control::-moz-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control:-ms-input-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control::-ms-input-placeholder {
        color: #869ab8;
    }
    .PrimaryTheme .form-label-group .form-control::placeholder {
        color: #869ab8;
    }
}

.PrimaryTheme .container, .PrimaryTheme .container-fluid, .PrimaryTheme .container-lg, .PrimaryTheme .container-md, .PrimaryTheme .container-sm, .PrimaryTheme .container-xl {
    position: relative;
    z-index: 1;
}

.PrimaryTheme .img-cover {
    width: 100vw;
    height: auto;
    margin-left: calc(50% - 50vw);
}

.PrimaryTheme .img-fluid > img, .PrimaryTheme .img-fluid > svg {
    max-width: inherit;
    height: inherit;
}

.PrimaryTheme .figure {
    display: block;
}

.PrimaryTheme .figure-img {
    margin-bottom: 1rem;
}

.PrimaryTheme .list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
}

.PrimaryTheme .list-group-flush .list-group-item:first-child {
    padding-top: 0 !important;
    border-top: 0;
}

.PrimaryTheme .list-group-flush .list-group-item:last-child {
    padding-bottom: 0 !important;
    border-bottom: 0;
}

.PrimaryTheme .modal-open .navbar.fixed-top {
    padding-right: inherit;
}

.PrimaryTheme .modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1000;
}

.PrimaryTheme .navbar {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transition-property: background-color,color;
    transition-property: background-color,color;
    z-index: 1030;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-dark .navbar-nav .nav-item .nav-link {
        color: #506690;
    }
    .PrimaryTheme .navbar-dark .navbar-nav .nav-item .nav-link:focus, .PrimaryTheme .navbar-dark .navbar-nav .nav-item .nav-link:hover {
        color: #335eea;
    }
    .PrimaryTheme .navbar-dark .navbar-nav .nav-item .nav-link.active, .PrimaryTheme .navbar-dark .navbar-nav .nav-item.active .nav-link {
        color: #335eea;
    }
    .PrimaryTheme .navbar-dark .navbar-collapse .navbar-toggler {
        color: #506690;
    }
}

.PrimaryTheme .navbar > .container, .PrimaryTheme .navbar > .container-fluid, .PrimaryTheme .navbar > .container-lg, .PrimaryTheme .navbar > .container-md, .PrimaryTheme .navbar > .container-sm, .PrimaryTheme .navbar > .container-xl {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.PrimaryTheme .navbar-brand {
    font-weight: 600;
}

.PrimaryTheme .navbar-brand-img {
    max-height: 2.4rem;
    width: auto;
}

.PrimaryTheme .navbar-dark .navbar-brand {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-btn {
        width: 100%;
        padding: .8125rem 1.25rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 1.0625rem;
    }
}

.PrimaryTheme .navbar-nav .nav-link {
    font-weight: 600;
}

.PrimaryTheme .navbar-nav .nav-link:focus, .PrimaryTheme .navbar-nav .nav-link:hover {
    outline: 0;
}

.PrimaryTheme .navbar-dark.fixed-top > .container {
    position: relative;
}

.PrimaryTheme .navbar-dark.fixed-top > .container::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1.25rem;
    left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        height: calc(100% - 2rem) !important;
        width: calc(100% - 2rem);
        background-color: #fff;
        border-radius: .375rem;
        box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .PrimaryTheme .navbar-collapse.collapsing, .PrimaryTheme .navbar-collapse.show {
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform-origin: top right;
        transform-origin: top right;
    }
    .PrimaryTheme .navbar-collapse.show {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    .PrimaryTheme .navbar-collapse.collapsing {
        opacity: 0;
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

.PrimaryTheme .navbar-collapse .navbar-toggler {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-collapse .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .nav-item {
        padding: 1.5rem;
    }
    .PrimaryTheme .navbar-nav .nav-item + .nav-item {
        border-top: 1px solid #f1f4f8;
    }
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .nav-link {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.PrimaryTheme .navbar-nav .dropdown-menu {
    box-shadow: none;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .dropdown-menu {
        min-width: 0;
        padding: 0;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-nav .dropdown-menu {
        box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    }
}

.PrimaryTheme .navbar-nav .dropdown > .dropdown-menu {
    display: block !important;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .dropright > .dropdown-menu {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: .5rem;
    }
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .dropdown-header, .PrimaryTheme .navbar-nav .dropdown-item {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.PrimaryTheme .navbar-nav .dropdown > .dropdown-toggle::after {
    display: none;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .dropdown > .dropdown-toggle {
        margin-bottom: 1.5rem;
        pointer-events: none;
    }
}

.PrimaryTheme .navbar-nav .dropright > .dropdown-toggle {
    display: -webkit-box;
    display: flex;
}

.PrimaryTheme .navbar-nav .dropright > .dropdown-toggle::after {
    margin-left: auto;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .navbar-nav .dropright > .dropdown-toggle::after {
        content: "\e92e";
    }
    .PrimaryTheme .navbar-nav .dropright > .dropdown-toggle[aria-expanded=true]::after {
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.PrimaryTheme .navbar-nav .dropdown-img-left {
    height: 100%;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 1.625rem 1.75rem;
    background: no-repeat center center/cover;
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-nav .dropdown-img-left {
        display: -webkit-box;
        display: flex;
    }
}

.PrimaryTheme .navbar-nav .dropdown-img-left::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 94, 234, 0.8);
    border-radius: inherit;
}

.PrimaryTheme .navbar-nav .dropdown-img-left * {
    position: relative;
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-nav .dropdown-menu-lg .dropdown-img-left, .PrimaryTheme .navbar-nav .dropdown-menu-xl .dropdown-img-left {
        padding: 2.375rem 2.5rem;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-nav .dropdown-body {
        padding: 1.625rem 1.75rem;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-nav .dropdown-menu-lg .dropdown-body, .PrimaryTheme .navbar-nav .dropdown-menu-xl .dropdown-body {
        padding: 2.375rem 2.5rem;
    }
}

.PrimaryTheme .navbar-nav .dropdown-menu .list-group-item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: inherit;
}

.PrimaryTheme .navbar-nav .dropdown-menu .list-group-item:hover {
    text-decoration: none;
}

@media (min-width: 992px) {
    .PrimaryTheme .navbar-nav .dropdown-menu {
        z-index: 1030;
        display: block !important;
        visibility: hidden;
        opacity: 0;
    }
    .PrimaryTheme .navbar-nav .hovered > .dropdown-menu {
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        transition-property: opacity,visibility,transform,-webkit-transform;
        -webkit-transform-origin: top center;
        transform-origin: top center;
    }
    .PrimaryTheme .navbar-nav .dropdown > .dropdown-menu {
        left: 50%;
        -webkit-transform: translate3d(-50%, 10px, 0);
        transform: translate3d(-50%, 10px, 0);
    }
    .PrimaryTheme .navbar-nav .dropright > .dropdown-menu {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
    .PrimaryTheme .navbar-nav .dropdown.show > .dropdown-menu, .PrimaryTheme .navbar-nav .dropright.show > .dropdown-menu {
        visibility: visible;
        opacity: 1;
    }
    .PrimaryTheme .navbar-nav .dropdown.show > .dropdown-menu {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
    }
    .PrimaryTheme .navbar-nav .dropright.show > .dropdown-menu {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.PrimaryTheme .pagination-sm .page-link {
    line-height: 1.3;
    font-size: 1.0625rem;
}

.PrimaryTheme .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.PrimaryTheme .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
}

.PrimaryTheme .pagination-lg .page-link {
    line-height: 1.3;
    font-size: 1.0625rem;
}

.PrimaryTheme .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.PrimaryTheme .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
}

.PrimaryTheme .popover-header {
    padding-bottom: 0;
    margin-bottom: .5rem;
    font-size: .75rem;
    color: #335eea;
}

.PrimaryTheme .popover-header:not(:empty) ~ .popover-body {
    padding-top: 0;
}

.PrimaryTheme body, .PrimaryTheme html {
    overflow-x: hidden;
}

.PrimaryTheme .table thead > tr > th {
    border-top-width: 0;
    border-bottom-width: 1px;
}

.PrimaryTheme .table-align-middle tbody > tr > td, .PrimaryTheme .table-align-middle thead > tr > th {
    vertical-align: middle;
}

@font-face {
    .PrimaryTheme {
        font-family: HKGroteskPro;
        font-weight: 400;
        src: url(../fonts/HKGroteskPro/HKGroteskPro-Regular.woff2) format("woff2"), url(../fonts/HKGroteskPro/HKGroteskPro-Regular.woff) format("woff");
    }
}

@font-face {
    .PrimaryTheme {
        font-family: HKGroteskPro;
        font-weight: 600;
        src: url(../fonts/HKGroteskPro/HKGroteskPro-Medium.woff2) format("woff2"), url(../fonts/HKGroteskPro/HKGroteskPro-Medium.woff) format("woff");
    }
}

.PrimaryTheme .h1, .PrimaryTheme .h2, .PrimaryTheme h1, .PrimaryTheme h2 {
    margin-bottom: 1rem;
}

.PrimaryTheme .h1, .PrimaryTheme .h2, .PrimaryTheme .h3, .PrimaryTheme .h4, .PrimaryTheme h1, .PrimaryTheme h2, .PrimaryTheme h3, .PrimaryTheme h4 {
    letter-spacing: -.01em;
}

.PrimaryTheme .h1, .PrimaryTheme h1 {
    line-height: 1.3;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .h1, .PrimaryTheme h1 {
        font-size: 1.875rem;
    }
}

.PrimaryTheme .h2, .PrimaryTheme h2 {
    line-height: 1.35;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .h2, .PrimaryTheme h2 {
        font-size: 1.5rem;
    }
}

.PrimaryTheme .h3, .PrimaryTheme h3 {
    line-height: 1.4;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .h3, .PrimaryTheme h3 {
        font-size: 1.125rem;
    }
}

.PrimaryTheme .h4, .PrimaryTheme h4 {
    line-height: 1.45;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .h4, .PrimaryTheme h4 {
        font-size: 1.0625rem;
    }
}

.PrimaryTheme .h5, .PrimaryTheme h5 {
    line-height: 1.5;
}

.PrimaryTheme .h6, .PrimaryTheme h6 {
    line-height: 1.55;
}

.PrimaryTheme .h6.text-uppercase, .PrimaryTheme h6.text-uppercase {
    letter-spacing: .08em;
}

.PrimaryTheme .display-1, .PrimaryTheme .display-2, .PrimaryTheme .display-3, .PrimaryTheme .display-4 {
    letter-spacing: -.02em;
}

.PrimaryTheme .display-1 {
    line-height: 1.2;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .display-1 {
        font-size: 3.125rem;
    }
}

.PrimaryTheme .display-2 {
    line-height: 1.2;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .display-2 {
        font-size: 2.5rem;
    }
}

.PrimaryTheme .display-3 {
    line-height: 1.2;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .display-3 {
        font-size: 2.25rem;
    }
}

.PrimaryTheme .display-4 {
    line-height: 1.2;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .display-4 {
        font-size: 2rem;
    }
}

@media (max-width: 991.98px) {
    .PrimaryTheme .lead {
        font-size: 1.1875rem;
    }
}

.PrimaryTheme .blockquote-img {
    max-height: 2.5rem;
    max-width: 100%;
    width: auto;
}

.PrimaryTheme .blockquote-footer::before {
    display: none;
}

.PrimaryTheme .list-social-icon {
    max-width: 1.25rem;
    height: auto;
}

.PrimaryTheme .hr-sm {
    width: 60px;
    border-top-width: 2px;
}

.PrimaryTheme .hr-md {
    width: 100px;
    border-top-width: 2px;
}

.PrimaryTheme code {
    line-height: 1.35;
}

.PrimaryTheme .bg-cover {
    background: no-repeat center center/cover;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .bg-between {
        background-image: none !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .bg-between {
        background-repeat: no-repeat;
        background-position: left center,right center;
        background-size: auto 70%;
    }
}

.PrimaryTheme .bg-gradient-light {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f9fbfd), to(#fff));
    background-image: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
}

.PrimaryTheme .bg-gray-200 {
    background-color: #f1f4f8 !important;
}

.PrimaryTheme .bg-gray-300 {
    background-color: #d9e2ef !important;
}

.PrimaryTheme .bg-gray-800 {
    background-color: #384c74 !important;
}

.PrimaryTheme .bg-gray-900 {
    background-color: #1b2a4e !important;
}

.PrimaryTheme .bg-black {
    background-color: #161c2d !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .border-sm {
        border: 1px solid #f1f4f8 !important;
        color: rose;
    }
    .PrimaryTheme .border-top-sm {
        border-top: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-right-sm {
        border-right: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-bottom-sm {
        border-bottom: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-left-sm {
        border-left: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-sm-0 {
        border: 0 !important;
    }
    .PrimaryTheme .border-top-sm-0 {
        border-top: 0 !important;
    }
    .PrimaryTheme .border-right-sm-0 {
        border-right: 0 !important;
    }
    .PrimaryTheme .border-bottom-sm-0 {
        border-bottom: 0 !important;
    }
    .PrimaryTheme .border-left-sm-0 {
        border-left: 0 !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .border-md {
        border: 1px solid #f1f4f8 !important;
        color: rose;
    }
    .PrimaryTheme .border-top-md {
        border-top: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-right-md {
        border-right: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-bottom-md {
        border-bottom: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-left-md {
        border-left: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-md-0 {
        border: 0 !important;
    }
    .PrimaryTheme .border-top-md-0 {
        border-top: 0 !important;
    }
    .PrimaryTheme .border-right-md-0 {
        border-right: 0 !important;
    }
    .PrimaryTheme .border-bottom-md-0 {
        border-bottom: 0 !important;
    }
    .PrimaryTheme .border-left-md-0 {
        border-left: 0 !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .border-lg {
        border: 1px solid #f1f4f8 !important;
        color: rose;
    }
    .PrimaryTheme .border-top-lg {
        border-top: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-right-lg {
        border-right: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-bottom-lg {
        border-bottom: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-left-lg {
        border-left: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-lg-0 {
        border: 0 !important;
    }
    .PrimaryTheme .border-top-lg-0 {
        border-top: 0 !important;
    }
    .PrimaryTheme .border-right-lg-0 {
        border-right: 0 !important;
    }
    .PrimaryTheme .border-bottom-lg-0 {
        border-bottom: 0 !important;
    }
    .PrimaryTheme .border-left-lg-0 {
        border-left: 0 !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .border-xl {
        border: 1px solid #f1f4f8 !important;
        color: rose;
    }
    .PrimaryTheme .border-top-xl {
        border-top: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-right-xl {
        border-right: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-bottom-xl {
        border-bottom: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-left-xl {
        border-left: 1px solid #f1f4f8 !important;
    }
    .PrimaryTheme .border-xl-0 {
        border: 0 !important;
    }
    .PrimaryTheme .border-top-xl-0 {
        border-top: 0 !important;
    }
    .PrimaryTheme .border-right-xl-0 {
        border-right: 0 !important;
    }
    .PrimaryTheme .border-bottom-xl-0 {
        border-bottom: 0 !important;
    }
    .PrimaryTheme .border-left-xl-0 {
        border-left: 0 !important;
    }
}

.PrimaryTheme .border-white-20 {
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.PrimaryTheme .border-gray-300 {
    border-color: #d9e2ef !important;
}

.PrimaryTheme .border-gray-800 {
    border-color: #384c74 !important;
}

.PrimaryTheme .border-gray-800-50 {
    border-color: rgba(56, 76, 116, 0.5) !important;
}

.PrimaryTheme .img-skewed {
    -webkit-perspective: 1500px;
    perspective: 1500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.PrimaryTheme .img-skewed-left {
    -webkit-perspective-origin: left center;
    perspective-origin: left center;
}

.PrimaryTheme .img-skewed-left .img-skewed-item {
    -webkit-transform: rotateY(-35deg) rotateX(15deg);
    transform: rotateY(-35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.PrimaryTheme .img-skewed-right {
    -webkit-perspective-origin: right center;
    perspective-origin: right center;
}

.PrimaryTheme .img-skewed-right .img-skewed-item {
    -webkit-transform: rotateY(35deg) rotateX(15deg);
    transform: rotateY(35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.PrimaryTheme .overlay {
    position: relative;
}

.PrimaryTheme .overlay::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
}

.PrimaryTheme .overlay-primary::before {
    background-color: #335eea;
}

.PrimaryTheme .overlay-gradient-primary-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#335eea));
    background-image: linear-gradient(to bottom, transparent, #335eea);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-primary-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #335eea), to(#335eea));
        background-image: linear-gradient(to right, transparent, #335eea 50%, #335eea);
    }
}

.PrimaryTheme .overlay-gradient-primary-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#335eea));
    background-image: linear-gradient(to bottom, transparent, #335eea);
}

.PrimaryTheme .overlay-gradient-primary-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#335eea));
    background-image: linear-gradient(to bottom, transparent, #335eea);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-primary-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #335eea), to(#335eea));
        background-image: linear-gradient(to left, transparent, #335eea 50%, #335eea);
    }
}

.PrimaryTheme .overlay-secondary::before {
    background-color: #506690;
}

.PrimaryTheme .overlay-gradient-secondary-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#506690));
    background-image: linear-gradient(to bottom, transparent, #506690);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-secondary-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #506690), to(#506690));
        background-image: linear-gradient(to right, transparent, #506690 50%, #506690);
    }
}

.PrimaryTheme .overlay-gradient-secondary-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#506690));
    background-image: linear-gradient(to bottom, transparent, #506690);
}

.PrimaryTheme .overlay-gradient-secondary-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#506690));
    background-image: linear-gradient(to bottom, transparent, #506690);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-secondary-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #506690), to(#506690));
        background-image: linear-gradient(to left, transparent, #506690 50%, #506690);
    }
}

.PrimaryTheme .overlay-success::before {
    background-color: #42ba96;
}

.PrimaryTheme .overlay-gradient-success-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#42ba96));
    background-image: linear-gradient(to bottom, transparent, #42ba96);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-success-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #42ba96), to(#42ba96));
        background-image: linear-gradient(to right, transparent, #42ba96 50%, #42ba96);
    }
}

.PrimaryTheme .overlay-gradient-success-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#42ba96));
    background-image: linear-gradient(to bottom, transparent, #42ba96);
}

.PrimaryTheme .overlay-gradient-success-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#42ba96));
    background-image: linear-gradient(to bottom, transparent, #42ba96);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-success-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #42ba96), to(#42ba96));
        background-image: linear-gradient(to left, transparent, #42ba96 50%, #42ba96);
    }
}

.PrimaryTheme .overlay-info::before {
    background-color: #7c69ef;
}

.PrimaryTheme .overlay-gradient-info-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#7c69ef));
    background-image: linear-gradient(to bottom, transparent, #7c69ef);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-info-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #7c69ef), to(#7c69ef));
        background-image: linear-gradient(to right, transparent, #7c69ef 50%, #7c69ef);
    }
}

.PrimaryTheme .overlay-gradient-info-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#7c69ef));
    background-image: linear-gradient(to bottom, transparent, #7c69ef);
}

.PrimaryTheme .overlay-gradient-info-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#7c69ef));
    background-image: linear-gradient(to bottom, transparent, #7c69ef);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-info-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #7c69ef), to(#7c69ef));
        background-image: linear-gradient(to left, transparent, #7c69ef 50%, #7c69ef);
    }
}

.PrimaryTheme .overlay-warning::before {
    background-color: #fad776;
}

.PrimaryTheme .overlay-gradient-warning-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fad776));
    background-image: linear-gradient(to bottom, transparent, #fad776);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-warning-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #fad776), to(#fad776));
        background-image: linear-gradient(to right, transparent, #fad776 50%, #fad776);
    }
}

.PrimaryTheme .overlay-gradient-warning-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fad776));
    background-image: linear-gradient(to bottom, transparent, #fad776);
}

.PrimaryTheme .overlay-gradient-warning-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fad776));
    background-image: linear-gradient(to bottom, transparent, #fad776);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-warning-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #fad776), to(#fad776));
        background-image: linear-gradient(to left, transparent, #fad776 50%, #fad776);
    }
}

.PrimaryTheme .overlay-danger::before {
    background-color: #df4759;
}

.PrimaryTheme .overlay-gradient-danger-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#df4759));
    background-image: linear-gradient(to bottom, transparent, #df4759);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-danger-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #df4759), to(#df4759));
        background-image: linear-gradient(to right, transparent, #df4759 50%, #df4759);
    }
}

.PrimaryTheme .overlay-gradient-danger-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#df4759));
    background-image: linear-gradient(to bottom, transparent, #df4759);
}

.PrimaryTheme .overlay-gradient-danger-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#df4759));
    background-image: linear-gradient(to bottom, transparent, #df4759);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-danger-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #df4759), to(#df4759));
        background-image: linear-gradient(to left, transparent, #df4759 50%, #df4759);
    }
}

.PrimaryTheme .overlay-light::before {
    background-color: #f9fbfd;
}

.PrimaryTheme .overlay-gradient-light-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfd));
    background-image: linear-gradient(to bottom, transparent, #f9fbfd);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-light-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f9fbfd), to(#f9fbfd));
        background-image: linear-gradient(to right, transparent, #f9fbfd 50%, #f9fbfd);
    }
}

.PrimaryTheme .overlay-gradient-light-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfd));
    background-image: linear-gradient(to bottom, transparent, #f9fbfd);
}

.PrimaryTheme .overlay-gradient-light-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfd));
    background-image: linear-gradient(to bottom, transparent, #f9fbfd);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-light-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f9fbfd), to(#f9fbfd));
        background-image: linear-gradient(to left, transparent, #f9fbfd 50%, #f9fbfd);
    }
}

.PrimaryTheme .overlay-dark::before {
    background-color: #1b2a4e;
}

.PrimaryTheme .overlay-gradient-dark-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#1b2a4e));
    background-image: linear-gradient(to bottom, transparent, #1b2a4e);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-dark-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #1b2a4e), to(#1b2a4e));
        background-image: linear-gradient(to right, transparent, #1b2a4e 50%, #1b2a4e);
    }
}

.PrimaryTheme .overlay-gradient-dark-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#1b2a4e));
    background-image: linear-gradient(to bottom, transparent, #1b2a4e);
}

.PrimaryTheme .overlay-gradient-dark-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#1b2a4e));
    background-image: linear-gradient(to bottom, transparent, #1b2a4e);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-dark-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #1b2a4e), to(#1b2a4e));
        background-image: linear-gradient(to left, transparent, #1b2a4e 50%, #1b2a4e);
    }
}

.PrimaryTheme .overlay-primary-desat::before {
    background-color: #6c8aec;
}

.PrimaryTheme .overlay-gradient-primary-desat-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
    background-image: linear-gradient(to bottom, transparent, #6c8aec);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-primary-desat-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #6c8aec), to(#6c8aec));
        background-image: linear-gradient(to right, transparent, #6c8aec 50%, #6c8aec);
    }
}

.PrimaryTheme .overlay-gradient-primary-desat-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
    background-image: linear-gradient(to bottom, transparent, #6c8aec);
}

.PrimaryTheme .overlay-gradient-primary-desat-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
    background-image: linear-gradient(to bottom, transparent, #6c8aec);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-primary-desat-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #6c8aec), to(#6c8aec));
        background-image: linear-gradient(to left, transparent, #6c8aec 50%, #6c8aec);
    }
}

.PrimaryTheme .overlay-black::before {
    background-color: #161c2d;
}

.PrimaryTheme .overlay-gradient-black-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
    background-image: linear-gradient(to bottom, transparent, #161c2d);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-black-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #161c2d), to(#161c2d));
        background-image: linear-gradient(to right, transparent, #161c2d 50%, #161c2d);
    }
}

.PrimaryTheme .overlay-gradient-black-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
    background-image: linear-gradient(to bottom, transparent, #161c2d);
}

.PrimaryTheme .overlay-gradient-black-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
    background-image: linear-gradient(to bottom, transparent, #161c2d);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-black-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #161c2d), to(#161c2d));
        background-image: linear-gradient(to left, transparent, #161c2d 50%, #161c2d);
    }
}

.PrimaryTheme .overlay-white::before {
    background-color: #fff;
}

.PrimaryTheme .overlay-gradient-white-right::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
    background-image: linear-gradient(to bottom, transparent, #fff);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-white-right::before {
        background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #fff), to(#fff));
        background-image: linear-gradient(to right, transparent, #fff 50%, #fff);
    }
}

.PrimaryTheme .overlay-gradient-white-down::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
    background-image: linear-gradient(to bottom, transparent, #fff);
}

.PrimaryTheme .overlay-gradient-white-left::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
    background-image: linear-gradient(to bottom, transparent, #fff);
}

@media (min-width: 768px) {
    .PrimaryTheme .overlay-gradient-white-left::before {
        background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #fff), to(#fff));
        background-image: linear-gradient(to left, transparent, #fff 50%, #fff);
    }
}

.PrimaryTheme .overlay-10::before {
    opacity: .1;
}

.PrimaryTheme .overlay-20::before {
    opacity: .2;
}

.PrimaryTheme .overlay-30::before {
    opacity: .3;
}

.PrimaryTheme .overlay-40::before {
    opacity: .4;
}

.PrimaryTheme .overlay-50::before {
    opacity: .5;
}

.PrimaryTheme .overlay-60::before {
    opacity: .6;
}

.PrimaryTheme .overlay-70::before {
    opacity: .7;
}

.PrimaryTheme .overlay-80::before {
    opacity: .8;
}

.PrimaryTheme .overlay-90::before {
    opacity: .9;
}

.PrimaryTheme .lift {
    -webkit-transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
}

.PrimaryTheme .lift:focus, .PrimaryTheme .lift:hover {
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
}

.PrimaryTheme .lift-lg:focus, .PrimaryTheme .lift-lg:hover {
    box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
}

.PrimaryTheme .top-0 {
    top: 0 !important;
}

.PrimaryTheme .right-0 {
    right: 0 !important;
}

.PrimaryTheme .bottom-0 {
    bottom: 0 !important;
}

.PrimaryTheme .left-0 {
    left: 0 !important;
}

.PrimaryTheme .center {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (min-width: 576px) {
    .PrimaryTheme .position-sm-static {
        position: static !important;
    }
    .PrimaryTheme .position-sm-relative {
        position: relative !important;
    }
    .PrimaryTheme .position-sm-absolute {
        position: absolute !important;
    }
    .PrimaryTheme .position-sm-fixed {
        position: fixed !important;
    }
    .PrimaryTheme .position-sm-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .position-md-static {
        position: static !important;
    }
    .PrimaryTheme .position-md-relative {
        position: relative !important;
    }
    .PrimaryTheme .position-md-absolute {
        position: absolute !important;
    }
    .PrimaryTheme .position-md-fixed {
        position: fixed !important;
    }
    .PrimaryTheme .position-md-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .position-lg-static {
        position: static !important;
    }
    .PrimaryTheme .position-lg-relative {
        position: relative !important;
    }
    .PrimaryTheme .position-lg-absolute {
        position: absolute !important;
    }
    .PrimaryTheme .position-lg-fixed {
        position: fixed !important;
    }
    .PrimaryTheme .position-lg-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .position-xl-static {
        position: static !important;
    }
    .PrimaryTheme .position-xl-relative {
        position: relative !important;
    }
    .PrimaryTheme .position-xl-absolute {
        position: absolute !important;
    }
    .PrimaryTheme .position-xl-fixed {
        position: fixed !important;
    }
    .PrimaryTheme .position-xl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
    }
}

.PrimaryTheme .shadow-light {
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05) !important;
}

.PrimaryTheme .shadow-light-lg {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
}

.PrimaryTheme .shadow-dark {
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.15) !important;
}

.PrimaryTheme .shadow-dark-lg {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15) !important;
}

.PrimaryTheme .shadow-lift {
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
}

.PrimaryTheme .mw-25 {
    max-width: 25% !important;
}

.PrimaryTheme .vw-25 {
    width: 25vw !important;
}

.PrimaryTheme .mw-50 {
    max-width: 50% !important;
}

.PrimaryTheme .vw-50 {
    width: 50vw !important;
}

.PrimaryTheme .mw-75 {
    max-width: 75% !important;
}

.PrimaryTheme .vw-75 {
    width: 75vw !important;
}

.PrimaryTheme .mw-100 {
    max-width: 100% !important;
}

.PrimaryTheme .vw-100 {
    width: 100vw !important;
}

.PrimaryTheme .mw-auto {
    max-width: auto !important;
}

.PrimaryTheme .vw-auto {
    width: autovw !important;
}

.PrimaryTheme .mw-110 {
    max-width: 110% !important;
}

.PrimaryTheme .vw-110 {
    width: 110vw !important;
}

.PrimaryTheme .mw-120 {
    max-width: 120% !important;
}

.PrimaryTheme .vw-120 {
    width: 120vw !important;
}

.PrimaryTheme .mw-130 {
    max-width: 130% !important;
}

.PrimaryTheme .vw-130 {
    width: 130vw !important;
}

.PrimaryTheme .mw-140 {
    max-width: 140% !important;
}

.PrimaryTheme .vw-140 {
    width: 140vw !important;
}

.PrimaryTheme .mw-150 {
    max-width: 150% !important;
}

.PrimaryTheme .vw-150 {
    width: 150vw !important;
}

@media (min-width: 576px) {
    .PrimaryTheme .h-sm-25 {
        height: 25% !important;
    }
    .PrimaryTheme .w-sm-25 {
        width: 25% !important;
    }
    .PrimaryTheme .mw-sm-25 {
        max-width: 25% !important;
    }
    .PrimaryTheme .vh-sm-25 {
        height: 25vh;
    }
    .PrimaryTheme .vw-sm-25 {
        width: 25vw;
    }
    .PrimaryTheme .h-sm-50 {
        height: 50% !important;
    }
    .PrimaryTheme .w-sm-50 {
        width: 50% !important;
    }
    .PrimaryTheme .mw-sm-50 {
        max-width: 50% !important;
    }
    .PrimaryTheme .vh-sm-50 {
        height: 50vh;
    }
    .PrimaryTheme .vw-sm-50 {
        width: 50vw;
    }
    .PrimaryTheme .h-sm-75 {
        height: 75% !important;
    }
    .PrimaryTheme .w-sm-75 {
        width: 75% !important;
    }
    .PrimaryTheme .mw-sm-75 {
        max-width: 75% !important;
    }
    .PrimaryTheme .vh-sm-75 {
        height: 75vh;
    }
    .PrimaryTheme .vw-sm-75 {
        width: 75vw;
    }
    .PrimaryTheme .h-sm-100 {
        height: 100% !important;
    }
    .PrimaryTheme .w-sm-100 {
        width: 100% !important;
    }
    .PrimaryTheme .mw-sm-100 {
        max-width: 100% !important;
    }
    .PrimaryTheme .vh-sm-100 {
        height: 100vh;
    }
    .PrimaryTheme .vw-sm-100 {
        width: 100vw;
    }
    .PrimaryTheme .h-sm-auto {
        height: auto !important;
    }
    .PrimaryTheme .w-sm-auto {
        width: auto !important;
    }
    .PrimaryTheme .mw-sm-auto {
        max-width: auto !important;
    }
    .PrimaryTheme .vh-sm-auto {
        height: autovh;
    }
    .PrimaryTheme .vw-sm-auto {
        width: autovw;
    }
    .PrimaryTheme .h-sm-110 {
        height: 110% !important;
    }
    .PrimaryTheme .w-sm-110 {
        width: 110% !important;
    }
    .PrimaryTheme .mw-sm-110 {
        max-width: 110% !important;
    }
    .PrimaryTheme .vh-sm-110 {
        height: 110vh;
    }
    .PrimaryTheme .vw-sm-110 {
        width: 110vw;
    }
    .PrimaryTheme .h-sm-120 {
        height: 120% !important;
    }
    .PrimaryTheme .w-sm-120 {
        width: 120% !important;
    }
    .PrimaryTheme .mw-sm-120 {
        max-width: 120% !important;
    }
    .PrimaryTheme .vh-sm-120 {
        height: 120vh;
    }
    .PrimaryTheme .vw-sm-120 {
        width: 120vw;
    }
    .PrimaryTheme .h-sm-130 {
        height: 130% !important;
    }
    .PrimaryTheme .w-sm-130 {
        width: 130% !important;
    }
    .PrimaryTheme .mw-sm-130 {
        max-width: 130% !important;
    }
    .PrimaryTheme .vh-sm-130 {
        height: 130vh;
    }
    .PrimaryTheme .vw-sm-130 {
        width: 130vw;
    }
    .PrimaryTheme .h-sm-140 {
        height: 140% !important;
    }
    .PrimaryTheme .w-sm-140 {
        width: 140% !important;
    }
    .PrimaryTheme .mw-sm-140 {
        max-width: 140% !important;
    }
    .PrimaryTheme .vh-sm-140 {
        height: 140vh;
    }
    .PrimaryTheme .vw-sm-140 {
        width: 140vw;
    }
    .PrimaryTheme .h-sm-150 {
        height: 150% !important;
    }
    .PrimaryTheme .w-sm-150 {
        width: 150% !important;
    }
    .PrimaryTheme .mw-sm-150 {
        max-width: 150% !important;
    }
    .PrimaryTheme .vh-sm-150 {
        height: 150vh;
    }
    .PrimaryTheme .vw-sm-150 {
        width: 150vw;
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .h-md-25 {
        height: 25% !important;
    }
    .PrimaryTheme .w-md-25 {
        width: 25% !important;
    }
    .PrimaryTheme .mw-md-25 {
        max-width: 25% !important;
    }
    .PrimaryTheme .vh-md-25 {
        height: 25vh;
    }
    .PrimaryTheme .vw-md-25 {
        width: 25vw;
    }
    .PrimaryTheme .h-md-50 {
        height: 50% !important;
    }
    .PrimaryTheme .w-md-50 {
        width: 50% !important;
    }
    .PrimaryTheme .mw-md-50 {
        max-width: 50% !important;
    }
    .PrimaryTheme .vh-md-50 {
        height: 50vh;
    }
    .PrimaryTheme .vw-md-50 {
        width: 50vw;
    }
    .PrimaryTheme .h-md-75 {
        height: 75% !important;
    }
    .PrimaryTheme .w-md-75 {
        width: 75% !important;
    }
    .PrimaryTheme .mw-md-75 {
        max-width: 75% !important;
    }
    .PrimaryTheme .vh-md-75 {
        height: 75vh;
    }
    .PrimaryTheme .vw-md-75 {
        width: 75vw;
    }
    .PrimaryTheme .h-md-100 {
        height: 100% !important;
    }
    .PrimaryTheme .w-md-100 {
        width: 100% !important;
    }
    .PrimaryTheme .mw-md-100 {
        max-width: 100% !important;
    }
    .PrimaryTheme .vh-md-100 {
        height: 100vh;
    }
    .PrimaryTheme .vw-md-100 {
        width: 100vw;
    }
    .PrimaryTheme .h-md-auto {
        height: auto !important;
    }
    .PrimaryTheme .w-md-auto {
        width: auto !important;
    }
    .PrimaryTheme .mw-md-auto {
        max-width: auto !important;
    }
    .PrimaryTheme .vh-md-auto {
        height: autovh;
    }
    .PrimaryTheme .vw-md-auto {
        width: autovw;
    }
    .PrimaryTheme .h-md-110 {
        height: 110% !important;
    }
    .PrimaryTheme .w-md-110 {
        width: 110% !important;
    }
    .PrimaryTheme .mw-md-110 {
        max-width: 110% !important;
    }
    .PrimaryTheme .vh-md-110 {
        height: 110vh;
    }
    .PrimaryTheme .vw-md-110 {
        width: 110vw;
    }
    .PrimaryTheme .h-md-120 {
        height: 120% !important;
    }
    .PrimaryTheme .w-md-120 {
        width: 120% !important;
    }
    .PrimaryTheme .mw-md-120 {
        max-width: 120% !important;
    }
    .PrimaryTheme .vh-md-120 {
        height: 120vh;
    }
    .PrimaryTheme .vw-md-120 {
        width: 120vw;
    }
    .PrimaryTheme .h-md-130 {
        height: 130% !important;
    }
    .PrimaryTheme .w-md-130 {
        width: 130% !important;
    }
    .PrimaryTheme .mw-md-130 {
        max-width: 130% !important;
    }
    .PrimaryTheme .vh-md-130 {
        height: 130vh;
    }
    .PrimaryTheme .vw-md-130 {
        width: 130vw;
    }
    .PrimaryTheme .h-md-140 {
        height: 140% !important;
    }
    .PrimaryTheme .w-md-140 {
        width: 140% !important;
    }
    .PrimaryTheme .mw-md-140 {
        max-width: 140% !important;
    }
    .PrimaryTheme .vh-md-140 {
        height: 140vh;
    }
    .PrimaryTheme .vw-md-140 {
        width: 140vw;
    }
    .PrimaryTheme .h-md-150 {
        height: 150% !important;
    }
    .PrimaryTheme .w-md-150 {
        width: 150% !important;
    }
    .PrimaryTheme .mw-md-150 {
        max-width: 150% !important;
    }
    .PrimaryTheme .vh-md-150 {
        height: 150vh;
    }
    .PrimaryTheme .vw-md-150 {
        width: 150vw;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .h-lg-25 {
        height: 25% !important;
    }
    .PrimaryTheme .w-lg-25 {
        width: 25% !important;
    }
    .PrimaryTheme .mw-lg-25 {
        max-width: 25% !important;
    }
    .PrimaryTheme .vh-lg-25 {
        height: 25vh;
    }
    .PrimaryTheme .vw-lg-25 {
        width: 25vw;
    }
    .PrimaryTheme .h-lg-50 {
        height: 50% !important;
    }
    .PrimaryTheme .w-lg-50 {
        width: 50% !important;
    }
    .PrimaryTheme .mw-lg-50 {
        max-width: 50% !important;
    }
    .PrimaryTheme .vh-lg-50 {
        height: 50vh;
    }
    .PrimaryTheme .vw-lg-50 {
        width: 50vw;
    }
    .PrimaryTheme .h-lg-75 {
        height: 75% !important;
    }
    .PrimaryTheme .w-lg-75 {
        width: 75% !important;
    }
    .PrimaryTheme .mw-lg-75 {
        max-width: 75% !important;
    }
    .PrimaryTheme .vh-lg-75 {
        height: 75vh;
    }
    .PrimaryTheme .vw-lg-75 {
        width: 75vw;
    }
    .PrimaryTheme .h-lg-100 {
        height: 100% !important;
    }
    .PrimaryTheme .w-lg-100 {
        width: 100% !important;
    }
    .PrimaryTheme .mw-lg-100 {
        max-width: 100% !important;
    }
    .PrimaryTheme .vh-lg-100 {
        height: 100vh;
    }
    .PrimaryTheme .vw-lg-100 {
        width: 100vw;
    }
    .PrimaryTheme .h-lg-auto {
        height: auto !important;
    }
    .PrimaryTheme .w-lg-auto {
        width: auto !important;
    }
    .PrimaryTheme .mw-lg-auto {
        max-width: auto !important;
    }
    .PrimaryTheme .vh-lg-auto {
        height: autovh;
    }
    .PrimaryTheme .vw-lg-auto {
        width: autovw;
    }
    .PrimaryTheme .h-lg-110 {
        height: 110% !important;
    }
    .PrimaryTheme .w-lg-110 {
        width: 110% !important;
    }
    .PrimaryTheme .mw-lg-110 {
        max-width: 110% !important;
    }
    .PrimaryTheme .vh-lg-110 {
        height: 110vh;
    }
    .PrimaryTheme .vw-lg-110 {
        width: 110vw;
    }
    .PrimaryTheme .h-lg-120 {
        height: 120% !important;
    }
    .PrimaryTheme .w-lg-120 {
        width: 120% !important;
    }
    .PrimaryTheme .mw-lg-120 {
        max-width: 120% !important;
    }
    .PrimaryTheme .vh-lg-120 {
        height: 120vh;
    }
    .PrimaryTheme .vw-lg-120 {
        width: 120vw;
    }
    .PrimaryTheme .h-lg-130 {
        height: 130% !important;
    }
    .PrimaryTheme .w-lg-130 {
        width: 130% !important;
    }
    .PrimaryTheme .mw-lg-130 {
        max-width: 130% !important;
    }
    .PrimaryTheme .vh-lg-130 {
        height: 130vh;
    }
    .PrimaryTheme .vw-lg-130 {
        width: 130vw;
    }
    .PrimaryTheme .h-lg-140 {
        height: 140% !important;
    }
    .PrimaryTheme .w-lg-140 {
        width: 140% !important;
    }
    .PrimaryTheme .mw-lg-140 {
        max-width: 140% !important;
    }
    .PrimaryTheme .vh-lg-140 {
        height: 140vh;
    }
    .PrimaryTheme .vw-lg-140 {
        width: 140vw;
    }
    .PrimaryTheme .h-lg-150 {
        height: 150% !important;
    }
    .PrimaryTheme .w-lg-150 {
        width: 150% !important;
    }
    .PrimaryTheme .mw-lg-150 {
        max-width: 150% !important;
    }
    .PrimaryTheme .vh-lg-150 {
        height: 150vh;
    }
    .PrimaryTheme .vw-lg-150 {
        width: 150vw;
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .h-xl-25 {
        height: 25% !important;
    }
    .PrimaryTheme .w-xl-25 {
        width: 25% !important;
    }
    .PrimaryTheme .mw-xl-25 {
        max-width: 25% !important;
    }
    .PrimaryTheme .vh-xl-25 {
        height: 25vh;
    }
    .PrimaryTheme .vw-xl-25 {
        width: 25vw;
    }
    .PrimaryTheme .h-xl-50 {
        height: 50% !important;
    }
    .PrimaryTheme .w-xl-50 {
        width: 50% !important;
    }
    .PrimaryTheme .mw-xl-50 {
        max-width: 50% !important;
    }
    .PrimaryTheme .vh-xl-50 {
        height: 50vh;
    }
    .PrimaryTheme .vw-xl-50 {
        width: 50vw;
    }
    .PrimaryTheme .h-xl-75 {
        height: 75% !important;
    }
    .PrimaryTheme .w-xl-75 {
        width: 75% !important;
    }
    .PrimaryTheme .mw-xl-75 {
        max-width: 75% !important;
    }
    .PrimaryTheme .vh-xl-75 {
        height: 75vh;
    }
    .PrimaryTheme .vw-xl-75 {
        width: 75vw;
    }
    .PrimaryTheme .h-xl-100 {
        height: 100% !important;
    }
    .PrimaryTheme .w-xl-100 {
        width: 100% !important;
    }
    .PrimaryTheme .mw-xl-100 {
        max-width: 100% !important;
    }
    .PrimaryTheme .vh-xl-100 {
        height: 100vh;
    }
    .PrimaryTheme .vw-xl-100 {
        width: 100vw;
    }
    .PrimaryTheme .h-xl-auto {
        height: auto !important;
    }
    .PrimaryTheme .w-xl-auto {
        width: auto !important;
    }
    .PrimaryTheme .mw-xl-auto {
        max-width: auto !important;
    }
    .PrimaryTheme .vh-xl-auto {
        height: autovh;
    }
    .PrimaryTheme .vw-xl-auto {
        width: autovw;
    }
    .PrimaryTheme .h-xl-110 {
        height: 110% !important;
    }
    .PrimaryTheme .w-xl-110 {
        width: 110% !important;
    }
    .PrimaryTheme .mw-xl-110 {
        max-width: 110% !important;
    }
    .PrimaryTheme .vh-xl-110 {
        height: 110vh;
    }
    .PrimaryTheme .vw-xl-110 {
        width: 110vw;
    }
    .PrimaryTheme .h-xl-120 {
        height: 120% !important;
    }
    .PrimaryTheme .w-xl-120 {
        width: 120% !important;
    }
    .PrimaryTheme .mw-xl-120 {
        max-width: 120% !important;
    }
    .PrimaryTheme .vh-xl-120 {
        height: 120vh;
    }
    .PrimaryTheme .vw-xl-120 {
        width: 120vw;
    }
    .PrimaryTheme .h-xl-130 {
        height: 130% !important;
    }
    .PrimaryTheme .w-xl-130 {
        width: 130% !important;
    }
    .PrimaryTheme .mw-xl-130 {
        max-width: 130% !important;
    }
    .PrimaryTheme .vh-xl-130 {
        height: 130vh;
    }
    .PrimaryTheme .vw-xl-130 {
        width: 130vw;
    }
    .PrimaryTheme .h-xl-140 {
        height: 140% !important;
    }
    .PrimaryTheme .w-xl-140 {
        width: 140% !important;
    }
    .PrimaryTheme .mw-xl-140 {
        max-width: 140% !important;
    }
    .PrimaryTheme .vh-xl-140 {
        height: 140vh;
    }
    .PrimaryTheme .vw-xl-140 {
        width: 140vw;
    }
    .PrimaryTheme .h-xl-150 {
        height: 150% !important;
    }
    .PrimaryTheme .w-xl-150 {
        width: 150% !important;
    }
    .PrimaryTheme .mw-xl-150 {
        max-width: 150% !important;
    }
    .PrimaryTheme .vh-xl-150 {
        height: 150vh;
    }
    .PrimaryTheme .vw-xl-150 {
        width: 150vw;
    }
}

@media (min-width: 576px) {
    .PrimaryTheme .w-cover {
        width: calc(100% + (100vw - 540px)/ 2 + 40px);
    }
}

@media (min-width: 768px) {
    .PrimaryTheme .w-cover {
        width: calc(100% + (100vw - 720px)/ 2 + 40px);
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .w-cover {
        width: calc(100% + (100vw - 960px)/ 2 + 40px);
    }
}

@media (min-width: 1200px) {
    .PrimaryTheme .w-cover {
        width: calc(100% + (100vw - 1040px)/ 2 + 40px);
    }
}

.PrimaryTheme .font-size-sm {
    font-size: .9375rem !important;
}

.PrimaryTheme .font-size-lg {
    font-size: 1.1875rem !important;
}

@media (max-width: 991.98px) {
    .PrimaryTheme .font-size-lg {
        font-size: 1.0625rem !important;
    }
}

.PrimaryTheme .text-gray-100 {
    color: #f9fbfd !important;
}

.PrimaryTheme .text-gray-200 {
    color: #f1f4f8 !important;
}

.PrimaryTheme .text-gray-300 {
    color: #d9e2ef !important;
}

.PrimaryTheme .text-gray-400 {
    color: #c6d3e6 !important;
}

.PrimaryTheme .text-gray-500 {
    color: #abbcd5 !important;
}

.PrimaryTheme .text-gray-600 {
    color: #869ab8 !important;
}

.PrimaryTheme .text-gray-700 {
    color: #506690 !important;
}

.PrimaryTheme .text-gray-800 {
    color: #384c74 !important;
}

.PrimaryTheme .text-gray-900 {
    color: #1b2a4e !important;
}

.PrimaryTheme .text-white-70 {
    color: rgba(255, 255, 255, 0.7) !important;
}

.PrimaryTheme .text-white-75 {
    color: rgba(255, 255, 255, 0.75) !important;
}

.PrimaryTheme .text-white-80 {
    color: rgba(255, 255, 255, 0.8) !important;
}

.PrimaryTheme .letter-spacing-lg {
    letter-spacing: .08em !important;
}

.PrimaryTheme .opacity-0 {
    opacity: 0 !important;
}

.PrimaryTheme .opacity-1 {
    opacity: 1 !important;
}

.PrimaryTheme [data-aos=img-skewed-item-left], .PrimaryTheme [data-aos=img-skewed-item-right] {
    opacity: 0;
    transition-property: opacity,transform,-webkit-transform;
    will-change: opacity,transform,-webkit-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.PrimaryTheme [data-aos=img-skewed-item-left].aos-animate, .PrimaryTheme [data-aos=img-skewed-item-right].aos-animate {
    opacity: 1;
}

.PrimaryTheme [data-aos=img-skewed-item-left] {
    -webkit-transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
    transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
}

.PrimaryTheme [data-aos=img-skewed-item-left].aos-animate {
    -webkit-transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0);
    transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0);
}

.PrimaryTheme [data-aos=img-skewed-item-right] {
    -webkit-transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0);
    transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0);
}

.PrimaryTheme [data-aos=img-skewed-item-right].aos-animate {
    -webkit-transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0);
    transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0);
}

.PrimaryTheme .avatar {
    position: relative;
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    font-size: .625rem;
}

.PrimaryTheme .avatar:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg);
}

.PrimaryTheme .avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.PrimaryTheme .avatar-title {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #abbcd5;
    color: #fff;
}

.PrimaryTheme .avatar-offline::before, .PrimaryTheme .avatar-online::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;
}

.PrimaryTheme .avatar-offline .avatar-img, .PrimaryTheme .avatar-online .avatar-img {
    -webkit-mask-image: url(../img/masks/avatar-status.svg);
    mask-image: url(../img/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.PrimaryTheme .avatar-online::before {
    background-color: #42ba96;
}

.PrimaryTheme .avatar-offline::before {
    background-color: #abbcd5;
}

.PrimaryTheme .avatar-xs {
    width: 1rem;
    height: 1rem;
    font-size: .33333rem;
}

.PrimaryTheme .avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
    font-size: .5rem;
}

.PrimaryTheme .avatar-lg {
    width: 2.25rem;
    height: 2.25rem;
    font-size: .75rem;
}

.PrimaryTheme .avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem;
}

.PrimaryTheme .avatar-xxl {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem;
}

@media (min-width: 768px) {
    .PrimaryTheme .avatar-xxl {
        width: 5rem;
        height: 5rem;
        font-size: 1.66667rem;
    }
}

.PrimaryTheme .avatar.avatar-4by3 {
    width: 2.5rem;
}

.PrimaryTheme .avatar-xs.avatar-4by3 {
    width: 1.33333rem;
}

.PrimaryTheme .avatar-sm.avatar-4by3 {
    width: 2rem;
}

.PrimaryTheme .avatar-lg.avatar-4by3 {
    width: 3rem;
}

.PrimaryTheme .avatar-xl.avatar-4by3 {
    width: 5.33333rem;
}

.PrimaryTheme .avatar-xxl.avatar-4by3 {
    width: 6.66667rem;
}

.PrimaryTheme .avatar-group {
    display: -webkit-inline-box;
    display: inline-flex;
}

.PrimaryTheme .avatar-group .avatar + .avatar {
    margin-left: -.46875rem;
}

.PrimaryTheme .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -.25rem;
}

.PrimaryTheme .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -.375rem;
}

.PrimaryTheme .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -.5625rem;
}

.PrimaryTheme .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1rem;
}

.PrimaryTheme .avatar-group .avatar-xxl + .avatar-xxl {
    margin-left: -1.25rem;
}

.PrimaryTheme .avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(../img/masks/avatar-group.svg);
    mask-image: url(../img/masks/avatar-group.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.PrimaryTheme .avatar-group .avatar:hover {
    -webkit-mask-image: none;
    mask-image: none;
    z-index: 1;
}

.PrimaryTheme .avatar-group .avatar:hover + .avatar {
    -webkit-mask-image: url(../img/masks/avatar-group-hover.svg);
    mask-image: url(../img/masks/avatar-group-hover.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.PrimaryTheme .avatar-group .avatar:hover + .avatar:last-child {
    -webkit-mask-image: url(../img/masks/avatar-group-hover-last.svg);
    mask-image: url(../img/masks/avatar-group-hover-last.svg);
}

.PrimaryTheme .collapse-chevron {
    display: inline-block;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.PrimaryTheme [aria-expanded=true] .collapse-chevron {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.PrimaryTheme .device {
    position: relative;
}

.PrimaryTheme .device > .img-fluid {
    position: relative;
}

.PrimaryTheme .device::before {
    content: "";
    background-color: #f9fbfd;
}

.PrimaryTheme .device-screen, .PrimaryTheme .device::before {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
}

.PrimaryTheme .device-iphonex::before, .PrimaryTheme .device-iphonex > .device-screen {
    top: 7.784431138%;
    left: 16.4021164%;
    width: 66.137566137%;
    height: 80.838323353%;
}

.PrimaryTheme .device-macbook::before, .PrimaryTheme .device-macbook > .device-screen {
    top: 11.53846154%;
    left: 13.38709677%;
    width: 73.548387096%;
    height: 73.076923076%;
}

.PrimaryTheme .device-combo {
    position: relative;
}

.PrimaryTheme .device-combo > .device {
    position: absolute;
}

.PrimaryTheme .device-combo-iphonex-iphonex {
    padding-bottom: 130.250482%;
}

.PrimaryTheme .device-combo-iphonex-iphonex > .device-iphonex:first-child {
    bottom: 0;
    left: 0;
    width: 65.5260116%;
    z-index: 1;
}

.PrimaryTheme .device-combo-iphonex-iphonex > .device-iphonex:last-child {
    top: 0;
    right: 0;
    width: 72.8323699%;
}

.PrimaryTheme .device-combo-iphonex-macbook, .PrimaryTheme .device-combo-macbook-iphonex {
    padding-bottom: 62.4260355%;
}

.PrimaryTheme .device-combo-iphonex-macbook > .device-macbook, .PrimaryTheme .device-combo-macbook-iphonex > .device-macbook {
    width: 91.7159763%;
}

.PrimaryTheme .device-combo-iphonex-macbook > .device-iphonex, .PrimaryTheme .device-combo-macbook-iphonex > .device-iphonex {
    width: 27.9585799%;
    z-index: 1;
}

.PrimaryTheme .device-combo-macbook-iphonex > .device-macbook {
    top: 0;
    left: 0;
}

.PrimaryTheme .device-combo-iphonex-macbook > .device-macbook {
    top: 0;
    right: 0;
}

.PrimaryTheme .device-combo-macbook-iphonex > .device-iphonex {
    bottom: 0;
    right: 0;
}

.PrimaryTheme .device-combo-iphonex-macbook > .device-iphonex {
    bottom: 0;
    left: 0;
}

.PrimaryTheme body.compensate-for-scrollbar {
    margin-right: 0 !important;
}

.PrimaryTheme .fancybox-container .fancybox-bg {
    background-color: rgba(255, 255, 255, 0.97);
}

.PrimaryTheme .fe-lg {
    font-size: 1.3125rem;
}

@media (min-width: 768px) {
    .PrimaryTheme .fe-lg {
        font-size: 1.125rem;
    }
}

.PrimaryTheme .bg-dark .footer-brand {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.PrimaryTheme .flickity-button {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: calc(1.6em + 1.625rem + 2px);
    height: calc(1.6em + 1.625rem + 2px);
    background-color: #335eea;
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05);
    color: #fff;
}

.PrimaryTheme .flickity-button::after, .PrimaryTheme .flickity-button::before {
    font-family: Feather;
}

.PrimaryTheme .flickity-button:focus, .PrimaryTheme .flickity-button:hover {
    background-color: #335eea;
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
}

.PrimaryTheme .flickity-button.previous {
    left: 0;
    -webkit-transform: translate(calc(-50% + 10px), -50%);
    transform: translate(calc(-50% + 10px), -50%);
}

@media (min-width: 768px) {
    .PrimaryTheme .flickity-button.previous {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.PrimaryTheme .flickity-button.previous::before {
    content: "\e910";
}

.PrimaryTheme .flickity-button.next {
    right: 0;
    -webkit-transform: translate(calc(50% - 10px), -50%);
    transform: translate(calc(50% - 10px), -50%);
}

@media (min-width: 768px) {
    .PrimaryTheme .flickity-button.next {
        -webkit-transform: translate(50%, -50%);
        transform: translate(50%, -50%);
    }
}

.PrimaryTheme .flickity-button.next::before {
    content: "\e912";
}

.PrimaryTheme .flickity-button-icon {
    display: none;
}

.PrimaryTheme .flickity-button-white .flickity-button {
    background-color: #fff;
    color: #335eea;
}

.PrimaryTheme .flickity-button-bottom .flickity-button {
    top: auto;
    bottom: 1.5rem;
    -webkit-transform: none;
    transform: none;
}

.PrimaryTheme .flickity-button-bottom .flickity-button.previous {
    left: auto;
    right: 6.375rem;
}

.PrimaryTheme .flickity-button-bottom .flickity-button.next {
    left: auto;
    right: 2.5rem;
}

.PrimaryTheme .flickity-button-inset .flickity-button {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.PrimaryTheme .flickity-button-inset .flickity-button.previous {
    left: 1.5rem;
}

.PrimaryTheme .flickity-button-inset .flickity-button.next {
    right: 1.5rem;
}

.PrimaryTheme .flickity-viewport-visible .flickity-viewport {
    overflow: visible;
}

.PrimaryTheme [data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.PrimaryTheme .hljs {
    background-color: transparent;
}

.PrimaryTheme .icon > svg {
    width: 3rem;
    height: 3rem;
}

.PrimaryTheme .icon[class*=text-] > svg [fill]:not([fill=none]) {
    fill: currentColor !important;
}

.PrimaryTheme .icon-xs > svg {
    width: 1.5rem;
    height: 1.5rem;
}

.PrimaryTheme .icon-sm > svg {
    width: 2.25rem;
    height: 2.25rem;
}

.PrimaryTheme .icon-lg > svg {
    width: 4rem;
    height: 4rem;
}

.PrimaryTheme .icon-xl > svg {
    width: 5rem;
    height: 5rem;
}

.PrimaryTheme .icon-circle {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 4.1875rem;
    height: 4.1875rem;
    border-radius: 50%;
}

.PrimaryTheme .icon-circle > .fe {
    font-size: 1.875rem;
}

.PrimaryTheme .list {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
}

.PrimaryTheme .list-item + .list-item {
    margin-top: .35rem;
}

.PrimaryTheme .list-link {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    align-items: center;
    font-size: .9375rem;
    color: #506690;
}

.PrimaryTheme .list-link:focus, .PrimaryTheme .list-link:hover {
    text-decoration: none;
    color: #335eea;
}

.PrimaryTheme .screenshot {
    border-radius: .625rem;
    box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1), 16px 40px 75px -40px rgba(0, 0, 0, 0.2);
}

.PrimaryTheme .section-border {
    position: relative;
}

.PrimaryTheme .section-border::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-width: 2px 0 0 0;
    border-style: solid;
    border-color: inherit;
    content: "";
}

.PrimaryTheme .shape {
    position: absolute;
    pointer-events: none;
}

.PrimaryTheme .shape > * {
    display: block;
}

.PrimaryTheme .shape:not([class*=shape-blur]) {
    overflow: hidden;
}

.PrimaryTheme .shape:not([class*=shape-blur]) > * {
    -webkit-transform: scale(2);
    transform: scale(2);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .PrimaryTheme .shape > svg {
        display: none;
    }
}

.PrimaryTheme .shape-top {
    top: 0;
    right: 0;
    left: 0;
}

.PrimaryTheme .shape-top > * {
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
}

.PrimaryTheme .shape-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.PrimaryTheme .shape-right > * {
    position: inherit;
    top: inherit;
    bottom: inherit;
    right: inherit;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.PrimaryTheme .shape-bottom {
    right: 0;
    bottom: 0;
    left: 0;
}

.PrimaryTheme .shape-bottom > * {
    -webkit-transform-origin: top center;
    transform-origin: top center;
}

.PrimaryTheme .shape-bottom-100 {
    right: 0;
    bottom: 100%;
    left: 0;
}

.PrimaryTheme .shape-bottom-100 > * {
    -webkit-transform-origin: top center;
    transform-origin: top center;
}

.PrimaryTheme .shape-left {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.PrimaryTheme .shape-left > * {
    position: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    -webkit-transform-origin: center right;
    transform-origin: center right;
}

.PrimaryTheme .shape-fluid-x > * {
    width: 100%;
    height: auto;
}

.PrimaryTheme .shape-fluid-y > * {
    width: auto;
    height: 100%;
}

.PrimaryTheme .shape-blur-1 {
    top: 0;
    right: 0;
    left: 0;
}

@media (min-width: 768px) {
    .PrimaryTheme .shape-blur-1 {
        bottom: 0;
        left: 50%;
        right: auto;
    }
}

.PrimaryTheme .shape-blur-1 > * {
    width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .PrimaryTheme .shape-blur-1 > * {
        width: auto;
        height: 100%;
    }
}

.PrimaryTheme .shape-blur-2 {
    top: 0;
    right: 0;
    left: 0;
}

@media (min-width: 768px) {
    .PrimaryTheme .shape-blur-2 {
        bottom: 0;
        right: 50%;
        left: auto;
    }
}

.PrimaryTheme .shape-blur-2 > * {
    width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .PrimaryTheme .shape-blur-2 > * {
        width: auto;
        height: 100%;
        float: right;
    }
}

.PrimaryTheme .shape-blur-3 {
    top: 0;
    right: 0;
    left: 0;
}

.PrimaryTheme .shape-blur-3 > * {
    width: 100%;
    height: auto;
}

.PrimaryTheme .shape-blur-4 {
    top: 0;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(1.3);
    transform: translateX(-50%) scale(1.3);
}

.PrimaryTheme .shape-blur-4 > * {
    width: auto;
    height: 100%;
}

@media (min-width: 992px) {
    .PrimaryTheme .sidenav {
        position: fixed;
        top: 84px;
        height: calc(100vh - 84px);
        overflow: auto;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .sidenav-left {
        left: 0;
    }
}

@media (min-width: 992px) {
    .PrimaryTheme .sidenav-right {
        right: 0;
    }
}

.PrimaryTheme .dimmer {
    position: relative;
}

.PrimaryTheme .dimmer.active .loader {
    display: block;
}

.PrimaryTheme .dimmer .loader {
    display: none;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
}
.PrimaryTheme .dimmer.active .dimmer-content {
    opacity: .02;
    pointer-events: none;
}

.PrimaryTheme .form-selectgroup {
    display: inline-flex;
    margin: 0 -.5rem -.5rem 0;
    flex-wrap: wrap;
}

.PrimaryTheme .form-selectgroup-item {
    display: block;
    position: relative;
}

.PrimaryTheme .form-selectgroup .form-selectgroup-item {
    margin: 0 .5rem .5rem 0;
}

.PrimaryTheme .form-selectgroup-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}

.PrimaryTheme .form-selectgroup-input:checked+.form-selectgroup-label {
    z-index: 1;
    color: #206bc4;
    background: rgba(32,107,196,.04);
    border-color: #7aade9;
    box-shadow: 0 1px 1px 0 rgba(32,107,196,.2);
}

.PrimaryTheme .form-selectgroup-label {
    position: relative;
    display: block;
    min-width: calc(1.4285714em + .875rem + 2px);
    margin: 0;
    padding: .4375rem .75rem;
    font-size: .875rem;
    line-height: 1.4285714;
    color: #6e7582;
    background: #fff;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid rgba(110,117,130,.2);
    border-radius: 3px;
    transition: border-color .3s,background .3s,color .3s;
}

.PrimaryTheme .form-select {
    display: block;
    width: 100%;
    height: calc(1.4285714em + .875rem + 2px);
    padding: .4375rem 1.75rem .4375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #354052;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid rgba(110,117,130,.2);
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.PrimaryTheme .round {
    position: relative;
}

.PrimaryTheme .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.PrimaryTheme .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.PrimaryTheme .round input[type="checkbox"] {
    visibility: hidden;
}

.PrimaryTheme .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.PrimaryTheme .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}